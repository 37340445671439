import EventEmitter     from "eventemitter3";
import DataTable        from 'datatables.net'
import { compareDates } from "../utils/base";
import UserModel        from "../models/user";

/**
 * List of user accounts
 *
 */
export default class UserList extends EventEmitter {
	constructor(BaseClass) {
		super();
		this.base = BaseClass;
		this.dataTable = null;

		this._initDatatable();
		this._initEvents();
	}

	_initEvents() {

	}

	/**
	 * Init DT
	 * Children: https://datatables.net/examples/api/row_details.html
	 * @returns {Promise<void>}
	 * @private
	 */
	async _initDatatable() {
		const data = await UserModel.getCollection()

		const format = (d) => {
			// `d` is the original data object for the row
			// console.log(d)
			let subscriptionsMarkup = '';
			d.subscriptions.map(s => {
				s.packages.forEach(st => {
					subscriptionsMarkup += `
					<tr>
						<td>${st.title}</td>
						<td>
							<progress value="${compareDates(new Date(), new Date(st.endDate))}" max="${compareDates(new Date(st.startDate), new Date(st.endDate))}"></progress>
						    &nbsp;${Math.max(0, compareDates(new Date(st.startDate), new Date(st.endDate)))} Tage
						</td>
						<td>
						Enddatum: ${st.endDate}
						</td>
					</tr>
					`
				})

			})

			return (
				`
				<table cellpadding="5" cellspacing="0" border="0" style="padding:0 0 0 50px; font-size: 12px;">
					<tr colspan="2">
						<strong>Pakete</strong>
					</tr>
					${subscriptionsMarkup}
				</table>`
			);
		}

		// ----- Datatable
		const dataTable = $("#omAccountsTable").DataTable({
			data: data,
			pageLength: 25,
			columns: [
				{
					className: 'dt-control',
					"render": function (data, type, row) {
						return `<strong>${row.firstName || ''} ${row.lastName || ''}</strong>`
					},
				},
				{
					data: 'role',
					render: function (data, type, row, meta) {
						return type === 'display'
							? data.label
							: data.id;
					}
				},
				{data: 'email'},
				// {
				// 	data: 'status',
				// 	render: function (data, type, row, meta) {
				// 		return type === 'display'
				// 			? data === 1 ? `<span uk-icon="check"></span>` : `<span uk-icon="close"></span>`
				// 			: data;
				// 	},
				// },
				{
					render: function (data, type, row) {
						return `<a href="/nutzer?id=${row.id}" uk-icon="icon: pencil"></a> &nbsp; &nbsp; <a href="" uk-icon="icon: trash"></a>`
					},
				},
			],
			language: {
				url: './web/assets/i18n/datatable.de.json'
			}
		});

		// Add event listener for opening and closing details
		$('#omAccountsTable tbody').on('click', 'td.dt-control', function () {
			let tr = $(this).closest('tr');
			let row = dataTable.row(tr);

			if (row.child.isShown()) {
				// This row is already open - close it
				row.child.hide();
				tr.removeClass('shown');
			} else {
				// Open this row
				row.child(format(row.data())).show();
				tr.addClass('shown');
			}
		});

		// Watch filters
		$('.uk-checkbox').on('change', () => {
			let searchTerms = [];
			// Roles
			$.each($('.filter-role'), function (i, elem) {
				if ($(elem).prop('checked')) {
					searchTerms.push("^" + $(this).val() + "$")
				}
			});
			dataTable.column(1).search(searchTerms.join('|'), true, false, true).draw();

			// Active
			let searchTermsActive = [];
			$.each($('.filter-active'), function (i, elem) {
				if ($(elem).prop('checked')) {
					searchTermsActive.push("^" + $(this).val() + "$")
				}
			});
			dataTable.column(3).search(searchTermsActive.join('|'), true, false, true).draw();
		});
	}
}
