export default class DimIcon {


	constructor(placeholder, position) {
		this.placeholder = placeholder
		$(placeholder).html(this.render(position))
	}

	render(position) {
		return `
			<svg class="o-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
			<path id="p1" class="st1 ${+position === 1 && 'active'}" d="M23.1,14.4V0H1C0.4,0,0,0.4,0,1v22.1h9.4C10.3,18.4,16,14.7,23.1,14.4z"/>
			<path id="p2" class="st1 ${+position === 2 && 'active'}" d="M24.9,14.4V0L47,0c0.6,0,1,0.4,1,1v22.1h-9.4C37.7,18.4,32,14.7,24.9,14.4z"/>
			<path id="p4" class="st1 ${+position === 4 && 'active'}" d="M24.9,33.5v14.4H47c0.6,0,1-0.4,1-1V24.8h-9.4C37.7,29.5,32,33.2,24.9,33.5z"/>
			<path id="p3" class="st1 ${+position === 3 && 'active'}" d="M23.1,33.5v14.4H1c-0.6,0-1-0.4-1-1l0-22.1h9.4C10.3,29.5,16,33.2,23.1,33.5z"/>
			<path id="p5" class="st1 ${+position === 5 || +position === 6 && 'active'}" d="M37,24c0,4.4-5.8,7.9-13,7.9S11,28.4,11,24s5.8-7.9,13-7.9S37,19.6,37,24z"/>
			</svg>
			<style>
			
				.st1{fill:#CCCCCC;}
				.active {fill:#CC0000;}
			</style>
		`;
	}

	update(position){
		$(this.placeholder).empty().html(this.render(position))
	}
}
