/**
 * Knowledge Component
 *
 * This can be STANDALONE or INCLUDED into the TASK view
 *
 */
import UIkit                                    from 'uikit';
import LibraryElement                           from "./components/library.element";
import KnowledgeModel                                    from "./models/knowledge";
import { eventList, getDateFormatted, omCodes, sumBits } from "./utils/base";
import Task                                              from "./models/task";
import Loader                                   from "./components/loader";
import EventEmitter                             from "eventemitter3";
import 'jquery.dirty'   // https://www.npmjs.com/package/jquery.dirty
import QRCode                                   from 'qrcode';
import Editor                                   from "./editor/editor";
import { parseParams }                          from "./utils/base";
import TaskModel                                from "./models/task";
import KnowledgeAssociations                    from "./components/knowledge.associations";
import DimIcon                                  from "./icons/dim.icon";
import KnIcon                                   from "./icons/kn.icon";


/**
 * Knowledge Class
 * @class
 * This allows CRUD operations (editing) for Knowledge Records
 * Due to "historical reasons" everything labelled KNOWLEDGE can be a lot of things
 * itemType 2 : Standard Knowledge
 * itemType 32: Hero,
 * itemType 8: Worksheet ("Arbeitsanregung")
 * ...it always helps to check the defines.php for a full list.
 */
export default class Knowledge extends EventEmitter {

	constructor(BaseClass) {
		super();
		// Inject the base class
		this.base = BaseClass;

		// DOM
		this.$OM_FORM = $('#f-om-knowledge');
		// Loader::
		this.loader = new Loader(`#f-om-knowledge`, {show: true});
		// Template to be @deprecated
		this.template = null;
		this.OM_Editor = null;
		// Library Elements on this page. Note: This is currently only the teaser image
		this.omLibElements = [];
		// Media Class (injected)
		this.OM_Media_Modal = this.base.OM_Media; //new Media({inline:true});
		// Associated data class (e.g. children and parent knowledges, tasks)
		this.OM_Associated = new KnowledgeAssociations();
		// Runtime
		this.initialised = false;   // Once the main editor is started, we're set
		// Task
		this.task = Task;   // This is the current TaskModel, holding the current task data. Will be overwritten @populate
		// Editor Class
		this.itemPos = null;        // Set when called from a task
		// @since 0.38.2 ~ auto-detect type by URL segment
		// This must be overwritten by any loaded record(!)
		const path = window.location.pathname
		switch (path) {
			case '/arbeitsblatt':
				this.setItemType(8);
				break;
			default:
				this.setItemType(2);
				break;
		}

		/// ------------------
		// This will initialise a new SINGLETON knowledge record
		// KnowledgeModel.create({itemType: this.getItemType().type});    // Initialise new data model

		// --------------
		// Parameters: Not all are required, no correct indication here.
		const params = parseParams(location.search);
		// @since 0.23 ~ viewId || itemId
		this.viewId = params.viewId || ''
		this.itemId = params.itemId || '';
		this.taskId = params.taskId;
		this.task = null
		this.position = params.position;        // Set when called from a task (create)
		this.parentId = params.parentId;    //quick fix to avoid trailing slashes
		// <-----

		// Icons
		// Dim Icon
		this.dimIcon = new DimIcon('[data-dynamic="kn.dim.icon"]', 0);
		// Wissensform Icon
		this.knIcon = new KnIcon('[data-dynamic="kn.itempos.icon"]', 0, 'kn');

		// Initialise
		const _init = async () => {
			this._initEvents();
			this._initUI();
			if (!this.viewId && !this.itemId)
				this.createKnowledge();
			else
				this.editKnowledge();
		}

		// If we have a taskId then load
		if (this.taskId)
			TaskModel.load(this.taskId).then((task) => {
				this.task = task;
				_init();
			})
		else
			_init();


	}

	_initUI() {
		// Hide parts, as per condition of itemType
		this.$OM_FORM.find(`[data-show-itemtype]`).map((i, t) => {
			let id = $(t).data('show-itemtype');
			if (id.includes(this.getItemType().type))
				$(t).show();
			else
				$(t).hide();
		});
		// Update dynamic labels
		$('[data-dynamic="page-subtitle"]').text(this.getItemType().label);
		if (!KnowledgeModel.get().itemId && !KnowledgeModel.get().viewId) {
			$('[data-dynamic="page-title"]').html(this.getItemType().label + ' anlegen ');
		} else {
			$('[data-dynamic="page-title"]').html(`Bearbeite &raquo;${ KnowledgeModel.get().title }&laquo;`);
		}
	}

	/**
	 * Returns an object with all info about the current type of record
	 * @return {{icon: string, id: string, label: string, type: number}}
	 */
	getItemType() {
		return this.base.getKNElementType($('input[name="itemType"]').val());
	}

	/**
	 * Sets the current itemType and updates the assoc input field
	 * @param type INT only
	 */
	setItemType(type, excludeSelector = false) {
		// Set selector field (2 || 32 - KN and HERO)
		if (!excludeSelector)	// if the select changes itself, it changes itself, it changes itself, it changes itself, it changes itself...
			$('select[name="itemTypeSelector"]').get(0).selectize.setValue(type);
		// Set input field
		$('input[name="itemType"]').val(type);
		// Show and hide what's to be seen
		this._initUI();
	}

	// ----------------------------------------------------------------------------------------------
	// CRUD
	// ----------------------------------------------------------------------------------------------
	/**
	 * Create or Edit Knowledge.
	 * @param param JSON object, expecting {taskId*, position*, itemId?, status?, itemPos?}
	 */
	editKnowledge() {
		// Set data according to the current edit mode
		// If we have a #viewId then add [position, itemPos, eduTasks]
		// If we have #itemId then we have less items

		// Hide small pre-modal
		if (this.omKnowledgeSelectModal) this.omKnowledgeSelectModal.hide();    // TODO: Doesn't belong here anymore, to TASK
		// Clear form and editor
		this._destroyEditor();
		// Clear input fields
		this.$OM_FORM.trigger('reset');

		// Try to load the knowledge record (the only way to find out if the param is okay)
		// EDIT mode: Load the specific record:
		KnowledgeModel.load(this.viewId, this.itemId, {showLoading: true})
		              .then(kn => {
			              // Make sure all fields missing are to default (DB should provide)
			              // @deprecated: set +1 year from now
			              // let date = new Date()
			              // date.setFullYear(date.getFullYear() + 1)
			              // Set null (i.e. 01-01-1900):
			              if (!kn.checkRemind || !kn.checkRemind.length) kn.checkRemind = "1900-01-01"
			              // console.info('Loaded ', kn)
			              // Populate main form fields
			              this._populate(kn);
		              })
		              .catch(error => {
			              // The knowledge record could not be loaded. So treat it as a new one
			              console.warn('Error loading ', error);
			              // (Way to improve, but for now its a:)
			              // simple clean reload, we assume all other params are ok
			              // window.location.href = `/wissensbeitrag/0/${param.taskId}/${param.position}`;
		              })
		              .finally(_ => {
			              // Generate QR, use frontend-url (once we have it!)
			              if (this.getItemType().type === 2)
				              QRCode.toDataURL(`https://omnimundi.de/wissensbeitrag/${ this.viewId || this.itemId }`)  // TODO: test
				                    .then(url => {
					                    $('.omfe-qr').empty().append(`<img src="${ url }"/>`)
				                    }).catch(err => {
					              console.error(err)
				              });

			              if (this.getItemType().type === 8)
				              QRCode.toDataURL(`https://omnimundi.de/arbeitsblatt/${ this.viewId || this.itemId }`)  // TODO: test
				                    .then(url => {
					                    $('.omfe-qr').empty().append(`<img src="${ url }"/>`)
				                    }).catch(err => {
					              console.error(err)
				              });

			              this.loader.hide();
		              })
	}

	/**
	 * Create a new knowledge record
	 * This will just load an empty model object and set this in memory.
	 * No API action yet.
	 */
	createKnowledge() {
		// Intercept when we do not have an associated task
		// Todo: give a select-option, because there may be orphaned records anyway!!!
		if (!this.task && (this.getItemType().type === 2 || this.getItemType().type === 8 || this.getItemType().type === 32)) {
			alert('Das geht so nicht. Wir brauchen eine Aufgabe dazu... erstmal...');
			return;
		}
		// Create an empty model
		KnowledgeModel.create();
		// Set dimension position, if in param (new records)
		KnowledgeModel.set({
			id:       null,
			itemType: this.getItemType().type,
			position: this.position,
			taskId:   this.taskId || null
		});
		// Reset form (but insert the position INT if set)
		this._populate(KnowledgeModel.get());
		this.loader.hide();
	}

	/**
	 * Save the knowledge
	 */
	save() {
		// ------------- Editor HTML fields -------------
		const editorContent = this.OM_Editor.getContent() && this.OM_Editor.getContent().data || null;    // Update event has already filled the input fields!
		// Todo: Remove this. A silly check for editor data. we do not want to overwrite stuff with empty
		if (!editorContent) {
			alert('Stop. Editor hat keinen Inhalt. Lege zumindest eine Komponente an.');
			this.base.emit('NAV_BUSY', false);
			return
		}

		// ------------- Standard Input fields -------------
		// Serialize all common form data
		let knData = {...this.base.getFormData($('#f-om-knowledge')), ...editorContent};    // Always set the type to 2 = knowledge (knowledgeSimple has own js)

		// Hack: delete some useless keys [Comes from LibraryElement, exclude these in base.parseForm)
		delete knData.h
		delete knData.sizes
		delete knData.w

		// Validate
		if (!this._validate(knData)) {
			this.base.emit('NAV_BUSY', false);
			return;
		}

		// -----------
		// Hotfix:
		knData = $('input[name="knowledgeItems[]"]').val().length ? {...knData, ...{knowledgeItems: $('input[name="knowledgeItems[]"]').val().split(',')}} : {...knData, ...{knowledgeItems: []}};
		// <----- // hackFixHotFixWhateverFix

		// console.log('Saving ', knData.libItems.split(','))
		// $('[name="eduTasks[]"]').get(0).selectize.setValue(_eduTasks);

		// Multi-Select schoolSubject are saved as Bits [ different to tasks api!!!!! :/ ]
		knData.schoolSubj = sumBits($('#om-select-schoolsubjects')[0].selectize.getValue());

		// No itempos? Sometimes it's like that
		// Todo: There are no positions 5 | 6 !
		if (!knData.itemPos) knData.itemPos = 1;

		KnowledgeModel.save(knData)
		              .then(r => {
			              // Reset dirty flag
			              this.$OM_FORM.dirty("setAsClean");   // refresh dirty state watcher
			              // Get the new record id (on create)
			              let newId = (r.data) ? r.data.itemId : null;
			              let newViewId = (r.data) ? r.data.viewId : null;
			              if (newId) {
				              // replace the location - to make sure we do not reCreate again
				              location.replace(`${ location.origin }${ this.getItemType().slugEdit }?viewId=${ newViewId }&itemId=${ newId }&taskId=${ this.taskId }`);
				              // Set to input
				              $('input[name=itemId]').val(newId);
			              }
		              })
		              .catch(e => {
			              this.base.emit('NAV_BUSY', false);
		              })
		              .finally(() => {
			              this.base.emit('NAV_BUSY', false);
		              });
	}

	/**
	 * Delete Knowledge
	 */
	delete(id) {
		// Confirm
		UIkit.modal.confirm('Willst du diesem Wissensbeitrag wirklich löschen?').then(function () {
			// Do
			// TODO: add loading overlay
			KnowledgeModel.delete(id).then(() => {
				// Mode may not be set, if called from outside.
				setTimeout(() => {
					// Forward to overview
					window.location = '/suche';
				});
			})
		}, function () {
			console.log('Rejected.')
		});
	}

	/**
	 * Update properties of one or multiple knowledge objects
	 * @param viewId ARRAY  - collection of viewIds (STRING)
	 * @param prop ARRAY - the properties to be set (INT - BITWISE)
	 */
	updateProps(viewId, prop) {
		KnowledgeModel.updateProps({viewId: viewId, prop: prop});
	}

	//--------------------------------------------------------------------------------------
	// Templating / Editor
	//--------------------------------------------------------------------------------------

	/**
	 * Set current main template, show and arrange associated fields
	 * This will preload field-sets for the editor (see templating.js)
	 * according to a) the itemType or b) selected template (KN type 2 only :: to be deprecated!)
	 * @deprecated
	 * @param id
	 */
	setTemplate(id) {
		this.template = id;
		// Init RTE // Unfortunately we need to destroy and reinit on every change WTF!!!?? TODO: one source of editor-problems
		if (this.OM_Editor) {
			this.OM_Editor.off('EDITOR_UPDATE', () => {
				console.info('Ed update')
			});
			this._destroyEditor();
		}
		this.OM_Editor = new Editor(this.base, '#om-editor-wrapper [data-name=content]', id, KnowledgeModel.get(), {hasTemplate: true});
		this.OM_Editor.once('EDITOR_READY', () => {
			// Re-Check visible areas (for editor)
			this._initUI();
			this._initEditorEvents();   // additional events
		})
		// Hook up events, again
		// If the editor changes some special fields (title, subtitle, teaser), then update the preview:
		this.OM_Editor.on('EDITOR_UPDATE', payload => {
			this._refreshPreviews();
			// just set form dirty ~~~ Form fields need to keep in sync
			if (this.initialised) {
				this.base.forceDirty();
			}
		});
		this.initialised = true;
	}

	/**
	 * Refresh all previews of teaser image, title, subtitle and teaser texts
	 * @private
	 */
	_refreshPreviews() {
		// Dynamic data (templating) ~ from input!
		$('[data-dynamic="title"]').text($('#f-om-knowledge [name="title"]').val());
		$('.teaser [data-dynamic="subTitle"]').text($('#f-om-knowledge [name="subTitle"]').val());
		$('.teaser [data-dynamic="teaser"]').html($('#f-om-knowledge [name="teaser"]').val());
		$('[data-dynamic="previewLink"]').html(`<a href="https://omnimundi.de/wissensbeitrag/${ $('#f-om-knowledge [name="itemId"]').val() }" target="_blank">Vorschau</a>`);
	}

	//--------------------------------------------------------------------------------------
	// Form / Data handling
	//--------------------------------------------------------------------------------------

	/**
	 * Populate the kn form with given data
	 * @param kn
	 * @private
	 */
	_populate(kn) {
		// If kn === null, then reset the form
		this.$OM_FORM.get(0).reset();

		// -------------
		// Populate form
		this.base.populate(this.$OM_FORM, kn, false);    // Populate form inputs with fresh data

		// Set type
		this.setItemType(kn.itemType);

		// ------------------------------
		// Initialise the teaser IMG (if not in template)
		let _teaserImgId = (kn.teaserImg) ? kn.teaserImg : {id: null, libType: 1};
		// NOTE: This is a specific teaserElement OUTSIDE of the editor.
		// this._updateLibElement(_teaserImgId, '.kn-teaser--container, .teaser--mini-inner', {input: 'teaserImg'});    // we could use 2 selectors, but the input would double
		this._updateLibElement(_teaserImgId, '#teaserImg', {input: 'teaserImg'});
		// Mini Teaser
		// this._updateLibElement(_teaserImgId, '.teaser--mini-inner', {cropped: true, meta: false, removeable: false});

		// -------------------------------
		// Get template from content field
		let template = 'default';

		// @since 0.43.n - we use the template for other types of kn too
		// Unify this with the redundant glossary.js class (!)
		switch (kn.itemType) {
			case 8:
				template = 'worksheet'
				break;
			default:	//
				template = (kn.content && kn.content.card) ? kn.content.card.template : 'default';
				break;
		}

		// The template selector is @deprecated!
		// Hide, if this KN does not have a template set || new
		if (kn.content?.card?.template && kn?.content?.card?.template !== 'default') {
			setTimeout(() => $('[data-show="ifTemplate"]').show(), 100)
		}

		$('[name="tpl"]').get(0).selectize.setValue(template);

		// ---------------
		// Set eduTasks []
		let _eduTasks = kn.eduTasks ? kn.eduTasks.map(r => r.id) : null;
		$('[name="eduTasks[]"]').get(0).selectize.setValue(_eduTasks);

		// --------------
		// Set schoolSubj | done in baseJS
		//$('#om-select-schoolsubjects')[0].selectize.setValue(kn.schoolSubj)

		// Refresh STATUS selectize dropdown
		// -------------------------
		// Set Status (Nav)
		this.base.NAVIGATION.setStatus(kn.status);

		// Position (readOnly)
		$('#f-om-knowledge input[name="position"]').val(kn.position);

		// If there is a viewId, we have associated data, show it
		// The following link prefix will try to insert dev-server uri. NOTE: Adjust first arg to your needs!
		const urlPrefix = process.env.NODE_ENV === 'development' ? 'http://localhost:8081' : 'https://omnimundi.de';
		if (this.viewId || this.task) {
			// If viewId, then set it
			$('input[name="viewId"]').val(this.viewId);
			// Set KnowledgeForm (itemPos) value
			const _itemPos = kn.itemPos ? kn.itemPos : this.position;   // lets just match if not set
			$('#om-select-knowledge1')[0].selectize.setValue(_itemPos);
			// Create preview link:
			$('[data-dynamic="preview-link"]').attr('href', `${ urlPrefix }${ this.getItemType().slug }/${ this.viewId }/${ this.task.id }`);
		} else {
			// Disable all elements that need viewId
			// Disable Wissensform DD
			$('#om-select-knowledge1')[0].selectize.disable();
			// Disable Edutasks
			$('select[name="eduTasks[]"]')[0].selectize.disable();
			// Create preview link:
			$('[data-dynamic="preview-link"]').attr('href', `${ urlPrefix }${ this.getItemType().slug }/${ kn.itemId }`);
		}

		// ----------------------------
		// Knowledge Parent
		// Todo: This throws a DB error.
		if (this.parentId)
			$('input[name="parentId"]').val(this.parentId);

		// ----------------------------
		// Tasks
		// Either get the associated task from URI
		// Or offer a list of connected tasks to chose from
		// If no task exists
		const $OM_TASKLIST = $('[data-dynamic="tasklist"]');
		if (kn?.tasks?.length) {
			// Hide Backlink
			$('[data-dynamic=backlink]').hide();
			// Show all associated tasks for selection:
			$('[data-dynamic="tasklist"]').empty();
			// Add all available tasks
			kn.tasks.forEach((t) => {
				if (this?.task?.id === t.id)
					$OM_TASKLIST.prepend(`<li style="border-left:5px solid;display:inline-flex;">
					<strong style="padding-left:0.5em;">${ t.title }</strong>
					</li><li style="margin:1em 0;"><h6>Weitere Aufgaben</h6></li>`);
				else
					// This jumps directly to the task
					// $OM_TASKLIST.append(`<li style="display:inline-flex;">
					// 	<a href="/aufgabe/${ t.id }#dimensionierung"><span style="padding-left:0.5em;">${ t.title }</span></a></li>`);
					// This will reload the KN with taskId
					$OM_TASKLIST.append(`<li style="display:inline-flex;">
					<a href="${ location.pathname }?itemId=${ kn.itemId }&taskId=${ t.id }"><span style="padding-left:0.5em;">${ t.title }</span></a>
					&nbsp;<a href="/aufgabe/${ t.id }#dimensionierung" style="margin-left:1.5em;font-size: 11px;"><span >(&#8599; zur Aufgabe)</span></a>
					</li>`);
			});
		} else {
			// A task is given (e.g. new record)
			if (this?.task) {
				$OM_TASKLIST.empty()
				            .prepend(`<li style="border-left:5px solid;display:inline-flex;">
								<strong style="padding-left:0.5em;">${ this.task.title }</strong>
							</li>`);
			} else {
				// 3. No task connection at all
				// Todo: preset position to 5 or 6... and itemPos to the same
				$OM_TASKLIST.empty()
				            .html(`Dieser Datensatz ist mit keiner Aufgabe verbunden. 
			Hier gibt es noch Arbeit zu tun.`);
			}
		}

		// Is a current task selected?
		if (this?.task) {
			// BackLinks
			$('[data-dynamic=backlink]').show().attr('href', `/aufgabe/${ this.task.id }#dimensionierung`);
			// Title
			$('[data-dynamic="task.title"]').text(this.task.title);
		}

		// ----------------------------
		// Task collection
		// This is interesting if the knowledge has been loaded without a viewId
		// We can show all associated tasks
		this.OM_Associated.showAssocTasks(kn);

		// ----------------------------
		// Populate child list
		this.OM_Associated.showAssocKnowledges(kn);

		// ----------------------------
		// Dim & Wissensform
		this._updateItemPosIcon(kn.itemPos, kn.position)

		// ----------------------------
		// Create main editor
		// Populate and open template
		this.setTemplate(template, kn)

		// Refresh previews
		this._refreshPreviews()

		// Reset dirty flag
		setTimeout(() => {
			this.$OM_FORM.dirty("setAsClean");   // refresh dirty state watcher
		}, 300);
	}

	/**
	 * Validate input fields
	 *
	 * @param formData collected form data
	 * @return {boolean}
	 * @private
	 */
	_validate(formData) {
		let valid = true, errors = [];
		if (!formData.title.length) {
			valid = false;
			errors.push('Bitte gib einen Titel an.');
		}
		// valid = false
		if (!valid) {
			UIkit.notification({
				message: `Achtung: ${ errors.join('<br/>') }`,
				status:  'warning',
				pos:     'top-right',
				timeout: 5000
			});
		}

		return valid;
	}

	/**
	 * Set or update a LibraryElement (TeaserImg only at this stage) -- make val[key] store to switch multiple?
	 * This is too much code. Now we can use global (see index.js) events and placeholders!
	 * @param media JSON optional, lib object
	 * @param placeholder STRING dom element to insert the LibElement
	 * @param input STRING with input name. Not needed within templates
	 * @private
	 */
	_updateLibElement(media, placeholder, options = {}) {
		// IMPROVE: this is old code and too much fuss. Integrate it into libElements
		let _options = {
			...{
				mode:          'cropped',
				showAddButton: true,
				caption:       true,
				editable:      false,
				selectable:    false,
				deleteable:    false,
				removeable:    true,
				size:          $(placeholder).data('size'),
				allowed:       $(placeholder).data('allowed'),	// read from html tag
				input:         null,     // Note: this should not be null once we select media!
			}, ...options
		};
		// placeholder is used as key for the array of LibElements (reuse, recycle)
		// THIS IS SUPERFLOUS::: Just 1 image!!!! :DDDD
		let current = this.omLibElements[placeholder];
		// if (_options.input === null) {
		// 	console.warn(`No input ID given. The element ${placeholder} will not be processed & saved!`);
		// }

		if (current) {
			current.setData(media, false);  // update the data of an existing LibraryElement
			// Mini teaser (exception) - a hack!
			if (placeholder === '.kn-teaser--container')
				this.omLibElements['.teaser--mini-inner'].setData(media, false);
		} else {
			// Create a new LibraryElement
			this.omLibElements[placeholder] = new LibraryElement(this.base, placeholder, media, _options);

			// Gotta watch the SELECTION event to change some extra things here
			this.base.once(eventList.MEDIA_SELECTION, (payload) => {
				let {mediaArr} = payload;
				// Mini teaser
				if (placeholder === '#teaserImg')
					this.omLibElements['.teaser--mini-inner'].setData(mediaArr[0]);
				// Update our special field (worksheets)
				// if (this.getItemType().id === 'worksheet') {
				// $('[data-dynamic="worksheet-file-title"]').text(payload.title);
				// $('[data-dynamic="worksheet-file-descr"]').text(media.descr);	// Todo: @henry. needed.
				// }
				this.base.forceDirty();
			});
			//
			// The MEDIA REMOVAL event will trigger some extra changes too
			this.omLibElements[placeholder].on(eventList.MEDIA_REMOVE, () => {
				// remove the ID from wrapping dom element / placeholder
				$(placeholder).attr('data-id', null);   // remove id??????
				if (placeholder === '#teaserImg')
					this.omLibElements['.teaser--mini-inner'].setData(null, false);
				// Worksheet
				// if (this.getItemType().id === 'worksheet')
				// 	$('[data-dynamic="worksheet-file-title"]').text('Wähle ein Arbeitsblatt-Dokument');
				this.base.forceDirty();
			});

			// MEDIA ADD event -- placeholder only!
			// This is silly code just for ONE image and updates the mini-preview.
			if (placeholder === '#teaserImg') {
				this.omLibElements[placeholder].on(eventList.OPEN_MEDIA_LIBRARY, (payload) => {
					this.base.emit('OPEN_MEDIA_LIBRARY', {
						placeholder: placeholder,
						options:     {
							stack:         true,
							readOnly:      true,
							selectionMode: true,
							types:         this.omLibElements[placeholder].getAllowedTypes()
						}
					});
					this.base.forceDirty();
				});
			}
		}
	}

	/**
	 * Just update / rotate the dimensioning indicator icon
	 * when a new itempos is selected
	 * @param itemPos
	 * @param position
	 * @private
	 */
	_updateItemPosIcon(itemPos, position) {
		const dimensions = ['', 'Konzepte', 'Orientierungen', 'Strategien', 'Handlungsmuster']
		$('[data-dynamic="kn.dim"]').text(dimensions[position]);
		$('[data-dynamic="kn.itempos"]').text(dimensions[itemPos]);
		// Dim Icon
		this.dimIcon.update(position);
		// Wissensform Icon
		this.knIcon.update(itemPos, 'kn');
	}

	/**
	 * Generate googleDoc download links
	 * https://www.labnol.org/internet/direct-links-for-google-drive/28356/
	 * Viewer: https://docs.google.com/viewer?url=FILE_URL
	 * Copy-link: https://www.howtogeek.com/442535/how-to-share-make-a-copy-links-to-your-google-files/
	 * NOTE: See editor-wsLink branch on how to integrate as editor-component
	 * @param uri
	 * @private
	 */
	_getGoogleDocId(uri) {
		const googleDocIdRegex = /\/(document|presentation)\/d\/([\w-]+)/;
		let googleDocId;
		let type;
		let hrType;
		$('#wsLink').removeClass('uk-form-success');
		$('#wsLink-results').empty();
		if (!uri) return;
		try {
			const match = uri.match(googleDocIdRegex);
			if (match) {
				type = match[1] === "document" ? "document" : "presentation";
				hrType = match[1] === "document" ? "Dokument" : "Presentation";
				googleDocId = match[2];
			}
		} catch (e) {
			// warn
			$('#wsLink').addClass('uk-form-error');
			throw (e)
		}
		// If ok, highlight:
		$('#wsLink').addClass('uk-form-success')
		// Note: This is not yet needed.
		// $('input[name="docLinkId"]').val(googleDocId); // this is for the editor to store
		// Fill results
		$('#wsLink-results-title').text(hrType);
		$('#wsLink-results').empty();
		// Create DL links for testing:
		let formats = type === 'document' ? ['pdf', 'doc', 'rtf', 'odt'] : ['pdf', 'pptx', 'odp'];
		formats.forEach((fmt) => {
			let dl = `https://docs.google.com/${ type }/d/${ googleDocId }/export?format=${ fmt }`;
			$('#wsLink-results').append(`<li style="margin-top:0;"><a href="${ dl }" uk-icon="icon: cloud-download" class="om-tag om-file--dl ${ fmt }" target="_blank" download>&nbsp;${ fmt }</a></li>`);
		});
	}

	//--------------------------------------------------------------------------------------
	// Events
	//--------------------------------------------------------------------------------------
	/**
	 * Initialise event listeners
	 * Note that we do not remove EL as the lifetime of this ends with page refresh / redirect
	 * @private
	 */
	_initEvents() {
		// Watch form state and apply to navigation
		this.$OM_FORM.dirty({
			onDirty: () => {
				this.base.emit(eventList.NAV_DIRTY, true);
				//Get array of dirty elements: maybe use base to set uk-danger? this.$OM_FORM.dirty("showDirtyFields");
			},
			onClean: () => {
				this.base.emit(eventList.NAV_DIRTY, false);
			}
		});

		// Stop nav
		window.addEventListener("beforeunload", (e) => {
			if (this.$OM_FORM.dirty("isDirty")) {
				e.preventDefault();
				// Note: No browser will do this anymore
				e.returnValue = 'Der Datensatz wurde geändert. Willst du die Seite wirklich verlassen?';
				;
			}
		});

		// Events & Routing
		$(document).on('click', "[data-action='knowledge']", (e) => {
			const $target = $(e.currentTarget);
			let command = $target.data('command');
			let param = $target.data('param');
			switch (command) {
				case 'save' :
					this.save();
					break;
				case 'saveClose' :
					this.save(true);
					break;
				// case 'delete' :
				// 	// this.delete();
				// 	break;
			}
		});

		// Direct input events (data-<listener>)
		// We're watching changes to template and itemPosition (wissensform) to update template/icons
		// https://stackoverflow.com/questions/20447104/google-apps-script-how-to-parse-a-link-and-extract-the-document-id
		$('[data-on-change]').on('change', (e) => {
			const cmd = $(e.target).data('on-change'),
			      val = $(e.target).val();
			switch (cmd) {
				case 'setTemplate':
					if (this.initialised)
						this.setTemplate(val);
					break;
				case 'setItemPos':
					this._updateItemPosIcon(val, KnowledgeModel.get().position);
					break;
			}
		});

		// Custom type selector, only in 2 and 32
		$('select[name="itemTypeSelector"]').on('change', (e) => {
			this.setItemType($(e.target).val(), true);
		})


		// STANDALONE mode events
		/**
		 * Nav Events
		 */
		this.base.NAVIGATION.on(eventList.NAV_STATE, (payload) => {
			$('input[name=status]').val(payload);
			this.base.forceDirty();
		});
		this.base.NAVIGATION.on(eventList.NAV_SAVE, () => {this.save();});
		this.base.NAVIGATION.on(eventList.NAV_DELETE, () => {
			// get itemId from Input field
			this.delete($('[name="itemId"]').val());
		});
	}

	/**
	 * Events to be initialised AFTER Editor has been initialised
	 * This is usually for editor-rendered HTML
	 * (see GDoc-Links and Title, teaser, etc.)
	 * @private
	 */
	_initEditorEvents() {
		$('[data-on-change]').on('change', (e) => {
			const cmd = $(e.target).data('on-change'),
			      val = $(e.target).val();
			switch (cmd) {
				case "wsLink":
					this._getGoogleDocId(val);
					break;
			}
		});
		// Click action for GDoc URI
		$('[data-action="wsLink"]').on('click', (e) => {
			this._getGoogleDocId($('#wsLink').val());
		});
		// Set GoogleDoc Link, if any.
		if (KnowledgeModel.get().wsLink) {
			this._getGoogleDocId(KnowledgeModel.get().wsLink);
		}
	}

	//--------------------------------------------------------------------------------------
	// Destroy that thing
	//--------------------------------------------------------------------------------------

	_removeEvents() {
		window.removeEventListener('beforeunload', (e) => {});
		$(document).off('click', "[data-action='knowledge']", () => {});
		$('[data-on-change]').off('change', () => {});
		this.base.NAVIGATION.off(eventList.NAV_SAVE, () => {});
		this.base.NAVIGATION.off(eventList.NAV_DELETE, () => {});
		this.base.NAVIGATION.off(eventList.NAV_STATE, () => {});
	}

	/**
	 * Destroy the RTE
	 * @private
	 */
	_destroyEditor() {
		if (this.OM_Editor) {
			this.OM_Editor.off('EDITOR_UPDATE', () => {});
			this.OM_Editor.off('EDITOR_OPEN_LIB', () => {});
			this.OM_Editor.destroy();
		}
		this.initialised = false;
	}

	/**
	 * Destroy is usually not needed because
	 * we reload the whole location on navigation
	 */
	destroy() {
		this._destroyEditor();
		this._removeEvents();
	}

}
