import EventEmitter     from "eventemitter3";
import DataTable        from 'datatables.net'
import { compareDates } from "../utils/base";
import CodeModel        from "../models/codes";


export default class CodesList extends EventEmitter {
	constructor(BaseClass) {
		super();
		this.base = BaseClass;
		this.dataTable = null;

		this._initDatatable().then(() => {
			this._initEvents();
		});
	}

	_initEvents() {

		// Filters
		// Custom filtering function which will search data in column four between two values
		$.fn.dataTable.ext.search.push(
			function (settings, data, dataIndex) {
				let min = new Date();
				let date = new Date(data[2]);

				if (min <= date && $('.filter-active').prop('checked')) {
					return true;
				} else if (
					!$('.filter-active').prop('checked')
				) return true
				return false;
			}
		);

		// Check user filters
		$('.uk-checkbox').on('change', () => {
			this.dataTable.draw();
		});
		$('.uk-checkbox').trigger('change');

		// Delete action
		$('[data-command="delete"]').on('click', (e) => {
			e.preventDefault();
			CodeModel.delete($(e.currentTarget).data('param'));
		})
	}

	/**
	 * Initialise DT.
	 * @returns {Promise<void>}
	 * @private
	 */
	async _initDatatable() {
		const data = await CodeModel.loadCollection();

		this.dataTable = $("#omCodesTable").DataTable({
			data: data,
			pageLength: 25,
			columns: [
				{data: 'label',
					"render": function (data, type, row) {
						return `
						<a href="/code?id=${row.id}" >${data}</a>`
					}},
				{data: 'startDate'},
				{data: 'endDate'},
				{
					// show progress nearing end of expiry.
					data: 'endDate',
					render: function (data, type, row, meta) {
						let duration = compareDates(new Date(row.startDate), new Date(data));   // days of validity
						let daysLeft = Math.max(0, compareDates(new Date(), new Date(data)));
						return type === 'display'
							? `<progress value="${daysLeft}" max="${duration}"></progress>`
							: daysLeft
					},
				},

				{
					// Show amount left
					data: 'amount',
					render: function (data, type, row, meta) {

						return type === 'display'
							? `<progress value="${row.amountAvailable}" max="${row.amount}"></progress>`
							: data;
					},
				},
				{data: 'id'}, // the code itself
				{
					"render": function (data, type, row) {
						return `
						<a href="/code?id=${row.id}" uk-icon="icon: pencil"></a> &nbsp; &nbsp; 
						<a href="javascript:void(0);" uk-icon="icon: trash" data-param="${row.id}" data-command="delete"></a>`
					},
				},
			],
			language: {
				url: './web/assets/i18n/datatable.de.json'
			},
			initComplete: function (settings, json) {
				// Done.
				return true;
			}
		});


	}
}
