import UIkit                  from 'uikit';
import EventEmitter           from "eventemitter3";
import { omCodes, eventList } from "./base";
import UserModel              from "../models/user";
// import { observable, observe, isObservable } from "simple-observer";


export default class Navigation extends EventEmitter {
	constructor(base) {
		super();

		this.base = base;

		// ---------------------------------
		// DomElements
		this.$stateDD = $('#navmeta-status');
		this.$stateLabel = $('#navmeta-status-label');   // The label of the state

		// ---------------------------------
		// Status: This is set and managed by calling classes, e.g. task, knowledge, worksheet
		// Because the NAV provides a global dropdown for this.
		this.status = 1;
		// this.state = {
		// 	status: 2,          // Current publication state (can be KNOWLEDGE or TASK) and will be overwritten by those classes!
		// 	dirty: false};      // If record is dirty, highlight save button

		// Reset busy flag
		this.toggleBusy(false);

		// Initialise
		this._initEvents();
	}

	/**
	 * Set navigation options, triggered by components.
	 * Currently a simple check if DIRTY
	 * TODO: check if ID (editmode) and delete needs to be shown
	 * @param opts
	 */
	setOptions(opts) {
		if (opts.dirty) {
			$("#om-meta--save a").addClass('uk-text-danger')
			$("#om-meta--save a small").text('SPEICHERN')
		} else {
			$("#om-meta--save a").removeClass('uk-text-danger')
			$("#om-meta--save a small").text('SPEICHERN')
		}

	}

	/**
	 * Set navigation status.
	 *
	 * @param status INT
	 */
	setStatus(status) {
		let _status = omCodes.status.filter(s => s.id === +[status])[0];
		this.status = status;
		if (_status) {
			this.$stateLabel.text(_status.title.toUpperCase());
			// DOM Highlight
			$('#navmeta-status li a').removeClass('active')
			$(`#navmeta-status li a[data-payload="${status}"]`).addClass('active');
		} else
			this.$stateLabel.text('unbekannt');

	}

	/**
	 * Return Status
	 * Note: Do not use this to retrieve a record's status
	 * because we decided to use INPUT fields for everything (except editor comps)
	 * we do not rely on memory variables. So watch the events - See: tasks.js ~L:1425ff
	 * and here L:123f
	 * @returns INT
	 */
	getStatus() {
		console.warn('DO NOT USE THIS to retrieve the record status. Use events!');
		return this.status;
	}

	toggleBusy(busy) {
		if (busy)
			$('#mainnav').addClass('busy');
		else
			$('#mainnav').removeClass('busy');
	}

	toggleDirty(dirty) {
		if (dirty)
			$('#mainnav').addClass('dirty');
		else
			$('#mainnav').removeClass('dirty');
	}


	// ------------------------------------------------------------------------------------
	// Private
	// ------------------------------------------------------------------------------------

	_initEvents() {

		/**
		 * Navigation buttons (default meta) will trigger actions.
		 * This will emit the events across the app
		 */
		$('a[data-action="nav"]').on('click', (e) => {
			let command = $(e.currentTarget).data('command');
			switch (command) {
				case 'save' :
					this.emit(eventList.NAV_SAVE);
					break;
				case 'cancel' :
					this.emit(eventList.NAV_CANCEL);
					break;
				case 'delete' :
					this.emit(eventList.NAV_DELETE);
					break;
				case 'state' :
					let val = $(e.currentTarget).data('payload');
					// Close
					UIkit.dropdown(this.$stateDD).hide();
					// Set
					this.setStatus(val);
					// Emit Action to whoever is interested (!)
					this.emit(eventList.NAV_STATE, val);
					break;
				case 'logout' :
					UserModel.logout();
					break;
			}
		});

		// Watch dirty events from forms
		this.base.on(eventList.NAV_UPDATE, payload => {
			this.setOptions(payload);
		})
	}


}
