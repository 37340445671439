const LibraryModel = {


	// This is not private
	collection: [],   // current collection
	params: null,       // current search params


	/**
	 * Get the
	 * See MEDIA API Docs for params
	 *
	 * @param params
	 * @returns {Promise}
	 */
	getCollection(params) {
		return new Promise((resolve, reject) => {
			// return a cached version unless params changed
			if (params !== this.params && this.params)
				resolve(this.collection)
			else
				window.omApi.getMedia(null, params)
				.then((result) => {
					this.collection = result.data
					resolve(result.data)
				})
		})
	},

	set(any) {
		throw ('No setter allowed');
	}


}

export default LibraryModel;
