import EventEmitter   from "eventemitter3";
import Slideshow      from "./components/affection/slideshow";
import SplitImage     from "./components/affection/splitimage";
import LibraryElement from "./components/library.element";

/**
 * @component
 * Affection Wrapper Component.
 *
 * Allows to select different types of affection components
 * It will load and switch e.g. slideshow || collage (all we got for now @0.74.x)
 * Gets, decodes and encodes affectionData (contentAff) from/to base64
 * Todo: Since we added multiple affection component, this is an anti-pattern. It should be merged with the AFFECTION class!
 *
 * @emits
 *
 * @listens
 * OPEN_MEDIA_LIBRARY
 *
 * @dataStructure
 *
 * {
 *  template: 'xx'                      // id of the affection component = slideshow || splitimage
 *  components: [
 *      { le object },                  // collection of library elements
 *      ..
 *  ],
 *  podcasts: [
 *      { le object }                   // collection of library elements
 *  ]
 * }
 *
 */
export default class AffectionWrapper extends EventEmitter {
	constructor(BaseClass) {
		super();

		this.base = BaseClass;
		this.OM_Media_Modal = this.base.OM_Media;

		// Main data object | default
		this._data = {
			template: 'affection_2',		// determines the MAIN COMPONENT to use affection_1 (= slideshow) || affection_2 (= image collection)
			components: [],
			podcasts: []
		}

		// Current component (from affection selector)
		this.currentComponent = null;

		// PODCAST Elements ------
		// The podcast elements are enumerated -> 4
		// They are just library elements that can be selected / removed
		// and always appear to a task, if set
		// They belong to the affection -- sort of.
		this.podcasts = [];
		this.podcasts[0] = new LibraryElement(this.base, '#podcast-video', null, {
			showAddButton: true,		// The (+) button to add a new
			mode: 'box',
			meta: true,
			title: false,
			editable: false,
			selectable: false,
			deleteable: false,
			removeable: true,
			allowed: ['video'],
			size: 'medium',
			options: {
				selectionMode: true,
				multiple: false
			},
			input: null
		});
		this.podcasts[1] = new LibraryElement(this.base, '#podcast-audio', null, {
			showAddButton: true,		// The (+) button to add a new
			mode: 'box',
			meta: true,
			title: false,
			editable: false,
			selectable: false,
			deleteable: false,
			removeable: true,
			allowed: ['audio'],
			size: 'medium',
			options: {
				selectionMode: true,
				multiple: false
			},
			input: null
		});
		this.podcasts[2] = new LibraryElement(this.base, '#podcast-event', null, {
			showAddButton: true,		// The (+) button to add a new
			mode: 'box',
			meta: true,
			title: false,
			editable: false,
			selectable: false,
			deleteable: false,
			removeable: true,
			allowed: ['document'],
			size: 'medium',
			options: {
				selectionMode: true,
				multiple: false
			},
			input: null
		});
		this.podcasts[3] = new LibraryElement(this.base, '#podcast-talk', null, {
			showAddButton: true,		// The (+) button to add a new
			mode: 'box',
			meta: true,
			title: false,
			editable: false,
			selectable: false,
			deleteable: false,
			removeable: true,
			allowed: ['audio', 'video'],
			size: 'medium',
			options: {
				selectionMode: true,
				multiple: false
			},
			input: null
		});

	}

	/**
	 * Initialise event listeners
	 * Listen for changes of template (which type of Affection -> n)
	 * @private
	 */
	_initEvents() {
		this._removeEvents()
		// Watch onChange events
		// NOTE: this will trigger whenever a setValue() is executed!!!
		$(document).on('change', "[data-on-change]", (e) => {
			const $target = $(e.target),
				command = $target.data('on-change');

			switch (command) {
				case "setTemplate":
					if ($target[0].selectize.getValue())
						this.setComponentTo($target[0].selectize.getValue())
					break;
				default:
					break;
			}
		});

	}

	/**
	 * Kill event listeners
	 * @private
	 */
	_removeEvents() {
		try {
			$('[data-on-change]').off('change');
		} catch (e) {
			console.warn('No events to remove');
		}
	}

	/**
	 * Set current data
	 * @param data
	 */
	setData(data) {
		// Make sure that defaults are set at least.
		this._data = {...this._data, ...data};    // Assign new data
		// Set selector to selected type of affection
		if ($('#template').length) $('#template')[0].selectize.setValue(this._data.template);
		this.setComponentTo(this._data.template); // will set the current component and call populate
		// Init events
		this._initEvents();
	}

	/**
	 * Get current data
	 * @returns JSON
	 * {
	 *     type: 'nameofcomponent',     // id of component (currently only slideshow)
	 *     data: [{}],                  // data as per spec of the component
	 *     libItems: []                 // array of the libraryItemIDs
	 * }
	 */
	getData() {
		// Initial data object: with template
		this._data = {...this._data, ...{template: $('#template')[0].selectize.getValue()}};

		// Mixin the podcast (across all types of affection)
		let podcasts = []
		podcasts.push(this.podcasts[0].getData())
		podcasts.push(this.podcasts[1].getData())
		podcasts.push(this.podcasts[2].getData())
		podcasts.push(this.podcasts[3].getData())
		this._data.podcasts = podcasts

		// Mixin the current data of the component
		// NOTE: To enable multiple components in affection, add some logic here. Else it will be only components[0]
		this._data.components[0] = this.currentComponent.getData(true);

		return this._data;
	}

	/**
	 * Return an array of library elements (data)
	 * Expects the current selected component (collage, slideshow, ...) to provide the interface .getLibItems()
	 * See comps "@extends Affection" - they must extend the abstract class (Affection)
	 * @returns {*|*[]}
	 */
	getLibItems() {
		return this.currentComponent.getLibItems();
	}

	/**
	 * Switch the loaded components
	 * @param template STRING enum | there are (2) templates: slideshow && left + right
	 * @private
	 */
	setComponentTo(template) {
		if (this.currentComponent)
			this.currentComponent.destroy()

		// Slideshow
		if (template === 'affection_1') {
			this.currentComponent = new Slideshow(this.base, '#om-affection-wrapper', null, {isResizeable: false, hasCaption: false, isFull: true, inlineMode: true})
		} else {
			// 3 Images
			this.currentComponent = new SplitImage(this.base, '#om-affection-wrapper', null)
		}
		// Execute data refresh anyway
		this.populate(this._data)	// Refresh the data from task again (see if it works...)

	}

	/**
	 * Populate podcasts && current component
	 * @param data
	 */
	populate(data) {
		// Fill podcasts
		this.podcasts[0].setData(data.podcasts[0])
		this.podcasts[1].setData(data.podcasts[1])
		this.podcasts[2].setData(data.podcasts[2])
		this.podcasts[3].setData(data.podcasts[3])

		// Component data
		// this.currentComponent.setData(data.components[0]);  // component_4 always. NOTE: To use multiple comps, add logic here
		// Fill data in
		if (data.components)
			// Note: this loop is not really necessary because there are no multi-components in affection
			// However, the same components are used in the editor that has multiple items
			// This means we need to loop through this here as well.
			data.components.forEach((cmp, i) => {
				// Some MAIN components share the same json structure - pics and videos mainly
				switch (cmp?.template) {
					case 'component_4': // Slideshow || Multi-image / Videos
						this.currentComponent.setData(data.components[0]);
						break;
					default:
						break; // nothing. Extend this with other components IF we ever need this.
				}
			})
	}


}
