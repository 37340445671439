// This is the minimum model for POST requests, refer to :https://docs.google.com/document/d/1myvX9dF_aR480rDHWP-Dg9FEF53ST_HHMqJUhrfoysk/edit#
// ACTUALLY this is no the libElement model but the media-metadata model. >:|
const model = {
	id: null,
	title: "",
	subTitle: "",
	alt: "",
	libType: 0,
	locationType: 0,
	sizes: null,
	tasks: null,
	mediaFiles: null,
	data: {
		type: 1,
		subType: 0,
		description: "",
		licence: "",
		licenceUrl: "",
		creator: "",
		tags: [],
		publishedYear: 0,
		edition: "",
		volumne: "",
		publicationPlace: "",
		pages: 0,
		anthologyTitle: "",
		bodyOfLaws: "",
		statute: "",
		lawTitle: "",
		lawSource: "",
		studio: "",
		channel: "",
		genre: [],
		artist: "",
		director: "",
		firstName: "",
		lastName: "",
		name: "",
		dob: null,
		dod: null,
		profession: []
	},
	media: {
		mimeType: null,
		locationType: 1,        // Todo: im Hauptknoten
		docLanguage: 'de-DE',
		mediaLanguage: "de-DE",
		alt: "",
		author: "",             // Todo: check? - vs creator
		creator: "",
		publishingDate: "",     // Todo: called date now!
		date: null
	},
	url: "",
	thumb: ""
}


/**
 * The Data & API Handler for LibraryElements.
 * It handles the API calls and keeps a simple state of the current Element's data
 * This is not a model per se. This can be found in the "initial" variable
 * TODO: This is not a closure, so this data is available & can be manipulated from all instances!
 *
 * @var
 *
 * @type {{dirty: boolean,
 * getInitial(): {id: null},
 * getCollection(*=): Promise,
 * set(*=, *=): void,
 * data: {id: null},
 * load(*=): Promise,
 * get(): LibraryElementModel.data,
 * save(*=): Promise,
 * create(): void,
 * delete(*=): Promise|Promise<unknown>}}
 */
const LibraryElementModel = {

	data: {...model},
	dirty: false,

	/**
	 * Initialise an empty data object
	 *
	 * @param presetData object adds custom prefilled data, e.g.: libType: INT when a new form is initialised
	 */
	create(presetData = {}) {
		this.data = {...model, ...presetData};
		return this.data;
	},

	getInitial() {
		return model;
	},

	get() {
		return this.data;
	},

	/**
	 * Set props
	 * @param props
	 * @param sync
	 */
	set(props, sync = false) {
		return new Promise((resolve, reject) => {
			const _data = {...model, ...props};
			this.dirty = true   // TODO. This will always be set, as soon as a preview is generated. Not properly working.
			// Save to DB if wanted
			if (sync) this.save(_data)
			.then((r) => {
				// console.log('SAVED? ', r, r.data)
				if (r && r.data) {
					this.data = r.data;
					resolve(r.data);
				} else reject();
			}).catch((e) => {
				console.warn('Error ', e)
				reject();
			})
			else {
				this.data = _data;  // just update memory
				resolve(this.data);
			}
		});
	},

	/**
	 * Load a single LibraryItem from DB
	 * @param id
	 * @returns {Promise}
	 */
	load(id) {
		return new Promise((resolve, reject) => {
			window.omApi.getMedia(id)
			.then((result) => {
				// ??? associated tasks
				// MediaModel.relations.tasks = result.data[0].tasks.map(t => t.id); // Store the associated tasks
				this.data = result.data;
				this.dirty = false;
				resolve(this.data);
			}).catch(e => {
				console.warn('Error ', e)
				reject('Library item not found');
			})
		});
	},

	/**
	 * Put to DB
	 * @param data flat json object using a strange dot notation ('.'). Ex: data[data.<key>]
	 * @returns {Promise}
	 */
	save(data) {
		// Arrghhh, hacky as. We need a simpler data structure here.
		// Hack: Tags clean
		if (data.data.tags === undefined) data.data.tags = "";
		else data.data.tags = data.data.tags && data.data.tags.join(',');
		// Hack: We do not save tasks anymore
		data.tasks = '';
		// Api call
		return window.omApi.createOrUpdateLibraryElement(data, {showLoading: true, convert: true})
	},


	delete(id) {
		return window.omApi.deleteMedia(id, {showLoader: false});
	},

	deleteFile(id) {
		return window.omApi.deleteFile(id, {showLoader: false});
	},


// -------- Collection -------
// Note: This breaks the logic of a single item model a bit

	/**
	 * See MEDIA API Docs for params
	 *
	 * @param params
	 * @returns {Promise}
	 */
	getCollection(params) {
		// TODO: return a cached version unless forced (.list)
		return new Promise((resolve, reject) => {
			window.omApi.getMedia(null, params)
			.then(function (result) {
				// this.list = result.data
				resolve(result.data)
			})
		})
	}


}

export default LibraryElementModel;
