import { eventList } from "../utils/base";

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Cls = (ContentTools.Tools.LibLink = class Link extends ContentTools.Tools.Bold {
	static initClass() {

		// Insert/Remove a link to a LibraryElement.

		ContentTools.ToolShelf.stow(this, 'omLinkLib');

		this.label = 'Bibliothek';
		this.icon = 'image';
		this.tagName = 'a';

		this.applied = false;


	}

	static getAttr(attrName, element, selection) {
		// Get an attribute for the element and selection

		// Fixtures
		if (element.isFixed() && (element.tagName() === 'a')) {
			return element.attr(attrName);

			// Text
		} else {
			// Find the first character in the selected text that has an `a` tag
			// and return the named attributes value.
			const [from, to] = Array.from(selection.get());
			const selectedContent = element.content.slice(from, to);
			for (let c of Array.from(selectedContent.characters)) {
				if (!c.hasTags('a')) {
					continue;
				}

				for (let tag of Array.from(c.tags())) {
					if (tag.name() === 'a') {
						return tag.attr(attrName);
					}
				}
			}
		}

		return '';
	}

	static canApply(element, selection) {
		// Return only true, if linkType-Attribute matches
		// Return true if the tool can be applied to the current
		// element/selection.
		if (element.isFixed() && (element.tagName() === 'a')) {
			return true;
		} else {
			// Must support content
			if (!element.content) {
				return false;
			}

			// A selection must exist
			if (!selection) {
				return false;
			}

			// If the selection is collapsed then it must be within an existing
			// link.
			if (selection.isCollapsed()) {
				const character = element.content.characters[selection.get()[0]];
				if (!character || !character.hasTags('a')) {
					return false;
				}
			}

			return true;
		}
	}

	static isApplied(element, selection, callback) {
		// Return true if the tool is currently applied to the current
		// element/selection.

		// Get wrapping tag and linkType, if it is not a "library" type then false
		const character = selection ? element.content.characters[selection.get()[0]] : element.content.characters[0];
		const aTags = character.tags().filter((t)=>t._name === "a")[0];
		const linkType = aTags ? aTags._attributes['data-linktype'] : undefined;

		// Ignore the link if it's not a library type
		if (linkType !== 'library') {
			this.applied = false;
			return false;
		}

		if (element.isFixed() && (element.tagName() === 'a')) {
			this.applied = true;
			return this.applied;
		} else {
			this.applied = super.isApplied(element, selection);
			return this.applied;
		}
	}

	static apply(element, selection, callback) {
		// Dispatch `apply` event
		let from, rect, selectTag, to;
		const toolDetail = {
			'tool': this,
			'element': element,
			'selection': selection
		};
		if (!this.dispatchEditorEvent('tool-apply', toolDetail)) {
			return;
		}


		// If already applied, remove
		if (this.applied) {
			// const selectTag = new HTMLString.Tag('span', {'class': 'ct--pseudo-select'});
			const [from, to] = Array.from(selection.get());
			// element.content = element.content.unformat(from, to, selectTag);
			element.content = element.content.unformat(from, to, 'a');
			element.updateInnerHTML();
			element.taint();
			// We could send an event with the vanished id to remove the ID of the libElement list. YET: If a user deletes the text w/o unlinking, this would not kick.
			// Therefore the editor will parse the contents. Lame but safe.
			//window.omBase.emit(eventList.EDITOR_SET_LINK_REMOVE, {id:null})   // send mediaId to attach
			return false;
		}
		//

		// Prepare text elements for adding a link
		if (element.isFixed() && (element.tagName() === 'a')) {
			// Fixtures
			// Note: NYI!
			rect = element.domElement().getBoundingClientRect();

		} else {
			// If the selection is collapsed then we need to select the entire
			// entire link.
			if (selection.isCollapsed()) {
				// Find the bounds of the link
				const {
					characters
				} = element.content;
				let starts = selection.get(0)[0];
				let ends = starts;

				while ((starts > 0) && characters[starts - 1].hasTags('a')) {
					starts -= 1;
				}

				while ((ends < characters.length) && characters[ends].hasTags('a')) {
					ends += 1;
				}

				// Select the link in full
				selection = new ContentSelect.Range(starts, ends);
				selection.select(element.domElement());
			}

			// Text elements
			element.storeState();

			// Add a fake selection wrapper to the selected text so that it
			// appears to be selected when the focus is lost by the element.
			selectTag = new HTMLString.Tag('span', {'class': 'ct--pseudo-select'});
			[from, to] = Array.from(selection.get());
			element.content = element.content.format(from, to, selectTag);
			element.updateInnerHTML();

			// Measure a rectangle of the content selected so we can position the
			// dialog centrally.
			// const domElement = element.domElement();
			// const measureSpan = domElement.getElementsByClassName('ct--pseudo-select');
			// rect = measureSpan[0].getBoundingClientRect();
		}

		// ----------------
		// Custom Dialog
		// @new open medialib and watch for return.
		// Await Library selection and handle it by
		// converting it into a link we can use
		/*
		LIBEL = <a href="/library/{id}" data-linktype="library" data-id="{id}" alt="{title}" >
		 */
		// Remove any EL
		window.omBase.off(eventList.EDITOR_SET_LINK)
		// @param r is the data from the LibraryElement data attribute
		window.omBase.once(eventList.EDITOR_SET_LINK, (r) => {
			// try to avoid using global triggers.

			// If there is no return then it is a cancel event.
			// Restore the original state of the element
			if (!r.length) {
				// Restore original state (remove selection)
				if (element.content) {
					// Remove the fake selection from the element
					element.content = element.content.unformat(from, to, selectTag);
					element.updateInnerHTML();

					// Restore the selection
					element.restoreState();
				}
				callback(this.applied);


				// Stop right here
				return;
			}

			// We have a result, go on and add the link
			// DATA comes from the media-lib with basic items in it.
			let detail = {
				// The raw url or a composed path?????
				// ISSUE - object keys are WRONG, not having camelCase! libType == libtype
				// href: `${r[0].url}`,
				href: `/library/${r[0].id}`,
				alt: r[0].alt,
				'data-title': r[0].title,
				'data-id': r[0].id,
				'data-linktype': 'library',
				'data-libtype': r[0].libType || r[0].libtype,
				'data-locationtype': r[0].locationType || r[0].locationtype,
				'data-uri': `${r[0].url}` || ''
			};
			// console.log(r[0], ' vs ', detail)

			// Now the link is applied
			this.applied = true;

			if (element.isFixed() && (element.tagName() === 'a')) {
				// Fixtures
				element.attr('href', detail.href);
			} else if (element.content) {
				// Text elements
				// Clear any existing link
				element.content = element.content.unformat(from, to, 'a');

				// If specified add the new link
				if (detail.href) {
					const a = new HTMLString.Tag('a', detail);
					element.content = element.content.format(from, to, a);
					element.content.optimize();
				}

				element.updateInnerHTML();
			}

			// Make sure the element is marked as tainted
			element.taint();

			// Let whoever know that we're done here.
			// @deprecate(!)
			window.omBase.emit(eventList.EDITOR_SET_LINK_DONE, {id: r[0].id})   // send mediaId to attach

			// Dispatch `applied` event
			if (this.applied) {
				return ContentTools.Tools.Link.dispatchEditorEvent(
					'tool-applied',
					toolDetail
				);
			}

			// Remove listener
			window.omBase.off(eventList.EDITOR_SET_LINK)

		})


		// Cancelled: Listen for close of modal and reset selection
		// TODO: IMPORTANT: If user cancels, the EDITOR_SET_LINK event is not removed
		// removing it here won't work as the order seems wrong
		window.omBase.once(eventList.MEDIAMODAL_CLOSED, () => {
			// Remove listener (!?)
			if (this.applied)
				window.omBase.off(eventList.EDITOR_SET_LINK)
			// Remove itself
			window.omBase.off(eventList.MEDIAMODAL_CLOSED);

			// Remove fake selection
			if (element.content) {
				// Remove the fake selection from the element
				element.content = element.content.unformat(from, to, selectTag);
				element.updateInnerHTML();

				// Restore the selection
				element.restoreState();

				callback(this.applied);
			}
		});



		// INTERCEPT the media event and trigger another to update the link with data from the media-library
		window.omBase.once(eventList.MEDIA_SELECTION, (payload) => {
			const {mediaArr, placeholder} = payload
			if (!mediaArr || !mediaArr.length) return;
			if (placeholder === 'NONE') {
				// Bubble upto base. The editor probably wants some content for an omLink
				// HACK: because I don't know how to trigger the method within here, I use the global scope to emit an event that is intercepted
				// right here --> see L ~ 196
				window.omBase.emit(eventList.EDITOR_SET_LINK, mediaArr);    // nasty
			}

		})

		// Open the Library(modal)
		window.omBase.OM_Media.showAsModal({placeholder: 'NONE'})



		return true;
	}
});
Cls.initClass();



