/**
 * API Class
 *
 * Notes:
 * - {convert:BOOL} if true, the data will be converted to FORMDATA (PUT only)
 * -
 *
 */
import EventEmitter                        from "eventemitter3";
import { base64url, eventList }            from "./base";
import UIkit                               from "uikit";
import CryptoJS                            from "crypto-js";

/**
 * API
 * IMPROVEMENTS:
 * - instead of a class, export methods separately
 * - add UIKit.notifications right here
 * - remove the silly /model/js files. [one level of abstraction less]
 *
 */


const axios = require('axios').default;

export default class Api extends EventEmitter {
	constructor() {
		super();

		this.progress = 0;

		// axios.defaults.baseURL = config.API_URL;
		axios.defaults.baseURL = '//' + window.location.host + '/api/v1/';

		// If we need to abort all requests
		this.cancelToken = null

		// Progress Events f. upload / download
		// NOTE: Progress.total is only available if server sends content-length flag && may not use gzip compression
		// axios.defaults.onUploadProgress = (progressEvent) => {
		// 	console.log(progressEvent.loaded, progressEvent.total);    // TODO: Make a progress indicator
		// }

		// axios.defaults.onDownloadProgress = (progressEvent => {
		//     this.progress = progressEvent.loaded / (progressEvent.total / 100);
		//     if ($('#om-loading label'))
		//         $('#om-loading label').text(this.progress + '%');
		// })

		// Default headers
		axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

		// ------
		// General error interception could be done here
		axios.interceptors.response.use(
			response => response,
			error => {

				// Take unauthorised users to the login page
				if (error.response && error.response.status === 403) {
					this.logout(true);
				}
				// if (error && error.response)
				// UIkit.notification({
				// 	message: `${ error.response.data.message }`,
				// 	status: 'danger',
				// 	pos: 'top-right',
				// 	timeout: 5000
				// });

				return new Promise((resolve, reject) => {
					return reject(error.response.data.message);
				})
			});

		/**
		 * Options objects is a conglomerate of our own props (seen here)
		 * and axios-specific settings, e.g. headers {}
		 * @type {{convert: boolean, showLoading: boolean}}
		 */
		this.options = {
			showLoading: false,  // Show a loading overlay
			convert: false       // Conversion to formData, currently the server nearly always expects this to be true
		}


	}

	login(userLogin, userPwd) {
		return this._doRequest('POST', '/user/login', {userLogin: userLogin, userPwd: userPwd, cms: 1}, {convert: true});
	}

	logout(killSecret = false) {
		// Logout locally first, to kill the secret
		if (killSecret) localStorage.setItem('$$omauth', null);
		return new Promise((resolve) => {
			// Using an active logout will cause a 403 if the session is invalid
			this._doRequest('POST', '/user/logout', null, {convert: true, showLoading: false}).then(() => {
				resolve(true);
			}).finally(() => {
				if (window.location.pathname !== '/login' && window.location.pathname !== '/login/reset')
					location.href = '/login';
			})
		}).finally(() => {
			// clear user in any case of error
			localStorage.setItem('$$omauth', null);
			if (window.location.pathname !== '/login' && window.location.pathname !== '/login/reset')
				location.href = '/login';

		})


	}

	resetPass(userLogin) {
		return this._doRequest('POST', '/user/resetpw', {userLogin: userLogin}, {convert: true});
	}

	changePass(data) {
		return this._doRequest('POST', '/user/changepw', {userLogin: data.userLogin, userPwd: data.userPwd, newPwd: data.newPwd, newPwdRepeat: data.newPwdRepeat}, {convert: true});
	}

	// -----------------------------------------------------------------------
	// ACCOUNTS CRUD
	// -----------------------------------------------------------------------

	/**
	 * Retrieve all users
	 * @returns {Promise<AxiosResponse<T>>}
	 */
	getAccounts() {
		return this._doRequest('GET', '/users');
		// return new Promise(resolve => {
		// 	resolve(dummyAccountList)
		// })
	}

	/**
	 * Get specific user
	 * @param id
	 * @returns {Promise<AxiosResponse<T>>}
	 */
	getAccount(id) {
		return this._doRequest('GET', `/user/${ id }`)
	}

	/**
	 * Create || Update a user
	 * @param data
	 * @returns {Promise<AxiosResponse<T>>}
	 */
	setAccount(data) {
		// console.warn('Saving API User-> ', data)
		if (data?.id)
			return this._doRequest('POST', `/user/${ data.id }`, data, {convert: true});
		else
			return this._doRequest('PUT', `/user`, data);
	}


	getCodes() {
		return this._doRequest('GET', `/codes`);
	}

	getCode(id) {
		return this._doRequest('GET', `/code/${ id }`);
	}

	// Different to other API methods here, this will Create || Update
	setCode(data) {
		// PUT
		if (data.id)
			return this._doRequest('PUT', `/code/${ data.id }`, data);
		else {
			if (!data.code) {
				console.warn('No code given, db is creating one for us');
			} else
				data.id = data.code;
			delete data.code;
			return this._doRequest('PUT', `/code`, data);
		}
	}

	deleteCode(id) {
		return this._doRequest('DELETE', `/code/${ id }`);
	}

	// -----------------------------------------------------------------------
	// TASKS CRUD
	// -----------------------------------------------------------------------


	getTask(id) {
		return this._doRequest('GET', `/task/${ id }`);
	}

	getTasks() {
		return this._doRequest('GET', `/tasks`);
	}

	createTask(data) {
		return this._doRequest('PUT', '/task', data);
	}

	updateTask(id, data) {
		return this._doRequest('PUT', `/task/${ id }`, data, {convert: false});
	}

	deleteTask(id, opts) {
		return this._doRequest('DELETE', `/task/${ id }`, opts);
	}

	/**
	 * Subtask sorting.
	 * @param data comma separated list of ids (in order)
	 * @param opts
	 * @return {Promise<AxiosResponse<T>>}
	 */
	taskSort(data, opts) {
		return this._doRequest('PUT', `/task/sort`, data, opts);
	}


	// -----------------------------------------------------------------------
	// KNOWLEDGE CRUD
	// -----------------------------------------------------------------------

	/**
	 * Get a knowledge record
	 * @param viewId String optional ~
	 * @param knId String optional ~ knowledge-id
	 * @param opts
	 * @return {Promise<AxiosResponse<T>>}
	 */
	getKnowledge(viewId, knId, opts = {convert: false}) {
		return (viewId)
			? this._doRequest('GET', `/knowledge/${ knId || 0 }?viewId=${ viewId }`, opts)
			: this._doRequest('GET', `/knowledge/${ knId || 0 }`, opts)
	}

	/**
	 * Return a list of knowledges.
	 * @param param object can contain {search:STRING} to return a full text search
	 * @returns {*}
	 */
	getKnowledgeCollection(param, opts) {
		// param += '&hierarchy=1'
		return this._doRequest('GET', `/knowledges/?${ param }`, null, {showLoading: false}); // Make sure opts: showLoading == false!
	}

	createKnowledge(data) {
		return this._doRequest('PUT', '/knowledge', data, {convert: false});
	}

	updateKnowledge(id, data) {
		// \\\: HACK: filter default subtitle (this WILL create issues, if....)
		if (data.subTitle.trim() === 'Untertitel' || data.subTitle.trim() === '...') data.subTitle = '';
		if (data.teaser.trim() === '...') data.teaser = '';
		// <---
		return this._doRequest('PUT', `/knowledge/${ id }`, data, {convert: false});
	}

	deleteKnowledge(id) {
		return this._doRequest('DELETE', `/knowledge/${ id }`, null, {convert: false});
	}

	/**
	 * Attaches a knowledge object to a task within a dimensioning position field
	 * @param data
	 * @return {Promise<AxiosResponse<T>>}
	 */
	attachKnToTask(data) {
		// Serialise viewProps:
		if (data.viewProps) {
			data.show = data.viewProps.show
			data.title = data.viewProps.viewTitle
		}
		return this._doRequest('PUT', `/knowledge/attach`, data, {convert: false});
	}

	/**
	 * Detach a knowledge from a task
	 * @param data
	 * @return {Promise<AxiosResponse<T>>}
	 */
	detachKnFromTask(data) {
		return this._doRequest('PUT', `/knowledge/detach`, data, {convert: false});
	}

	/**
	 * Simply sends an array of knowledge-viewIds from a position in a tasks dimension (sorting)
	 * @param data
	 * @return {Promise<AxiosResponse<T>>}
	 */
	sortKnowledges(data) {
		return this._doRequest('PUT', `/knowledge/sort`, data, {convert: false});
	}

	/**
	 * Sets a new parent (viewId) to a knowledge object within a task dimensioning list (hierarchy)
	 * @param data
	 * @return {Promise<AxiosResponse<T>>}
	 */
	setKnowledgeParent(data) {
		return this._doRequest('PUT', `/knowledge/setParent`, data, {convert: false});
	}

	/**
	 * Move knowledge within hierarchies (task)
	 * @param data
	 * @return {Promise<AxiosResponse<T>>}
	 */
	moveKnowledge(data) {
		return this._doRequest('PUT', `/knowledge/move`, data, {convert: false});
	}

	/**
	 * Update properties of knowledge (bit)
	 * @param data {itemId, props}
	 * @return {Promise<AxiosResponse<T>>}
	 */
	updatePropsKnowledge(data) {
		return this._doRequest('PUT', `/knowledge/props`, data, {convert: false});
	}

	// -----------------------------------------------------------------------
	// DIMENSIONING CRUD
	// -----------------------------------------------------------------------

	updateDimension(data) {
		return this._doRequest('POST', `/dimension/${ data.taskId }`, data, {convert: true});
	}

	// -----------------------------------------------------------------------
	// @deprecated WORKBOOK/WORKSHEET CRUD
	// -----------------------------------------------------------------------

	getWorkBook(id) {
		return this._doRequest('GET', `/workbook/${ id }`);
	}

	updateWorkBook(data) {
		if (data.id)
			return this._doRequest('PUT', `/workbook/${ data.wbId }`, data, {convert: false});
		else
			return this._doRequest('PUT', `/workbook`, data, {convert: false});
	}

	getWorkSheet(id) {
		return this._doRequest('GET', `/worksheet/${ id }`);
	}

	updateWorkSheet(data) {
		if (data.wsId)
			return this._doRequest('PUT', `/worksheet/${ data.wsId }`, data, {convert: false});
		else
			return this._doRequest('PUT', `/worksheet`, data, {convert: false});
	}


	// -----------------------------------------------------------------------
	// MEDIA CRUD
	// -----------------------------------------------------------------------

	getMedia(id, params) {
		if (id)
			return this._doRequest('GET', `/media/${ id }`, {}, {showLoading: false});
		else if (!params)
			return this._doRequest('GET', `/media`, {}, {showLoading: false});
		else if (params)
			return this._doRequest('GET', `/media${ params }`, {}, {showLoading: false});
	}


	/**
	 * Create || Update LibraryElement
	 *
	 * @param data JSON model for LibraryElement
	 * @param options JSON option object
	 * @returns {Promise<AxiosResponse<T>>}
	 */
	createOrUpdateLibraryElement(data, options) {
		const self = this;
		let config = {
			...options,
			...{
				onUploadProgress: function (progressEvent) {
					let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
					self.emit(eventList.MEDIA_UPLOAD, {progress: percentCompleted, id: data.id || null})
				},
				convert: true   // !!! for mediaFile
			}
		};
		if (data.id)
			return this._doRequest('POST', `/media/${ data.id }`, data, config)
		else
			return this._doRequest('POST', '/media', data, config)
	}

	/**
	 * Delete the whole media record
	 * @param id
	 * @param options
	 * @returns {Promise<AxiosResponse<T>>}
	 */
	deleteMedia(id, options) {
		return this._doRequest('DELETE', `/media/${ id }`, options);
	}

	/**
	 * Delete the associated (image)file to the record
	 * Currently only type 2048 (image)
	 * @param id
	 * @param options
	 * @returns {Promise<AxiosResponse<T>>}
	 */
	deleteFile(id, options) {
		return this._doRequest('DELETE', `/media/image/${ id }`, options);
	}


	// -----------------------------------------------------------------------
	// SEARCH
	// -----------------------------------------------------------------------

	search(params) {
		return this._doRequest('GET', `/search${ params }`)
	}

	// -----------------------------------------------------------------------
	// UTILITIES
	// -----------------------------------------------------------------------

	/**
	 * Get enumerated lists
	 * @param field
	 * @param type
	 * @param subType
	 * @return {Promise<AxiosResponse<T>>}
	 */
	getOption(field, type = 0, subType = 0) {
		return this._doRequest('GET', `/options/${ field };${ type };${ subType }`);
	}

	/**
	 * Tag / Autocomplete queries
	 * @param field STRING the field to search [see docs]
	 * @param s STRING minimum of 3 chars to search for
	 * @param length INT defaut 10 results
	 * @return {Promise<AxiosResponse<T>>}
	 */
	getTaglist(field, s, length = 10) {
		return this._doRequest('GET', `/taglist/${ field };${ s };${ length }`);
	}

	// -----------------------------------------------------------------------
	// RSVP stuff
	// -----------------------------------------------------------------------

	getRsvpList() {
		return this._doRequest('GET', 'mailer/registerEvent')
	}

	// -----------------------------------------------------------------------
	// GENERAL
	// -----------------------------------------------------------------------

	/**
	 * Convert JSON Object to Form data (POST only)
	 * @param obj JSON
	 * @param form* String of form name | optional
	 * @param namespace* String of current namespace, used for recursivity
	 * @returns {FormData}
	 * @private
	 */
	_convertToFormData(obj, form, namespace = '') {

		var fd = form || new FormData();
		var formKey;

		for (var property in obj) {
			if (obj.hasOwnProperty(property)) {
				if (namespace) {
					// formKey = namespace + '[' + property + ']';
					formKey = namespace + '.' + property;
				} else {
					formKey = property;
				}
				// if the property is an object, but not a File,
				// use recursivity.
				if (typeof obj[property] === 'object' && !(obj[property] instanceof File) && property !== 'mediaFiles') {
					this._convertToFormData(obj[property], fd, property);
				} else if (property === 'mediaFiles' && obj[property]) {
					console.log('Append mediafiles ', obj[property])
					for (let i = 0; i < obj[property].length; i++)
						fd.append('mediaFiles[]', obj[property][i])
				} else {
					// if it's a string or a File object
					fd.append(formKey, obj[property]);
				}
			}
		}

		return fd;

	}

	/**
	 * Show a global or local loading overlay
	 * @param message
	 * @param el STRING - dom element to attach the loader to, optional
	 * @private
	 */
	_showLoading(message) {
		// this.loader.show();
	}

	/**
	 * Hide the global overlay
	 * @private
	 */
	_hideLoading() {
		// Hide global
		// this.loader.hide();
	}


	/**
	 * Execute XHR
	 *
	 * @param method
	 * @param url
	 * @param payload
	 * @param options
	 * @returns {Promise<AxiosResponse<T>>}
	 * @private
	 */
	_doRequest(method, url, payload, options) {
		let message = '';
		// Update global options (why?)
		this.options = {...this.options, ...options};
		options = this.options;

		//
		// ---------- Authorization & JWT
		const jwtEnabled = true;
		// Add secret
		let u = JSON.parse(localStorage.getItem('$$omauth')),
			_secret = u ? u.secret : '';

		// If a user is available, use secret for authorization
		if (u && u.secret) {

			if (!jwtEnabled) {
				axios.defaults.headers.common['Authorization'] = `CMS ${ _secret }`
			} else {
				// https://www.jonathan-petitcolas.com/2014/11/27/creating-json-web-token-in-javascript.html
				// eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzcmMiOiJDTVMiLCJpZCI6IjVlMWVkYWM3Nzk0MDkzLjEyMTI3MzMxIiwic2VjcmV0IjoiNjJmNjcxOGMwOTk2NTEuMzIxNjkyMzYifQ.swjes1qxBGc3WEFCD7s0WFbvBUQkeh4VYX5Og4tmvkM
				// Assemble JWT
				const privateKey = 'cd#172_6b92abd31b3$8bf_eb23§Fff9e87#dacc8'; // Todo: this should not be in the code. Move to an env-variable !!!
				const header = {
					typ: 'JWT',
					alg: 'HS256'
				};
				const data = {
					src: `CMS`,
					id: u?.id || null,
					secret: _secret
				};

				const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
				const encodedHeader = base64url(stringifiedHeader);
				const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
				const encodedData = base64url(stringifiedData);
				const token = encodedHeader + '.' + encodedData;

				let signature = CryptoJS.HmacSHA256(token, privateKey);
				signature = base64url(signature);

				// console.log('::: ', encodedHeader + '.' + encodedData + '.' + signature)


				axios.defaults.headers.common['Authorization'] = token + '.' + signature;
			}

		}

		// Show Loader?
		if (this.options.showLoading) {
			switch (method) {
				case 'GET' :
					message = this.options.message || 'Lade...';
					break;
				case 'PUT' :
				case 'POST' :
					message = this.options.message || 'Speichere...';
					break;
			}
			this._showLoading(message);
		}

		// To Formdata?
		if (this.options.convert) {
			payload = this._convertToFormData(payload);
		}

		// Execute REST Methods
		switch (method) {
			case 'GET':
				return axios.get(url, payload)
				.finally(_ => {
					if (this.options.showLoading)
						this._hideLoading();
				})
				break;
			case 'POST':
				return axios.post(url, payload, options)
				.finally(_ => {
					if (this.options.showLoading)
						this._hideLoading();
				})
				break;
			case 'PUT':
				return axios.put(url, payload, options)
				.finally(_ => {
					if (this.options.showLoading)
						this._hideLoading();
				})
				break;
			case 'PATCH':
				break;
			case 'DELETE':
				return axios.delete(url, payload)
				.finally(_ => {
					if (this.options.showLoading)
						this._hideLoading();
				})
				break;
			default:
				throw new Error('Method not supported');
		}


	}


}
