export default class KnIcon {

	/**
	 *
	 * @param placeholder
	 * @param position
	 * @param type kn || abl
	 */
	constructor(placeholder, position, type = 'kn') {
		this.placeholder = placeholder;
		$(placeholder).html(this.render(position, type))
	}

	render(position, type) {
		return `
			<svg class="o-icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
			 viewBox="0 0 48 48" style="enable-background:new 0 0 48 48;" xml:space="preserve">
				<path id="a4" class="st0 ${+position === 3 && type === 'abl' && 'active-abl'}" d="M12,24.8c0.4,6,5.2,10.8,11.2,11.2V24.8H12z"/>
				<path id="w4" class="st0 ${+position === 3 && type === 'kn' && 'active-kn'}"  d="M11,24.8H0C0.4,37.4,10.6,47.6,23.2,48V37C16.7,36.6,11.4,31.3,11,24.8z"/>
				<path id="a3" class="st0 ${+position === 4 && type === 'abl' && 'active-abl'}" d="M24.8,36c6-0.4,10.8-5.2,11.2-11.2H24.8V36z"/>
				<path id="w3" class="st0 ${+position === 4 && type === 'kn' && 'active-kn'}" d="M24.8,37v11C37.4,47.6,47.6,37.4,48,24.8H37C36.6,31.3,31.3,36.6,24.8,37z"/>
				<path id="a2" class="st0 ${+position === 2 && type === 'abl' && 'active-abl'}" d="M36,23.2c-0.4-6-5.2-10.8-11.2-11.2v11.2H36z"/>
				<path id="w2" class="st0 ${+position === 2 && type === 'kn' && 'active-kn'}" d="M37,23.2h11C47.6,10.6,37.4,0.4,24.8,0v11C31.3,11.4,36.6,16.7,37,23.2z"/>
				<path id="a1" class="st0 ${+position === 1 && type === 'abl' && 'active-abl'}" d="M23.2,12c-6,0.4-10.8,5.2-11.2,11.2h11.2V12z"/>
				<path id="w1" class="st0 ${+position === 1 && type === 'kn' && 'active-kn'}" d="M23.2,11V0C10.6,0.4,0.4,10.6,0,23.2h11C11.4,16.7,16.7,11.4,23.2,11z"/>
			</svg>
		
			<style>
				
				.st0{fill:#CCCCCC;}
				.active-abl {fill:#0099CC;}
				.active-kn {fill:#66CC66}
			</style>
		`;
	}

	update(position, type = 'kn') {
		$(this.placeholder).empty().html(this.render(position, type))
	}
}
