import "../utils/config.json"
import EXIF                          from "exif-js/exif";
import { eventList, omPlaceholders } from "../utils/base";
import EventEmitter                  from "eventemitter3";
import LibraryElementModel           from "../models/libelement";
import UIkit                         from "uikit";

/**
 * LibraryElement
 * This element is a stupid UI-render-template that will show itself as image with a meta-navigation as
 * requested (edit, delete, remove -- actions)
 * This class receives data and renders html and it emits events from its little navigation.
 *
 * If a placeholder is given, this element renders itself into the placeholder (DOM) and expects an input field for the mediaID.
 * ****************
 * IMPORTANT: The placeholder needs to have this attribute: [data-type="libelement"]
 * And the $el of this class is within the placeholder. There always needs to be a placeholding element ()
 * ****************
 *
 * It is *not* immutable!
 *
 * All data (get, set, save, delete) are being handled by the libraryElementModel.js and the form data is
 * handled / controlled by media.meta.modal.js [<- this is the main form controller]
 *
 * EMITS:
 * - MEDIAMETA_UPDATED	~ when a
 * - MEDIA_REMOVE
 * - OPEN_MEDIA_LIBRARY
 * - ACTION
 * -
 *
 * DOM FORMAT:
 * <div data-type="libelement" name="important-to-have"><div>
 *
 * [ data-type="libElement" | PLACEHOLDER | is positioned by CSS and to user's wish. | holds data-id | data-size
 *
 *      [.empty
 *         -- this holds the actual image / background-url
 *      ]
 *
 *      [.state
 *         [inner (caption)
 *            -- caption data, eg. TITLE, if set in options
 *         ]
 *         [meta
 *            -- controls for edit, remove, resize
 *         ]
 *      ]
 * ]
 *
 * Note that the placeholder element is a wrapper that has css applied to dynamicallt! E.g. alignment, size (see setState())
 * ... are set to the wrapper,
 * thus directly interfering with any styles you've given it. This might be an **issue** if one is not aware!!!
 *
 * HOW TO USE:
 * Best see gDocs for more (to be done!)
 *
 * @constructor
 */

export default class LibraryElement extends EventEmitter {

	/**
	 * Constructor
	 * @param base CLASS inject base class
	 * @param placeholder the named ID/ATTR of the DOM element to insert this into
	 * @param data JSON representation of a LibraryElement Model OR at least {id: STRING }
	 * @param options JSON
	 * @return {LibraryElement|boolean}
	 */
	constructor(base,
		placeholder,
		data,
		options = {
			allowed: ['image', 'video', 'audio', 'document', 'worksheet', 'reference'],
			showAddButton: true,        // BOOL : if empty, show (+) and accept opening the media-modal to insert a item
			input: null,                // STRING : Attach an input field with ID of this element
			sizeData: null,             // JSON: {key,w,h,class} If set, preselect the sizing options dropdown
			size: 'thumb',              // STRING : The preview src to load. Exactly as the size in the API return (thumb, small, medium, large, original)
			mode: 'default',            // STRING : cropped (only bg is shown) | box (only image is shown) -- Bullsh...
			caption: true,              // BOOL : Show title / subtitle
			// Control meta nav
			resizeable: false,          // BOOL : Placeholder can have sizes
			editable: true,             // BOOL : shows pen icon to open media-meta-modal
			deleteable: true,           // BOOL : if set, show delete option for the whole record
			deleteableFile: true,       // BOOL : if set, allow the assoc. file to be removed
			selectable: true,           // BOOL : If true, there's no meta, the whole element uses the click/touch and does not propagate
			removeable: false,          // BOOL : If true, the content of the placeholder can be removed; only for single use (e.g. teaserimage)
			// Custom behaviour
			showOnClick: false          // If true, then open the linkWrapper / Preview instead of the edit form
		}) {

		super();

		this.defaultState = {loading: true, progressing: false, error: false, initial: true};

		// ---- DEV
		this.scale = 1;  // MUST be the same as in CSS (_frontend.scss):: scales everything up for screen (because knowledge cards have print setup) :/
		//
		// -------------------------------

		// -------------------------------
		// Runtime
		this.base = base;
		this._data = null
		this._options = options;        // Apply options
		this._isNew = true;              // true when the record has no ID data, set in setData()
		this._preview = null;           // Preview image url

		// -------------------------------
		// Check for placeholder
		if (!placeholder) {
			console.warn('NO PLACEHOLDER GIVEN! Returnin.');
			return false;
		}

		this.placeholder = placeholder;         // Store the string
		this.$placeholder = $(placeholder);     // Store the DOM element

		// -------------------------------
		// Simply apply the DOM shell / template and add to DOM
		this.$el = $(this._render());

		try {
			this.$placeholder.empty().append(this.$el);
		} catch (e) {
			console.warn('This should not happen. Well crap!', e);
		}

		// -------------------------------
		// Is the element really in the DOM?
		if (!this.$el.length) {
			console.warn('NO LIB ELEMENT FOUND.', this.$placeholder);
			return;
		}

		// -------------------------------
		// Render the element with data & initialise
		this.setData(data, false).then(() => {
			// Initialise
			this._addEvents();  // only once.
		});

		return this;
	}

	destroy() {
		this._removeEvents();
		this.$el.empty();
		this.$el = null;
	}

	/**
	 * Return current element's data
	 * @return {*}
	 */
	getData() {
		return this._data;
	}

	/**
	 * Set item data, rerender or save, if requested
	 * It will try to check if:
	 * - it is a new record
	 * - it needs to load the record (only id available)
	 * - it needs to update an existing record
	 *
	 * @param data JSON, expecting
	 * @param save
	 * @param state {} - this will ser the state of the element
	 */
	async setData(data, save = false, state, addEvents) {		// Just add an error cssClass, nothing else really
		data = {...LibraryElementModel.getInitial(), ...data};
		state = {...this.defaultState, ...state};
		// console.warn('--- setting data --- ', data)
		// Is this a new record?
		this._isNew = !(data?.id);
		// If no data is set at all, we need to initialise a simple placeholder (and only if it is not explicitly forbidden --> options.initial)
		// else: render the element with data
		return new Promise(async (resolve) => {
			// a. sync data with model
			// This makes this component something complex.
			// E.g: If an newly uploaded byte-array is set, this component will show the preview
			// if save is set, the data model will be updated too
			// All this because I wanted a super-component man-bear-pig, top-notch-logic-thing
			// This component checks valid urls etc.
			// Some sane programmer would have made smaller methods and used them in places where it would make sense
			// But hey, this is the most universal component of this cms. Maybe.
			if (save) {
				// We need data to save = Update || Create:
				// API Call:
				// Note: LibraryElementModel is supposed to hold the JSON-data of this class. However, it is
				// not properly used all along. E.g. setData() / getData() should ALWAYS refer to the model
				LibraryElementModel.set(data, true).then((data) => {
					// mixin the ID
					// data = {...data, ...{id: r.id}};
					// console.log('Got id', data.id)
					this._data = data
					// b. set the state
					this._setState({...this.defaultState, ...{loading: false, progressing: false, error: false, initial: false}}, data);
					// debugger
					UIkit.notification({
						message: !this._isNew ? `Das Element »${ data.title }« wurde aktualisiert.` : `Das Element »${ data.title }« wurde gespeichert.`,
						status: 'success',
						pos: 'top-right',
						timeout: 5000
					});
					this._data = data;
					// Let the caller know that we have new data
					this.emit(eventList.MEDIAMETA_UPDATED, {...data, ...{isNew: this._isNew}});

					resolve(data.id);
				}).catch((e) => {
					// Nope: something could not be saved
					// The lib gets null, so no updating of the media list /grid
					this.emit(eventList.MEDIAMETA_UPDATED, null);
					this._setState({...this.defaultState, ...{loading: false, progressing: false, error: true, initial: false}}, data);
					// Todo: e == undefined. We should get {code: 400, message: ""}
					UIkit.notification({
						message: `Das Element »${ data.title }« konnte nicht gespeichert werden.`,
						status: 'warning',
						pos: 'top-right',
						timeout: 5000
					});
					resolve(null);
				}).finally(() => {
					// remove global EL
					this.base.off(eventList.MEDIA_UPLOAD);
				});
			} else {
				// b. DEFAULT: Just set the state of this component and render the preview or sth
				// The state (not the government)
				this._data = data;
				this._setState({...state, ...{initial: this._isNew}}, data);
				resolve(true);
			}
		}).finally(() => {
			// Todo: HACK: this is needed for editor when moving stuff around. Because the destroy()  method may be called
			if (addEvents) this._addEvents()
		})
	}

	/**
	 * Get current libType
	 * @return {*|string}
	 */
	getType() {
		return this.getData().libType ? this.base.getLibElementType(this.getData().libType) : 'unbekannt';
	}

	getAllowedTypes() {
		return this._options.allowed;       // Todo: improve. do not use options. read directly from placeholder.
	}

	/**
	 * Handle uploaded files
	 * @param files
	 */
	handleFiles(files) {
		let src = '', type = this.getType(), self = this;
		// `files` is always an Array!
		// Get data for preview
		files.forEach(file => {
				const reader = new FileReader();    // convert the file to a Buffer that we can use!
				reader.addEventListener('load', async e => {
					reader.removeEventListener('load', () => {});
					if (type.id === 'image' || type.id === 'reference') {
						// Convert the buffer to an image -> either the bitmap data of the current image file OR a default icon for document MIME-type ...
						// to display the raw image before upload
						const arr = new Uint8Array(e.target.result);        // e.target.result is an ArrayBuffer
						const buffer = new Buffer(arr);
						src = 'data:image/jpeg;base64,' + buffer.toString('base64');
						// Try to get EXIF right here.
						// EXIF.readFromBinaryFile(arr, function () {
						// 	self._updateExif(EXIF.getAllTags(this));
						// });
						// this.renderPreview({...this.getData(), ...{title: file.name, thumb: src, mediaFiles: [file], locationType: 1}})
						// console.log('0) Type ', type, src)
					} else {
						src = type.icon;
					}

					// Set data and rerender src
					// Note that the _preview property is not in the db data model and ALWAYS USED for the preview currently
					this._preview = {src: src, object: null};    // globally set preview image [shitty, but working]
					this.setData({...this.getData(), ...{title: file.name, thumb: src, mediaFiles: [file], locationType: 1}}, false, {initial: false, loaded: true, progressing: true});

				})
				reader.addEventListener('error', err => {
					console.error('FileReader error' + err)     // LibElementModel should be flagged dirty
				})
				reader.readAsArrayBuffer(file)
			}
		);
		// This elements locationType MUST be 1
		// this.setData({locationType: 1});
	}

	/**
	 * Handle linked files from external sources
	 * This checks for allowed mimeType & tries to get preview images from video-urls
	 * @param url
	 */
	handleFileLink(url) {
		// Todo: Is the MIME TYPE allowed?
		let allowedMIME = true; //this._checkAllowedMimeType(url);
		// Complete URL analysis
		if (!url) return;
		let urlInfo = this.base.checkURL(url);

		if (allowedMIME && urlInfo.valid) {
			// Clear any mediaFiles in the data (scenario: if a user tried to upload a video, then decides to link)
			let {locationType} = urlInfo !== 0 ? urlInfo : 2;   // FALLBACK: 2 = external
			let _data = {...this.getData(), ...{url: url, mediaFiles: [], locationType: locationType}};
			// Set this data and rerender [Todo: won't kick. The setData fn may try to load it because there is no id)
			this._preview = null;
			this.setData(_data, false, {initial: false, loaded: true, progressing: true});
			this.emit(eventList.ACTION, {message: 'OK', action: 'success.mediaLinkField'});
			// This will trigger an update of the associated input field (data.locationtype)
			this.emit(eventList.ACTION, {message: 'OK', action: 'set.locationType', data: locationType});       // TODO: locationType not correct!! 8 - vimeo, etc. REMOVE THIS.
		} else if (!urlInfo.valid) {
			UIkit.notification({
				message: `Dieser Link scheint nicht gültig zu sein!`,
				status: 'warning',
				pos: 'top-right',
				timeout: 5000
			});
			this.emit(eventList.ACTION, {message: 'Unzulässiger Link', action: 'error.mediaLinkField'})
		} else if (!allowedMIME) {
			UIkit.notification({
				message: `Dieser Dateityp scheint nicht passend zu sein. Vorerst lasse ich dich das nicht verknüpfen. It's for our own safety. :P`,
				status: 'warning',
				pos: 'top-right',
				timeout: 5000
			});
			this.emit(eventList.ACTION, {message: 'Unzulässiger Link', action: 'error.mediaLinkField'})
		}
	}

	/**
	 * Handle (video) embed codes.
	 * @param embedCode
	 */
	handleEmbedCode(embedCode) {
		if (!embedCode) return;
		this.$placeholder.find('.object').empty().append(embedCode);
		this.$el.addClass('has-object');
		this.$el.removeClass('has-image');
		this.emit('LE_RENDERED');
	}


	// ----------------------------------------------------------------------------------------
	// CRUD
	// ----------------------------------------------------------------------------------------

	/**
	 * Set Element state. This is the main DOM manipulation method
	 * Applying all data and sizes to the current RENDERED element
	 * Using the state and soooo many different options is CRAZY.
	 *
	 * STATES:
	 * - initial | no data given, awaiting input
	 * - loading | loading content
	 * - progressing | progress bar, uploading
	 * - error | erroneous
	 *
	 * @param state
	 * @param data
	 * @private
	 */
	async _setState(state = this.defaultState, data) {
		let mainCssClasses = 'om-lib-element';  // assembles all controlling css clases. We now avoid rerendering of the dom element
		// Get the current type of the media
		let type = (data) ? this.getType().id : '';
		let typeLabel = (data) ? this.getType().label : '';

		if (!this.$el) {
			console.warn('No Element found!');
			return;
		}

		/// ------- PATCH :: BACKWARDS COMPATIBILITY for editor elements 6 && 7
		// Refresh size & alignment (backwards comp) || Backwards compat for KEY:
		// There is no is-pulled-- alignment anymore, so this needs to be replaced
		const _sd = this._getSizeData()
		if (_sd) {
			const {key} = _sd
			if (key) {
				// example: is-two-thirds:66:auto  => width:width in %:alignment
				let alignment = key.split(':')[2]
				// We had 2 diff components: left | right which is now one.
				// So we need to determine what original aligment we had, because the ALIGMENT key is surely 'auto' - and useless
				if (alignment === 'auto') {
					// try to get initial position || this.$placeholder.hasClass('is-pulled-left')
					if (this.$placeholder.hasClass('is-pulled-left')) alignment = 'is-pulled-left';
					else if (this.$placeholder.hasClass('is-pulled-right')) alignment = 'is-pulled-right';
				}
				this._uppdateSizeAndAlignment('align', alignment)
			}
		}
		// <---- end of patch

		// Initial state
		mainCssClasses += (state.initial) ? ' initial' : '';
		// Show add button if initial state and requested
		mainCssClasses += (state.initial && this._options.showAddButton) ? ' add' : '';
		// Loading state
		mainCssClasses += (state.loading) ? ' loading' : '';
		// Progress state
		mainCssClasses += (state.progressing) ? ' progressing' : '';
		// Error state
		mainCssClasses += (state.error) ? ' error' : '';
		// Element is resizeable, img && videos
		mainCssClasses += ((type !== 'audio') && this._options.resizeable) ? ' resizeable' : '';
		// Selectable state
		mainCssClasses += (this._options.selectable) ? ' selectable' : '';
		// Caption
		mainCssClasses += (this._options.caption) ? ' show-caption' : '';
		// ShowOnClick (lightbox wrapper)
		mainCssClasses += (this._options.showOnClick) ? ' show-lightbox' : '';
		// Mode
		mainCssClasses += (this._options.mode) ? ' ' + this._options.mode : '';

		// Meta state, enable or disable buttons simply with css
		let metaCssClasses = 'meta';
		metaCssClasses += (this._options.editable) ? ' editable' : '';
		// metaCssClasses += (this._options.swapable) ? ' swapable' : '';
		metaCssClasses += (this._options.deleteable) ? ' deleteable' : '';
		metaCssClasses += (this._options.deleteableFile) ? ' deleteable-file' : '';
		metaCssClasses += (this._options.removeable) ? ' removeable' : '';
		this.$el.find('.meta').attr('class', metaCssClasses);

		// Todo: highlight current selected size (.resizer .tag.mini)

		// Main meta state. Do not show if no elements are visible OR the state is inital
		mainCssClasses += (!state.initial && metaCssClasses.length) ? ' show-meta' : '';

		// Add the media's origin - e.g. vimeo needs different css
		mainCssClasses += ' locationtype-' + data?.locationType;

		this.$el.attr('class', mainCssClasses);

		// ------------------
		// Apply current data-
		this.$el.data('libtype', data?.libType);

		// Linkwrapper === lightbox
		if (this._options.showOnClick) {
			let {_linkWrapperStart, _linkWrapperEnd, _url} = this._getLightboxWrapper(this.getData());
			// this.$el.find('.state').unwrap('<div uk-lightbox><a></a></div>');
			if (!this.$placeholder.find('[uk-lightbox]').length)
				this.$placeholder.find('.inner').wrap(_linkWrapperStart + _linkWrapperEnd);
			else
				this.$el.find('a').attr('href', _url);  // Just update the link
		}

		// Caption
		let title = (data) ? data.title : '';
		let subtitle = (data) ? data?.subtitle : '';
		// Internal, external location
		let tags = '';
		if (data.id && data.media && +data.locationType > 1)
			tags = '<span class="om-tag xs"><span uk-icon="icon: cloud-download"></span></span>';
		// else if (data.id && +data.locationType === 1) tags = '<span class="tag">Intern <span uk-icon="icon: server"></span></span>';

		// Dynamic title(s) ---------
		this.$el.find('[data-dynamic="le--title"]').empty().text(title);
		this.$el.find('[data-dynamic="le--subtitle"]').empty().text(subtitle);
		this.$el.find('[data-dynamic="le--type"]').empty().text(typeLabel);
		this.$el.find('[data-dynamic="le--tags"]').empty().html(tags);

		// Data attributes are put to the WRAPPING PLACEHOLDER ---------------
		// this.$placeholder.data(data);   // !!!!
		this.$placeholder.data('id', data.id);
		// ISSUE: This breaks the camelCase convention!!! See editor_tools_omlinks.js ~L:218ff for issue
		this.$placeholder.data('libtype', data.libType);
		this.$placeholder.data('locationtype', data.locationType);
		// hacked / needs cleanup: all API-models use camelcase. If you look through the code,
		// you will find lots of places where NO camelcase is used.
		// Hence this sh... all twice.
		this.$placeholder.data('libType', data.libType);
		this.$placeholder.data('locationType', data.locationType);
		// ~~~

		// Action attributes. Currently watched by media.js (external listeners), no emit! -----------------
		this.$placeholder.attr('data-type', "libelement");
		this.$placeholder.attr('data-action', "libelement");
		this.$placeholder.attr('data-command', this._options.selectable || this._options.showOnClick ? 'select' : 'editmeta');

		// -----------------
		// Input fields(old)
		this.$el.find('[data-dynamic="input-id"]').val((data) ? data.id : null);

		// Apply Sizes ------
		let {w, cssClass} = this._getSizeData() || ''
		// Improve and simplify. Use is-pulled on $parent and apply size to $el instead!
		// LEFT / RIGHT FLOATS. Not elegant // worse: keep alignment with these hacks
		// cssClass += ' ' + this.$placeholder.data('alignment')
		this.$placeholder.attr('class', cssClass);
		// Apply to meta (highlight current)
		this.$placeholder.find(`.resizer [data-size]`).removeClass('active');
		this.$placeholder.find(`.resizer [data-size="${ w }"]`).addClass('active');

		// Initial state means that there is no image/preview at all.
		// Improve this logic: 1st get preview, then 2nd check for content. Initial state should not matter.
		if (state.initial) {  // still wonky logic!\
			this.$el.find('img').attr('src', '');
			this.$el.css(`background-image`, `none`);
			this.$el.find('.object').empty();  // Remove any embedded stuff
			this.$el.removeClass('loading');
			// Render a placeholder at least:
			this._preview = await this.renderPreview(data)
			this.emit('LE_RENDERED');
			return true; // Stop here for now.
		}  // background

		// -----------------
		// Image/Video/Character, render preview, promise
		// NOTE: Previews can be embedded objects (audio, video) too!
		// NEW: Embed code. Only indication is data.media.embedCode

		if (data.media && data.media.embedCode && data.media.embedCode.length) {
			const $embed = $.parseHTML(this.base.htmlDecode(data.media.embedCode));
			this.handleEmbedCode($embed);
			this.$el.removeClass('loading');
			return true;
		}

		// -------------- Render preview
		this._preview = this._preview ? this._preview : await this.renderPreview(data, this._getSizeData());
		// console.info('StATE -> ', this._preview, data, this._data)


		const {src, object} = this._preview;
		// Remove bg image
		this.$el.css(`background-image`, `none`);
		// Place the img src
		this.$el.find('img').attr('src', src);
		if (src) this.$el.addClass('has-image'); else this.$el.removeClass('has-image');
		if (!object) {
			// Background image if a resize or cropped item
			if (this._options.resizeable || this._options.mode === 'cropped') this.$el.css(`background-image`, `url("${ src }")`);  // background
		} else {
			// Place an embedded object (vid, aud)
			// NOTE: See ~L:535ff -- this should not execute anymore:
			this.$placeholder.find('.object').empty().html(object);
			this.$el.addClass('has-object');
			this.$el.addClass('has-image'); // image too, to stretch
			// Use preview image to create a placeholder for w/h
			this.$el.css(`background-image`, `url(${ src })`);

		}

		this.emit('LE_RENDERED');
		this.$el.removeClass('loading');
		return true;

	}

	/**
	 * Render the element into this.$placeholder and return the template string (this.$el)
	 * @return {string}
	 * @private
	 */
	_render() {
		// --------------------------------------------------------------------------
		// Wrap in lightbox tag
		// let {_linkWrapperStart, _linkWrapperEnd} = this._getLightboxWrapper(this.getData());

		// --------------------------------------------------------------------------
		// Assemble initial container and return
		let tpl = `<figure class="om-lib-element" leinstance>
		
	                 <div class="inner">
	                    <img src='' />
	                     <div class="object"></div>
	                    <div class="tags" data-dynamic="le--tags"></div>
						<div class="caption">
                            <h4 data-dynamic="le--title" style="margin:0;">&nbsp;</h4>
                            <div data-dynamic="le--subtitle">&nbsp;</div>
                            <span class="type" data-dynamic="le--type"></span>
                        </div>
	                 </div>

				     <div class="state">
						<progress class="uk-progress" value="0" max="100"></progress>
		                <span class="loader uk-margin-small-right" uk-spinner="ratio: 2"></span>
		                ${ this._getMetaControls() }
	                 </div>
	                 
	                 <a class="om-control" href="javascript:void(0)" data-action="libelement" data-command="add" >
	                        <span uk-icon="icon: plus-circle; ratio: 2" class="uk-icon"><svg width="40" height="40" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" data-svg="plus-circle"><circle fill="none" stroke="#000" stroke-width="1.1" cx="9.5" cy="9.5" r="9"></circle><line fill="none" stroke="#000" x1="9.5" y1="5" x2="9.5" y2="14"></line><line fill="none" stroke="#000" x1="5" y1="9.5" x2="14" y2="9.5"></line></svg></span>
	                        <span>Element wählen </p>
	                 </a>
	                 
	                 <input data-dynamic="input-id" type="text" name='${ this._options.input }' class="om__placeholder_input" value="">	
				</figure>`
		return tpl;
	}

	/**
	 * Check url for real
	 * Only successful load the thumb will be displayed, else fallback to placeholder
	 * @since 0.16.22: add urlPrefix
	 */
	async _urlCheck(url, placeholder) {
		return new Promise((resolve) => {
			let tmpimg = new Image(), self = this;
			// HACK to circumvent  local CORS issues
			url = url.replace('omnicms.local', 'localhost:3003')
			//
			tmpimg.onload = function (e) {
				// Nope, @deprecated here. Or else this will happen all the time.
				// EXIF.getData(this, function () {
				// 	self._updateExif(EXIF.getAllTags(this));
				// });
				resolve(url);
			}
			tmpimg.onerror = function () {
				console.warn('IMG source not valid')
				this._setState({error: true})
				resolve(placeholder);
			}
			tmpimg.src = url;
		});
	}

	/**
	 * Return a requested image sizes,
	 * test if available and if uri is valid
	 * else return thumb or placeholder
	 * @param data
	 * @param size
	 * @param urlCheck Boolean if set, then load image, test if exists
	 * @return {Promise<{src: unknown, object: null}>}
	 */
	async getImageSize(data, size = "thumb", urlCheck = false) {
		// this._setState({loading: true})
		let src = '';
		// let type = data.libType ? this.base.getLibElementType(+data.libType) : -1;
		// Get initial placeholder
		// let placeholder = type ? type.icon : ''; //omPlaceholders.NOT_FOUND;

		// Check if data has all things needed or if we need to load the whole record
		// ::: THIS SHOULD NOT HAPPEN, because the setData() fn should have taken care of it. kerfuffle...
		// If a larger image is requested, we may need to fetch this from the api
		// If data.id || this._options.inital? -- better make a proper state!\
		const sizes = data?.media?.sizes || null;
		//	console.log('----------------> ', size, data.title, sizes)

		// Get requested size
		if (size === "thumb") {
			src = data.thumb;
			return ({src: src, object: null});	// NO uri check. We assume the best.
		} else if (sizes) {
			switch (size) {
				case 'small' :
					src = sizes.small.url;
					break;
				case 'medium' :
					src = sizes.medium.url;
					break;
				case 'large' :
					src = sizes.large.url;
					break;
				case 'original' :
					src = urlPrefix + data.url;
					break;
			}
		} else if (data.thumb) {
			// Retry to get thumbnail at least
			src = data.thumb
		}

		// if (!urlCheck)
		return ({src: src, object: null});

		// urlcheck will do an XHTTP request to make sure it's there. Don't use unless really needed.
		// const r = await this._urlCheck(`${src}`, placeholder)
		// return ({src: r, object: null});
	}

	/**
	 * Render preview image
	 * Use buffer image, url or placeholders
	 *
	 * @param _data
	 * @param _type
	 * @private
	 */
	async renderPreview(_data, options = {size: 'thumb'}) {
		return new Promise(async resolve => {

			// ----- Return empty if this is a freshly initialised object
			if (!_data) {
				resolve(this._options.initial ? {src: '', object: ''} : {src: omPlaceholders.GENERIC, object: ''});
			}
			// ----- Check, if we have the whole record or just an id
			// Todo: preview API should deliver all sizes.
			if (!_data.thumb && _data.id) {
				await LibraryElementModel.load(_data.id).then((d) => {
					// Reload it, but stop executing this. Because .setData() will recur:
					this.setData(d, false, {loading: false, loaded: true, initial: false});
					_data = d;
					return false
				});
			}

			// ----- Assemble
			let object = '',			// just used when we have embeddable objects
				type = _data.libType ? this.base.getLibElementType(+_data.libType) : {id: 'image', type: 1};		// D.R.Y.
			// Get initial placeholder
			let placeholder = type ? type.icon : ''; //omPlaceholders.NOT_FOUND;

			// ----- Check the type and render the preview.
			switch (type.id) {
				case 'image' :
				case 'reference' :
					// (1) Base64match
					// A freshly added image will need to get the base64 daa from buffer before upload
					const base64Matcher = /^\s*data:([a-z]+\/[a-z]+(;[a-z\-]+\=[a-z\-]+)?)?(;base64)?,[a-z0-9\!\$\&\'\,\(\)\*\+\,\;\=\-\.\_\~\:\@\/\?\%\s]*\s*$/i;
					if (base64Matcher.test(_data.thumb)) {
						// let mimeType = src.substring("data:image/".length, src.indexOf(";base64"))
						// let r = await this._urlCheck(_data.thumb, placeholder);
						resolve({src: _data.thumb, object: ''});
						break;
					}

					// (2) Existing image on server
					const im = await this.getImageSize(_data, this._options.size);		// we just had the id, re/load the image
					// Nothing? Render placeholder then.
					if (im.src === '' && !im.object)
						im.src = type.id === 'image' ? omPlaceholders.IMAGE : omPlaceholders.REF;

					resolve(im);
					break;
				case 'video' :
					//https://www.youtube.com/watch?v=kn9ONbFbX9I&list=RDkn9ONbFbX9I&start_radio=1
					//https://vimeo.com/146610824 || https://vimeo.com/432447864
					const r = await this.base.getVideoPoster(_data.urlPrefix + _data.url)
					if (r) {
						let ret = {}
						// Set this preview
						this._preview = r.src;
						// Update "exif" with video data if there are some & this is a new form
						// this._updateExif(r.exif);
						// mime/ not needed yet

						// Embed?
						if (this._options.mode === 'box') {
							// This is the display in the medialib and has only preview image
							ret = {src: r.src, object: null};
						} else {
							// Get the video as object to embed
							// If internal, prefix with server url
							object = _data.locationType < 2 ? this.base.getVideoObject(_data.urlPrefix + _data.url) : this.base.getVideoObject(_data.url);
							// Todo: returning both requires the video to be absolute positioned
							ret = {src: object ? '' : r.src, object: object};
						}

						// Let the caller (medialib) know !]
						this.emit(eventList.ACTION, {message: 'OK', action: 'success.mediaLinkField'});
						resolve(ret);

					} else {
						this.emit(eventList.ACTION, {message: 'Unzulässiger Link', action: 'error.mediaLinkField'});
						// Remove preview
						this.handleFileLink('');
						resolve({src: omPlaceholders.NOT_FOUND, object: object});

					}
					break;
				case 'text' :
				case 'document':
					// this.setData({...this.getData(), ...{thumb: placeholder}})
					// Our object is simple html with some info [if not a small box in library]
					if (this._options.mode !== 'box')
						object = `<div style="padding:0.75em;background:rgba(255,255,255,0.6);backdrop-filter:blur(1px);">
					<h4>${ _data.title }</h4>
					<small>${ _data.data && _data.data.type && _data.data && _data.data.type.label }<br/>
					${ _data.data && _data.data.subType && _data.data && _data.data.subType.label }</small>
				</div>`
					resolve({src: placeholder, object: ''});
					break;
				case 'audio' :
					// We show the HTML5 player NOTE: Assuming MP3 only!!!! Todo: check MIME.
					placeholder = omPlaceholders.AUDIO;
					object = `<audio controls name="media"><source src="${ _data.urlPrefix + _data.url }" type="audio/mp3"></audio>`
					resolve({src: '', object: object});
					break;
				default:
					// src = omPlaceholders.NOT_FOUND;
					resolve({src: placeholder, object: null});
					break;
			}
		});
	}

	/**
	 * Return meta controls tpl string
	 * @return {string}
	 * @private
	 */
	_getMetaControls() {
		// --------------------------------------------------------------------------
		/**
		 * META Resizer Options
		 * This allows the dynamic resizing of the image
		 * The values are stored in the DOM as [data-size] JSON object
		 */
		let _resizer = `
			<div class="resizer uk-flex">
				<span class="uk-tag mini" data-on-click="setsize" data-command="size" data-param="auto">auto</span>
				<span class="uk-tag mini" data-on-click="setsize" data-command="size" data-param="15">15</span>
				<span class="uk-tag mini" data-on-click="setsize" data-command="size" data-param="25">25</span>
				<span class="uk-tag mini"  data-on-click="setsize" data-command="size" data-param="33">33</span>
				<span class="uk-tag mini"  data-on-click="setsize" data-command="size" data-param="50">50</span>
				<span class="uk-tag mini"  data-on-click="setsize" data-command="size" data-param="66">66</span>
				<span class="uk-tag mini"  data-on-click="setsize" data-command="size" data-param="75">75</span>
				<span class="uk-tag mini"  data-on-click="setsize" data-command="size" data-param="100">100</span>
				
				<span class="uk-tag mini"  data-on-click="setsize" data-command="align" data-param="is-pulled-left">
					<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
					  <g transform="translate(-647 -310)">
					    <g transform="translate(647 310)" fill="none" stroke="#ffffff" stroke-width="2">
					      <rect x="0.5" y="0.5" width="35" height="35" fill="none" stroke="#ffffff" stroke-width="1"/>
					    </g>
					    <g transform="translate(651 314)" stroke="#ffffff" stroke-width="1">
					      <rect x="0.5" y="0.5" width="13" height="13" fill="white"/>
					    </g>
					  </g>
					</svg>
				</span>
				<span class="uk-tag mini"  data-on-click="setsize" data-command="align" data-param="is-block">
					<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
					  <g transform="translate(-647 -310)">
					    <g transform="translate(647 310)" fill="none" stroke="#ffffff" stroke-width="2">
					      <rect x="0.5" y="0.5" width="35" height="35" fill="none" stroke="#ffffff" stroke-width="1"/>
					    </g>
					    <g transform="translate(651 314)" stroke="#ffffff" stroke-width="1">
					      <rect x="7" y="0.5" width="13" height="13" fill="white"/>
					    </g>
					  </g>
					</svg>
				</span>
				<span class="uk-tag mini"  data-on-click="setsize" data-command="align" data-param="is-pulled-right">
					<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36">
					  <g transform="translate(-647 -310)">
					    <g transform="translate(647 310)" fill="none" stroke="#ffffff" stroke-width="2">
					      <rect x="0.5" y="0.5" width="35" height="35" fill="none" stroke="#ffffff" stroke-width="1"/>
					    </g>
					    <g transform="translate(651 314)" stroke="#ffffff" stroke-width="1">
					      <rect x="11" y="0.5" width="13" height="13" fill="white"/>
					    </g>
					  </g>
					</svg>
				</span>
			</div>
			`;

		// Trigger change would only be possible with $(DOM), but we do not catch the events
		// --------------------------------------------------------------------------
		// Meta controls, combined
		return `<div class="meta">
			${ _resizer }
			<span uk-icon="icon: pencil" data-action="libelement" data-command="editmeta"></span>
			<span uk-icon="icon: refresh" data-action="libelement" data-command="swap"></span>
			<span uk-icon="icon: trash" data-action="libelement" data-command="delete"></span>
			<span uk-icon="icon: trash" data-action="libelement" data-command="deletefile"></span>
            <span uk-icon="icon: ban" data-action="libelement" data-command="remove"></span>
        </div>`;
	}

	/**
	 *
	 * @param data
	 * @returns {{_linkWrapperEnd: string, _linkWrapperStart: string}}
	 */
	_getLightboxWrapper(data) {
		let _linkWrapperStart, _linkWrapperEnd, _url;
		if (!this._options.showOnClick || !data) {
			return {_linkWrapperStart: '', _linkWrapperEnd: ''};
		}
		let _type = this.getType().id;
		let _mimeType = data.media && data.media.mimeType || 'UNKNOWN';   // TODO: mimeType!
		switch (_type) {
			case 'image':
			case 'reference' :
				if (data.locationType > 1) {
					_url = data.url;
				} else
					_url = (data.media && data?.media?.sizes) ? `${ data?.media?.sizes.large.url }` : `${ data.thumb }`;
				_linkWrapperStart = `<div uk-lightbox><a href="${ _url }">`;
				break;
			case 'video' :
				// Switch locationType here and embed as per specs
				_url = (data.url) ? data.urlPrefix + data.url : `${ data.thumb }`
				_linkWrapperStart = `<div uk-lightbox><a href="${ _url }">`;
				_linkWrapperEnd = '</div>';
				break;
			case 'audio' :
				if (data.url && data.media) {
					// Audio plays inline, no lightbox needed
					_linkWrapperStart = '';//`<audio controls name="media"><source src="${data.url}" type="audio/mp3"></audio>`
					_linkWrapperEnd = '';
				} else {
					_linkWrapperStart = _linkWrapperEnd = '';
				}
				break;

			default:
				// Internal vs. external
				_url = (data.media && data.locationType > 1) ? data.url : `${ data.urlPrefix + data.url }`
				_linkWrapperStart = `<div uk-lightbox><a href="${ _url }" data-type="iframe">`;
				break;
		}
		return {_linkWrapperStart: _linkWrapperStart, _linkWrapperEnd: _linkWrapperEnd, _url: _url}
	}

	/**
	 * Handle EXIF data, currently emit availability
	 * and map data to libModel
	 *
	 * @param exif JSON object
	 * @private
	 */
	_updateExif(exif) {
		// Map
		// Filter needed exif data
		/*
		 Artist -> author
		 Copyright -> licence
		 ImageDescription -> description (abstract) + Alt
		 DateTimeOriginal -> publicationDate
		 // older:
		 mediaDescriptionEx
		 mediaLicenceEx"
		 mediaAuthorEx"
		 mediaCopyrightNoticeEx"
		 mediaRightsEx"
		 mediaCreatorEx"
		 */
		console.log('EXIF = ', exif)
		if (!exif) return;
		this.setData({
			...this.getData(), ...{
				data: {
					description: exif.ImageDescription,
					publicationDate: exif.DateTimeOriginal // TODO: publicationDate
				},
				media: {
					author: exif.Artist,
					licence: exif.Copyright,
					alt: exif.ImageDescription
				}
			}
		});
		// TODO: Update data without rerendering.
		// this.setData(_data, false, false);
		// Emit
		this.emit(eventList.MEDIA_EXIF_AVAILABLE, {...exif});    // Let the meta modal handle this (for now)
	}

	/**
	 * Return current element's size json
	 * @return {*}
	 */
	_getSizeData() {
		return this.$placeholder.data('size')
	}

	/**
	 * Add events to THIS
	 * @private
	 */
	_addEvents() {
		// Clear all events
		this._removeEvents();
		// cr4p
		const EL = this.$placeholder //$(this.placeholder + ' .om-lib-element')

		// --------------------------------------------------------------------------

		// Edit = Swap
		// EL.on('click', 'span[data-command="swap"]', () => {
		// 	// Send to base / main script
		// 	this.base.emit(eventList.OPEN_MEDIA_LIBRARY, this.placeholder);
		// });

		// Edit: Open the meta-modal
		EL.on('click', 'span[data-command="editmeta"]', () => {
			// Todo: We can update the element right here
			// After the form has been updated
			// Send to base / main script
			const payload = {id: this.getData().id, type: this.getData().libType};
			this.base.emit(eventList.OPEN_MEDIA_LIBRARY_EDITOR, payload)
			// Todo: Update itself autonomously
			// this.OM_Media_Meta_Modal.on(eventList.MEDIAMETA_UPDATED, (payload) => {
			// 	// ...
			// })
		});

		// Watch removal event
		EL.on('click', 'span[data-command="remove"]', this._remove.bind(this));
		// Delete Record: Watch delete event :: put this element to busy state and send to API
		EL.on('click', 'span[data-command="delete"]', this._delete.bind(this));
		// Delete File only:
		EL.on('click', 'span[data-command="deletefile"]', this._deleteFile.bind(this));
		// Add
		EL.on('click', '[data-command="add"]', this._add.bind(this));
		// Set size & alignment
		EL.on('click', '[data-on-click="setsize"]', this._setSize.bind(this));

		// NOTE: this is new. Make sure we do not make a mess out of events. All older components handle this separately.
		// Todo: this will trigger per ever(!!!) loaded element on screen. It may push eventListeners to max.
		// Wait on media-selection event.
		// The @payload params are a JSON object from the DOM-data
		// LIKE: {id: '', libType: n, locationType: n, name: ''}
		// and they will not include media-sizes and data-node,
		// So the renderPreview() fn will take care of this.
		this.base.on(eventList.MEDIA_SELECTION, async (payload) => {
			if (payload.placeholder === this.placeholder) {
				// Set payload
				let data = payload.mediaArr[0]
				this._preview = null;       // important to reset, so renderPreview will re-render the image / preview
				this.setData(data, false, {initial: false, loaded: false, progressing: false});
			}
		});

		// --------------------------------------------------------------------------
		// Custom panning

		// - step by step: http://jsfiddle.net/CqcHD/2/
		// - npm: https://www.npmjs.com/package/panzoom
		//


	}

	_removeEvents() {
		const EL = this.$placeholder //$(this.placeholder + ' .om-lib-element')
		if (EL) {
			EL.off()
		}
	}

	// -------------
	// LE internal events
	// Remove Element from placeholder
	_remove() {
		this._options.initial = true;   // force initial state. NOT: mutation
		//this._options.showAddButton = true;       // should be within "initial"
		this._preview = null;
		this.setData(null, false, this.defaultState).finally(() => {
			// Reset size data too??
			// Notify whoever needs to know
			this.emit(eventList.MEDIA_REMOVE);
		});
	}

	/**
	 * Delete record and file
	 * @private
	 */
	_delete() {
		UIkit.modal.confirm(`Soll dieses Element wirklich gelöscht werden? Echt jetzt?`, {stack: true})
		.then(() => {
			// let loaderImg = new Loader($el);
			// loaderImg.show();
			LibraryElementModel.delete(this.getData().id)
			.then(r => {
				UIkit.notification({
					message: `Das Element wurde gelöscht.`,
					status: 'success',
					pos: 'top-right',
					timeout: 3000
				});
				this.$placeholder.fadeOut().remove();
			}).catch(error => {
				if (error && error.response)
					UIkit.notification({
						message: `${ error.response.data.message }`,
						status: 'warning',
						pos: 'top-right',
						timeout: 5000
					});
			})
			.finally(_ => {
				// loaderImg.hide();
			})
		}, function () {
			console.log('Rejected.')
		});
	}

	/**
	 * Delete a file only connected to this Element
	 * Note: currently only type = 2048 (reference)
	 * @param file
	 */
	_deleteFile() {
		LibraryElementModel.deleteFile(this.getData().id)
		.then(r => {
			// Assuming r.status === 200 //
			UIkit.notification({
				message: `Das Bild wurde entfernt.`,
				status: 'success',
				pos: 'top-right',
				timeout: 3000
			});
			// Todo: unclean still. If the thumb === null then we won't get a proper preview.
			this._preview = null;
			this.setData({...this.getData(), ...{url: '', thumb: null, media: null}}, false, {...this.defaultState, ...{initial: true}});
		}).catch(error => {
			if (error && error.response)
				UIkit.notification({
					message: `${ error.response.data.message }`,
					status: 'warning',
					pos: 'top-right',
					timeout: 5000
				});
		})
		.finally(_ => {
			// loaderImg.hide();
		});
	}

	/**
	 * Add a media element (file) to this record
	 *
	 * @private
	 */
	_add() {

		// Emit own event to leave the handling the mediaLib overlay to superClass
		this.emit(eventList.OPEN_MEDIA_LIBRARY, {placeholder: this.placeholder, input: this._options.input});
		// Options for media-lib-modal
		let modalOptions = {
			placeholder: this.placeholder,
			input: this._options.input,
			options: {
				stack: true,
				readOnly: true,
				selectionMode: true,
				types: this.getAllowedTypes()
			}
		}
		// emit
		this.base.emit(eventList.OPEN_MEDIA_LIBRARY, {...modalOptions, ...{placeholder: this.placeholder}});
	}


	/**
	 * Update element size
	 * and alignment.
	 * Opinionised styles added here (bulma for frontend!)
	 * @param command
	 * @param val
	 * @private
	 */
	_uppdateSizeAndAlignment(command, val) {
		const currentData = this._getSizeData();            // ambigous name -> getting size + alignment
		let {w, cssClass, key} = currentData;                    // get css from js data
		// Strip size from alignment and vice versa
		let cssSize = cssClass && cssClass.replace(/is-pulled-left|is-pulled-right|is-block/gi, "").replace(/\s\s+/g, ''),
			cssAlignment = cssClass && cssClass.replace(/is-one-eighth|is-one-quarter|is-one-third|is-half|is-two-thirds|is-three-quarters|is-full/gi, '').replace(/\s\s+/g, ' ');

		if (command === 'size') {
			// Translate to bulma css:
			switch (val.toString()) {
				case '15':
					cssSize = 'is-one-eighth';
					break;
				case '25':
					cssSize = 'is-one-quarter';
					break;
				case '33':
					cssSize = 'is-one-third';
					break;
				case '50':
					cssSize = 'is-half';
					break;
				case '66':
					cssSize = 'is-two-thirds';
					break;
				case '75':
					cssSize = 'is-three-quarters';
					break;
				case '100':
					cssSize = 'is-full';
					break;
				default:
					'';
					break;
			}
			w = val;
		} else if (command === 'align') {
			cssAlignment = val.replace(/\s\s+/g, ' ');
		}

		// Now, the funny part. We need to keep alignment / and / or size css
		cssClass = cssAlignment + ' ' + cssSize;
		// Set size data again
		this.$placeholder.data('size', {w: w || 'auto', h: 'auto', cssClass: cssClass.replace('undefined', '')});
		// Update this element
		this.setData(this.getData());
		// Let anyone interested know.
		this.emit(eventList.MEDIAMETA_UPDATED, this.getData());
	}

	// Set size & alignment data from event (user clicked btn)
	_setSize(e) {
		if (!e) return
		const command = $(e.currentTarget).data('command'),
			val = $(e.currentTarget).data('param');
		this._uppdateSizeAndAlignment(command, val);
	}

}
