/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Cls = (class Link extends ContentTools.Tools.Link {
	static initClass() {

		// Insert/Remove a link.

		ContentTools.ToolShelf.stow(this, 'omLink');

		this.label = 'Link';
		this.icon = 'link';
		this.tagName = 'a';
	}


	static canApply(element, selection) {

		if (element.type() === 'Image') {
			return true;
		} else if (element.isFixed() && (element.tagName() === 'a')) {
			return true;
		} else {
			// Must support content
			if (!element.content) {
				return false;
			}

			// A selection must exist
			if (!selection) {
				return false;
			}

			// If the selection is collapsed then it must be within an existing
			// link.
			if (selection.isCollapsed()) {
				const character = element.content.characters[selection.get()[0]];
				if (!character || !character.hasTags('a')) {
					return false;
				}
			}

			return true;
		}
	}

	static isApplied(element, selection) {
		// Return true if the tool is currently applied to the current
		// element/selection.

		// Get wrapping tag and linkType. Filter out any custom linkTypes we use (knowledge / library)
		const character = selection ? element.content.characters[selection.get()[0]] : element.content.characters[0];
		// Check if there is another tag wrapping the link (e.g. <i>,<strong>,...)
		const aTags = character.tags().filter((t)=>t._name === "a")[0];
		const linkType = aTags ? aTags._attributes['data-linktype'] : undefined;
		if (typeof linkType !== 'undefined') return false

		if (element.isFixed() && (element.tagName() === 'a')) {
			return true;
		} else {
			return super.isApplied(element, selection);
		}
	}


});
Cls.initClass();
