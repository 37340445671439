import UIkit from "uikit";

// The corresponding model (simple as) to the API
let initial = {
	id: null,
	parentId: null,
	children: [],
	status: 2,	// default = 2
	level: 0,
	world: 0,
	epoch: 0,
	wbId: null,
	schoolSubj: [],
	eduTasks: [],
	operators: [],
	dimensioning: {},
	title: '',
	teaser: '',
	teaserImg: null,
	content: '',
	contentAff: ''
};


const TaskModel = {

	data: {...initial},

	/**
	 * Initialise an empty data object
	 */
	create() {
		this.data = {...initial};
	},

	/**
	 * Set props
	 * @param props
	 * @param sync
	 */
	set(props, sync = false) {
		return new Promise((resolve, reject) => {
			this.data = {...this.data, ...props};
			// Save to DB if wanted
			if (sync) this.save(props).then((r) => {
				resolve(true);
			}).catch(() => {
				reject();
			})
			else
				resolve(true);  // just set
		})
	},

	/**
	 * Get props
	 * @returns {{parent: null, eduTasks: [], level: number, teaserImg: null, epoch: number, title: string, contentAff: string, content: string, world: number, children: [], operators: [], id: null, schoolSubj: [], dimensioning: {}, status: number, teaser: string}}
	 */
	get() {
		return this.data;
	},

	/**
	 * Get a single task by ID from API
	 * @param id
	 * @returns {Promise}
	 */
	load(id) {
		return new Promise((resolve, reject) => {
			window.omApi.getTask(id)
				.then((result) => {
					// NOTE: We need to deep clone the object in the receiving logic or we loose deeply nested props!
					if (result.data) {
						this.data = result.data;
						resolve(result.data);
					} else {
						reject(false)
					}
				})
			.catch(e => {
				console.warn(e)
			});
		})
	},

	loadCollection() {
		return window.omApi.getTasks();
	},

	/**
	 * Send current data to API
	 * @param data
	 * @returns {Promise}
	 */
	save(data) {
		return new Promise((resolve) => {
			// ----
			// Todo: Clean up the data we get. It may be a set of all inputs available
			// Idea: match with this model and build only when keys are available
			// ----
			if (data.id) {
				window.omApi.updateTask(data.id, data).then(r => {
					// Todo: this should be intercepted properly.
					if (r && +r.status === 200) {
						UIkit.notification({
							message: `Die Aufgabe wurde gespeichert.`,
							status: 'success',
							pos: 'top-right',
							timeout: 5000
						});
						resolve(r);
					} else {
						UIkit.notification({
							message: `Die Aufgabe konnte nicht gespeichert werden.`,
							status: 'danger',
							pos: 'top-right',
							timeout: 5000
						});
					}
				}, e => {
					UIkit.notification({
						message: `Die Aufgabe konnte nicht gespeichert werden.`,
						status: 'danger',
						pos: 'top-right',
						timeout: 5000
					});
				})
			} else {
				// Create
				window.omApi.createTask(data).then(r => {
					UIkit.notification({
						message: `Die Aufgabe wurde angelegt.`,
						status: 'success',
						pos: 'top-right',
						timeout: 5000
					});
					// TODO: we need the ID of the new task
					// Reload the page (unfortunately, quick hack to refresh php tree as well)
					console.log('Created new Task with ', r)
					window.location.href = `/aufgabe/${r.data.taskId}`;
					resolve(r);	// useless.
				}, e => {
					UIkit.notification({
						message: `Die Aufgabe konnte nicht gespeichert werden.`,
						status: 'danger',
						pos: 'top-right',
						timeout: 5000
					});
				})
			}
		})
	},

	/**
	 * Delete this thing via API
	 * @param id
	 * @returns {Promise}
	 */
	delete(id) {
		return new Promise((resolve, reject) => {
			window.omApi.deleteTask(id, {showLoading: false}).then(r => {
					UIkit.notification({
						message: `Die Aufgabe wurde gelöscht.`,
						status: 'success',
						pos: 'top-right',
						timeout: 5000
					});
					this.data = {...initial};
					this.dirty = false
					// Go home
					window.location.href = '/';
				},
				e => {
					UIkit.notification({
						message: `Die Aufgabe konnte nicht gelöscht werden.`,
						status: 'danger',
						pos: 'top-right',
						timeout: 5000
					});
					this.dirty = true;
				});
		})

	},


	/**
	 * Attach media objects to a task
	 * TODO: refactor this name
	 * @param data object of two strings with IDs {tasks:"ID,ID...", media:"ID,ID.." }
	 * @returns {*}
	 */
	attach(data) {
		// Convert arr to string
		data.tasks = (data.tasks && Array.isArray(data.tasks)) ? data.tasks.join(',') : data.tasks;
		data.media = (data.media && Array.isArray(data.media)) ? data.media.join(',') : data.media;
		// Put
		return window.omApi.attach(data);
	},

	/**
	 * Detach media objects from task
	 * TODO: refactor this name
	 * @param data
	 * @returns {*}
	 */
	detach(data) {
		// Convert arr to string
		data.tasks = (data.tasks && Array.isArray(data.tasks)) ? data.tasks.join(',') : data.tasks;
		data.media = (data.media && Array.isArray(data.media)) ? data.media.join(',') : data.media;
		// Put
		return window.omApi.detach(data);
	},


	/**
	 * Update dimensioning fields (title)
	 *
	 * @param payload
	 */
	async updateDimension(payload) {
		payload.taskId = payload.id;

		// [1,2,4,8,16].map(v=>{
		let v = payload.position;
		switch (payload.position) {
			case 3 :
				v = 4;
				break;
			case 4 :
				v = 8;
				break;
			case 5 :
				v = 16;
				break;
			case 6 :
				v = 32;
				break;
		}
		payload.position = v;
		// Post it
		return window.omApi.updateDimension(payload, {showLoading: false}).then(_ => {
			// UIkit.notification({
			// 	message: `Dimensionierung gespeichert`,
			// 	status: 'success',
			// 	pos: 'top-right',
			// 	timeout: 5000
			// });
		})
	},


	sort(data) {
		return window.omApi.taskSort(data, {})
	},

	// --------------------------------------------------------------------------------
	// Helpers
	// --------------------------------------------------------------------------------

	getKnowledgesAtPos(pos) {
		return this.data.dimensioning[`pos${pos}`].knowledges;
	}

}

export default TaskModel;
