import EventEmitter   from "eventemitter3";
import LibraryElement from "../library.element";

/**
 * @class
 * Simple Affection super-class
 *
 * Just provides the basic structure / interface for affection
 * see: affectionWrapper.js which should be merged into this
 *
 */
export default class Affection extends EventEmitter {
	constructor(base, placeholder, data, options) {
		super();
		this.base = base;
		this.$placeholder = $(placeholder);
		this._data = data;
		this.options = options;


	}

	/**
	 * Initialise custom event listeners
	 * @private
	 */
	_initEvents() {}

	/**
	 * Remove all custom event listeners
	 * @private
	 */
	_removeEvents() {}

	/**
	 * Destroy and kill that thing
	 */
	destroy() {
		this._removeEvents();
	}

	/**
	 * Return all currently associated libraryItemIds
	 * @return {*[]}
	 */
	getLibItems() {
		// extract libraryItemIds
		return (this._data) ? Object.keys(this._data).map(d => this._data[d] && this._data[d].id || null) : [];
	}

	/**
	 * Set component data
	 * This will extract the PODCASTS array data,
	 * other data (components[n] is up the the specific component calling this (super)
	 * @param data
	 */
	setData(data) {
		// ----- Set
		this._data = data;
	}

	getData() {
		this._convertToEditorFormat(this._data);
	}

	/**
	 * on command actions method
	 * Usually here we would react to all on-change, click events etc.
	 * But you will NEED to do this in each component.
	 * @param e
	 */
	commandActions(e) {}


	/**
	 * Convert to the component format for the editor.
	 * It will return an object: { components: [{THIS}] }   // it's in an array, because.... don't ask
	 * @param data
	 * @return {{}}
	 * @private
	 */
	_convertToEditorFormat(data) {
		let tmpData = {};
		// Mixin the LibraryElements (images, videos...) for main aff
		if (data && data.length)
			data.forEach((d, i) => {
				if (d)	// some bug corrupted data. May be there is a NULL item in there.
					tmpData[`le_0_s_${i}`] =
						{
							id: d.id,
							size: this.sizeData,
							meta: d.meta
						}
			});

		return tmpData;
	}

	/**
	 * Convert to a simple array of libraryOBjects.
	 * That's what we're working with
	 * @param data
	 * @return {*[]|*}
	 * @private
	 */
	_convertFromEditorFormat(data) {
		let _arr = [];
		if (!data) {
			console.warn('No proper data given to affection component');
			return [];
		}
		for (const [key, value] of Object.entries(data)) {
			// Only le_* elements
			if (key.substr(0, 3) == 'le_')
				_arr.push(value);
		}
		return _arr;
	}


}
