/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import {eventList, uuidv4} from "../utils/base";
import LibraryElement      from "../components/library.element";

const Cls = (class Link extends ContentTools.Tools.Link {
	static initClass() {

		// Insert/Remove a link.

		ContentTools.ToolShelf.stow(this, 'omImage');

		this.label = 'Bild';
		this.icon = 'image';
		this.tagName = 'img';

		this._uid = uuidv4();
	}


	static canApply(element, selection) {
		// Todo: check why:
		return true;
		console.log(element)
		if (element.type() === 'Image') {
			return true;
		} else if (element.isFixed() && (element.tagName() === 'p')) {
			return true;
		} else {
			// Must support content
			if (!element.content) {
				return false;
			}

			// A selection must exist
			if (!selection) {
				return false;
			}

			// If the selection is collapsed then it must be within an existing
			// link.
			if (selection.isCollapsed()) {
				const character = element.content.characters[selection.get()[0]];
				if (!character || !character.hasTags('a')) {
					return false;
				}
			}

			return true;
		}
	}

	static isApplied(element, selection) {
		// Return true if the tool is currently applied to the current
		// element/selection.

		// Get wrapping tag and linkType. Filter out any custom linkTypes we use (knowledge / library)
		// const character = selection ? element.content.characters[selection.get()[0]] : element.content.characters[0];
		// // Check if there is another tag wrapping the link (e.g. <i>,<strong>,...)
		// const aTags = character.tags().filter((t)=>t._name === "a")[0];
		// const linkType = aTags ? aTags._attributes['data-linktype'] : undefined;
		// if (typeof linkType !== 'undefined') return false
		//
		// if (element.isFixed() && (element.tagName() === 'a')) {
		// 	return true;
		// } else {
		// 	return super.isApplied(element, selection);
		// }
		return false
	}

	static apply(element, selection, callback) {

		let _imgelement = `<div data-name="omimg-${this._uid}"></div>`
		$(element._domElement).parent().prepend(_imgelement)

		// a) Open up media
		window.omBase.emit(eventList.OPEN_MEDIA_LIBRARY, {
			// placeholder: `.${this._uid}`,
			placeholder: `[data-name="omimg-${this._uid}"]`,
			callback: this.insertElement.bind(this),
			// param: {insertAt: 0},
			options: {
				types: ['image', 'video'],
				selectionMode: true,
				multiple: false,
				size: 'large',
			}
		});
	}

	static insertElement(media) {
		console.log('inserting image ', media[0])
		if (media && media[0].id) {
			new LibraryElement(omBase, `[data-name="omimg-${this._uid}"]`, media[0]);
		}
	}

});
Cls.initClass();
