/**
 * @class Loader creating a DOM loader within a given DOM element
 *
 */
export default class Loader {
	/**
	 * Creates a loading-wrapper around a DOM elemt
	 *
	 * @constructor
	 * @author Marco Siebert
	 * @param el STRING of DOM element id, class or JS dom object
	 * @param options JSON
	 */
	constructor(el, options = { full: false, class: '', show: false }) {
		this.$el = (el instanceof jQuery) ? el : $(el);
		this.$loader = null;

		if (!this.$el.length) {
			// console.warn(`LOADER FOR ${el} COULD NOT BE ATTACHED. Not found.`)
			return;
		}

		/**
		 * Configuration
		 * @type {{full: boolean}}      - covers the whole element
		 */
		this.options = options;
		if (options.show) this._wrap();
	}

	/**
	 * Append to DOM (this.$el)
	 * @private
	 */
	_wrap() {
		// No dupes
		this.$el.unwrap('.om__loader')
		this.$el.wrap(`<div class="om__loader" ></div>`);
		this.$loader = this.$el.parent('.om__loader');

		// if (!this.options.show) this.hide();
		// else this.show();
	}


	/**
	 * Show loader. TODO: move constructor logic to here. Give the loader a name
	 * @param el DOM Element : If set, it's created on the fly.
	 * @param opts JSON : Settings
	 */
	show(opts) {
		this._wrap()    // make sure its still there
		this.options = { ...this.options, ...opts };
		// NAV
		// window.omBase.emit('NAV_BUSY', true);
	}

	hide() {
		this.$el.unwrap('.om__loader_inner');
		this.$el.unwrap('.om__loader');
		// window.omBase.emit('NAV_BUSY', false);
	}

}
