import UIkit               from 'uikit';
import LibraryElement      from "./components/library.element";
import { Base, eventList } from "./utils/base";
import EventEmitter        from "eventemitter3";

/**
 * OM Tree
 *
 */

export default class Tree extends EventEmitter {

	constructor(Base) {
		super();
		let self = this;

		this.base = Base;

		this.html = ''; // Render nodes
		// TreeData is baked into PHP
		let data;
		if (typeof treeData !== "undefined")
			data = treeData;
		else {
			// TODO: Superflous if class instantiation is done right
			console.warn('NO TREE FOUND')
			return
		}

		// Build our tree
		this.buildTree(data).then(() => {
			this._initEvents();
		});

	}

	_initEvents() {
		let self = this;
		// Init Events
		/**
		 * NOTE:
		 * data-<COMPONENT>-command = default click action for all related actions
		 * data-<COMPONENT>-change = watch changes (e.g. inputs)
		 */
		let id;
		$('[data-task-command]').on('click', (e) => {
			e.preventDefault()
			let command = $(e.currentTarget).data('taskCommand');
			switch (command) {
				case 'TASK_EDIT' :
					id = $(e.currentTarget).data('task-id');
					window.location.assign(`/aufgabe/${id}`);
					break;
				case 'TASK_DELETE' :
					id = $(e.currentTarget).data('task-id');
					UIkit.modal.confirm('Soll diese Aufgabe wirklich gelöscht werden?').then(function () {
						self.emit(eventList.TASK_DELETE, id);
					}, function () {
						console.log('Rejected.')
					});

					break;
				case 'TASK_CREATE' :
					// Just forward to the task page and send parentID (TODO: PHP router must accept second parameter)
					let parentId = $(e.currentTarget).data('parent-id');
					window.location.assign(`/aufgabe/?parentId=${parentId}`);
					break;
			}
		});

		/**
		 * Filtering
		 * https://stackoverflow.com/questions/51250765/uikit-3s-filter-component-set-active-filter-with-url-hash-unable-to-change-a
		 * https://jsfiddle.net/triooleg/12ty5r8a/  !!!
		 * https://stackoverflow.com/questions/48690039/jquery-filter-nested-list-based-on-textbox-input
		 */
		$('[data-task-command-keyup]').on('keyup', (e) => {

			let li = $('#OM_TREE_INSERT li')
			// Search value
			let s = $(e.target).val();
			// let re = new RegExp(s, 'i');
			if (s.length > 2) {
				// Start searching

				// Use UIKIT
				// let filterCtrl = $('#OM_TREE_FILTER');
				// let filter = $('.js-filter');
				// UIkit.filter( filterCtrl, {
				//     target: filter,
				//     selActive: 'li [data-name:' + s + ']'
				// });
				// console.log('FILTER -> ', 'li [data-name:' + s + ']')


				$("#OM_TREE_INSERT ul > li").removeClass("hide");
				$("#OM_TREE_INSERT ul > li").removeClass("show");
				$("#OM_TREE_INSERT ul > li").each(function () {
					if ($(this).text().toLowerCase().search(new RegExp(s, "i")) < 0 && !$(this).hasClass('show')) {
						$(this).addClass('hide');
						$(this).hide();
					} else {
						// TODO: highlight, expand parent!
						$(this).addClass('show');
						$(this).find('ul > li').addClass('show');
						$(this).find('ul > li').show();

						// Expand tree, if parent
						// UIkit.nav('#OM_TREE_INSERT ul').toggle($(this).parentsUntil('#OM_TREE_INSERT').length);
						// UIkit.nav('#OM_TREE_INSERT ul').toggle(0);
					}
				});


			} else {
				//reset
				li.show()
			}
		});

		// Listen for refresh events, i.e. task update
		this.base.on(eventList.TASK_UPDATED, ()=>{
			// Todo: Task Tree is php generated, so we cannot use an API yet
		});
	}


	/**
	 * Build a HTML tree from data
	 * @param data
	 */
	buildTree(data) {
		return new Promise(resolve => {
			let renderedlist = this.buildListFromJsonArray(data);
			$("#OM_TREE_INSERT").append(renderedlist);
			$('.__numTasks').text(data.length);
			resolve(true);
			// });
		});

	}


	/**
	 * What it says...
	 * @param data
	 * @param levelDepth
	 * @returns {Mixed|jQuery|jQuery|HTMLElement}
	 */
	buildListFromJsonArray(data, levelDepth) {
		if (typeof (data) !== 'object') return;
		let self = this;
		let _parent = 0;
		let list = (levelDepth > 0) ? $('<ul/>') : $('<ul class="js-filter uk-nav-default uk-nav-parent-icon" uk-nav="multiple: true"/>');

		let curDepth;
		if (levelDepth) {
			curDepth = parseInt(levelDepth, 10);
			list.addClass("level" + curDepth);
			if (curDepth >= 1) {
				list.addClass("uk-nav-sub");
			}
		} else {
			curDepth = 0;
		}

		// Build tree UI

		$.each(data, function (index, item) {
			let listItem = $("<li class='js-filter' data-name='" + item.title + "' />");
			// _parent = (curDepth === 0) ? item.id : 0;
			// Add classes for first or last list item
			if (index === 0) {
				listItem.addClass("first");
			} else {
				if (index === (data.length - 1)) {
					listItem.addClass("last");
				}
			}

			// Teaser image
			// TODO: either a new, simple image item or update the LE
			// let me = (item.teaserImg) ? new LibraryElement(this.base, 1, null, {
			// placeholder: null,
			// 	uimode: 'icon',
			// 	progress: false,
			// 	editable: false,
			// 	selectable: false,
			// 	deleteable: false,
			// 	removeable: false,
			// 	caption: false,
			// 	mode: 'icon'
			// }) : null;

			// if (me) {
			// 	let $el = me.getElement();
			// 	listItem.append($el);
			// }

			// Add link
			let link = $("<a />");
			let _pIcons = item.children && item.children.length ? `<span uk-nav-parent-icon></span>` : ``;
			link.attr("href", '/aufgabe/' + item.id);
			link.html(`<div><h6 style="margin:0;">${item.subTitle}</h6><div><strong>${item.title}</strong></div>${_pIcons}</div>`);
			listItem.append(link);
			// listItem.append(`<small class="uk-margin-remove">${item.teaser}</small>`)
			listItem.append(`<div class="om_tree__controls">
                    <a href="javascript:void(0);" uk-icon="icon: pencil" data-task-command="TASK_EDIT" data-task-id="${item.id}" title="Bearbeiten"></a>
                    <a href="javascript:void(0);" uk-icon="icon: plus-circle" data-task-command="TASK_CREATE" data-parent-id="${item.id}" title="Neu anlegen"></a>
                    <!-- <a href="#" uk-icon="icon: minus-circle" data-task-command="TASK_DELETE" data-task-id="${item.id}" title="Löschen"></a> -->
                    </div>`)
			listItem.append(`<hr>`);
			if (item.children && item.children.length > 0) {
				_parent = item.id;
				if (curDepth === 0)
					listItem.addClass("uk-parent");
				let newDepth = curDepth + 1;
				listItem.append(self.buildListFromJsonArray(item.children, newDepth));
			}
			list.append(listItem);
		});

		return list;
	}


	// CRUD


	// /**
	//  * TODO: Add a new task. If parentId given, make this a child
	//  * @param parentId
	//  */
	// addTask(parentId = null) {
	// 	console.log('ADDING New Task for parent ', parentId)
	// }


}
