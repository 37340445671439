import EventEmitter                            from "eventemitter3";
import UIkit                                   from 'uikit';
import Sortable                                from 'sortablejs';
import { deepClone, eventList, getStatiLabel } from "./utils/base";
import LibraryElement                          from "./components/library.element";
import LibraryElementModel                     from "./models/libelement";
import TaskModel                               from "./models/task";
import 'jquery.dirty'
import Loader                                  from "./components/loader";
import Editor                                  from "./editor/editor";
import KnowledgeModel                          from "./models/knowledge";
import EditorTemplating                        from "./editor/templating";
import AffectionWrapper                        from "./affectionWrapper";

const Base64 = require('js-base64').Base64;

/**
 * OM Task
 * @class Tasks
 * This manages the task editor view. It loads a task and generates knowledge / edutasks and editor views
 * See the php-templates /modules/tasks/views for the templating
 *
 *
 */

export default class Tasks extends EventEmitter {

	constructor(base) {
		super()
		this.base = base;

		// Editors (enumerated)
		// ISSUES: https://github.com/artf/grapesjs/issues/1331

		// ---------------------------------
		// DomElements
		this.$OM_taskwrapper = $('#om-editor-wrapper');
		this.$DOM_form1 = $('#f_task');
		this.$DOM_form2 = $('#f_task_1');
		this.$OM_teaserimage = $('.om-task-teaserimage');
		this.$OM_subtasks = $('#om-subtasks--tasks');

		// Media Class (Modal)
		this.OM_Media_Modal = this.base.OM_Media;
		// Apply special options to the media modal
		// this.OM_Media_Modal.applyOptions({forceRefresh: true, types: ['image'], tasks: null, readOnly: false, inline: true});
		// Task Model Class
		this.task = TaskModel;

		// Affection obj
		this.OM_Affection = new AffectionWrapper(this.base);
		// Subtasks
		// this.OM_SubTasks = new TaskSubtasks(this.base);
		// Editor
		this.OM_Editor = null;
		// LibraryElement (teaser)
		this.omLibElementTeaser = null;
		// Runtime vars
		this.editors = {};   // CKEditor only
		this.attachedMedia = [];  // Store last media ids from teaser, affection and detach/attach the used media
		this.currentlySelectedEduTask = null;

		//  The loader, universal
		this.loaders = {}

		// Intialise UI DOM Comps
		this._initUI();
		this._toggleLoader('.omnimundi', true);
		// Populate Form
		this.getCurrentTask();

		this._initEvents();

	}

	/**
	 * Populate the form with data, if an ID is given
	 */
	async getCurrentTask() {
		this._toggleLoader('.omnimundi', true);
		let id = this.base.getUrlSegment();	// Very lame. It REQUIRES to have trailing slash after /aufgabe/ <--- or it will fail!!!
		let parentId = null;	// The parentId is set to the input field [parentId] via PHP (see index.php ~ L: 57f)
		if (id === 'aufgabe') {
			// Is New
			id = null;
		}

		this.attachedMedia = [];    // reset last media array

		if (this.OM_Editor) {
			this.OM_Editor.off(eventList.EDITOR_UPDATE, () => {});  // Nah, it should be a named fn. Or else this is useless.
			this.OM_Editor.destroy();
		}

		if (id) {
			// Some elements are only shown when create
			$('.hide-on-edit').hide();
			// ================ Edit
			// Use 'model'
			try {
				const result = await this.task.load(id)
				// Clone data before manipulating
				let r = deepClone(result);

				// Add status css class (for any cool use we would make...)
				// this.$OM_taskwrapper_outer.addClass(`status-${r.status}`)
				$('body').addClass(`status-${ r.status }`);	// not the fine english.. an event system to have each component take care of what it shows or not would be ...
				// <---

				// Todo: move to _initUI() && add global is-subtask and use CSS!
				// Todo: Hiding things may cause trouble as IDs may be double in the DOM!
				// Show/Hide conditional template elements [data-if]
				// Main Tasks have no worksheets
				if (r.parentId) {
					$('.show-if-subtask').show();
					$('.hide-if-subtask').hide();
				} else {
					$('.show-if-subtask').hide();
					$('.hide-if-subtask').show();
				}


				// Set form data [will auto-populate anything possible, including selectize-fields]
				this.base.populate(this.$DOM_form1, r, false)    // Populate form inputs with fresh data
				this.base.populate(this.$DOM_form2, r, false)    // Populate form inputs with fresh data
				// this.base.populate(this.$DOM_form3, r, false)    // Populate form inputs with fresh data

				// Set link to new subTask
				$('[data-dynamic="link_new_subtask"]').attr('href', `/aufgabe?parentId=${ r.id }`);

				// Remove dirty flag from forms
				$('#f_task, #f_task_1').dirty("setAsClean");   // refresh dirty state watcher

				// Set teaser image ... && typeof r.teaserImg.sizes !== "undefined"
				if (r.teaserImg) {
					LibraryElementModel.load(r.teaserImg.id).then(r => {
						// Update link
						this.attachedMedia.push(r.id);    // add to currently attached media
						// Create LibElement Teaser
						this._updateLibElement(r, this.$OM_teaserimage, 'teaserImg');
					});
				} else {
					this._updateLibElement(null, this.$OM_teaserimage, 'teaserImg');
				}

				// -------------------------
				// Set Status (Nav)
				this.base.NAVIGATION.setStatus(r.status);

				// -------------------------
				// Update Title & other labels
				if (r.id) {
					if (r.parentId) {
						// If this is a subtask with parent, show link up
						$('[data-dynamic-linktoparent]').html('&laquo;&nbsp;Zur Elternaufgabe');
						$('[data-dynamic-linktoparent]').attr('href', `/aufgabe/${ r.parentId }`);
					}

					$('[data-dynamic="title"]').text(r.world?.idMapped + '. ' + r.epoch?.idMapped + '. ' + r.title);
					$('[data-dynamic="worldepoch"]').text(r.world.label + ' / ' + r.epoch.label);
				}

				// -------------------------
				// Fill eduTasks
				r.eduTasks = !r.eduTasks ? [] : r.eduTasks;
				if (!this.task.data.eduTasks) this.task.data.eduTasks = [];
				this._populateEduTasks(r.eduTasks)

				// -------------------------
				// Fill dimension fields
				this._populateDimensioning(r.dimensioning, r.children);

				// -------------------------
				// Initialise Media @deprecated: load only when tab is triggered
				// this._populateAssocMedia(r.id);

				// -------------------------
				// Fill goals fields
				this._populateGoals(r.goals);

				// -------------------------
				// Affection Component
				this.OM_Affection.setData(r.contentAff);

				// -------------------------
				// Load editor with content (hand over ALL data!)
				this.OM_Editor = new Editor(this.base, this.$OM_taskwrapper, null, r);

				// -------------------------
				// @deprecated: Workbook / sheet components
				// They are now independent records.
				if (!r.wbId)
					$('[data-dynamic="workbookId"]').html(`Speichere die Aufgabe, um das Arbeitsheft anzulegen.`);

			} catch (err) {
				console.warn('Error loading task ', err);
				UIkit.notification({
					message: `Die Aufgabe konnte nicht geladen werden`,
					status:  'warning',
					pos:     'top-right',
					timeout: 5000
				});
			}

			this.emit('TASK_LOADED', this.task);
			this._toggleLoader('.omnimundi', false);

		} else {

			// Try to get the parentId:
			// Todo: this is only dependent on GET parameters, we need a fn that gives us a main vs a child task
			parentId = this.base.findGetParameter('parentId');

			// Hide UI tabs. Once the page has reloaded they will automagically appear
			$('.hide-on-new').hide();

			// ================ Create
			TaskModel.create({parentId: parentId});
			this.task.data = TaskModel.get()

			// Add status css class (for any cool use we would make...)
			$('body').addClass(`status-${ this.task.data.status }`);

			// Set title
			$('[data-dynamic="title"]').text(!parentId ? 'Neue Aufgabe' : 'Neue Teilaufgabe');

			// TeaserImg
			this._updateLibElement(null, this.$OM_teaserimage, 'teaserImg');

			// EduTasks
			this._populateEduTasks(this.task.data.eduTasks)

			// -------------------------
			// Affection Component
			this.OM_Affection.setData(null);

			// ------------------------
			// Initialise editor without content
			this.OM_Editor = new Editor(this.base, this.$OM_taskwrapper, null, null);

			this._toggleLoader('.omnimundi', false);

		}

		// Show and hide UI containers depending on type of task (level)
		// We currently support only level-0 (HA) and 1 (TA)
		// This assumes a reload of the page always (since it does not remove the classes!)
		// console.info('PARENT? ', this.task.data)
		if (!this.task.data.parentId) {
			$('html').addClass('is-maintask');
			$('html').removeClass('is-subtask');

		} else {
			$('html').addClass('is-subtask');
			$('html').removeClass('is-maintask');
		}

		// Hook up editor events
		if (this.OM_Editor)
			this.OM_Editor.on(eventList.EDITOR_UPDATE, () => {
				// Check if there is a component-9 (dimensioning aside) and if so, hide the teaser region
				// because this component will replace it.
				// More or less @deprecated since 0.91.x BUT we need it for older content >:(
				const hasComponent9 = $('[data-name*="comp_9"]').length
				if (hasComponent9 > 0) {
					$('html').addClass('has-component-9');
				} else {
					$('html').removeClass('has-component-9');
				}
				// just set form dirty ~~~ Form fields need to keep in sync
				this.base.forceDirty();
			});
		else
			console.warn('Editor not initialised');

	}


	/**
	 * Show media library
	 * TODO: deprecate and use global
	 * @param placeholder - placeholder image DOM
	 * @param input - input field for mediaId
	 * @param create STRING - if set, create the element to insert
	 */
	openMediaLibrary(placeholder, input, create) {
		this.OM_Media_Modal.showAsModal({
			readOnly:      true,
			selectionMode: true,
			types:         ['image', 'character']    // Limit to these
		});   // , {stack: true}

		/**
		 * A library item has been selected
		 */
		this.OM_Media_Modal.on(eventList.MEDIA_SELECTION, (mediaArr) => {
			if (!mediaArr || !mediaArr.length) return;
			let media = mediaArr[0];    // Only one teaserImg
			this._updateLibElement(media, placeholder, input);
			this.OM_Media_Modal.off(eventList.MEDIA_SELECTION);   // Remove eventlistener
		});
	}

	// ------------------------------------------------------------------------------
	// KNOWLEDGES

	/**
	 * Add associated media to media tab (readonly)
	 * @param taskId
	 * @deprecated
	 */
	_populateAssocMedia(taskId) {
		// Logic to fill associated library elements.
		// Deleted here - see any code before 0.73.x
	}

	/**
	 * Open the select window to attach a knowledge record (or create)
	 * Intercept and check if the task is created and not dirty
	 * @param param object of {knowledgeId || parentId, viewId, title, ... } -- see dom template in ~
	 */
	_selectKnowledge(param = {taskId: null, position: 0, viewId: null, itemId: null, title: null, parentViewId: null},
		options            = {filters: null}) {
		if (!this.task || !this.task.data.id)   // TODO: show confirm to save [dirty flag]
			UIkit.notification({
				message: `Du mußt die neue Aufgabe erst speichern, um einen Wissensbeitrag / ein Arbeitsblatt zu verknüpfen.`,
				status:  'warning',
				pos:     'top-right',
				timeout: 5000
			});
		else {
			// Open the KN Selector modal and wait for click action
			// The click action will be intercepted (_initEvents) and action will be taken with these params:
			// Now we watch for the event given from the modal above... --- User may click the action button (action=[knowledge] which calls attach-method here)
			this.base.emit(eventList.OPEN_KN_SELECTOR, {
				params:  {
					...param, ...{
						taskId:       this.task.data.id,
						itemPos:      param.position, // default: set itemPos same as pos, unless user selects different
						parentViewId: param.parentViewId
					}
				},
				options: options
			})
		}
	}

	/**
	 * Insert placeholder knowledge (just a title, no link)
	 * @param param
	 */
	_addPlaceholderKnowledge(param) {
		this._attachKnowledge({
			knowledgeId: '',
			itemPos:     0,
			position:    param.position,
			subPos:      0,
			taskId:      this.task.data.id
		}, true).then(r => {
			if (r.status === 200) this._refreshKNLists()
		});
	}

	/**
	 * A knowledge record has been clicked.
	 * Intercept and check if the task is created and not dirty,
	 * then forward location to edit form of kn
	 * @param JSON object from data attribute. {taskId, itemId, position}
	 */
	_editKnowledge(param) {
		if (!this.task || !this.task.data.id)   // TODO: show confirm to save.
			UIkit.notification({
				message: `Du mußt die neue Aufgabe erst speichern, um einen Wissensbeitrag zu verknüpfen.`,
				status:  'warning',
				pos:     'top-right',
				timeout: 5000
			});
		else {
			// Route to knowledge OR any other type
			const type = this.base.getKNElementType(param.itemType)
			// console.log(param, type)
			if (param.itemId) {
				location = `${ type.slugEdit }?viewId=&itemId=${ param.itemId }&taskId=${ param.taskId }&position=${ param.position }`;   // Edit
			} else {
				location = `${ type.slugEdit }?viewId=&itemId=&taskId=${ param.taskId }&position=${ param.position }`;   // Edit
			}

		}
	}

	/**
	 * Adds a reference to existing task (attachKnowledge(s) to task)
	 * Called after selectKnowledge() opened the KN-Select-Modal (see index.js)
	 *
	 * Expects taskId & position to be set
	 * The param isPlaceholder determines a custom KN
	 * The param usChild expects the parentId (= knowledgeId ) of the parent KN
	 *
	 * @param param
	 * @param isPlaceholder
	 * @param isChild
	 * @return promise
	 */
	_attachKnowledge(param = {
			knowledgeId:  null,
			taskId:       null,
			position:     null,
			itemPos:      null,
			parentViewId: null,  // If set, the KN will be added as child to this
			viewProps:    null
		},
		isPlaceholder      = false) {
		// Store
		const {taskId, knowledgeId, position, itemPos, parentViewId, viewProps} = param
		return KnowledgeModel.attachToTask(taskId, knowledgeId, position, 0, 0, +itemPos, isPlaceholder, parentViewId, viewProps);
	}

	/**
	 * Detach Knowledge from task
	 * @param knowledges STRING - a single(!) ID of a knowledge to detach
	 * @return promise
	 */
	_detachKnowledge(viewId) {
		// Arrays are not required anymore
		return KnowledgeModel.detachFromTask(viewId);
		// .then(() => {
		// 	// onSuccess: refresh
		// 	this._refreshKNLists();
		// });
	}

	/**
	 * Update a knowledge's view props
	 * @param param object of props (see fn)
	 * @return {Promise<unknown>}
	 */
	_updateKNViewProps(param = {taskId: null, viewId: null, title: null, show: true}, $el = null) {
		return KnowledgeModel.load(param.viewId).then(() => {
			if ($el)
				$el.addClass('busy');

			return KnowledgeModel.updateProps({
				// knowledgeId: param.knowledgeId,
				taskId:    param.taskId,
				viewId:    param.viewId,
				viewTitle: param.viewTitle,
				show:      param.show
				// parentId: param.parentViewId !== null && param.parentViewId !== "null" ? param.parentViewId : '' // @deprecated
			})
			                     .then(r => {
				                     if (r.status !== 200) return    // Something didn't work.
				                     // Set new data to the current item (viewProps)
				                     const newParam = {...$el.data('param'), ...{viewProps: {viewTitle: param.viewTitle, show: param.show}}}
				                     $el.data('param', newParam)
			                     })
			                     .finally(() => {
				                     if ($el) {
					                     // Reset state
					                     $el.removeClass('busy').removeClass('focused');
				                     }
			                     })
		});
	}

	/**
	 * Updates knowledge viewprops from an emitted event
	 * This is the TITLE change usually
	 * Todo: @deprecate, send the params
	 * @param e
	 * @private
	 */
	_updateKNViewPropsFromEvent(e) {
		if (!e || !e.currentTarget) return
		// Change a knowledge vp.Title
		// Directly load the KNModel and update.
		let $parent = $(e.currentTarget).parent();
		let param = e.type === 'keyup' ? $(e.currentTarget).data('param') : $(e.currentTarget).prev().data('param');
		let title = e.type === 'keyup' ? $(e.currentTarget).val() : $(e.currentTarget).prev().val();
		if (!param) return
		let show = param.show;
		// $parent.addClass('busy');
		$(e.currentTarget).removeClass('uk-text-danger')
		if (!param) return;
		this._updateKNViewProps({
			// knowledgeId: param.knowledgeId, // @deprecated
			taskId:    param.taskId,
			viewId:    param.viewId,
			viewTitle: title,
			show:      show
		}, $(e.currentTarget).closest('.knowledge-item'));

	}

	/**
	 * Fill dimensioning fields, main method
	 * Adds (1) Subtasks, (2) Worksheets, (3) InfoFields (free text) and (4) Knowledges
	 *
	 * @param dimensions
	 */
	_populateDimensioning(dimensions, subTasks) {

		// 1. Subtasks (pos5 = middle)
		this.$OM_subtasks.empty();
		// Append SubTasks
		if (subTasks && subTasks.length) {
			const subsub = (st) => {
				let subTasks = [];
				if (st && st.length) {
					st.map(sst => {
						subTasks.push(`<div><div class="uk-card uk-card-primary uk-card-body subsubtask" style="border:5px solid white;">${ sst.title }</div></div>`);
					});
				}
				return subTasks.length ? `<div class="uk-grid-small uk-margin" uk-grid>${ subTasks.join('') }</div>` : '';
			}

			subTasks.map((t) => {
				let subTaskLvl2 = subsub(t.children);
				// NOTE: teaserImg.length does not work on objects. We get an array if empty. Change! @Henry
				// let _img = (t.teaserImg) ? `<img src="${t.teaserImg.thumbnail}" alt="" />` : ``;
				let _sTask = `<div class="subtask uk-width-1-2 status-${ t.status }" data-id="${ t.id }">
						<div class="uk-card uk-card-primary uk-card-small uk-card-body">
	                        <div class="uk-text-left uk-grid">
	                            <div>
	                                <span class="uk-sortable-handle uk-margin-small-right" uk-icon="icon: table"></span>
	                            </div>
	                            <div class="uk-width-expand">
	                                <h4 class="uk-margin-remove"><a href="/aufgabe/${ t.id }">${ t.title }</a></h4>
	                            </div>
	                            <span class="tag">${ getStatiLabel(t.status) }</span>
	                        </div>
	                        ${ subTaskLvl2 }
	                    </div>
                    </div>`;
				//<p class="uk-margin-remove">${_img} ${t.teaser}</p>
				$('#om-subtasks--tasks').append(_sTask);
			});
		}

		// 2. Worksheets @deprecated
		// console.log('WS ', dimensions)
		this._populateWorksheets(dimensions);

		// if (TaskModel.data.id)
		// 	WorkBookModel.load(TaskModel.data.id).then(wb => {
		// 		$('#om-task--workbook-sheets').empty(); // workbook tab
		// 		$('#om-subtasks--worksheets').empty();  // dimensioning middle
		// 		if (wb.length) {
		// 			// console.log('WB ', wb)
		// 			wb.forEach(wbook => {
		// 				wbook.worksheets.forEach((sheet) => {
		// 					// Append into dimensioning
		// 					$('#om-task--worksheets').append(`
		// 				<a href="/arbeitsblatt/${sheet.wsId}/${wbook.wbId}/${TaskModel.data.id}" class="uk-sortable">${sheet.title || 'Arbeitsblatt'}</a>
		// 			`);
		// 					// Workbook tab
		// 					$('#om-task--workbook-sheets').append(`<div class="uk-card uk-card-default uk-card-body uk-width-1-2@m"><span class="uk-sortable-handle uk-margin-small-right" uk-icon="icon: table"></span><a href="/arbeitsblatt/${sheet.wsId}/${sheet.wbId}/${TaskModel.data.id}">${sheet.title || '(Arbeitsblatt ohne Titel)'}</a></div><br/>`);
		// 				});
		//
		// 			})
		//
		// 		}
		// 		// if (wb.wbId)
		// 		// Add a new worksheet -- subTasks only
		// 		if (this.task.data.parentId)
		// 			$('.worksheets-list').append(`<a href="/arbeitsblatt/null/${wb[0].wbId}/${TaskModel.data.id}" class="uk-padding-small uk-margin-bottom uk-display-inline-block"><span class="uk-icon uk-margin-left" uk-icon="icon: plus"></span> Neues Arbeitsblatt</a>`);
		// 	});

		// 3. Dimensions around, including Knowledges
		// To be probably @deprecated after 0.23:
		const dimBuilder = (pos) => {
			// temporary workaround for undefined
			dimensions[pos].infoChildren = dimensions[pos].infoChildren || [];
			// Fill DOM
			$(`[name="om-dim-${ pos }-fieldTitle"]`).val(dimensions[pos].fieldTitle || '');
			$(`[name="om-dim-${ pos }-info"]`).val(dimensions[pos].info || '');
			dimensions[pos].infoChildren.map(child => {
				let content = `<li>
				            <a class="uk-accordion-title" href="#">${ child.title }</a>
				            <div class="uk-accordion-content uk-text-small">${ this.base.nl2br(child.info, true) }</div>
				        </li>`;
				$(`#om-task-dimensions .${ pos } .__child_dimensions`).append(content);
			});
		};

		// Cycle each dim / position
		if (dimensions) {
			// Main dimension fields
			Object.keys(dimensions).map(function (key, index) {
				dimBuilder(key);
			});

			// -------------------------
			// Knowledges -- load list
			this._refreshKNLists();
		}
	}

	/**
	 * Populate worksheets
	 * [use this as universal fill?]
	 * @param dimensions
	 * @private
	 */
	_populateWorksheets(dimensions) {
		$('#om-task--workbook-sheets > div').empty();
		let domStr = '';
		if (!dimensions) return;
		Object.keys(dimensions).map(function (key, index) {
			if (dimensions[key].knowledges) {
				dimensions[key].knowledges.map(itm => {
					if (+itm.itemType === 8) {
						// Well, this should be a proper template...
						domStr = `
						<div>
								<div class="uk-card uk-card-default">
									<div class="uk-card-header">
										<div class="uk-grid-small uk-flex-middle" uk-grid>
											<div class="uk-width-expand">
												<p class="uk-text-meta uk-margin-remove-top type--worksheet">${ itm.subTitle }</p>
												<h3 class="uk-card-title uk-margin-remove-bottom type--worksheet">${ itm.title }</h3>
											</div>
										</div>
									</div>
									<!-- <div class="uk-card-body">
										<p>Todo</p>
									</div> -->
									<div class="uk-card-footer">
										<a href="${ window.omBase.getKNElementType(itm.itemType).slugEdit }?itemId=${ itm.itemId }" class="uk-button uk-button-text">Bearbeiten</a>
									</div>
								</div>
							</div>	
								`;
						$('#om-task--workbook-sheets > div').append(domStr);
					}
				});
			}
		});

	}

	/**
	 * Generate a DOM string for a knowledge record (dimensioning)
	 * @param k OBJECT of a knowledge record
	 * @param parentViewId STRING id of the parent's view id
	 * @param level INT the simple 1-based level for nesting
	 * @return {string}
	 * @private
	 */
	_generateKN(k, parentViewId, level = 0) {
		// Either the standard title or a title in relation to task (viewProps.viewTitle)
		const _title = (k.viewProps && k.viewProps.viewTitle.length) ? k.viewProps.viewTitle : k.title;
		// Editable link
		const _type = this.base.getKNElementType(k.itemType);
		const _editKN = k.itemType === 2 || k.itemType === 32 || k.itemType === 8 ? `<span class="f-center">
							<a href="${ _type.slugEdit }?viewId=${ k.viewId }&itemId=&taskId=${ k.taskId }&parentId=${ k.parentId }" data-action="NONE" data-command="editKnowledge" data-param='{"knowledgeId":"${ k.itemId }", "position":"${ k.position }", "taskId":"${ k.taskId }", "title": "${ k.viewProps.viewTitle }"}' data-tippy-content="Den Wissensbeitrag bearbeiten"><i uk-icon="icon: pencil"></i></a>
						</span>` : '';
		// viewProp: show or hide in Dimension
		let _checked = (k.viewProps && (k.viewProps.show === 1 || k.viewProps.show === 3)) ? 'checked' : '';
		// viewProp: show or hide in Knowledge strip
		let _checkedForKNStrip = (k.viewProps && (k.viewProps.show === 2 || k.viewProps.show === 3)) ? 'checked' : '';
		// If level < 3 then allow additional children [got to use styles to keep whitespace the same width w/o visibility]
		const _addChildAction = level < 2 ? `` : `pointer-events:none;visibility:collapse;`
		// Is kn of a child task (no detach!)
		let _detachAction = (k.taskId !== this.task.data.id) ? `pointer-events:none;visibility:collapse;` : '';
		// If we have a kn from a child task:
		let _childAction = (k.taskId === this.task.data.id) ? `pointer-events:none;visibility:collapse;` : '';

		const _cssClass = 'type--' + _type.id;
		// `<div class="knowledge-item uk-card uk-card-default uk-card-body uk-card-small" data-param='{"itemId":"${k.itemId}", "position":"${k.position}", "taskId":"${k.taskId}", "itemPos":"${k.itemPos}","viewId":"${k.viewId}", "parentViewId":"${parentViewId}"}'>

		// Note: data-camelCase will be ignored
		return `
		<div class="knowledge-item ${ _cssClass }" 
			data-param='{"position":"${ k.position }", "viewId":"${ k.viewId }", "knowledgeId": "${ k.itemId }", "taskId": "${ k.taskId }", "position":"${ k.position }", "parentViewId": "${ k.parentId }", "itemPos": "${ k.itemPos }", "itemType":"${ k.itemType }", "viewProps": { "show": "${ k.viewProps.show }", "title": "${ k.viewProps.viewTitle }" } }'>
			<div class="uk-grid-collapse uk-grid-small" uk-grid ">
				<div>
	                <span class="knsort uk-margin-small-right" uk-icon="icon: table"></span>
	            </div>
	            <div class="uk-width-expand">
					<span class="editable-input">
						<input class="om-kn--title" data-on-keyup="checkKnowledgeTitle" data-on-focus="editKnowledgeTitle" data-on-blur="editKnowledgeTitle" data-param='{"knowledgeId":"${ k.itemId }", "position":"${ k.position }", "taskId":"${ k.taskId }", "viewId":"${ k.viewId }", "show":${ k.viewProps.show }}' data-tippy-content="${ k.title }" value="${ _title }"/>
						<a class="icon-save" href="javascript:void(0);"  data-action="knowledge" data-command="editKnowledgeTitle"><i uk-icon="icon: check"></i></a>
						<i class="icon-busy" uk-icon="icon: cloud-upload"></i>
					</span>
				</div>
				${ _editKN }
				<div class="f-center">
					<label class="uk-switch" for="feature-${ k.viewId }">
			            <input type="checkbox" id="feature-${ k.viewId }" ${ _checked } data-action="task" data-command="updateKNFeatured" data-param='{"knowledgeId":"${ k.itemId }", "viewId":"${ k.viewId }", "position":"${ k.position }", "taskId":"${ k.taskId }", "title": "${ k.viewProps.viewTitle }", "parentViewId":"${ parentViewId }"}'>
			            <div class="uk-switch-slider" data-tippy-content="In der Dimensionierung anzeigen"></div>
		            </label>
				</div>
				<div class="f-center">
					<label class="uk-switch vertical" for="featurestrip-${ k.viewId }">
			            <input type="checkbox" id="featurestrip-${ k.viewId }" ${ _checkedForKNStrip } data-action="task" data-command="updateKNFeatured" data-param='{"knowledgeId":"${ k.itemId }", "viewId":"${ k.viewId }", "position":"${ k.position }", "taskId":"${ k.taskId }", "title": "${ k.viewProps.viewTitle }", "parentViewId":"${ parentViewId }"}'>
			            <div class="uk-switch-slider" data-tippy-content="In der Wissensbeitrags-Liste anzeigen"></div>
		            </label>
				</div>
				<span class="f-center">
					<div style="${ _addChildAction }" class="om-addchild" data-action="task" data-command="selectKnowledge" uk-icon="icon: plus-circle" style="cursor:pointer;" data-tippy-content="Ein Kindelement hinzufügen" data-param='{"parentViewId":"${ k.viewId }", "position":"${ k.position }", "taskId":"${ k.taskId }", "title": "${ k.viewProps.viewTitle }"}'></div>
				</span>
				<span class="f-center">
					<div style="${ _detachAction }" class="om-detach uk-margin-small" data-action="task" data-command="_detachKnowledge" data-param='{"viewId":"${ k.viewId }"}' uk-icon="icon: ban" style="cursor:pointer;" data-tippy-content="Von der Aufgabe lösen"></div>
					<div style="${ _childAction }" class="om-detach uk-margin-small" data-action="task" data-command="_openChildLink" data-param='{"taskId":"${ k.taskId }"}' uk-icon="icon: link" style="cursor:pointer;" data-tippy-content="Teilaufgabe öffnen"></div>
				</span>
			</div>
		</div>
		`
	}

	/**
	 * RePopulate the knowledge containers with knowledges assoc. to task
	 * This is an expensive rerendering of all lists that needs to be done on each update
	 * This method renders ONE (1) position box at a time -
	 * @param knowledges ARRAY of knowledge objects for a position
	 * @param position INT current position (1-6)
	 */
	// https://jsfiddle.net/bribar/hvvaadk9/1343/   -- droppable
	_populateKnowledgeLists(knowledges, position) {

		if (!knowledges) {
			this._toggleLoader(`pos${ position }`, false);    // remove the loader in any case
			return;
		}
		// Clear?
		$(`.om-knowledge-list .pos${ position }`).empty(); // clear all list elements

		/**
		 * https://stackoverflow.com/questions/39348112/drop-a-sortable-into-a-sortable-nested-divs
		 * http://jsfiddle.net/hjarvard/JQwsf/8/
		 */
		setTimeout(() => {
			// Build lists
			let content = '';
			knowledges.forEach(k => {
				content = `<li class="list-group-item nested-1 ${this.task.data.id !== k.taskId && 'subtask'}">`
				content += this._generateKN(k, null);
				content += `<ul class="nested-sortable">`;
				// Level 2
				if (k.children.length) {
					k.children.forEach(kc1 => {
						content += `<li class="list-group-item nested-2">${ this._generateKN(kc1, k.viewId, 1) }`;
						content += `<ul class="nested-sortable">`
						if (kc1.children.length) {
							kc1.children.forEach(kc2 => {
								content += `<li class="list-group-item nested-3">${ this._generateKN(kc2, kc1.viewId, 2) }</li>`;
							});
						}
						content += `</ul></li>`;
					});
					content += `</ul>`;
				}
				content += `</ul>`;
				content += '</li>';
				$(`[data-dynamic="pos${ position }group"]`).append(content);
			});
			// do not initialise multiple times or risk leak of memory!!!
			// Sortable npm sortablejs
			// if (this.SORTABLE) return	// Todo: If not intercepted, we may get a memory leak!
			//https://jsbin.com/reyecop/edit?html,js,output
			for (let p = 1; p < 7; p++) {
				const nestedSortables = [].slice.call($(`.pos${ p }`).find(`.nested-sortable`)); // This would make all lists as one. Allows for dragging elements across lists. We do not have an API for this
				// Loop through each nested sortable element
				for (let i = 0; i < nestedSortables.length; i++) {
					this.SORTABLE = new Sortable(nestedSortables[i], {
						group:                'nested',
						animation:            150,
						fallbackOnBody:       true,
						swapThreshold:        0.7,
						emptyInsertThreshold: 8,
						draggable:            ".list-group-item",
						onEnd:                (e) => {
							$('.list-group').removeClass('over')
							$('.nested-sortable').removeClass('over')
							this._sortKnowledges(e)
						},
						onMove:               (e) => {
							// https://codepen.io/nuranto/pen/MWJxLOR?editors=1100
							// Highlight triggered target (e.to)
							$('.list-group').removeClass('over')
							$(e.to).addClass('over')	// Todo: remove too somewhere (onEnd).
							// Check if it is droppable here
							// (1) Only if the nesting level is <3
							const toDepth = $(e.to).parents(`.nested-sortable`).length;
							// (2) Limit drag depth (just 2 levels below)
							const fromDepth = $(e.dragged).find('.nested-3').length ? 3 : $(e.dragged).find('.nested-2').length ? 2 : $(e.dragged).find('.nested-1').length ? 1 : 0
							// const fromDepth = $(e.dragged).find('.nested-sortable > *').length;	// < orig. example && bullshit. It's a count not a depth.
							// console.log(fromDepth, toDepth, fromDepth + toDepth)
							return !(toDepth > 2 || fromDepth + toDepth > 4);

						}
					});
				}
			}

		}, 200);
		// Remove loader
		this._toggleLoader(`pos${ position }`, false)
		this.emit(eventList.TASK_KNOWLEGE_READY, knowledges);

	}

	/**
	 * Sort knowledges or re-attach || Drag and drop
	 * This fn figures out if a sort was just a straight change or position (simple sort)
	 * or an element has been added as child to another (detach / attach)
	 * @param e Event with item, to & from object
	 */
	_sortKnowledges(e) {
		// Get all data from dragged element
		const {viewId, parentViewId, knowledgeId, viewProps, taskId} = $(e.item).find('.knowledge-item').data('param');
		// Get the position of the containing group (dim position)
		const fromPosition = +$(e.from).closest('ul.list-group').data('position');
		const fromViewId = parentViewId;
		// TO Data
		const toData = $(e.to).prev('.knowledge-item').length ? $(e.to).prev('.knowledge-item').data('param') : {position: $(e.to).data('position'), viewId: ''};

		const toPosition = +toData.position;
		const toViewId = toData.viewId;

		// console.info('X->', $(e.from), $(e.from).closest('.list-group'))
		// console.info('POS - ', fromPosition, toPosition, ' viewID - ', fromViewId, toViewId);
// return;

		/**
		 * Return the new position within the new target (order)
		 * Just loop all items within new position and ++ order until
		 * the newly inserted (e.item / dragged el) has been found.
		 * @param pos INT new position to search
		 * @param viewId STRING of dragged element [we could use the DOM $ as well]
		 * @return {number} INT absolute position in view - regardless of nesting
		 */
		const getSubPosition = (pos, viewId) => {
			let newOrder = 0
			const $knItems = $(`[data-dynamic="pos${ pos }group"]`).find('.knowledge-item');
			$knItems.each(function (i) {
				if ($(this).data('param').viewId === viewId) newOrder = i  // or return i ?
			});
			return newOrder;
		}

		// --------------------------------------------------
		// (1) Sort across lists : w/o Position change
		// --------------------------------------------------
		if (fromPosition !== toPosition || fromViewId !== toViewId) {

			// If the taskId of the dragged knowledge is NOT the current task
			// then we have a "inherited" knowledge (subTask)
			// This cannot be moved within this task but only in the original one
			if (taskId !== this.task.data.id) {
				alert('Du kannst keinen Wissensbeitrag einer Unteraufgabe verschieben');
				this._refreshKNLists();
				return
			}
			// Toggle loaders on both affected containers
			this._toggleLoader(`pos${ fromPosition }`, true);
			this._toggleLoader(`pos${ toPosition }`, true);
			const subPosition = getSubPosition(toPosition, viewId);
			// (a) Position change -------------------

			if (fromPosition !== toPosition) {
				console.info('(1) new position, new list', {
					viewId:   viewId,
					parentId: toViewId,
					position: toPosition,
					subPos:   subPosition + 1
				})

				// Do api call
				KnowledgeModel.move({
					viewId:   viewId,
					parentId: toViewId,
					position: toPosition,
					subPos:   subPosition + 1
				}).then(r => {
					if (r.status !== 200) {
						UIkit.notification({
							message: `Das ging leider schief. Probiere, die Elemente einzeln zu verschieben`,
							status:  'warning',
							pos:     'top-right',
							timeout: 5000
						});
					}
				}).finally(() => {
					this._refreshKNLists();
					this._toggleLoader(`pos${ fromPosition }`, false);
					this._toggleLoader(`pos${ toPosition }`, false);
				});

				return
			}
			console.info('(1b) same position, new hierarchy',
				{viewId: viewId, parentId: toViewId, subPos: subPosition, position: toPosition})

			// (b) No position change, only change into a new hierarchy -------------------
			KnowledgeModel.setParent({viewId: viewId, parentId: toViewId, subPos: subPosition, position: toPosition}).then(r => {
				// Wait for response and refresh the position (either error or success: the return of the server will be used)
				this._populateKnowledgeLists(r.data.knowledges, r.data.position);
			}).catch(e => {
				console.warn('Nope.')
				this._refreshKNLists();		// reset. expensive. IMPROVE: snap back - should have been intercepted within @move event before
			}).finally(() => {
				this._toggleLoader(`pos${ fromPosition }`, false);
				this._toggleLoader(`pos${ toPosition }`, false);
				this._refreshKNLists();
			})


			// done.
			return;
		}

		// --------------------------------------------------
		// (3) Simple sort
		// --------------------------------------------------
		// Send complete order to server altogether
		const $position = $(`.pos${ fromPosition }`).find('.knowledge-item');
		let indices = []
		$position.each(function () {
			indices.push($(this).data('param').viewId);
		})
		// Create some data for API
		const _data = {};
		_data[`pos${ fromPosition }`] = indices
		// Send
		KnowledgeModel.sort(_data).finally(() => {
			this._toggleLoader(`pos${ fromPosition }`, false)
			this._toggleLoader(`pos${ toPosition }`, false)
		});

	}

	/**
	 * Refresh current knowledge lists
	 */
	_refreshKNLists() {
		this._toggleLoader(`pos1`, true)
		this._toggleLoader(`pos2`, true)
		this._toggleLoader(`pos3`, true)
		this._toggleLoader(`pos4`, true)
		this._toggleLoader(`pos5`, true)
		this._toggleLoader(`pos6`, true)

		// It will uneccesarily reload the whole task
		// Todo: add position-param to this method: only refresh a single position instead of all
		TaskModel.load(this.task.data.id).then(t => {
			TaskModel.set(t);
			// A clean populate should do. But then... its older code
			Object.keys(this.task.data.dimensioning).map((key) => {
				// extract index from key
				this._populateKnowledgeLists(this.task.data.dimensioning[key].knowledges, +key.substr(-1));
			})
		})
	}

	// ------------------------------------------------------------------------------
	// EduTasks


	/**
	 * Populate educational dimension view (first view in task)
	 * @param eduTasks
	 * @private
	 */
	_populateEduTasks(eduTasks) {
		// Check already stored eduTasks and toggle checkBoxes in selector
		eduTasks.map(edt => {
			// tick checkboxes accordingly. It will trigger a change event (not used)
			$('#om-edutasks--selector').find(`#edt-${ edt.id }`).prop('checked', true).trigger('change');
		});

		// Now we build ALL fields for all 9 eduTasks and show / hide them in UI when required
		$('#om-edutasks .edutasklist').empty();	// clear
		$('#om-edutasks--selector').find(`input:checkbox`).each((i, cb) => {
			this._addEduTask($(cb).closest('li').data('params'), $(cb)[0].checked)
		});
	}

	/**
	 * UI Switch to selected eduTask in checklist
	 * This will show the dimensioning -- only if the current eduTask is active as well (checked)
	 * @param eduTask
	 * @private
	 */
	_switchEduTask(eduTask) {
		this.currentlySelectedEduTask = eduTask;
		// Fill current dimensioning with selected eduTask
		const _edt = this.task.data.eduTasks.length && active
			? this.task.data.eduTasks.find(t => +t.id === +eduTask.id)
			: {id: eduTask.id, label: eduTask.label};
		// Check select status
		const active = $(`#om-edutasks--selector #edt-${ _edt.id }`)[0].checked
		// (1) - Enable ALL?
		const enableAll = (_edt.id === 0);
		// (2) Set a css class to determine if the current dimensioning can be edited
		// FALSE = hide input fields, show only sub-tasks || TRUE = show input fields
		if (active || enableAll)
			$('#om-edutasks--dimensioning').removeClass('inactive')
		else
			$('#om-edutasks--dimensioning').addClass('inactive')
		// (3) List
		// Deactivate all list items
		$('#om-edutasks--selector').find(`.stripe`).addClass('inactive');
		// Highlight current item only
		$('#om-edutasks--selector').find(`.om-edutasks--${ _edt.id }`).removeClass('inactive');	// In case you wonder why we removeClass('inactive') to activate: its simpler css
		// (4) Dimensioning content
		// Hide all
		if (!enableAll) {
			$(`#om-edutasks .edu-wrap`).addClass('inactive');
			// Show what we got
			$(`#om-edutasks .edt-${ _edt.id }`).removeClass('inactive');
		} else {
			$(`#om-edutasks .edu-wrap`).removeClass('inactive');
		}

		// (5) Show info
		let _html = `<h3 class="uk-margin-remove">${ eduTask.label }</h3>`;
		_html += (!active) ? '<p class="uk-margin-remove"><strong>Aktiviere diese Bildungsaufgabe links, um sie hier zu bearbeiten</p>' : '<p class="uk-margin-remove">&nbsp</p>'
		$('[data-dynamic="edutasks-info"]').html(_html);

		// Show or hide selectize / input fields
		// If ALL is selected, we show readonly text
		if (enableAll)
			$('#om-edutasks').addClass('readonly');
		else
			$('#om-edutasks').removeClass('readonly');

	}

	/**
	 * Build all 5 Dimensioning fields for eduTask
	 * @param eduTask OBJECT {id, label, dimensioning:[{position, descr..}] }
	 * @param active BOOL if checked true
	 * @param templateEduTask OBJECT - if this is filled, override the eduTask loaded from task.data,
	 * 									make all other params NULL !
	 * @private
	 */
	_addEduTask(eduTask, active, templateEduTask) {
		// Selected eduTask ~ try to get more data from DB if already selected
		const _edata = this.task.data.eduTasks && this.task.data.eduTasks.length ? this.task.data.eduTasks.find(t => +t.id === +eduTask.id) : {};
		const _edt = Object.assign(eduTask, _edata)
		let label = _edt.label || '';

		// add corresponding fields
		for (let i = 0; i < 6; i++) {
			// (1) Extract some strings:
			// If we have data to any eduTask already, insert
			// Get data from eduTask object, IF already present in task
			let _oe = _edt && _edt.dimensioning ? _edt.dimensioning.find(d => +d.position === i) : null;
			// Description is expected to be base64 @deprecated sind 0.98.1
			let _eduTaskDescrBASE64 = _oe ? Base64.encode(_oe.descr) : Base64.encode('');
			// In case we have any non-encoded data in the database already... make sure the base64 string IS a base64 string:
			// _eduTaskDescrBASE64 = (!this.base.isBase64String(_eduTaskDescrBASE64)) ? Base64.encode(_eduTaskDescrBASE64) : _eduTaskDescrBASE64
			// let _eduTaskDescr = this.base.isBase64String(_eduTaskDescrBASE64) ? Base64.decode(_eduTaskDescrBASE64) || '<p>...</p>' : _oe.descr;	// Now clear html
			let _eduTaskDescr = _oe ? _oe.descr : '';

			// Operator list (selected).
			let _operators = _oe && _oe.operator ? _oe.operator : '';
			// let _opsReadonly = _oe && _oe.operator ? _oe.operator.join('<li> ').replace(/(^,)|(,$)/g, '') : '';
			let _opsReadonly = _oe && _oe.operator
				? _oe.operator.map(elem => `<li>${ elem }</li>`).join('')
				: '';

			// (2) Simple AC list for operators ~ from DOM (expecting php to provide!)
			let _ops = '';
			let autoComplete;
			if (window.operators && _edt) {
				autoComplete = window.operators.find(op => +op.id === +_edt.id)
				if (autoComplete)
					autoComplete.operator.forEach(op => {
						_ops += `<option value="${ op }">${ op }</options>`;
					})
			}

			// (3) Get children's contents
			let children = (_oe && _oe.children && !Array.isArray(_oe.children)) ? _oe.children : null;

			// (4) SubTasks ---->
			// Show operator as tag only if not empty
			let __ops = children && children.operator.length ? `<span class="om-tag-- s">${ children.operator.map(elem => `<li>${ elem }</li>`).join('') }</span>` : ``;

			// Assemble the operators list of children (subTask contents)
			let _subtasks = children && (children.operator.length || children.descr.length) ? `
						<div class="edu-subtasks">
							<h6 class="uk-margin-remove uk-margin-top">${ children.title }</h6>
							<span class="show-if-not-all">${ children.descr }</span>
							<h6 class="show-if-not-all">Operatoren</h6>
							${ __ops }
						</div>` : ``;

			// (5) Show anything at all?
			const isEmpty = (!_oe && !children);

			/*
			 <textarea class="uk-input show-if-not-all" name="edutasks_descr_${eduTask.id}_${i}" type="text" value="${_eduTaskDescr}" placeholder="">${_eduTaskDescr}</textarea>
			 */
			// Note: CSS classes used to show and hide content in regards to single eduTask || Addition :
			// .show-if-not-all  	will show element only when a single eduTask is selected
			// .show-if-all			vice-versa: Only shown if the "Addition" view (all) is active
			// .is-empty 			hide, if set (no content)

			// :::: Render ::::
			let _form = '';
			// Center (pos 5) only operators
			let column = '';
			// only pos 5:
			let pos5TemplateBtn = i === 5 ? '<span class="om-button--templateloader hide-on-status-1 show-if-not-all" uk-icon="pull" data-on-click="loadEduTemplate" data-params="5"></span>' : '';
			if (i === 5) {
				column = '.l-2';	// center, default
				switch (eduTask.id) {
					case 1:
					case 8:
					case 64:
						column = '.l-1';
						break;
					case 4:
					case 32:
					case 256:
						column = '.l-3';
						break;
				}
			}

			_form = eduTask.id !== 0
				? `<div class="edu-form uk-width-expand ${ isEmpty && 'is-empty' }">
							${ pos5TemplateBtn }
							<!-- (1) Description -->
							<!-- Editor field -->
							<article class="" data-editable data-name="edutasks_descr_${ eduTask.id }_${ i }" style="min-height:6em;">
								${ _eduTaskDescr }
							</article>
							<!-- editor will store data here ... to make it accessible for getFormData() -->
							<input type="hidden" name="edutasks_descr_${ eduTask.id }_${ i }" value="${ _eduTaskDescrBASE64 }" />
							
							<!-- (2) Operators --> 
							<h6>Operatoren</h6>
							<select class="selectize show-if-not-all"
									name="edutasks_ops_${ eduTask.id }_${ i }[]"
									data-init="selectize"
									multiple
									>
										${ _ops }
							</select>
							
							<!-- ReadOnly (addition) -->
							<ul class="show-if-all">${ _opsReadonly }</ul>
						</div>`
				: ``;
			//om-edutasks--${eduTask.id} stripe
			$(`#om-edutasks .pos${ i } .edutasklist${ column }`).append(
				`<li class="edu-wrap edt-${ eduTask.id } ${ active ? '' : 'inactive' } uk-margin-bottom ">	
						<div class="uk-card uk-card-default uk-card-small uk-card-body ${ active ? '' : 'inactive' } uk-margin-bottom ${ isEmpty && 'is-empty' } ">
							<h5 class="om-edutasks--${ eduTask.id } label ${ isEmpty && 'is-empty' } show-if-all" >${ label }</h5>
							${ _form }
							${ _subtasks }
						</div>
				</li>`
			);


			// ReInit selectize on the new fields
			setTimeout(() => {
				$(`[name="edutasks_ops_${ eduTask.id }_${ i }[]"]`).selectize({
					multiple:  true,
					delimiter: ';',
					maxItems:  null,
					items:     _operators,
					create:    function (input) {
						// We need to append this to the PHP generated taglist!
						// Because this is not an API call. So this is to prevent a reload
						// Not nice, because it is decoupled from database and creates ambigous datasets
						// Todo: use an api call for operators instead.
						autoComplete.operator.push(input);
						window.operators.map(op => {
							if (op.id === autoComplete.id) {
								op = autoComplete	// replace this
							}
						});
						// return new value to select
						return {value: input, text: input};
					}
				});
			}, 50)
		}
	}

	// ------------------------------------------------------------------------------
	// Dimensioning

	/**
	 * Update dimension field(s)
	 * This sets the title
	 * @param param
	 */
	async updateDimensionInfo() {
		if (!this.task.data.id) return;
		// Loop through all dimensions and save each
		const positions = [1, 2, 3, 4, 6];
		for (let d = 0; d < positions.length; d++) {
			let pos = positions[d];
			let payload = {
				id:         this.task.data.id,
				fieldTitle: $(`[name="om-dim-pos${ pos }-fieldTitle"]`).val(),
				info:       $(`[name="om-dim-pos${ pos }-info"]`).val(),
				position:   pos
			}
			await this.task.updateDimension(payload);
		}
		return true;    // well, error-checking would make sense here, wouldn't it?
	}

	// ------------------------------------------------------------------------------
	// Goals
	/**
	 * Tick saved goals
	 * @param goals Array {id, title}
	 * @private
	 */
	_populateGoals(goals) {
		goals.map(goal => {
			$('#goals').find(`:checkbox[value="${ goal.id }"]`).prop('checked', true);
		});
	}

	// ------------------------------------------------------------------------------
	// CRUD (All in Task)

	/**
	 * Create or update a task?
	 * An IMPORTANT note on saving:
	 * There is a universal fn in base.js that will collect all input-fields with [name=*]
	 * which means that a POST/PUT will always have superflous fields that will be sent.
	 * Either we come up with a a) discrete solution (simple - see /models/ folder) or b) tag the inputs that need to be sent
	 *
	 */
	async save() {
		this._toggleLoader('.omnimundi', true);
		// Buttons in Nav
		this.base.emit('NAV_BUSY', true);

		// get editor data ONLY IF the editor does not set all INPUT fields with data.
		// @since 0.35.0 ~~~ Get editor data WITHOUT using form fields
		// Well, it breaks the concept to store ALL data in input fields. Yet this JSON structure is too deep.
		const editorContents = (this.OM_Editor) ? this.OM_Editor.getContent() : {data: {}};
		// Note: all attached KNOWLEDGES & LIBITEMS in the editor should be set in the form
		// BUT: We IGNORE THEM and do not attach them to the task (yet)!
		// console.log('Attached KN: ', this.OM_Editor.getAttachedKnowledgeElements())

		// Serialize all common form data
		let taskData = this.base.getFormData($('#f_task'));
		let taskData1 = this.base.getFormData($('#f_task_1'));

		// Validation (SIMPLE FOR NOW!)
		if (!taskData.title.length || !taskData.epoch || !taskData.world || +taskData.world === 0 || +taskData.epoch === 0) {
			UIkit.notification({
				message: `Gib zumindest einen Titel, die Welt und Epoche an!`,
				status:  'danger',
				pos:     'top-right',
				timeout: 5000
			});
			this._toggleLoader('.omnimundi', false);
			this.base.emit('NAV_BUSY', false);
			return;
		}

		// Editor content :: Todo: this is a lame hack to avoid errors from editor. Clean this up!!!!!!!!
		if (this.task?.data?.id !== '621f4aad86f1f4.03985896' && (!editorContents || !editorContents.data)) {
			alert('ACHTUNG: Trage bitte etwas in den Editor ein - ein Trenner genügt');
			this._toggleLoader('.omnimundi', false);
			this.base.emit('NAV_BUSY', false);
			return;

		}
		// field_item_id='621f4aad86f1f4.03985896'
		// The special case task. This is hacky code
		taskData = this.task?.data?.id === '621f4aad86f1f4.03985896' ? {...taskData, ...taskData1, ...{content: 'eyJjYXJkIjp7InRlbXBsYXRlIjpudWxsfSwiY29tcG9uZW50cyI6W119'}} : {...taskData, ...taskData1, ...editorContents.data};

		// ---------------------------------------------
		//  Goals ~ should send an array, for now it's a BIT, ad them
		if (taskData.goals) {
			let gs = 0;
			taskData.goals.map(g => {gs += +g;});
			taskData.goals = gs;
		}
		// <<<< goals


		// ---------------------------------------------
		// Get selectized content
		taskData.schoolSubj = $('#om-select-schoolsubjects')[0].selectize.getValue();

		// ---------------------------------------------
		// ::: EduTasks
		// Awkwardly assemble **eduTasks** like:
		// eduTasks: {id: '', dim: [ {position: 1..4, description: ''} ]}
		taskData.eduTasks = []
		// Add checked items
		$('#om-edutasks--selector input:checkbox').each(function () {
			if (this.checked)
				taskData.eduTasks.push($(this).val())
		});

		// Assemble JSON object for selected edt
		let _eduTasksJSON = {eduTasks: []};
		taskData.eduTasks.map(edt => {
			let _eduTask = {id: edt, dimensioning: []};
			// Exclude the 0 eduTask ~ which is just UI for "Addition"
			if (+edt !== 0) {
				let _allDescriptions = '';	//
				for (let _pos = 1; _pos < 6; _pos++) {
					// Get from input field ( default )
					// Get from editor contenteditable field
					let _descr = $(`[name="edutasks_descr_${ edt }_${ _pos }"]`).val()
					_descr = this.base.isBase64String(_descr) ? _descr : Base64.encode(_descr);
					_eduTask.dimensioning.push({
						position: _pos,
						descr:    _descr,
						operator: taskData[`edutasks_ops_${ edt }_${ _pos }`]
					});
					// delete operator keys
					delete taskData[`edutasks_ops_${ edt }_${ _pos }`];
					// @since 0.31.9 : do check if not empty
					_allDescriptions += taskData[`edutasks_descr_${ edt }_${ _pos }`];
				}
				// @since 0.31.9 : do check if not empty
				if (!_allDescriptions) {
					// uncheck the box for the eduTask:
					UIkit.notification({
						message: `Die Bildungsaufgabe(n) ohne Beschreibung wurden nicht gespeichert!`,
						status:  'warning',
						pos:     'top-right',
						timeout: 5000
					});
					$(`#edt-${ edt }`).prop('checked', false);
				} else
					_eduTasksJSON.eduTasks.push(_eduTask)
			}
		})

		// @deprecated // Delete ALL superflous keys (we create empties as well)
		const edtArr = [1, 2, 4, 8, 16, 32, 64, 128, 256];
		for (let i = 0; i < edtArr.length; i++) {
			for (let p = 1; p < 6; p++) {
				delete taskData[`edutasks_descr_${ edtArr[i] }_${ p }`];
			}
		}
		// Add new object
		taskData.eduTasks = _eduTasksJSON.eduTasks
		// console.warn('EDT ->> ', taskData.eduTasks)
		// <--------- EduTasks

		// Get Affection content ------------------------
		// These are either images (3) in header or a slideshow component
		taskData.contentAff = JSON.stringify(this.OM_Affection.getData()) || ''; //JSON.stringify(this.OM_Affection.getData());
		taskData.contentAff = Base64.encode(taskData.contentAff);

		// Sustainable development goals. Shouldn't be necessary here, if base.js conversion would kick properly ---------------------------------------------
		if (!taskData.goals)
			taskData.goals = 0;


		// Dimensioning (Title + Info field) ---------------------------------------------
		await this.updateDimensionInfo()

		// Entfalter content Html --------------------------------------------------------
		// needs base64...
		taskData.contentEntfalter = this.base.isBase64String(taskData.contentEntfalter) ? taskData.contentEntfalter : Base64.encode(taskData.contentEntfalter);

		// Get libraryItemIds and merge with formdata ---------------------------------------------
		this._linkMediaToTask().then((libItems) => {
			taskData['libItems'] = libItems

			// TODO: not necessary when using events!!! Get status (published, etc) from nav
			// taskData.status = this.base.NAVIGATION.getStatus();

			// Save the task
			this.task.set(taskData, true).then(() => {
					// NOW?
				}
			).catch(e => {
				console.warn('::ERROR SAVING:::', e);
				this.base.emit('NAV_BUSY', false);
			});
		}).finally(() => {
			// Hide loader
			this._toggleLoader('.omnimundi', false);
			// Emit saved event
			this.base.emit(eventList.TASK_UPDATED, taskData);
			// Buttons in Nav
			this.base.emit('NAV_BUSY', false);
		})
	}

	/**
	 * Public : External call from e.g. Tree can trigger this.
	 * Note: There is no confirm!
	 * @param id
	 */
	delete(id) {
		// Confirm
		UIkit.modal.confirm('Willst du diese Aufgabe wirklich löschen?').then(() => {
			// Do
			this.base.emit('NAV_BUSY', true);
			TaskModel.delete(id).then(() => {
				this.base.emit('NAV_BUSY', false);
				location.href('/'); // Redirect.
			}).catch(e => {
				this.base.emit('NAV_BUSY', false);
				throw (e);
			})
		}, function () {
			console.log('Rejected.')
			this.base.emit('NAV_BUSY', false);
		});

	}

	//--------------------------------------------------------------------------------------
	// Private
	//--------------------------------------------------------------------------------------

	/**
	 * Initialise UI
	 * Not much to do, except starting loaders (in dedicated areas)
	 * @private
	 */
	_initUI() {
		// Show and hide elements as per Task type (MAIN or SUBTASK)
		// Why did I predefine them???
		this.loaders = [
			{id: '.omnimundi', loader: new Loader(`.omnimundi`, {show: false})},
			{id: 'pos1', loader: new Loader(`.om-knowledge-list .pos1`, {show: true})},
			{id: 'pos2', loader: new Loader(`.om-knowledge-list .pos2`, {show: true})},
			{id: 'pos3', loader: new Loader(`.om-knowledge-list .pos3`, {show: true})},
			{id: 'pos4', loader: new Loader(`.om-knowledge-list .pos4`, {show: true})},
			{id: 'pos5', loader: new Loader(`.om-knowledge-list .pos5`, {show: true})},
			{id: 'pos6', loader: new Loader(`.om-knowledge-list .pos6`, {show: true})},
			{id: 'edu-pos1', loader: new Loader(`#om-edutasks--dimensioning .pos1`, {show: false})},
			{id: 'edu-pos2', loader: new Loader(`#om-edutasks--dimensioning .pos2`, {show: false})},
			{id: 'edu-pos3', loader: new Loader(`#om-edutasks--dimensioning .pos3`, {show: false})},
			{id: 'edu-pos4', loader: new Loader(`#om-edutasks--dimensioning .pos4`, {show: false})},
			{id: 'edu-pos5', loader: new Loader(`#om-edutasks--dimensioning .pos5`, {show: false})},
		]

	}

	/**
	 * Events. Setup.
	 * @private
	 */
	_initEvents() {

		// -------------------------------------------------
		// Main Navigation Events (Save, change state, etc.)

		this.base.NAVIGATION.on(eventList.NAV_STATE, (payload) => {
			// Update Form Data
			$('input[name=status]').val(payload);
		});
		this.base.NAVIGATION.on(eventList.NAV_SAVE, () => {
			this.save();
		});
		this.base.NAVIGATION.on(eventList.NAV_CANCEL, () => {
			if (history.length) history.back()
			else location.href('/')
		});
		this.base.NAVIGATION.on(eventList.NAV_DELETE, () => {
			try {
				this.delete(this.task.data.id);
			} catch (e) {
				console.warn('No current task model!');
			}
		});


		// -------------------------------------------------
		// Task has been loaded
		// -------------------------------------------------
		this.on('TASK_LOADED', (t) => {
			// First load: show content corresponds to location hash
			let active = 0;
			while (anchors[active]) {
				if (anchors[active].hash === window.location.hash) {
					switcher.show(active);
					setTimeout(() => {
						triggerActionOnTabChange(anchors[active].hash);
					}, 1000);
					break;
				}
				active++;
			}
		})


		// -------------------------------------------------
		// Tab/Switch change
		// -------------------------------------------------

		// TASK #314
		// Assign # to tabs and keep the last tab open
		const switcherEl = document.getElementById('om-task-tabs');
		const anchors = switcherEl.querySelectorAll('li > a');
		const switcher = UIkit.switcher(switcherEl);

		/**
		 * Trigger certain actions when a tab has changed.
		 * For once, show or hide the editors-toolbox widget
		 * More important: initialise a view only when requested (NIY)
		 * @param tab
		 */
		const triggerActionOnTabChange = (tab) => {
			switch (tab) {
				case '':
				case '#details':
					$('.ct-widget.ct-toolbox').show();
					break;
				case '#inhalt':
					$('.ct-widget.ct-toolbox').show();
					break;
				case '#dimensionierung':
					$('.ct-widget.ct-toolbox').hide();
					break;
				case '#arbeitsheft':
					$('.ct-widget.ct-toolbox').hide();
					break;
				// case '#goals':
				// 	$('.ct-widget.ct-toolbox').hide();
				// 	break;
				case '#wissen':
					if (!this.task.data.id) break;
					$('.ct-widget.ct-toolbox').hide();
					// console.log('Wissen = ', this.task.data)
					//return;
					//const TPL = new EditorTemplating(this.base, this.OM_Editor, [])
					// Todo: DEV only: VERY INEFFICIENT!!!
					// KnowledgeModel.loadCollection(`taskId=${this.task.data.id}`).then(r => {
					// 	$('#om-task-knowledge-render .om-template-wrapper').empty();
					// 	r.forEach(kn => {
					// 		KnowledgeModel.load(null, kn.itemId)
					// 			.then(knn => {
					// 				// console.log(knn)
					// 				let compStr = ``
					// 				knn.content.components.forEach(cmp => {
					// 					console.log(cmp)
					// 					// compStr+=cmp
					// 				})
					// 				let tpl = TPL.getTemplateString(knn.content.card.template, knn, knn.content.card, true);
					// 				let tplCpl = `
					// 				<div class="uk-card uk-card-default uk-card-body om-kn-card--" style="margin: 0 auto 2rem auto;width: 210mm;height: 297mm;" data-id="${knn.itemId}">
					// 					<a name="kn--${knn.itemId}" />
					// 					<div class="om-card-header uk-grid" style="background: rgba(0,0,0,0.05);margin: -2.5rem -2.5rem 0 -2.5rem;padding: 10px;">
					// 						<!--
					// 						<div>
					// 							<h6>Dimensionierung</h6>
					// 							<h3>${knn.position}</h3>
					// 						</div>
					// 						<div class="uk-margin-right">
					// 							<h6>Wissensform</h6>
					// 							<h3>${knn.itemPos}</h3>
					// 						</div>
					// 						-->
					// 						<div>
					// 							<a class="uk-button" href="/wissensbeitrag?itemId=${knn.itemId}">BEARBEITEN</a>
					// 						</div>
					// 					</div>
					//
					// 					<div>
					// 						${tpl}
					// 					</div>
					// 				</div>
					// 			`;
					// 				$('#om-task-knowledge-render .content').append(tplCpl);
					// 				// SidePanel to scroll to
					// 				// $('#om-task-knowledge-render .side').append(`
					// 				// 	<div class="uk-card uk-card-default uk-card-body om-kn-card" style="margin: 0 auto 2rem auto;width: 210mm;" data-id="${knn.itemId}">
					// 				// 			${tpl}
					// 				// 	</div>
					// 				// `);
					//
					// 				// Fill libElements
					// 				// fillLibElements(this.base, $('#om-task-knowledge-render'));
					//
					// 			});
					// 	});
					// 	// A link would be simpler, yet css is not behaving
					// 	// $(document).on('click', '.side .om-kn-card', (e)=>{
					// 	// 	location.hash = `#kn--${$(e.currentTarget).data('id')}`
					// 	// 	// console.log('Scroll to ',  $(e.currentTarget).data('id'), $(`#kn--${$(e.currentTarget).data('id')}`))
					// 	// 	// $(document.body).animate({
					// 	// 	// 	'scrollTop':   $(`[data-id="${$(e.currentTarget).data('id')}"]`).offset().top
					// 	// 	// }, 2000);
					// 	//
					// 	// })
					// })
					break;
				case '#medien':
					// Initialise Media
					// For now, just a simple list, non-editable
					this._populateAssocMedia(this.task.data.id);
					// Hide editor toolbox
					$('.ct-widget.ct-toolbox').hide();
					break;
			}
		}

		// Update location hash in address bar.
		switcherEl.addEventListener('click', (event) => {
			window.location.hash = event.target.hash && event.target.hash.substr(1);
			triggerActionOnTabChange(window.location.hash);
		});

		// -------------------------------------------------
		// Tree Events
		this.on(eventList.TASK_DELETE, (payload) => {
			this.delete(payload);
		});

		// -------------------------------------------------
		// Watch form state and apply to navigation
		$('#f_task, #f_task_1').dirty({
			onDirty: () => {
				this.base.emit(eventList.NAV_DIRTY, true);
			},
			onClean: () => {
				this.base.emit(eventList.NAV_DIRTY, false);
			}
		});

		// -------------------------------------------------
		// Interaction Events
		// -------------------------------------------------

		// -------------------------------------------------
		// Global CHANGE listeners
		// Dynamic events *enumerated* for [on-change, on-focus, etc.]
		// Direct input events (data-<listener>) for changes'
		// ONE MAY ASK why we have dedicated data-action="xxx" handlers as well -- don't panic, it's organic!
		$(document).on('change', "[data-on-change]", (e) => {
			const cmd = $(e.target).data('on-change');
			switch (cmd) {
				case 'checkKnowledgeFields':
					let kn      = $('#om-knowledge-lookup')[0].selectize.getValue(),
					    itemPos = $('#om-select-knowledge-form')[0].selectize.getValue();
					// Add the selected KN-ID to data-param attribute
					$('#om-attach-button').data('param', {
						...$('#om-attach-button').data('param'), ...{
							knowledgeId: kn,
							itemPos:     itemPos
						}
					});
					// Enable / Disable select button
					if ((!!kn && !!itemPos))
						$('#om-attach-button').prop('disabled', false);
					else
						$('#om-attach-button').prop('disabled', true);
					break;
				// This toggles an eduTask ON or off (for collection)
				case 'toggleEduTask':
					// Activate input fields for the selected one:
					this._switchEduTask($(e.target).closest('li').data('params'))
					break;

			}
		});

		// -------------------------------------------------
		// Global CLICK listeners (data-on-click)
		$(document).on('click', "[data-on-click]", (e) => {
			let cmd    = $(e.target).data('on-click') || $(e.currentTarget).data('on-click'),
			    params = $(e.target).data('params') || $(e.currentTarget).data('params');

			switch (cmd) {
				// This is a pure UI event, opening the "tab" to show the dimensions for currently selected
				case 'showEduTask':
					// ~~~ $(e.target).siblings('input[type="checkbox"]')[0].checked
					// Params are special here
					e.preventDefault();
					e.stopImmediatePropagation();
					params = $(e.target).closest('li').data('params')
					this._switchEduTask(params);
					break;
				case 'loadEduTemplate':
					// Load template data into eduTask-Fields
					// console.log(operatorsTemplate.eduTasks, this.currentlySelectedEduTask)
					// a) Figure out, which eduTask to load,
					// b) Filter position from param
					if (!this.currentlySelectedEduTask) return;
					// this._toggleLoader(`edu-pos${params}`, true);
					e.preventDefault();
					e.stopImmediatePropagation();
					// Todo: Ask user to confirm?
					const eduTask = operatorsTemplate.eduTasks.find(edt => +edt.id === +this.currentlySelectedEduTask.id)
					if (eduTask) {
						const eduTaskTplData = eduTask.dimensioning.find(edt => +edt.position === +params);
						if (eduTaskTplData) {
							// this._toggleLoader(`edu-pos${params}`, true);
							const edscr = eduTaskTplData ? eduTaskTplData.descr : '';
							// Set content to corresponding form ::: This involves the EDITOR to acknowledge content
							this.OM_Editor.setContent(`[data-name="edutasks_descr_${ eduTask.id }_${ eduTaskTplData.position }"]`, edscr).then(() => {
								// this.OM_Editor.setContent(`data-name="edutasks_descr_${eduTask.id}_${eduTaskTplData.position}"`, edscr).then(() => {

								// this._toggleLoader(`edu-pos${params}`, false);
							});
							// Set INPUT field to as base64!
							$(`input[name="edutasks_descr_${ eduTask.id }_${ eduTaskTplData.position }"]`).val(eduTaskTplData.descr);
						}
					}
					break;
			}
		});


		// -------------------------------------------------
		//	Task Actions
		// -------------------------------------------------

		// Global listener for CLICK on task-action
		// Trigger: any click on a task-action link
		$(document).on('click', "[data-action='task']", (e) => {
			const $target = $(e.currentTarget);
			const command = $target.data('command');
			const param = $target.data('param');

			switch (command) {
				case 'selectKnowledge' :
					e.preventDefault();
					// Edit a knowledge record. Check and redirect
					this._selectKnowledge(
						{...param, ...{itemType: 2}},
						{showAddButton: true, filters: [2, 32]});
					break;

				case 'selectWorksheet' :
					e.preventDefault();
					// Edit a worksheet record. Check and redirect
					this._selectKnowledge({...param, ...{itemType: 8}}, {showAddButton: true, filters: [8]});
					break;

				case 'addPlaceholderKnowledge':
					this._addPlaceholderKnowledge(param)
					break;

				case '_detachKnowledge' :
					e.preventDefault();
					this._detachKnowledge(param.viewId).then(() => {
						this._refreshKNLists();
					})
					break;

				case '_openChildLink' :
					e.preventDefault();
					// open the page with param.taskId
					// change location
					window.location.href = `/aufgabe/${param.taskId}`;
					break;

				case 'setKNStatus' :
					console.warn('KN Status set is not implemented');
					// Directly load the KNModel and update.
					break;

				case 'updateKNFeatured' :
					// Update viewProp: show
					let elDim        = document.getElementById(`feature-${ param.viewId }`),
					    elStrip      = document.getElementById(`featurestrip-${ param.viewId }`),
					    checkedDim   = elDim.checked ? 1 : 0,
					    checkedStrip = elStrip.checked ? 2 : 0,
					    val          = checkedDim + checkedStrip,
					    title        = param.title
					// paramViewId: param.parentViewId,
					this._updateKNViewProps({...param, ...{show: val, viewTitle: title}}, $target.closest('.knowledge-item'));
					break;

				case 'updateDimension' :
					e.preventDefault();
					this.updateDimensionInfo(param);
					break;

				case 'save':
					this.save();
					break;
			}
		});

		// -------------------------------------------------
		// Knowledge-related events
		// -------------------------------------------------

		// -------------------------------------------------
		// Trigger: click on any button in the Knowledge-Select-Modal (see index.js for initialisation)
		$(document).on('click', "[data-action='knowledge']", (e) => {
			const $target = $(e.currentTarget);
			const command = $target.data('command');
			const param = $target.data('param');
			switch (command) {
				case 'editKnowledge' :
					e.preventDefault();
					// Edit a knowledge record. Check and redirect
					this._editKnowledge(param);
					break;
				case 'attachKnowledge' :
					e.preventDefault();
					if (param.ignore) {
						return  // someone wants us to ignore this one. probably the editor
					}
					this._attachKnowledge(param).then(r => {
						if (r.status === 200) this._refreshKNLists()
					});
					break;
				case 'editKnowledgeTitle':
					this._updateKNViewPropsFromEvent(e);
					break;
			}
		});

		// -------------------------------------------------
		// CHANGES to title field of a KNOWLEDGE record trigger its update
		$(document).on('keyup', "[data-on-keyup]", (e) => {
			$(e.target).addClass('uk-text-danger')
			if (e.code === 'Enter' && $(e.target).hasClass('om-kn--title')) {
				this._updateKNViewPropsFromEvent(e);
			}
		});

		// -------------------------------------------------
		// Focus on an input box (KNOWLEDGE)
		$('body').on('focus', '[data-on-focus]', (e) => {
			// Focus on a selected element: This case = knowledge item in DIMENSION view
			$(e.target).closest('.knowledge-item').addClass('focused');
		})

		// -------------------------------------------------
		// Change event (KNOWLEDGE)
		$(document).on('change', "[data-action='knowledge']", (e) => {
			const $target = $(e.currentTarget); //
			let command = $target.data('command'),
			    param   = $target.closest('li').data('param');

			// If we have multiple items in different positions (possible), then change all
			let collection = $(`[data-id="${ $target.data('id') }"]`);
			if (collection && collection.length > 1) {
				collection.map((i, c) => {
					$(c).prop('checked', $target.is(':checked'));
				});
			}

			switch (command) {
				case 'updateFeatured' :
					// A knowledge item has been (un)tagged as featured (show in kn-bar)
					this.updateProps(param.viewId, $target.is(':checked') ? 1 : 0);
					break;
			}

		});


		// -------------------------------------------------
		// Sortables
		// -------------------------------------------------

		// Watch Sortable (uikit)
		// for Subtasks && Worksheets
		$(document).on('moved', '.uk-sortable', function (e) {
			const $target = $(e.target),
			      type    = $target.parent().attr('id')
			// var currentLi = e.originalEvent.explicitOriginalTarget.parentNode;

			switch (type) {
				// Subtasks
				case 'om-subtasks':
					let indices = [];
					$target.parent().find('div.subtask').each(function () {
						indices.push($(this).data('id'));
					});
					// Send straight to api
					TaskModel.sort({tasks: indices.join(',')}).then(r => {
						console.log('Done ', r)
						// Todo: on error, reset order / UI
						if (r.status !== 200 || r.data.result !== 200)
							UIkit.notification({
								message: `Die Teilaufgaben konnten nicht sortiert werden. Bitte lade die Seite neu.`,
								status:  'warning',
								pos:     'top-right',
								timeout: 5000
							});
						else
							UIkit.notification({
								message: `Die Teilaufgaben wurden neu sortiert.`,
								status:  'success',
								pos:     'top-right',
								timeout: 5000
							});
					})
					break;
				// Worksheets
				case 'om-subtasks--worksheets':
					// // Create some data for API
					// const _data = {};
					// _data[`pos${pos}`] = indices
					// // Send
					// KnowledgeModel.sort(_data);
					break;
			}
		})

		// -------------------------------------------------
		// Sortable worksheets
		// $('#om-subtasks--worksheets').on('moved', '.uk-sortable', (e) => {
		// 	const $targetEl = $(e.target), pos = $targetEl.data('position');
		// 	console.log('SORT WORKSHEETS')
		// 	// let indices = [];
		// 	// $targetEl.find('li').each(function () {
		// 	// 	indices.push($(this).data('param').viewId);
		// 	// });
		// 	// // Create some data for API
		// 	// const _data = {};
		// 	// _data[`pos${pos}`] = indices
		// 	// // Send
		// 	// KnowledgeModel.sort(_data);
		// });

		// -------------------------------------------------
		// Global Library Events
		// This will use the media-modal events from index.js which is always loaded and waiting for events
		// NOTE: By the time we implemented tasks.js there was no such listener, so before we can use this
		// a little refactoring is in place.
		// this.base.on(eventList.OPEN_MEDIA_LIBRARY, (payload)=>{
		// 	console.log('TASK :: OPEN LIB :: ', payload)
		// 	this.openMediaLibrary(payload, 'teaserImg');
		// });

		// - - - - - - - - - - - - - - - -
		// Remove all events when leaving.
		// Since this is a load-per-view-app this is no dealbreaker, because
		// the app will reload and re-initialise on page load
		// Any browser should auto-GC this! (Sure? --> Test!)

	}


	/**
	 * Simple abstraction for the many loaders we may have
	 * @param at
	 * @param show
	 * @param opts
	 * @private
	 */
	_toggleLoader(at, show = false, opts) {
		const l = this.loaders.find(l => l.id === at)
		if (l && show) l.loader.show(opts)
		if (l && !show) {
			l.loader.hide()
			// Totally silly/lame to do this here. AINT IT????????????
			// Toggle initial eduTasks view (=all)
			if (this.task.data.eduTasks) {
				$('#edt-0').prop('checked', true)
				this._switchEduTask($('#edt-0').closest('li').data('params'))
			}
			// << -- end silly code
		}
	}

	/**
	 * Set or update a LibraryElement (TeaserImg only at this stage) -- make val[key] store to switch multiple?
	 * @param media
	 * @param placeholder
	 * @param input
	 * @private
	 */
	async _updateLibElement(media, placeholder, input) {
		if (this.omLibElementTeaser)
			this.omLibElementTeaser.setData(media)
		else {
			// NOTE: This is specific for the teaser image.
			this.omLibElementTeaser = new LibraryElement(this.base, placeholder, media, {
				mode:          'default',
				showAddButton: true,
				caption:       false,
				editable:      false,
				selectable:    false,
				deleteable:    false,
				removeable:    true,
				size:          'medium',
				allowed:       $(placeholder).data('allowed'),
				input:         input
			});
			// MEDIA REMOVAL event
			// this.omLibElementTeaser.on(eventList.OPEN_MEDIA_LIBRARY, (payload) => {
			// 	this.openMediaLibrary(payload.placeholder, 'teaserImg');
			// });

			// HACK: Fill all other placeholders. This is currently an editor-placeholder (component: dimensioning w. teaser inside)
			if (media) {
				let _img = await this.omLibElementTeaser.renderPreview(media);
				$('.om-task-teaserimage-placeholder')
				.css(`background-image`, `url(${ _img.placeholder })`);
			}


		}

		// There's no destroy method (yet) for the LibElements
		// since there is no destroy method for this class either
	}

	/**
	 * Collect all libraryIds from components used
	 * Match previous media array with new one and detach or attach to task
	 * @private
	 */
	_linkMediaToTask() {
		return new Promise((resolve) => {
			// Assemble ids from 3 different sources
			// (1) Teaser, (2) Affection, (3) Editor
			let mediaIdsFromTeaser = $('input[name=teaserImg]').val();  // Only one.
			let mediaIds = [mediaIdsFromTeaser, ...this.OM_Editor.getAttachedLibElements(), ...this.OM_Affection.getLibItems()].filter(n => n);                        // add (single) teaser Id
			// Just set to the input as value
			$('input[name=libItems]').val(mediaIds.join(','));  // Don't rely on this
			resolve(mediaIds.join(','));	// just let the caller know, it's been set
		});
	}

}
