import UIkit                from "uikit";
import { getDateFormatted } from "../utils/base";

// HACK: make sure all fields missing are to default (DB should provide)
// API should provide
let date = new Date();
date.setFullYear(date.getFullYear() + 1);

let initial = {
	itemId: null,
	itemType: 2,    // bit : see docs.
	parentId: null,
	status: 2,
	title: '',
	teaser: '',
	teaserImg: null,
	content: '',
	position: -1,
	itemPos: -1,
	checkRemind: getDateFormatted(date),
	schoolSubj: [],
	eduTasks: []
};


/**
 * Knowledge model
 * simple SINGLETON type abstraction for API that catches errors and keeps a model state
 * SUPERFLOUS, yet it's here now...
 *
 * @type {{set(*=, *=): void, data: {teaserImg: null, id: null, title: string, content: string, status: number, teaser: string}, save: (function(*): (Promise<never>)), loadCollection: (function(*=, *=): *), sort(*=): *, delete(*=): (Promise<unknown>|Promise<T>), load(*=, *, *=): Promise|Promise<unknown>, busy: boolean, get(): KnowledgeModel.data, attachToTask(*=, *=, *=, *=, *=): Promise<AxiosResponse<T>>, create(): void, updateProps(*=): *, detachFromTask(*=, *=, *=): Promise}}
 */
let KnowledgeModel = {
	data: {...initial},

	/**
	 * Initialise an empty data object
	 */
	create() {
		this.data = {...initial};
	},

	/**
	 * Set props
	 * @param props
	 * @param sync
	 */
	set(props, sync = false) {
		this.data = {...this.data, ...props};
		// Save to DB if wanted
		if (sync) this.save(props);
	},

	/**
	 * Get the current record (note: no ID required, make sure this is always up to date!)
	 * @return {{itemId: null, itemType: number, teaserImg: null, title: string, parentId: null, content: string, status: number, teaser: string}}
	 */
	get() {
		return this.data;
	},

	/**
	 * Get a knowledge record either by viewId (from a task)
	 * or straight via id = knowledge.itemId
	 * @param viewId
	 * @param id
	 * @param params
	 * @param opts
	 * @return {Promise<unknown>}
	 */
	load(viewId = null, id = null, params, opts) {
		// Either by view or knId
		return new Promise((resolve, reject) => {
			window.omApi.getKnowledge(viewId, id, opts)
				.then(result => {
					if (result.data) {
						this.data = result.data ? result.data : {...initial}
						resolve(this.data);
					} else {
						reject(false)
					}
				});
		});
	},

	/**
	 * Load a (filtered) collection of knowledges
	 * @param param STRING queryString (make this an object!)
	 * @param opts Array of : ????? TODO: what options?
	 * @return {*}
	 */
	loadCollection: (param, opts) => {
		// Get all
		return new Promise((resolve, reject) => {
			// debugger
			window.omApi.getKnowledgeCollection(param, opts)
				.then(result => {
					// We should return the result.request / xhr OR a cancelToken (for AutoLookup)
					resolve(result.data.knowledges);
				}).catch(e => {
				console.warn('Could not fetch knowledge collection ', e)
			});
		});

	},

	save: (data) => {
		// C || U

		return new Promise((resolve, reject) => {
			if (data.itemId) {
				window.omApi.updateKnowledge(data.itemId, data)
					.then(r => {
						if (r) {
							UIkit.notification({
								message: `${window.omBase.getKNElementType(data.itemType).label} wurde aktualisiert`,
								status: 'success',
								pos: 'top-right',
								timeout: 5000
							});
							resolve(true);
						} else {
							reject();
						}
					}, () => {
						// Error message
						reject();
					})
			} else {
				// Create
				console.info('Create', data)
				window.omApi.createKnowledge(data).then(r => {
					if (r) {
						UIkit.notification({
							message: `Wissensbeitrag wurde angelegt`,
							status: 'success',
							pos: 'top-right',
							timeout: 5000
						});
						resolve(r);
					} else
						reject();
				})
			}
		}).finally(_ => {
			//
		});
	},


	/**
	 *
	 * @param id the id of the record. It should be null, so this one
	 * @return {Promise<unknown> | Promise<T>}
	 */
	delete(id) {
		return new Promise((resolve, reject) => {
			window.omApi.deleteKnowledge(id, {showLoading: false}).then(r => {
					if (r) {
						UIkit.notification({
							message: `Der Wissensbeitrag wurde gelöscht.`,
							status: 'success',
							pos: 'top-right',
							timeout: 5000
						});
						this.data = {...initial};
						resolve(r);
					} else
						reject();
				},
				() => {
					UIkit.notification({
						message: `Der Wissensbeitrag konnte nicht gelöscht werden.`,
						status: 'danger',
						pos: 'top-right',
						timeout: 5000
					});
					reject();
				});
		}).finally(_ => {
			//
		});

	},

	/**
	 * Attach knowledges with tasks
	 * @param taskId
	 * @param knowledgeId
	 * @param position
	 * @param subPosition
	 * @param prop
	 * @param itemPos
	 * @param isPlaceholder BOOL if true, then we got a simple text entry (using a generic kn)
	 * @param parentViewId STRING the viewId of a knowledge object, if set, the kn will be attached as child
	 * @returns {Promise<AxiosResponse<T>>}
	 */
	attachToTask(taskId, knowledgeId, position, subPosition, prop, itemPos, isPlaceholder = false, parentViewId = null, viewProps = null) {
		return new Promise((resolve, reject) => {
			let payload = {
				taskId: taskId,
				knowledgeId: knowledgeId,
				position: +position,
				subPos: +subPosition,
				itemPos: +itemPos,
				viewProps: viewProps
				// isPlaceholder: (isPlaceholder) ? 1 : 0,
				// parentId: parentViewId || ""
			}
			// It's the placeholder KN (itemPos is set to 1! Todo: this may need some update!)
			if (isPlaceholder) payload = {...payload, ...{placeholder: 1, itemPos: 1}}
			// It's a child of parentId
			if (parentViewId) payload = {...payload, ...{parentId: parentViewId}}

			window.omApi.attachKnToTask(payload).then(
				r => {
					if (r) {
						// UIkit.notification({
						// 	message: `Wissensbeitrag erfolgreich verknüpft`,
						// 	status: 'success',
						// 	pos: 'top-right',
						// 	timeout: 5000
						// });
						resolve(r);
					} else
						reject();
				},
				() => {
					console.warn('KN ATTACH REJECTED. ');
					reject();
				}
			).catch(e => {
				console.warn('Some error happened on attach', e);
				UIkit.notification({
					message: `Ein Fehler trat auf (${e})`,
					status: 'warning',
					pos: 'top-right',
					timeout: 5000
				});
				reject();
			})
		});
	},

	/**
	 * Detach Knowledge from task
	 * @param viewId STRING
	 * @returns {Promise}
	 */
	detachFromTask(viewId) {
		return new Promise((resolve, reject) => {
			window.omApi.detachKnFromTask({
				viewId: viewId
			}).then(
				r => {
					if (r) {
						// UIkit.notification({
						// 	message: `Wissensbeitrag erfolgreich entfernt.`,
						// 	status: 'success',
						// 	pos: 'top-right',
						// 	timeout: 5000
						// });
						resolve(r);
					}
				},
				() => {
					console.warn('KN ATTACH REJECTED. ');
					reject();
				}
			).catch(e => {
				console.warn('Some error happened on attach', e);
				UIkit.notification({
					message: `Ein Fehler trat auf (${e})`,
					status: 'warning',
					pos: 'top-right',
					timeout: 5000
				});
				reject();
			})
		});
	},


	/**
	 * Sort knowledges
	 * @param data object of {pos1,pos2,pos3,pos4...}
	 * @return {Promise<unknown>}
	 */
	sort(data) {
		this.busy = true;

		// HACK
		if (data.pos1) data.pos1 = data.pos1.join(',')
		if (data.pos2) data.pos2 = data.pos2.join(',')
		if (data.pos3) data.pos3 = data.pos3.join(',')
		if (data.pos4) data.pos4 = data.pos4.join(',')
		if (data.pos6) data.pos6 = data.pos6.join(',')  // TODO: untested

		return new Promise((resolve, reject) => {
			window.omApi.sortKnowledges(data)
				.then(r => {
						// console.log('sort promise ', r)
						resolve(r);
					},
					e => {
						reject(e);
					})
		}).finally(_ => {
			this.busy = false;
		});
	},

	/**
	 * View Properties update
	 * @param data object {
            viewId: STRING,
            show: BIT,  1 || 2 / 1 && 2
            title: STRING
		}
	 * @returns {*}
	 */
	updateProps(data) {
		this.busy = true;
		return new Promise((resolve, reject) => {
			window.omApi.updatePropsKnowledge(data)
				.then(r => {
						if (r.status !== 200) reject()
						resolve(r);
					},
					e => {
						throw new Error(e);
						reject();
					})
		}).finally(_ => {
			this.busy = false;
		});
	},

	move(data = {viewId: null, parentId: null, position: 1, subPos: 1, title: '', show: 0}) {
		this.busy = true
		return new Promise((resolve, reject) => {
			window.omApi.moveKnowledge(data)
				.then(r => {
						if (r.status !== 200) reject()
						resolve(r);
					},
					e => {
						throw new Error(e);
						reject();
					})
		}).finally(_ => {
			this.busy = false;
		});
	},

	setParent(data) {
		// console.log('Setting new parent view Id', data)
		this.busy = true
		return new Promise((resolve, reject) => {
			window.omApi.setKnowledgeParent(data)
				.then(r => {
						if (r.status !== 200) reject()
						resolve(r);
					},
					e => {
						throw new Error(e);
						reject();
					})
		}).finally(_ => {
			this.busy = false;
		});
	}

}

export default KnowledgeModel

