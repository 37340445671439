import axios        from "axios";
import EventEmitter from "eventemitter3";
import DataTable    from 'datatables.net'

/**
 * List of user accounts
 *
 */
export default class Rsvp extends EventEmitter {
	constructor(BaseClass) {
		super();
		this.base = BaseClass;
		this.dataTable = null;

		this._initDatatable();
		this._initEvents();
	}

	_initEvents() {

	}

	/**
	 * Init DT
	 * Children: https://datatables.net/examples/api/row_details.html
	 * @returns {Promise<void>}
	 * @private
	 */
	async _initDatatable() {
		// axios.defaults.baseURL = '//' + window.location.host + '/api/v1/';
		// const data = await axios.get('mailer/registerEvent')
		const r = await window.omApi.getRsvpList()
		const data = r.data
		console.log(data)

		// ----- Datatable
		const dataTable = $("#omAccountsTable").DataTable({
			data: data,
			pageLength: 25,
			columns: [
				{
					className: 'dt-control',
					width: 'auto',
					"render": function (data, type, row) {
						return `<strong>${ row.userName || '' }</strong>`
					},
				},
				{
					data: 'email',
					width: '25%',
				},
				{
					data: 'date',
					width: '15%',
				},
				// {
				// 	data: 'status',
				// 	render: function (data, type, row, meta) {
				// 		return type === 'display'
				// 			? data === 1 ? `<span uk-icon="check"></span>` : `<span uk-icon="close"></span>`
				// 			: data;
				// 	},
				// },
			],
			language: {
				url: './web/assets/i18n/datatable.de.json'
			}
		});

		// Add event listener for opening and closing details
		$('#omAccountsTable tbody').on('click', 'td.dt-control', function () {
			let tr = $(this).closest('tr');
			let row = dataTable.row(tr);

			if (row.child.isShown()) {
				// This row is already open - close it
				row.child.hide();
				tr.removeClass('shown');
			} else {
				// Open this row
				row.child(format(row.data())).show();
				tr.addClass('shown');
			}
		});

		// Watch filters
		$('.uk-checkbox').on('change', () => {
			let searchTerms = [];
			// Roles
			$.each($('.filter-role'), function (i, elem) {
				if ($(elem).prop('checked')) {
					searchTerms.push("^" + $(this).val() + "$")
				}
			});
			dataTable.column(1).search(searchTerms.join('|'), true, false, true).draw();

			// Active
			let searchTermsActive = [];
			$.each($('.filter-active'), function (i, elem) {
				if ($(elem).prop('checked')) {
					searchTermsActive.push("^" + $(this).val() + "$")
				}
			});
			dataTable.column(3).search(searchTermsActive.join('|'), true, false, true).draw();
		});
	}
}
