import Affection      from "./affection";
import LibraryElement from "../library.element";

export default class SplitImage extends Affection {

	constructor(base, placeholder, data, options) {
		super(base, placeholder, data, options);

		// Placeholders for libraryElements. Just 2 images. But render() needs to happen first
		this.imageTop = null;
		this.imageLeft = null;
		this.imageRight = null;
		this.insertImageAtPlaceholder = null

		// Initial render
		this._render();

		// If the constructor received data
		if (this._data) this.setData(this._data);

		this._initEvents();
	}


	_initEvents() {
		super._initEvents();
		// Initialise our 2 image elements
		this.imageLeft = new LibraryElement(this.base, '#omImgLeft', null, {

			showAddButton: true,		// The (+) button to add a new
			mode: 'box',
			meta: true,
			title: false,
			editable: false,
			selectable: false,
			deleteable: false,
			removeable: true,
			size: 'large',
			allowed: ['image', 'video'],
			options: {
				selectionMode: true,
				multiple: false
			},
			input: 'imgLeft'
		});
		this.imageRight = new LibraryElement(this.base, '#omImgRight', null, {

			showAddButton: true,		// The (+) button to add a new
			mode: 'box',
			meta: true,
			title: false,
			editable: false,
			selectable: false,
			deleteable: false,
			removeable: true,
			size: 'large',
			allowed: ['image', 'video'],
			options: {
				selectionMode: true,
				multiple: false
			},
			input: 'imgRight'
		});
		// The top image / video, full, optional
		this.imageTop = new LibraryElement(this.base, '#omImgTop', null, {
			showAddButton: true,		// The (+) button to add a new
			mode: 'box',
			meta: true,
			title: false,
			editable: false,
			selectable: false,
			deleteable: false,
			removeable: true,
			allowed: ['image', 'video'],
			size: 'large',
			options: {
				selectionMode: true,
				multiple: false
			},
			input: 'imgTop'
		});


	}

	/**
	 * Set data to component
	 * @param data
	 */
	setData(data) {
		this._data = this._convertFromEditorFormat(data);
		super.setData(this._data);
		// Simply render the 2 images, if any
		if (this._data.length && this._data[0])
			this.imageLeft.setData(this._data[0])
		if (this._data.length && this._data[1])
			this.imageRight.setData(this._data[1])
		// Large top media. Optional
		if (this._data.length && this._data[2])
			this.imageTop.setData(this._data[2])


	}

	/**
	 * Return current collection
	 * @param withId BOOL - if set, mixin the name of this component. Which is STUPIDLY component_4 []
	 * @return {*}
	 */
	getData(withId = false) {
		// Remove any images that exceed the max number (2) of this component
		// This is only kicking if a user changes from an existing template_1 (slideshow) component
		// which has n images
		// Therefore we replace the data with our 2 images here: (deletes all slider images finally)
		this._data = [this.imageLeft.getData(), this.imageRight.getData(), this.imageTop.getData()]
		return withId
			? {...{template: 'component_4'}, ...this._convertToEditorFormat(this._data)}
			: this._convertToEditorFormat(this._data)
	}


	/**
	 * Render output
	 * @private
	 */
	_render() {
		// clear
		this.$placeholder.empty()

		const tpl = `
		<div id="omImgTop" data-type="libelement"></div>
		<div class="uk-child-width-expand@s uk-text-center" uk-grid style="width: 100%;margin: 0 auto;">
			<div id="omImgLeft" class="uk-padding-remove" data-type="libelement" style="padding:0;"></div>
			<div id="omImgRight" class="uk-padding-remove" data-type="libelement" style="padding:0;"></div>
		</div>`;

		this.$placeholder.html(tpl);

	}

}
