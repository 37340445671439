import UIkit     from 'uikit';
import UserModel from "./models/user";

/**
 * OM User
 */

export default class User {

	/**
	 *
	 * @param BaseClass Class - inject base class
	 */
	constructor(BaseClass) {

		this.base = BaseClass;

		// Basic data for user. Doubled by the user-object in localStorage
		this.user = {
			userName: null,
			email: null,
			secret: null,
			role: {
				id: 0,
				name: ''
			}
		}

		this.busy = false;

		// DOM (~ login.component.php)
		this.$OM_LOGIN_FORM = $('#om-form-login');
		this.$OM_LOGOUT_FORM = $('#om-form-logout');
		this.$OM_PWRESET_FORM = $('#om-form-reset');
		this._updateUI();

		// Loader
		// this.loader = new Loader('body', {full: true});

		// Init
		this._initialiseEvents();

		// Check authentication
		this.get();

	}

	// ---- Public

	/**
	 * Return the current user
	 */
	get() {
		if (window.location.pathname === '/login') return null;         // No action on login screen
		try {
			const user = JSON.parse(localStorage.getItem('$$omauth'));
			if (!this.hasAccess(user)) {
				return this;
			}
			this.user = {...user, ...{authenticated: user.loggedIn}};
			return this;
		} catch (e) {
			return null;
		}
	}

	/**
	 * This will just return the user logged in state
	 * @returns {*|{role: {name: string, id: number}, secret: null, userName: null, email: null}|null|boolean}
	 */
	isAuthenticated() {
		this.get();
		return this.user && this.user.secret && this.user.role?.id >= 10
	}

	/**
	 * Will check if the user exists and has the admin role.
	 * @param user
	 * @returns {false|*}
	 */
	async hasAccess(user) {
		const access = (user && +user?.role?.id >= 10 && user?.secret);
		return access;
	}


	// ---- Private

	_initialiseEvents() {
		// We watch the buttons / click, not the submit event as this won't stop on errors
		$('form').on('submit', (e) => {
			// $('[data-action="auth"]').on('click', (e)=> {
			e.preventDefault();

			const command = $(e.target).data('command');
			switch (command) {
				case 'login' :
					this._onLogin();
					break;
				case 'logout' :
					this._onLogout();
					break;
				case 'reset' :
					this._onReset();
					break;
				case 'changepw' :
					this._onChangePassword();
					break;
			}
		});
	}


	/**
	 * Login action
	 * @returns {Promise<void>}
	 * @private
	 */
	async _onLogin() {
		this.busy = true;
		this._updateUI();
		let data = this.base.getFormData(this.$OM_LOGIN_FORM);
		let _msg = 'Willkommen beim OmniMundi CMS';
		try {
			const usr = await UserModel.login(data.userLogin, data.userPwd)

			// User is no admin
			if (!this.hasAccess(usr.user)) {
				return;
			}
			// Okay
			UIkit.notification({
				message: _msg,
				status: 'success',
				pos: 'top-right',
				timeout: 5000
			});
			this.user = usr.user;   // Todo: no need for nested objects
			localStorage.setItem('$$omauth', JSON.stringify(this.user));    // Store.
			location.href = '/';
		} catch (e) {
			this.busy = false;
			this.user = {}

			let _msg = e?.message;
			this._setMessage(_msg);
			this._updateUI();
			UIkit.notification({
				message: e?.message,
				status: 'warning',
				pos: 'top-right',
				timeout: 5000
			});

			UserModel.logout();

		}

		this._setMessage(_msg);
		this.busy = false;
		this._updateUI();
	}

	/**
	 * Logout action
	 * @returns {Promise<void>}
	 * @private
	 */
	async _onLogout() {
		const r = await UserModel.logout();
		return r
	}

	/**
	 * Reset password action
	 * @returns {Promise<void>}
	 * @private
	 */
	async _onReset() {
		this.busy = true;
		this._updateUI();
		let data = this.base.getFormData(this.$OM_PWRESET_FORM);
		if (!data) return;
		const r = await UserModel.resetPass(data.userLogin);
		let _msg = `Dein Passwort wurde geändert und dir als Email zugesandt.`;
		if (r.status === 200 && r.data && r.data.result === 200) {
			this._setMessage(_msg);
			UIkit.notification({
				message: _msg,
				status: 'success',
				pos: 'top-right',
				timeout: 5000
			});
		} else {
			_msg = `Leider konnten wir das Passwort nicht zurücksetzen.`;
			this._setMessage(_msg);

		}
		this.busy = false;
		this._updateUI();
	}


	/**
	 * Password Reset Form
	 * @private
	 */
	_onChangePassword() {
		// User data - Fill Form
		// Todo: use THIS.
		const ud = JSON.parse(localStorage.getItem('$$omauth'));

		// Translate to what we need
		let udTrans = {...ud, ...{userLogin: ud.email}}
		this.base.populate(this.$OM_PWRESET_FORM, udTrans);

		this._updateUI();

		// On Submit
		// Get Form data // Note: this form is in another, separate php file /
		let data = {...this.base.getFormData($('#om-form-changepw')), ...{userLogin: udTrans.userLogin}};

		// Simple validation
		if (!data.userPwd.length || !data.newPwd.length || !data.newPwdRepeat.length) {
			UIkit.notification({
				message: `Bitte fülle alle Felder aus.`,
				status: 'warning',
				pos: 'top-right',
				timeout: 5000
			});
			return;
		} else if (data.newPwd !== data.newPwdRepeat) {
			// Match passwords
			UIkit.notification({
				message: `Die Passwörter stimmen nicht überein.`,
				status: 'warning',
				pos: 'top-right',
				timeout: 5000
			});
			return;
		}

		// Send
		UserModel.changePass(data).then(r => {
			if (r.status === 200) {
				// Success
				UIkit.notification({
					message: `Passwort wurde geändert.`,
					status: 'success',
					pos: 'top-right',
					timeout: 5000
				});
			} else {
				// Fail
				UIkit.notification({
					message: `Dein Passwort wurde nicht geändert!`,
					status: 'warning',
					pos: 'top-right',
					timeout: 5000
				});
			}
		}).finally(() => {
			$('#om-form-changepw').get(0).reset()
		});
	}

	/**
	 * Just update the ui according to the current state
	 * Shows and hides things...
	 * @private
	 */
	_updateUI() {
		this.get()
		// Labels
		$('.__userEmail').text(this.user && this.user.email && this.user?.email);   // using ?. should make the long check obsolete. Yet, it's ES6 experimental
		$('.__userRole span').text(this.user && this.user.role && this.user.role.name);
		// Show/Hide areas depending on login state
		if (!this.isAuthenticated()) {
			$('[data-if="authenticated"').hide();
			$('[data-if="!authenticated"').show();
		} else {
			$('[data-if="!authenticated"').hide();
			$('[data-if="authenticated"').show();
		}

		// Login || Reset form?

		if (location.pathname === '/login/reset') {
			this.$OM_LOGIN_FORM.hide();
			this.$OM_PWRESET_FORM.show();
		} else {
			this.$OM_LOGIN_FORM.show();
			this.$OM_PWRESET_FORM.hide();
		}


		// Busy states
		if (this.busy) {
			$('[data-if="busy"]').parent().addClass('uk-disabled');
			$('[data-if="busy"]').show();
			$('[data-if="!busy"]').hide();
		} else {
			$('[data-if="busy"]').parent().removeClass('uk-disabled');
			$('[data-if="busy"]').hide();
			$('[data-if="!busy"]').show();
		}

	}

	/**
	 * Set or unset a message and show the message div
	 * NOTE: The box is currently styled to be RED
	 * @param msg
	 * @private
	 */
	_setMessage(msg = null) {
		if (!msg) {
			$('[data-type="message"]').empty();
			$('[data-type="message"]').css('display', 'none');
			return;
		}
		$('[data-type="message"]').css('display', 'block');
		$('[data-type="message"]').empty().html(msg);
	}
}
