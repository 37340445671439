import {eventList} from "../utils/base";

/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
const Cls = (ContentTools.Tools.KnLink = class Link extends ContentTools.Tools.Bold {
	static initClass() {

		// Insert/Remove a link.
		ContentTools.ToolShelf.stow(this, 'omLinkKN');

		this.label = 'Glossar / Wissensbeitrag verlinken';
		this.icon = 'drawer';
		this.tagName = 'a';
		this.applied = false;


	}

	static getAttr(attrName, element, selection) {
		// Get an attribute for the element and selection

		// Fixtures
		if (element.isFixed() && (element.tagName() === 'a')) {
			return element.attr(attrName);

			// Text
		} else {
			// Find the first character in the selected text that has an `a` tag
			// and return the named attributes value.
			const [from, to] = Array.from(selection.get());
			const selectedContent = element.content.slice(from, to);
			for (let c of Array.from(selectedContent.characters)) {
				if (!c.hasTags('a')) {
					continue;
				}

				for (let tag of Array.from(c.tags())) {
					if (tag.name() === 'a') {
						return tag.attr(attrName);
					}
				}
			}
		}

		return '';
	}

	static canApply(element, selection) {
		// Return only true, if linkType-Attribute matches
		// Return true if the tool can be applied to the current
		// element/selection.
		if (element.isFixed() && (element.tagName() === 'a')) {
			return true;
		} else {
			// Must support content
			if (!element.content) {
				return false;
			}

			// A selection must exist
			if (!selection) {
				return false;
			}

			// If the selection is collapsed then it must be within an existing
			// link.
			if (selection.isCollapsed()) {
				const character = element.content.characters[selection.get()[0]];
				if (!character || !character.hasTags('a')) {
					return false;
				}
			}

			return true;
		}
	}

	static isApplied(element, selection, callback) {
		// Return true if the tool is currently applied to the current
		// element/selection.

		// Get wrapping tag and linkType, if it is not a "library" type then false
		const character = selection ? element.content.characters[selection.get()[0]] : element.content.characters[0];
		const aTags = character.tags().filter((t) => t._name === "a")[0];
		const linkType = aTags ? aTags._attributes['data-linktype'] : undefined;

		// Ignore if it's not a knowledge type
		// Todo: knowledgeSimple as well
		if (linkType !== 'knowledge') {
			this.applied = false;
			return false;
		}

		if (element.isFixed() && (element.tagName() === 'a')) {
			this.applied = true;
			return this.applied;
		} else {
			this.applied = super.isApplied(element, selection);
			return this.applied;
		}
	}

	static reset(element, from, to, selectTag) {
		// Restore original state (remove selection)
		if (element.content) {
			// Remove the fake selection from the element
			element.content = element.content.unformat(from, to, selectTag);
			element.updateInnerHTML();

			// Restore the selection
			element.restoreState();
		}
	}

	static apply(element, selection, callback) {
		// Dispatch `apply` event
		let from, rect, selectTag, to;
		const toolDetail = {
			'tool': this,
			'element': element,
			'selection': selection
		};
		if (!this.dispatchEditorEvent('tool-apply', toolDetail)) {
			return;
		}

		// If already applied, remove
		if (this.applied) {
			// const selectTag = new HTMLString.Tag('span', {'class': 'ct--pseudo-select'});
			const [from, to] = Array.from(selection.get());
			// element.content = element.content.unformat(from, to, selectTag);
			element.content = element.content.unformat(from, to, 'a');
			element.updateInnerHTML();
			element.taint();
			return false;
		}
		//

		// Prepare text elements for adding a link
		if (element.isFixed() && (element.tagName() === 'a')) {
			// Fixtures
			// Note: NYI!
			rect = element.domElement().getBoundingClientRect();

		} else {
			// If the selection is collapsed then we need to select the entire
			// entire link.
			if (selection.isCollapsed()) {
				// Find the bounds of the link
				const {
					characters
				} = element.content;
				let starts = selection.get(0)[0];
				let ends = starts;

				while ((starts > 0) && characters[starts - 1].hasTags('a')) {
					starts -= 1;
				}

				while ((ends < characters.length) && characters[ends].hasTags('a')) {
					ends += 1;
				}

				// Select the link in full
				selection = new ContentSelect.Range(starts, ends);
				selection.select(element.domElement());
			}

			// Text elements
			element.storeState();

			// Add a fake selection wrapper to the selected text so that it
			// appears to be selected when the focus is lost by the element.
			selectTag = new HTMLString.Tag('span', {'class': 'ct--pseudo-select'});
			[from, to] = Array.from(selection.get());
			element.content = element.content.format(from, to, selectTag);
			element.updateInnerHTML();

		}

		// ----------------
		// Custom Dialog
		// @new open kn selector and watch for return.

		// Await KN selection and handle it by
		// converting it into a link we can use
		// THIS IS WILD!

		// Cancelled: Listen for close of modal and reset selection
		window.omBase.on('KN_SELECTOR_CLOSED', () => {
			// Remove fake selection
			this.reset(element, from, to, selectTag)
			window.omBase.off('KN_SELECTOR_CLOSED')
		});

		// Once the knowledge selector window (see index.js) is closed,
		// use the given event data and get the knowledge record
		window.omBase.on('KN_SELECTOR_SELECTION', (e) => {
			const $target = $(e.currentTarget);
			const param = $target.data('param');
			const {knowledgeId} = param
			// Nothing selected
			if (!knowledgeId) {
				console.warn('NO KN ID given!')
				this.reset(element, from, to, selectTag);
			}

			// Some KN was selected
			window.omApi.getKnowledge(null, knowledgeId)
				.then(result => {
					// NOTE: GET returns alaways an ARRAY where key [0] is the actual KN Object
					if (result.data) {
						let detail = {
							href: `/knowledge/${knowledgeId}`,
							'data-title': result.data.title,
							'data-id': knowledgeId,
							// 'data-task': taskId,     // Todo: we may need this when we are in the task editor
							'data-linktype': 'knowledge',
							'data-itemtype': result.data.itemType,  // knowledge or knowledgeSimple?
							'data-template': (result.data.content) ? result.data.content.card.template : ''
						};

						this.applied = true;

						if (element.isFixed() && (element.tagName() === 'a')) {
							// Fixtures
							element.attr('href', detail.href);
						} else {
							// Text elements
							// Clear any existing link
							element.content = element.content.unformat(from, to, 'a');

							// If specified add the new link
							if (detail.href) {
								const a = new HTMLString.Tag('a', detail);
								element.content = element.content.format(from, to, a);
								element.content.optimize();
							}

							// NOTE: do you know what you're doing? This removes the pseudo-selection again
							element.content = element.content.unformat(from, to, selectTag);
							element.updateInnerHTML();
						}


						// Let whoever know that we're done here.
						window.omBase.emit(eventList.EDITOR_SET_LINK_DONE, {})   // send mediaId to attach

						// Make sure the element is marked as tainted
						element.taint();

						// Remove fake selection
						if (element.content) {
							// Remove the fake selection from the element
							element.content = element.content.unformat(from, to, selectTag);
							element.updateInnerHTML();

							// Restore the selection
							element.restoreState();
						}

						// Dispatch `applied` event
						ContentTools.Tools.Link.dispatchEditorEvent(
							'tool-applied',
							toolDetail
						);

						// No more cancel event
						window.omBase.off('KN_MODAL_CLOSED');
					}

				})
				.catch(e => {
					console.warn('Could not load KN', e);
					this.reset(element, from, to, selectTag);
				})
				.finally(() => {
					// Remove the EL!
					window.omBase.off('KN_SELECTOR_SELECTION')
				})

		});


		// Open the Knowlege Selector (modal, see index.js)
		window.omBase.emit(eventList.OPEN_KN_SELECTOR, {
			params: {
				ignore: true,       // no data-action (to be received by any component else)
				itemPos: 1			// needs ALWAYS to be set or the done button will be disabled
			}, options: {
				showAddButton: false,       // No (+) button
				showItemPos: false,
			}
		})


		return true;
	}
});
Cls.initClass();



