class ContentTools.Tools.Blockquote extends ContentTools.Tools.Heading

  # Convert the current text block to a blockquote (e.g <blockquote>foo</blockquote>)

  ContentTools.ToolShelf.stow(@, 'blockquote')

  @label = 'Zitat'
  @icon = 'blockquote'
  @tagName = 'blockquote'

