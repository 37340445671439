import { eventList, omPlaceholders, uuidv4 } from "../../utils/base";
import LibraryElement                        from "../library.element";
// One of the following themes
import '@simonwep/pickr/dist/themes/nano.min.css';      // 'nano' theme
import Pickr                                 from '@simonwep/pickr';
import Affection                             from "./affection";

/**
 * Slideshow class
 * @component
 *
 * @interface
 * setData()                            ARRAY of LibraryElements (TeaserObjects)
 * getData()                            ARRAY of LibraryElements (TeaserObjects)
 * insertElement(element, position)     LibraryElement OBJ, position INT
 *
 * event driven interface
 *
 * @emits
 * OPEN_MEDIA_MODAL {position}
 *
 *
 *
 *
 */
export default class Slideshow extends Affection {

	/**
	 * Constructor
	 * @param base
	 * @param placeholder   DOM ID of wrapping element (to identify this on multiple)
	 * @param data          JSON Array of LibraryElements (TeaserObject, short)
	 * @param options Object
	 *          hasCaption: if true, show draggable caption containers
	 *          inlineMode: if true, show the control strip on top of  main image (on hover)
	 *          isResizeable: if true, show resize options. Note: only working with 1 single image (!)
	 */
	constructor(base, placeholder, data = {}, options = {hasCaption: true, inlineMode: false, isResizeable: true, isFull: false}) {
		super(base, placeholder, data, options);
		// this.uid = 'slider_'+uuidv4();    // Add a unique id todo: not needed. placeholder suffices
		// this._data = data;                  // It is an object with named props
		// ex: {le_0_s_0: {}, le_0_s_1:{}, ...} where each le_ is a libElement (at least with ID)

		// Default size of images
		this.sizeData = {w: 100, h: 100, cssClass: '', key: ''};

		this.currentIndex = 0;              // current index of slideshow

		// A single libElement to get and apply data
		if (!$('#noop').length) $('body').append('<div id="noop"/>');
		this.libElement = new LibraryElement(this.base, '#noop', null, {});

		// DOM
		this._renderComponent();
		this.$MAINSLIDER = $(placeholder + ' .om-slider-main');
		this.$CONTROLSLIDER = $(placeholder + ' .om-slider-control');
		this.$OM_TEASER_CONTAINER = $(placeholder + ' .teaser-container');
		this.$OM_TEASER = $(placeholder + ' .aff_teaser');

		// An initial meta object
		this.defaultMeta = {title: '', color: '#ffffff', position: {x: 50, y: 50}};
		this.added = false; // global indicator for a newly added image

		// DEV
		// this._devFillCollection();

		// Set data if any
		if (this._data) this.setData(this._data);


		this._initEvents();
	}


	// ------------------------------------------------------------------------------

	/**
	 * Return current collection
	 * @param withId BOOL - if set, mixin the name of this component. Which is STUPIDLY component_4 []
	 * @return {*}
	 */
	getData(withId = false) {
		return withId
			? {...{template: 'component_4'}, ...this._convertToEditorFormat(this._data)}
			: this._convertToEditorFormat(this._data)
	}

	/**
	 * Set collection
	 * @param data JSON array of LibraryElements (TeaserObjects)
	 */
	setData(data) {
		super.setData(data);
		this._data = this._convertFromEditorFormat(data);    // this makes this components data complicated. Make [] from {}
		this._render();
	}

	/**
	 * Insert a LibraryElement (TeaserObject) at a certain(!) position
	 * @param element
	 */
	insertElement(elements, param = {insertAt: 0}) {
		elements.forEach(element => {
			let _meta = {...this.defaultMeta, ...{title: element.title}};
			element = {...element, ...{meta: _meta}};
			if (this._data)
				this._data.splice(param.insertAt, 0, element);
			this.currentIndex = param.insertAt; // pointer to current item!
		});

		this.added = true;
		this._render();


		// om-slider-main : would be better to dynamically add / remove slides
		// UIkit.slideshow(this.$placeholder.find('.om-slider-main')).show(this.currentIndex);
		// Bubble event up:
		// this.emit(eventList.SLIDER_UPDATE);
	}


	destroy() {
		super.destroy();
		this.$placeholder.empty();
	}

	/**
	 * Render a size selector. D.R.Y: This is duplicate code from library.element!!!
	 * @return {string}
	 * @private
	 */
	_renderSizeSelector() {
		if (!this.options.isResizeable) return '';
		// --------------------------------------------------------------------------
		// @since 0.9.3 : Resizing options : NOTE: they are NOT changeable once saved.
		let sizes = [
			{val: '', label: 'auto'},
			// {val: 'custom', label: 'Benutzerdefiniert'},
			{val: 'auto', label: '---------------------'},

			// {val: 'is-full:100:auto', label: '100% x auto'},
			{val: 'is-full h--half:100:50', label: '100% x 1/2'},
			{val: 'is-full h--third:100:33', label: '100% x 1/3'},
			{val: 'is-full h--quarter:100:25', label: '100% x 1/4'},
			{val: 'is-full h--fifth:100:20', label: '100% x 1/5'},

			// {val: 'is-one-quarter:25:auto', label: '25% Breite'},
			// {val: 'is-one-third:33:auto', label: '33% Breite'},
			// {val: 'is-half:50:auto', label: '50% Breite'},
			// {val: 'is-two-thirds:66:auto', label: '66% Breite'},
			// {val: 'is-three-quarters:75:auto', label: '75% Breite'},


		];

		let _resizer = '',
			{key} = this.sizeData ? this.sizeData : {w: 100, h: 100, x: 0, y: 0, scale: 1, key: "", cssClass: ""};

		// --------------------------------------------------------------------------
		/**
		 * META Resizer Options
		 * This allows the dynamic resizing of the image
		 * The values are stored in the DOM as [data-size] JSON object
		 */
		_resizer = `
		<div class="meta">
			<div class="resizer ${key} uk-flex">
				<div>
					<select name="sizes" data-on-change="setsize">`;
						sizes.map(s => {
							let _selected = (key === s.val) ? 'selected' : '';
							_resizer += `<option value="${s.val}" ${_selected}>${s.label}</option>`;
						});
						_resizer += `
					</select>
				</div>
				
	
			</div>
		</div>`;
		/* TODO: custom size
		<div class="resizer--inputs">
							<input name="w" type="number" value="${w}" min="1" max="12.65" steps="0.1" data-on-change="setsize.custom"/>
							<span uk-icon="icon: close; ratio: 0.5"></span>
							<input name="h" type="number" value="${h}" min="1" max="18" data-on-change="setsize.custom"/>
						</div>
		 */

		return _resizer;
	}

	/**
	 * Apply a user selected size to the wrapper / placeholder and resize the whole slideshow
	 * @param data
	 * @private
	 */
	_applySizeData(data) {
		// --------------------------------------------------------------------------
		// Apply data && custom sizes, if given
		let {w, h, x, y, scale, key, cssClass} = data;
		// Set globally (this will be returned for the component data)
		this.sizeData = data
		// User custom size (NYI!)
		if (key === 'custom') {
			// NO CUSTOM SIZES YET
			// let _w = w !== 'auto' ? w * this.scale + 'cm' : 'auto',
			// 	_h = h !== 'auto' ? h * this.scale + 'cm' : 'auto';
			// _style = `style="width: ${w * this.scale}cm;height:${h * this.scale}cm;left:${x}px;top:${y}px;transform:scale(${scale})"`;
		} else if (cssClass !== '') {
			// Css Class
			this.$placeholder.attr('class', cssClass + ' resized');
			this.$placeholder.css('overflow', 'hidden');
			// this.$placeholder.find('img.first').attr('uk-cover');
			this.$placeholder.find('img.first')
			.css('position', 'absolute')
			.css('transform', 'translate(-50%,-50%')
			.css('top', '50%')
			.css('left', '50%');  // Move image up, fake cover


		} else if (cssClass === '') {
			// No cssClass, no size. Just rest
			this.$placeholder.attr('class', '');
			this.$placeholder.css('overflow', 'initial');
			// reset the dropdown to auto
			this.$placeholder.find('input[name=sizes]').val(0);
			this.$placeholder.find('img.first').css('position', 'relative')
			.css('transform', 'translate(0,0')
			.css('top', 'auto')
			.css('left', 'auto');  // remove fake cover
		}

		// Update the size selector (rerender!)
		this.$placeholder.find(`select[name=sizes]`).val(data.key)
	}

	/**
	 * Await events (see SuperClass for more)
	 * any DOM element with data-command should trigger this.
	 * @param e
	 */
	commandActions(e) {
		super.commandActions(e);
		let $target = $(e.currentTarget),
			command = $target.data('command'),
			param = $target.data('param') || 0;

		// console.info('SLIDER === Commander wants: ', command, param)

		let _uid = uuidv4();
		this.$CONTROLSLIDER.append(`<li class="${_uid}"></div>`);   // will be overwritten properly

		switch (command) {
			case 'attach-aff-media':
				e.preventDefault();
				let insertAt = (param) ? +$target.attr('data-position') + 1 + +param : 0;
				this.base.emit(eventList.OPEN_MEDIA_LIBRARY, {
					placeholder: `.${_uid}`,
					callback: this.insertElement.bind(this),
					param: {insertAt: insertAt},
					options: {
						types: ['image', 'video'],
						selectionMode: true,
						multiple: true
					}
				});
				// now we wait for the "insertElement" call
				break;
			case 'detach-aff-media':
				this._data.splice(+$target.attr('data-position'), 1);
				this._render();
				break;
		}
	}

	/**
	 * Typical event initialisation
	 * @private
	 */
	_initEvents() {
		super._initEvents();
		// -------------------------------------------
		// Watch custom command action
		this.$placeholder.on('click', '[data-command]', (e) => {
			let $target = $(e.currentTarget),
				command = $target.data('command'),
				param = $target.data('param') || 0;

			e.preventDefault();
			// console.info('Commander wants: ', command, param)

			let _uid = uuidv4();
			this.$CONTROLSLIDER.append(`<li class="${_uid}"></div>`);   // will be overwritten properly

			switch (command) {
				case 'attach-aff-media':
					e.preventDefault();
					let insertAt = (param) ? +param : 0;
					this.base.emit(eventList.OPEN_MEDIA_LIBRARY, {
						placeholder: `.${_uid}`,
						callback: this.insertElement.bind(this),
						param: {insertAt: insertAt},
						options: {
							types: ['image', 'video'],
							selectionMode: true,
							multiple: true
						}
					});
					// now we wait for the "insertElement" call

					break;
				case 'detach-aff-media':
					this._data.splice(+$target.attr('data-position'), 1);
					this._render();
					break;
			}
		});

		// -------------------------------------------
		// Watch the main slider position and set the current text content
		this.$MAINSLIDER.on('beforeitemshow', (e) => {
			let $target = $(e.target),
				position = $target.data('position')
			this.currentIndex = position;
			this._applyCaptionData();
		});

		// -------------------------------------------
		// Watch changes of the teaser text input
		this.$placeholder.find('[data-on-change]').change(e => {
			const $target = $(e.target),
				command = $target.data('onChange');

			// Either set size or change caption
			if (command === 'setsize') {
				let val = $target.val(), data = {}   // TODO
				// this.$placeholder.find('.resizer').attr('class', 'resizer ' + val);    // NOTE: only needed for css show/hide the input fields
				// Get values array from select
				let arr = val.split(':');
				// Add as data to the element
				this._applySizeData({...this._sizeData, ...{w: +arr[1], h: +arr[2], cssClass: arr[0], key: val}});

			} else {
				let title = this.$OM_TEASER.find('textarea').val();
				this._applyCaptionData({title: title.trim()});
			}

		});

		// -------------------------------------------
		// Watch Colorpicker, if it is shown
		// @deprecated: nobody wants to use caption at this stage anymore
		if (this.$placeholder.find('.om-aff-colorpicker').length) {
			const pickr = Pickr.create({
				el: '.colorpicker',
				theme: 'nano', // or 'monolith', or 'nano'
				useAsButton: false,
				position: 'top-middle',
				lockOpacity: true,
				default: '#f90',

				swatches: [
					'rgba(0, 0, 0, 1)',
					'rgba(255, 255, 255, 1)',
					'rgba(255, 0, 0, 1)',
					'rgba(255, 120, 0, 1)',
					'rgba(0, 0, 255, 1)'
				],

				// Translations, these are the default values.
				i18n: {

					// Strings visible in the UI
					'btn:save': 'Speichern',
				},

				components: {

					// Main components
					preview: true,
					opacity: false,
					hue: true,

					// Input / output Options
					interaction: {
						hex: false,
						rgba: false,
						hsla: false,
						hsva: false,
						cmyk: false,
						input: false,
						clear: false,
						save: true
					}
				}
			});
			pickr.on('changestop', (source, instance) => {
				this._setColor(pickr.getColor().toHEXA().toString());
			});
			pickr.on('swatchselect', (color, instance) => {
				this._setColor(pickr.getColor().toHEXA().toString());
			});
		}

		// ---------------------------
		// Dragging of caption
		// @deprecated
		let handle = this.$placeholder.find('.teaser-container').get(0);
		let dragged, position = null;

		document.addEventListener("mouseup", e => {
			if (position)
				this._applyCaptionData({position: {x: position.x, y: position.y}})
			dragged = null
		});
		document.addEventListener("mousedown", e => {
			if (e.target.classList.contains("draggable-handle")) {
				e.preventDefault()
				dragged = this.$placeholder.find('.aff_teaser').get(0)
			}
		});
		document.addEventListener("mousemove", e => {
			if (dragged) {
				let x = e.clientX - handle.getBoundingClientRect().left,
					y = e.clientY - handle.getBoundingClientRect().top + 10;

				// const w = this._convertToPerc(dragged.getBoundingClientRect().width, dragged.getBoundingClientRect().height).px;
				// const h = this._convertToPerc(dragged.getBoundingClientRect().width, dragged.getBoundingClientRect().height).py;
				// // Clamp in
				// if (x < 0 || y < 0 || x + w >= 100 || y + h >= 100) {
				// 	return;
				// }
				// $(dragged).css('left', x).css('top', y)
				let {px, py} = this._convertToPerc(x, y);
				$(dragged).css('left', px + '%').css('top', py + '%')
				this._applyCaptionData({position: {x: px, y: py}})
			}
		});


		// Todo: Watch Sort of items
		// https://stackoverflow.com/questions/47549648/uikit-how-to-get-order-with-the-sortable-component
		// this.$CONTROLSLIDER.on('moved',(e)=>{
		// 	// let $target = $(e.currentTarget),
		// 	// 	position = $target.attr('data-position');
		// 	// console.log('moved ', $target, position)
		// 	var currentLi = e.originalEvent.explicitOriginalTarget.parentNode;
		// 	var indexes = [];
		// 	$(this).find('li').each(function() {
		// 		indexes.push($(this).data("index"));
		// 	});
		// 	console.log("New position: " + $(currentLi).index());
		// 	console.log(indexes);
		// })


	}

	_removeEvents() {
		this.$MAINSLIDER.off('beforeitemshow', () => {});
		this.$placeholder.off('click', '[data-command]', () => {});
		console.log("EVENTS++++REMOVED:::::::")
		document.removeEventListener("mouseup", e => {});
		document.removeEventListener("mousedown", e => {});
		document.removeEventListener("mousemove", e => {})
	}


	/**
	 * Apply current metadata to curent slide (caption)
	 * @param data {title: '', color: '', position: {x,y} }
	 * @private
	 */
	_applyCaptionData(data) {
		if (!this.options.hasCaption) return;
		if (!this._data) return;

		const meta = {...this._data[this.currentIndex].meta, ...data};
		this._data[this.currentIndex].meta = meta
		// let meta = this._data[this.currentIndex].meta || this.defaultMeta;

		if (meta.position)
			this.$OM_TEASER.css('left', meta.position.x + '%').css('top', meta.position.y + '%');
			// if (meta.title)	// Auto-Title? No!
		// 	this.$OM_TEASER.find('textarea').val(meta.title);
		else
			this.$OM_TEASER.find('textarea').val('');

		if (meta.color)
			this.$OM_TEASER.find('textarea').css('color', meta.color);
	}

	_setColor(color) {
		this._applyCaptionData({color: color})
	}

	/**
	 * Render the main component (main window slider) where needed
	 * @private
	 */
	_render() {
		// :::: Expensive to rerender the dom :::

		// IMPORTANT: If just 1 element, make the slider relative, adapt image size and allow custom size
		// And this will only be needed if the resize-Option is true, else: keep a cropped aspect ratio
		if (this._data.length === 1) {
			this.$placeholder.find('.om-slider--wrapper').addClass('single');
		} else {
			this.$placeholder.find('.om-slider--wrapper').removeClass('single');
			// Reset sizes!
			this._applySizeData({cssClass: ''});
		}

		// Control Slider
		this.$CONTROLSLIDER.empty();
		this.$MAINSLIDER.empty();

		// Loop images, get data and render previews and main images / videos.
		this._data.forEach(async (el, i) => {
			if (el) {
				this.libElement.setData(el);
				// Render preview
				// Use the libElement to render our preview:
				let preview = await this.libElement.renderPreview(el);
				// Image record is either from DOM (el misses fields) or from database (edit, le = has all fields)
				let imageRecord = el?.media?.sizes ? el : this.libElement.getData();    // Full record with sizes and URIs
				// Render image / preview placeholder (thumb):
				this.$CONTROLSLIDER.append(this._renderPreviewImage(preview.src, i));
				// Find the placeholder of the small preview in DOM:
				let placeholder = `.placeholder_${i}`;
				$(placeholder).find('img').attr('src', preview.src);    //

				// Main image : Todo: need to send video type --> and render the preview.object
				// We send this one the whole record:
				this.$MAINSLIDER.append(this._renderMainImage(imageRecord, i));

			}
		});

		// If no data, add the ADD ...:)
		if (!this._data.length) {
			this.$CONTROLSLIDER.append(`<li class="add">
                <a data-command="attach-aff-media" href="#" uk-icon="icon: plus-circle; ratio: 2" data-tippy-content="Medien danach einfügen" style="color:black"></a>
            </li>`);
		}

		// Apply the size (of the first(!) image
		if (this._data.length === 1) {
			setTimeout(()=>{
				// Either apply a given size or default to 'auto'
				this._applySizeData(this._data[0].size || {cssClass: '', key: ''});
			}, 500);
		}

	}

	/**
	 * Render the preview item
	 * @param element
	 * @param index
	 * @return {string}
	 * @private
	 */
	_renderPreviewImage(_uri, index) {
		// Use preview from LibElement, note: This will happen in a promise-callback in the main loop!!!!!
		const placeholder = `placeholder_${index}`
		let tpl = `
		 <li data-position="${index}" data-name="le_0_s_${index}" uk-slideshow-item="${index}">
		    <a href="#">
			    <div class="inner uk-position-cover" style="background-image:url(${_uri});background-size:cover;background-position:center;">
			        <div class="${placeholder}"></div> 
	             </div>
	             <div class="uk-position-center uk-panel">
	                    <a data-command="attach-aff-media" data-param="${index}" data-position="${index}" class="lctrl" href="javascript:void(0)" uk-icon="icon: plus-circle; ratio: 1.5" data-tippy-content="Medien davor einfügen"></a>
	                    <a data-command="detach-aff-media"  data-position="${index}" href="javascript:void(0)" uk-icon="icon: ban; ratio: 1.5" data-tippy-content="Entfernen"></a>
	                    <a data-command="attach-aff-media" data-param="${index + 1}"  data-position="${index}" class="rctrl" href="javascript:void(0)" uk-icon="icon: plus-circle; ratio: 1.5" data-tippy-content="Medien danach einfügen"></a>
	                </div>
             </a>
         </li>`

		return tpl;
	}

	/**
	 * Render the main view
	 * @param element
	 * @param index
	 * @return {string}
	 * @private
	 */
	_renderMainImage(element, index) {
		let obj;

		let _uri = '';
		if (element && element.media?.sizes && element.media.sizes?.large) {
			_uri = element.media.sizes.large.url;
		} else if (element.url) {
			// Fing HACK:
			const _prefix = element.urlPrefix || window.location.origin + '/web/media/';
			_uri = _prefix + element.url;
		} else
			_uri = omPlaceholders.GENERIC;

		// Switch by type (image || video)
		if (element.libType === 1 || element.libType === 2048) {
			// let cover = this._data.length !== 1 || !this.options.isResizeable ? 'uk-cover' : '',
			let cover = 'uk-cover', // no more stretching!
				isFirstImage = this._data.length === 1 ? 'first' : '';
			obj = `<img class="${isFirstImage}" src="${_uri}" alt="${element.title}" ${cover}/>`;   //uk-cover
		} else {
			// Switch locationType && mimeType
			// OPTIMISE: we should use libraryElement to render this.
			obj = this.base.getVideoObject(element.url, element.locationType, element.mimeType);
		}

		// @deprecated: First image is the only placeholder for LibElement (!)
		// let initial = index === 0 ? `data-name="initialimage"` : ``; // ${initial}

		// Render tpl for single element
		let tpl = `
		 <li data-position="${index}" >
		     <div>
                ${obj}
                <div class="uk-position-center uk-position-medium uk-text-center">
				</div>
            </div>  
         </li>`

		return tpl;
	}

	/**
	 * Convert x/y from pixels to % within the wrapper
	 * @param x
	 * @param y
	 * @return {{px: number, py: number}}
	 * @private
	 */
	_convertToPerc(x, y) {
		return {px: (x / this.$OM_TEASER_CONTAINER.width() * 100).toFixed(2), py: (y / this.$OM_TEASER_CONTAINER.innerHeight() * 100).toFixed(2)};
	}

	_renderComponent() {
		// this.options.hasCaption = true
		let caption = (this.options.hasCaption)
			? `<!-- Teaser Text -->
			        <div class="aff_teaser draggable">
			            <textarea class="aff_teasertext h1" class="" rows="3" name="aff_teasertext" data-on-change="setMeta"></textarea>
			            <div class="aff_settings" class="draggable draggable-handle">
		                    <span class="draggable draggable-handle" style="cursor: move;">
		                        <span class="uk-margin-small-right" style="pointer-events: none;" data-tippy-content="Positionieren" uk-icon="grid"></span>
		                    </span>
			                <span class="om-aff-colorpicker uk-margin-small-right" style="display:inline-block;">
			                    <span uk-icon="paint-bucket" class="colorpicker"></span>
			                </span>
			            </div>
			        </div>`
			: ``;

		this.$placeholder.empty().html(`
		<!-- Controlling JS : slideshow.js -->
			<div class="om-slider--wrapper uk-slider-container ${this.options.inlineMode ? 'inline' : ''} ${this.options.isFull ? 'is-full' : ''}">
			
			    <div class="teaser-container">
			
			        <div class="uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slideshow="draggable: false;">
			
			            <ul class="om-slider-main uk-slideshow-items" style="pointer-events: none;">
			                <li uk-cover></li>
			            </ul>
			
			            <a class="uk-position-center-left uk-position-small uk-hidden-hover" href="javascript:void(0)" uk-slidenav-previous uk-slideshow-item="previous"></a>
			            <a class="uk-position-center-right uk-position-small uk-hidden-hover" href="javascript:void(0)" uk-slidenav-next uk-slideshow-item="next"></a>
			
			            <!-- Bottom control strip -->
			            <div class="om-slider-control-wrapper uk-position-relative uk-visible-toggle uk-light" tabindex="-1" uk-slider="draggable:false">
			                <ul class="om-slider-control uk-slider-items uk-child-width-1-2 uk-child-width-1-3@s uk-child-width-1-6@m">
			                    <li class="add">
			                        <a data-command="attach-aff-media" class="rctrl" href="javascript:void(0)" uk-icon="icon: plus-circle; ratio: 2" data-tippy-content="Medien einfügen"></a>
			                    </li>
			                </ul>
			                <a class="uk-position-center-left uk-position-small" href="javascript:void(0)" uk-slidenav-previous uk-slider-item="previous"></a>
			                <a class="uk-position-center-right uk-position-small" href="javascript:void(0)" uk-slidenav-next uk-slider-item="next"></a>
			            </div>			
			        </div>

			        ${caption}
			    </div>
			
			
				${this._renderSizeSelector()}
			
			
			</div>
			
		`);
	}

}
