/**
 * OMNI Media
 *
 */

import UIkit                                   from "uikit";
import MediaMetaModal                          from "./components/media.meta.modal";
import Loader                                  from "./components/loader";
import EventEmitter                            from "eventemitter3";
import LibraryElement                          from "./components/library.element";
import LibraryElementModel                     from "./models/libelement";
import { eventList, debounce, omPlaceholders } from "./utils/base";
import LibraryModel                            from "./models/library";

export default class Media extends EventEmitter {

	constructor(base, wrapper = null, opts = {
		init: true,
		selectionMode: false,          							// If true, then items are selectable by default
		multiple: false,										// enables multi-select
		types: ['image', 'video', 'document', 'audio', 'character', 'doc', 'worksheet'],      //  Allow only a certain type to be selected
		readOnly: false,                                        // true = no uploads
		forceRefresh: false,                                    // Force the refresh to be from server (most cases)
		searchParams: [],                                       // See API
		pageSize: 20,                                           // Items to show per page
		page: 0,                                                // sure...
		// Controls from include (task)
		filters: {
			tasks: null
		}
	}) {
		super();
		this.base = base;

		// DOM
		// Check for existence of the DOM
		if (!$('.om-media-library').length) {
			console.warn('No media editor available in DOM');
			return;
		}


		// If there is a modal wrapper, then this component will render inside it (!)
		this.WRAPPER = (wrapper) ? wrapper : '';
		// Meta-Edit Modal. Only once
		// this.$OM_META_MODAL = $(`#om-media-meta-modal`);
		this.OM_Media_Meta_Modal = new MediaMetaModal(this.base);

		// Inner dom elements
		this.$OM_GRID = $(`${this.WRAPPER} .om-media-grid`);

		// Search Comp.
		this.$OM_SEARCH_TASK = $(`${this.WRAPPER} .om-search-task`);
		this.$OM_SEARCH_WORLD = $(`${this.WRAPPER} .om-search-world`);
		this.$OM_SEARCH_EPOCH = $(`${this.WRAPPER} .om-search-epoch`);
		this.$OM_MEDIA_FILTER = $(`${this.WRAPPER} .f-om-media-filter`);
		this.$OM_SEARCH_TYPES = $(`${this.WRAPPER} .om__search_types`);
		this.$OM_SEARCH_TAGS = $(`${this.WRAPPER} .om-search-tags`);

		// Infinite scroll
		this.$OM_MEDIA_PAGINATION = $(`${this.WRAPPER} .om-media-pagination`);

		// Components
		this.loader = new Loader(`${this.WRAPPER} .initial__loader`, {show: true});

		// Note: This will wrap the contents of this into a modal via PHP
		if (this.WRAPPER) {
			this.$OM_MEDIA_MODAL = $('#om-media-modal');
			this.OM_Media_Modal = UIkit.modal(`#om-media-modal`, {stack: true});
		} else {
			$('#om-media-modal').remove()
		}



		// Runtime
		this.mediaArr = [];
		this.total = 0;
		this.collLibElements = [];
		this.eventsBound = false;
		this.selectionEnabled = false;  // If enabled, the direct click will select the element. Disable selection of media in grid on upload...
		this.selectedItems = [];        // media selections
		this.searchDirty = false;       // If true, the search has changed
		this.callback = null            // Any callback FN carried along when media is selected (see index.js)
		this.param = null               // params for callback

		this.isBusy = false;

		this.lastScrollTop = 0;
		// Auto-Populate if standalone component (onLoad)
		// Other option: see method openAsModal()
		// if (location.pathname.includes('medien'))


		this._initUI();
		this._initEvents();
		// Apply options and populate, ONLY if init is set True.
		// Otherwise the Library is standing by to wait for applyOption()
		if (opts.init)
			this.applyOptions(opts);


	}


	//
	// ------------------------------------------------------------------------------------------
	// Public
	// ------------------------------------------------------------------------------------------
	//

	/**
	 * Apply current options.
	 * This prefilters the displayed data
	 * @param opts JSON object with options (see below)
	 * @private
	 */
	applyOptions(opts = this.options) {
		if (!$('.om-media-library').length) {
			console.warn('No media editor available in DOM');
			return;
		}

		/**
		 * Options:
		 * These can be overwritteon on method : showAsModal()
		 * -> inline = shows in modal. Image click will select it and allow it to be inserted. Returns image ID
		 * -> multiple = (only in INLINE mode) = allows multi-selection, returns []
		 *
		 */
		this.options = {
			...{
				placeholder: '',                                        // REQUIRED: Where to place selected stuff into.
				selectionMode: false,                                   // If true, then this component is a modal. All items are not editable but selectable only
				multiple: false,
				types: ['image', 'video', 'document', 'audio', 'character', 'doc', 'worksheets'],      //  Allow only a certain type to be selected
				readOnly: false,                                        // true = no uploads
				forceRefresh: false,                                    // Force the refresh to be from server (most cases)
				searchParams: [],                                       // See API
				pageSize: 20,                                           // ...
				page: 0,                                                // ...
				// Controls from include (task)
				filters: {
					tasks: null
				}
			}, ...opts
		};

		if (!this.options.placeholder) console.warn('No placeholder string given!')

		// Reset selected items
		this.selectedItems = [];

		// Set selectmode globally:
		this.selectionEnabled = this.options.selectionMode;

		// Older code: use ctrl to select multiple
		// if (this.options.selectionMode && this.options.multiple) {
		// 	$('#multiselect-message').css('display','inline-block');
		// }

		// a. Types / Typen der angezeigen /selektierbaren Elemente (4)
		let $typeChecks = this.$OM_SEARCH_TYPES.find('input');
		// Allow all initially (shouldn't be necessary, yet it is)
		$typeChecks.each((i, tc) => {
			// Uncheck all, but "All"
			if (i === 0) $(tc).prop('checked', true); else $(tc).prop('checked', false);
			// Enable all checkboxes
			$(tc).attr('disabled', false);
		});

		// Enable only allowed ==> Enabled only allowed filters
		if (this.options.types && this.options.types.length < 3) {
			// Don't trigger selection of ALL if there's no limit
			$typeChecks.each((i, tc) => {
				// check those which are not included in current options type array
				$(tc).prop('checked', (this.options.types.includes($(tc).data('is'))));
				// disable those which are not included in current options type array
				$(tc).attr('disabled', (!this.options.types.includes($(tc).data('is'))));
			});
		}

		// b. Task
		// (Note: Currently the selectbox is still active and can change. This won't affect the this.options.filters.tasks ! )
		// TODO: Strategy to use with task but see ALL media @Henry: get initial value of ~~~ null
		if (this.options.tasks && this.options.tasks.length) {
			this.$OM_SEARCH_TASK[0].selectize.setValue(opts.tasks[0]); // Only one for now
		} else {
			this.$OM_SEARCH_TASK[0].selectize.setValue(''); // Only one for now
		}

		// c. Tags
		this.$OM_SEARCH_TAGS[0].selectize.setValue(0);

		// ReadOnly?
		if (this.options.tasks) {
			// Disable the selectboxes (task, world, epoch) if task(s are) given
			this.$OM_SEARCH_TASK[0].selectize.disable();
			this.$OM_SEARCH_WORLD[0].selectize.disable();
			this.$OM_SEARCH_EPOCH[0].selectize.disable();
		} else {
			this.$OM_SEARCH_TASK[0].selectize.enable();
			this.$OM_SEARCH_WORLD[0].selectize.enable();
			this.$OM_SEARCH_EPOCH[0].selectize.enable();
		}

		this._doFilter();   // will call populate()
	}

	/**
	 * Open this in its own modal
	 * @param options : JSON Configuration object (see this.options) to control behaviour of this component
	 * TODO: Better to remove this logic and use the caller to add a modal?
	 *
	 */
	showAsModal(opts = {}, callback = null, param = null) {
		if (!this.OM_Media_Modal) return
		this.callback = callback
		this.param = param
		opts.selectionMode = true;      // Automatically a select-only component, no matter what
		this.applyOptions(opts);        // Apply current options (filters, etc.) to initialise modal
		this._initEvents();
		this.OM_Media_Modal.show();
		this.isHidden = false;
	}

	/**
	 * Close this modal
	 */
	close() {
		this.OM_Media_Modal.hide();
		this.isHidden = true;			// why not use UIKit modal state?
	}

	/**
	 * Populate the grid with library elements
	 * TODO: Caching strategies
	 * @param forceRefresh BOOL if true, do not cache
	 * @param searchParams JSON object of search params to use with GET urlParam
	 * @param append BOOL if true, append the results to the current dom (infinite loading)
	 * @returns {[]|*[]}
	 */
	populate(forceRefresh = false, append = false) {
		let loadedRecords = [];
		this._setSearchDirty(false);
		this.loader.show();
		this.isBusy = true

		// Mixin current pagination
		let searchParams = `?pageSize=${this.options.pageSize}&page=${this.options.page}&` + this.options.searchParams;
		/**
		 * Retrieve the collection form the libraryModel
		 * @type {LibraryModel}
		 */
		LibraryModel.getCollection(searchParams).then(r => {
			// Append or refresh the grid with items
			// console.log('Appending? ', append, 'searching for ', searchParams, this.mediaArr, r.mediaLib.libObjs, r.mediaLib.total)
			if (r.mediaLib) {
				this.mediaArr = (append) ? [...this.mediaArr, ...r.mediaLib.libObjs] : r.mediaLib.libObjs;  // either append
				this.total = +[r.mediaLib.total];              // total available items
				loadedRecords = r.mediaLib.libObjs;
			} else this.total = 0;

			// No results
			if (this.total === 0) {
				// Notify user
				this.$OM_GRID.empty().append('<div class="message uk-width-1-1 uk-text-center" style="padding:10vh 0;">Es wurden keine Elemente mit diesen Suchkriterien gefunden.</div>');
				return;
			}

			// Add to dom
			// a. if refresh, clear the grid, empty the cache (unused!)
			if (!append) {
				// Reset all LibraryElements in collection; NOTE: does this properly garbage collect?
				this.collLibElements.map(el => {el = null});
				this.collLibElements = [];
				this.$OM_GRID.empty();
			}

			// b. build each LibElement thumb
			// r.mediaLib.libObjs.map(media => { Only use the latest to append.
			loadedRecords.forEach(media => {
				this._addElement2Dom(media);
			});

		}).finally(_ => {
			this.loader.hide();
			// Update Pagination
			this._updatePaginationUI();
			this.isBusy = false
			// Test:
			// tippy('[data-tippy-content]');
		});
		// }
	}

	// ------------------------------------------------------------------------------------------
	// CRUD
	// ------------------------------------------------------------------------------------------

	create(type) {
		// Show the empty modal
		this.showMetaModal(null, type);
		// Hide the caller
		UIkit.drop('#om-le-create-selector').hide(0);
	}

	/**
	 * Edit LibraryElement meta data, open the modal
	 * @param id
	 * @param type
	 */
	showMetaModal(id, type) {
		// this.base.emit(eventList.OPEN_MEDIA_LIBRARY_META, {id, type})
		this.OM_Media_Meta_Modal.show(id, type);
	}

	// ------------------------------------------------------------------------------------------
	// Private
	// ------------------------------------------------------------------------------------------

	/**
	 * Initialise UI
	 * @private
	 */
	_initUI() {
		// ...
	}

	/**
	 * Initialise all zones
	 * @private
	 */
	_initEvents() {

		if (this.eventsBound) return;   // No double initialisation

		// --------------------------
		// (1) Handle default click actions
		// Note: the LibElement() could emit this action, but we want to save memory and keep performance
		//
		$('[data-action="media"]').on('click', (e) => {
			const $target = $(e.target);
			const command = $target.data('command');
			const param = $target.data('param') || null;

			switch (command) {
				case 'addItem' :
					/**
					 * Add a new item
					 */
					e.stopImmediatePropagation();                       // No doubles!
					// Todo: hide the popover
					this.create(param.type);
					break;
				case 'selectMedia' :
					/**
					 * Select Media from media library (modal)
					 * Usually this would be done on LibElements itself
					 */
					e.preventDefault();
					e.stopImmediatePropagation();
					// Emit a global event that media has been selected (see index.js ~L:289ff)
					this.base.emit(eventList.MEDIA_SELECTION, {placeholder: this.options.placeholder, mediaArr: this.selectedItems, callback: this.callback, param: this.param});
					this.close();
					break;
				case 'searchfilter' :
					// Searchfilter has been updated. Update the pagination and trigger filter via API
					e.preventDefault();
					e.stopImmediatePropagation();
					this._updatePagination(true);
					this._doFilter();
					break;
				case 'resetSFTypes' :
					// Reset type filter checkboxes
					if ($target.hasClass('first'))
						this.$OM_MEDIA_FILTER.find(`.om__search_types input`).not($target).prop('checked', false);  // Uncheck all others
					else
						this.$OM_MEDIA_FILTER.find(`.om__search_types input.first`).prop('checked', false);         // Uncheck "ALL"

					let anyTypeSelected = false;
					$.each(this.$OM_MEDIA_FILTER.find('.om__search_types input:checked'), function () {
						anyTypeSelected = ($(this).is(':checked'));
					});
					// Check "ALL" if all others were deselected
					if (!this.options.types && !anyTypeSelected) this.$OM_MEDIA_FILTER.find('.om__search_types input.first').prop('checked', true);  // If none is selected, then set ALL
					// ...
					this._setSearchDirty(true);
					// this._updatePagination(true);
					// this._doFilter();
					break;
				case 'paginate' :
					this._updatePagination();
					break;
			}
		});

		// --------------------------
		// (1a) Change events (search): Highlight search button [too much trouble]
		$('[data-on-change]').on('change', (e) => {
			let cmd = $(e.target).data('on-change');
			switch (cmd) {
				case 'searchUpdate':
					this._setSearchDirty(true);  // Just reset search
					break;
			}
		});
		// Focus on search input (regardless of content)
		$('[data-on-focus]').on('focus', () => {
			this._setSearchDirty(true);  // Just reset search
		});
		// Triggered, when html5-clear button has been clicked.
		$('input[type=search]').on('search', (e) => {
			// Update search immediately
			this._updatePagination(true);
			this._doFilter();
		});


		// --------------------------
		// (2) Handle actions in media grid
		// * Open a modal with metadata once a document has been clicked
		// * Trash
		// * Select, deselect
		/*
			NOTE: It would be a good decoupled approach if we added these events to the media-elements.
			However, by attaching events per element we may use up heaps of mem and performance.
			Hopefully this approach is simply MORE PERFORMANT.
			All events are forwarded to the element clicked.
		 */
		// Use a delegated event to make sure we stay on top of dynamically added things
		this.$OM_GRID.on('click', '[data-action="libelement"]', (e) => {
			e.stopImmediatePropagation();                       // No doubles!
			let $item = $(e.currentTarget).closest('[data-type=libelement]'),
				itemData = $item.data(),    // NOTE: NYI!!! We use the ID mainly.
				id = $item.data('id'),
				command = $(e.currentTarget).data('command'),  // Note, this is the clicked element, not necessarily the om-element container
				ctrlKey = (e.metaKey || e.ctrlKey);

			// console.info('The commander wants: ', command || 'something ...', id, itemData, self)
			// Open meta editor only if not in selection mode
			if (this.options.selectionMode && (typeof command === "undefined" || command === 'editmeta')) command = 'select';    // a direct click in the media lib usually opens editor

			// We COULD move this into the libElement and $emit the action
			// which would save code, however, the concern is a flood of eventlisteners

			// Click Command -----------------------------------------
			switch (command) {
				// Selection of library element
				case 'select' :
					// (De)Select media element(s) and return.
					if (!$item.hasClass('selected')) {
						if (this.options.multiple)
							this.selectedItems.push(itemData);
						else
							this.selectedItems = [itemData];
					} else {
						this.selectedItems = this.selectedItems.filter(itemDataIn => itemDataIn.id !== id);
						$item.removeClass('selected');
					}

					// Dis- / Enable Select button
					if (this.selectedItems.length)
						$('[data-command="selectMedia"]').removeClass('disabled');
					else
						$('[data-command="selectMedia"]').addClass('disabled');

					// Highlight currently selected items
					const $allItems = $(`.om-media-library [data-type="libelement"]`)
					$allItems.removeClass('selected');  // unselect all
					const plainIds = this.selectedItems.map(i => i.id);
					// re-select
					[].slice.call($allItems).map(el => {
						if (plainIds.includes($(el).data('id'))) $(el).addClass('selected')
					});
					// self.selectedItems.forEach(s => {
					// 	// $(`[data-id="${s.id}"]`).addClass('selected');
					// })
					break;
				case 'editmeta' :
					// Set data && Open modal
					// Todo: use EMITTER eventlist.OPEN_MEDIA_LIBRARY and let the index.js handle this
					this.showMetaModal(itemData.id, itemData.libType);
					break;
			}
		});


		// --------------------------
		// (4) Media Modal behaviour
		if (this.$OM_MEDIA_MODAL) {
			// Watch modal behaviour
			this.$OM_MEDIA_MODAL.on({
				'show.uk.modal': () => {
					// Visible, nothing yet
				},
				'hide.uk.modal': () => {
					this.loader.hide();
					this.base.emit('MEDIAMODAL_CLOSED');
				}
			});
		}

		// ------------------------------------------------
		// 4a. LibElement when data update, update grid too
		this.base.on(eventList.MEDIAMETA_UPDATED, (payload) => {
			// console.log('[2] media meta', payload);
			if (payload && payload.isNew) {
				// Note that the payload is a media-meta structure. It has no libElement data as such [stupido. Gotta clean this]. But there is an ID which suffices
				this._addElement2Dom(payload, true);
			} else {
				// Data update the specific element, DOM only.
				this._refreshGrid(payload);

			}
		})


		// ------------------------------------------------
		// Load more
		$('[data-command="media-loadmore"]').on('click', () => {
			self._updatePagination();
		});

		// (5) Watch scroll position for infinite loading
		// $(window).on("scroll", this._onScrollAction.bind(this));
		$('.scrollWatcher').on("scroll", this._onScrollAction.bind(this));


		// (5.1.) Watch media-modal scrolling
		// Watch this space for potential errors
		// let prevPos = 1;
		// Todo: ~~~~ causes ISSUES on live.
		// $('#om-media-modal .om-media-container').on("scroll", function () {
		// 	if (thisIsHidden) return;	// Bullsh... Todo: make named functions and destroy event(s) when hidden
		// 	let scrollHeight = $('.om-media-library').height();
		// 	let scrollPosition = $(this).height() + $(this).scrollTop();
		// 	scrollPosition = Math.floor((scrollHeight - scrollPosition) / scrollHeight)
		// 	if (scrollPosition < prevPos) {
		// 		// when scroll to bottom of the page
		// 		self._updatePagination();
		// 		prevPos = scrollPosition
		// 	}
		// })

		// Flag
		this.eventsBound = true;
	}

	/**
	 * Refresh DOM element in grid with new data
	 * @param data JSON subset of needed data: title, subtitle, id, preview
	 * @private
	 */
	_refreshGrid(data) {
		if (!data) return;
		let $el = $(`[data-name="lewpl-${data.id}"]`);
		// Update the title
		$el.find('[data-dynamic="le--title"]').text(data.title);
		// Subtitle is assembled differently by libType (e.g. character = name, image = ? )
		// $el.find('[data-dynamic="le--subtitle"]').text(data.title);
		// Update the thumbnail
		if (data.thumb) {
			// $(`[data-id="${data.id}"]`).find('.inner').css('background-image', `url(${data.thumb})`);
			$el.find('img').prop('src', data.thumb);
		} else {
			// Set a placeholder image. This should happen in the LibraryElement automatically
			// But it does not (see all L with: this.emit(eventList.MEDIAMETA_UPDATED...)
			// There is no preview set, that's why it's not working.
			// Hence: this is a quick HACK.
			// Since it's only possible to remove a reference image, we don't need to bother about anything else
			let _ph = data.libType === 1 ? omPlaceholders.IMAGE :
					data.libType === 2048 ? omPlaceholders.REF :
					data.libType === 16 ? omPlaceholders.DOC : omPlaceholders.GENERIC;
			$el.find('img').prop('src', _ph);
		}
	}

	/**
	 * Remove all events. This is not really needed on a multi-page app
	 * as the script will initialise on every load** [caching must be done locally]
	 * @private
	 */
	_removeEvents() {
		$('.scrollWatcher').off("scroll", this._onScrollAction);
		// ...+++
	}

	/**
	 * Append elements to library grid
	 * @param media
	 * @private
	 */
	_addElement2Dom(data, preprend = false) {
		let placeholder = 'lewpl-' + data.id,
			$placeholder = $(`<div><div class="omle-item--wrapper" data-name="${placeholder}"></div></div>`);
		// Do not show unwanted items
		// if (!this.options.types.includes(type)) return;

		// Append the placeholder and insert the LibraryElement into this
		if (preprend)
			this.$OM_GRID.prepend($placeholder);
		else
			this.$OM_GRID.append($placeholder);

		$.when($($placeholder).length > 0).then(() => {
			// Insert the LibraryElement
			let me = new LibraryElement(this.base,
				`[data-name="${placeholder}"]`,
				data,
				{
					size: 'thumb',
					mode: 'box',    // show image, no bg
					showAddButton: false,
					meta: true,
					caption: true,
					editable: !this.selectionEnabled,
					deleteable: true,
					selectable: this.selectionEnabled
				})

			// Append to current collection && CACHE
			this.collLibElements.push(me);  // the DOM element, not the class
		});

	}

	/**
	 * Update the pagination parameters. Calculate current page to load (append)
	 * @param reset BOOL if true, then set all to 0
	 * @private
	 */
	_updatePagination(reset = false) {
		if (!this.options) return;      // Hotfix for the new init mode
		let currentNum = !reset ? this.options.pageSize * (this.options.page + 1) : 0;
		if (currentNum >= this.total) return;
		this.options.page = !reset ? this.options.page + 1 : 0;
		// Refresh Data
		if (!reset) this.populate(true, true);
	}

	/**
	 * Update the pagination UI
	 * @private
	 */
	_updatePaginationUI() {
		// Set a loading icon if there is more OR a text if that's all we got!
		let totalLoaded = this.collLibElements.length;     // currently loaded items
		let _content = (this.mediaArr.length >= this.total) ? 'Du hast das Ende der Bibliothek erreicht.' : `<button class="uk-button-primary uk-icon-button uk-align-center uk-border-rounded" uk-icon="refresh" data-action="media" data-command="paginate"></button><small>${totalLoaded + this.options.pageSize} von ${this.total}</small>`;
		this.$OM_MEDIA_PAGINATION.html(_content);
	}

	_setSearchDirty(to) {
		this.searchDirty = to;
		// Nice idea, bad excecution:
		// if (this.searchDirty) {
		// 	$('button[data-command="searchfilter"]').html('<span uk-icon="icon: search; ratio: 1"></span> SUCHEN');
		// 	$('button[data-command="searchfilter"]').prop('disabled', false);
		// 	// Reset pagination
		// 	this._updatePagination(true);
		// } else {
		// 	$('button[data-command="searchfilter"]').text('Wähle Optionen');
		// 	$('button[data-command="searchfilter"]').prop('disabled', true);
		// }

	}

	/**
	 * Combine all filters and query server
	 * @private
	 */
	_doFilter() {
		let searchParams = '', concat = '&',            // Note: we always have ?page=&pageSize prepended. So the vars are joined with &,
			fltTypes = [];


		// Media types ----------------------
		$.each(this.$OM_MEDIA_FILTER.find('.om__search_types input:checked'), function () {
			fltTypes.push($(this).val());
		});
		let fltType = (fltTypes.length) ? fltTypes.reduce(function (a, b) {
			return a | b;   // bitwise
		}) : 0;
		if (fltType != 0) {
			searchParams += `${concat}libType=${fltType}`;
		}

		// Task ----------------------
		let fltTask = this.$OM_SEARCH_TASK.val();
		if (fltTask) {
			searchParams += `${concat}tasks=${fltTask}`;
		}

		// World ----------------------
		let fltWorld = this.$OM_SEARCH_WORLD.val();
		if (fltWorld) {
			searchParams += `${concat}world=${fltWorld}`;
		}

		// Epoch ----------------------
		let fltEpoch = this.$OM_SEARCH_EPOCH.val();
		if (fltEpoch) {
			searchParams += `${concat}epoch=${fltEpoch}`;
		}

		// Tags ----------------------
		let fltTags = this.$OM_SEARCH_TAGS.val();
		if (fltTags && fltTags.length) {
			searchParams += `${concat}tags=${fltTags.join(',')}`;
		}

		// ---------------------------
		// Full text
		searchParams += `${concat}search=${$('input[name=om-media-s-js]').val()}`;

		this.options.searchParams = searchParams;
		// this._setSearchDirty(false);
		this.populate(true);
	}

	/**
	 * Auto-load more on scroll
	 * @private
	 */
	_onScrollAction() {
		if (this.isHidden || this.isBusy) return;
		let scrollPosition =  $('.om-media-container').height() - $('.scrollWatcher').scrollTop();
		// still need fixing.
		if (scrollPosition > this.lastScrollTop - 200
			&& $('.om-media-container').height() - $('.scrollWatcher').scrollTop() - $(window).innerHeight() <= 0) {
			debounce(this._updatePagination());
			this.lastScrollTop = scrollPosition;
		}
	}

	destroy() {
		this._removeEvents()
	}
}
