/**
 * Template rendering for Editor (Knowledge) Templates
 * used by editor.js || tasks.js
 * This is partly outsourced from editor.js since we may want to show the Knowledge-Cards
 * (see tasks.js --> render all Knowledges to Task)
 *
 * To outsource all templating fn (componentTemplates ~ editor.js) the editor instance (etc) must be injected
 * Too much work for not much gain...
 */
import LibraryElement from "../components/library.element";
import { eventList }  from "../utils/base";

export default class EditorTemplating {
	constructor(base, editor, omLibElement = []) {
		this.base = base;					// inject base
		this.editor = editor;				// inject for now.
		this.omLibElements = omLibElement; 	// array of all used library elements (media)
	}

	// ---------------------------------------------------------
	// Public
	//

	/**
	 * Return a templating string for RTE editor (Knowledge CARDS and different types of editors (kn, worksheets, glossary...))
	 * This will build the whole form from scratch and set data
	 * These TEMPLATES are a predefined set (Knowledge view only)
	 * @param id
	 * @param data JSON with content field
	 * @param content JSON a subset, normally === data.content.card
	 * @param readOnly BOOL if true, then do not cater for editor tags
	 * @return {string|number}
	 * @private
	 * @deprecated ~ it will be working still for backwards-comp, YET: these are not used anymore.
	 */
	getTemplateString(id, data, content, readOnly = false) {
		// console.log('GOT DATA ===> ', id, data, content)
		/**
		 * Return compiled template HTML
		 * Note:
		 * - css-classes are dynamically changed by LibElements, only 'is-pulled-left' or 'is-pulled-right' are kept
		 * - same applies for components
		 *
		 */
		let tpl = '';
		let editable = readOnly ? '' : 'data-editable';
		let readOnlyCSS = readOnly ? 'readonly' : '';

		// Regarding MEDIA: The editor.js uses this templating and loops through all items again, getting the data-id from each media element
		// and then creates a new LibraryElement that loads an interactive preview. Therefore these templates will NOT return any images/video/audio filled in
		// but a data-id for LibElements to pick up. Good idea to make a tooling fn...

		switch (id) {
			case 'default':
				tpl = `
					${this._renderTeaserTpl(data)}
				`;

				break;
			case 'glossary':
				tpl = `
				<div>
					${this._renderGlossaryTpl(data, false)}		
				</div>
				`;
				break;
			case 'citation':
				tpl = `
				<figure class="citation">
					<blockquote data-fixture ${editable} data-name="title">${data.title || 'Titel'}</blockquote>
					<figcaption>
						 <p data-fixture ${editable} data-name="teaser" > ${data.teaser || 'Autor'}</p>
					</figcaption>
				</figure>
				`;
				break;
			case 'worksheet':
				tpl = this._renderTeaserTplForWorksheets(data, false);
				break;
			case 'template_1':
				tpl = `
					
					${this._renderTeaserTpl(data)}
					<div class="card--inner">
						<div class="uk-margin-bottom">
							<div class="${readOnlyCSS}" ${editable} data-type="component_4" data-name="card_0_0_slideshow_0" data-index="0"></div>
						</div>
						
						<article ${editable} data-name="card_0_0_html_0_0_0" >
							<p>${this.htmlContentFragment(content, 0)}</p>
						</article>
						<hr class="om-pagebreak"/>
					</div>
				`;

				break;
			case 'template_2':
				tpl = `
					
					${this._renderTeaserTpl(data)}
					<div class="card--inner">
						<article ${editable} data-name="card_0_0_html_0_0_0" >
							<p>${this.htmlContentFragment(content, 0)}</p>
						</article>
					
						<div class="uk-margin-top">
							<div ${editable} data-type="component_4" data-name="card_0_0_slideshow_0" data-index="0"></div>
						</div>
						
						<hr class="om-pagebreak"/>
						<article ${editable} data-name="card_0_0_html_1_0_0" >
							<p>${this.htmlContentFragment(content, 1)}</p>
						</article>	
					</div>`;
				break;
			case 'template_3':
				tpl = `
				
					${this._renderTeaserTpl(data)}
					<div class="card--inner">	
						<div ${editable} class="is-one-third is-pulled-left ${readOnlyCSS}" data-initialcss="is-one-third is-pulled-left" data-type="libelement" data-name="card_0_0_le_0_0_0" data-id="${this.libElementIdFragment(content, 0)}" data-size='${this.libElementSizeFragment(content, 0)}'></div>
						<div ${editable} data-name="card_0_0_html_0_0_0" >
							${this.htmlContentFragment(content, 0)}
						</div>
					</div>
				`;
				/*
				<hr class="om-pagebreak"/>
						<article ${editable} data-name="card_0_0_html_1_0_0" >
							${this.htmlContentFragment(content, 1)}
						</article>
				 */

				break;
			case 'template_4':
				tpl = `
						${this._renderTeaserTpl(data)}
						<div class="card--inner">
							<div ${editable} class="is-one-third is-pulled-right ${readOnlyCSS}" data-initialcss="is-one-third is-pulled-right" data-type="libelement" data-name="card_0_0_le_0_0_0" data-id="${this.libElementIdFragment(content, 0)}" data-size='${this.libElementSizeFragment(content, 0)}'></div>
							<article ${editable} data-name="card_0_0_html_0_0_0" >
								<p>${this.htmlContentFragment(content, 0)}</p>
							</article>
						</div>`;
				/*
				<hr class="om-pagebreak"/>
						<article ${editable} data-name="card_0_0_html_1_0_0" >
							${this.htmlContentFragment(content, 1)}
						</article>
				 */
				break;
			case 'template_5':
				tpl = `
					${this._renderTeaserTpl(data)}
					<div class="card--inner">
						<div uk-grid>
							<div class="uk-width-1-3">
								<div ${editable} class="is-full ${readOnlyCSS}" data-type="libelement" data-initialcss="is-full" data-name="card_0_0_le_0_0_0" data-id="${this.libElementIdFragment(content, 0)}" data-size='${this.libElementSizeFragment(content, 0)}'></div>
							</div>
							<div class="uk-width-expand">
								<article ${editable} data-name="card_0_0_html_0_0_0" >
									<p>${this.htmlContentFragment(content, 0)}</p>
								</article>
							</div>	
						</div>
						
						<div uk-grid>
							<div class="uk-width-1-3">
								<div ${editable} class="is-full ${readOnlyCSS}" data-initialcss="is-full" data-type="libelement" data-name="card_0_0_le_1_0_0" data-id="${this.libElementIdFragment(content, 1)}" data-size='${this.libElementSizeFragment(content, 1)}'></div>
							</div>
							<div class="uk-width-expand">
								<div ${editable} data-name="card_0_0_html_1_0_0" >
									<p>${this.htmlContentFragment(content, 1)}</p>
								</div>
							</div>
						</div>
						
						<div uk-grid>
							<div class="uk-width-1-3">
								<div ${editable} class="is-full ${readOnlyCSS}" data-initialcss="is-full" data-type="libelement" data-name="card_0_0_le_2_0_0" data-id="${this.libElementIdFragment(content, 2)}" data-size='${this.libElementSizeFragment(content, 2)}'></div>
							</div>
							<div class="uk-width-expand">
								<div ${editable} data-name="card_0_0_html_2_0_0" >
									<p>${this.htmlContentFragment(content, 2)}</p>
								</div>
							</div>
						</div>
				
				
					</div>`;
				break;
			case 'template_6':
				tpl = `
					${this._renderTeaserTpl(data)}
					<div class="card--inner">
						<div uk-grid>
							<div class="uk-width-3-4">
								<article ${editable} data-name="card_0_0_html_0_0_0" >
									<p>${this.htmlContentFragment(content, 0)}</p>
								</article>
							</div>
							<div class="uk-width-expand">
								<div ${editable} class="is-full ${readOnlyCSS}" data-initialcss="is-full" data-type="libelement" data-name="card_0_0_le_0_0_0" data-id="${this.libElementIdFragment(content, 0)}" data-size='${this.libElementSizeFragment(content, 0)}'></div>
								<div ${editable} class="is-full ${readOnlyCSS}" data-initialcss="is-full" data-type="libelement" data-name="card_0_0_le_1_0_0" data-id="${this.libElementIdFragment(content, 1)}" data-size='${this.libElementSizeFragment(content, 1)}'></div>
								<div ${editable} class="is-full ${readOnlyCSS}" data-initialcss="is-full" data-type="libelement" data-name="card_0_0_le_2_0_0" data-id="${this.libElementIdFragment(content, 2)}" data-size='${this.libElementSizeFragment(content, 2)}'></div>
							</div>
						</div>
					
					</div>`;
				break;
			case 'template_7':
				tpl = `
					${this._renderTeaserTpl(data)}
					<div class="card--inner">
						<div uk-grid>
							<div class="uk-width-1-2">
								<article ${editable} data-name="card_0_0_html_0_0_0" >
									<p>${this.htmlContentFragment(content, 0)}</p>
								</article>
							</div>
							<div class="uk-width-1-2">
								<div ${editable} class="is-full ${readOnlyCSS}" data-initialcss="is-full" data-type="libelement" data-name="card_0_0_le_0_0_0" data-id="${this.libElementIdFragment(content, 0)}" data-size='${this.libElementSizeFragment(content, 0)}'></div>
								<article ${editable} data-name="card_0_0_html_1_0_0" >
									<p>${this.htmlContentFragment(content, 1)}</p>
								</article>
							</div>
						</div>
					</div>`;
				break;
			case 'template_8':
				tpl = `
					${this._renderTeaserTpl(data)}
					<div class="card--inner">
						<div ${editable} class="is-one-third is-pulled-right ${readOnlyCSS}" data-type="libelement" data-name="card_0_0_le_0_0_0" data-id="${this.libElementIdFragment(content, 0)}""></div>
						<article ${editable} data-name="card_0_0_html_0_0_0" >
							<p>${this.htmlContentFragment(content, 0)}</p>
						</article>
						<hr class="om-pagebreak"/>
						<article ${editable} data-name="card_0_0_html_1_0_0" >
							<p>${this.htmlContentFragment(content, 1)}</p>
						</article>
					</div>`;
				break;
			default:
				tpl = '';
				break;
		}

		return tpl;

	}

	/**
	 * Get html content from editor content by key
	 * @param content
	 * @param key
	 * @return {*|string}
	 * @private
	 */
	htmlContentFragment(content, key) {
		// all 0s are static, just to keep the schema
		return (content && typeof content[`html_${key}_0_0`] !== 'undefined') ? content[`html_${key}_0_0`] : ''
	}

	// /**
	//  * Proxy to search for library elements in DOM
	//  * and load read-only content (img, audio, video)
	//  * It will create a LibraryElement and initialise it(!)
	//  * @param base
	//  * @param $wrapper
	//  */
	// fillLibElements(base, $wrapper) {
	// 	$wrapper.find('[data-type="libelement"]').map((i, el) => {
	// 		let data = {id: $(el).data('id') || null, libType: $(el).data('libtype')},        // ID of the libElement-record, if any
	// 			size = $(el).data('size') || {};
	// 		new LibraryElement(base, $(el)[0], data, {sizeData: size, editable: false, size: 'medium'});
	// 	})
	// }

	/**
	 * Return the ID fragment of the editor content
	 * @param content
	 * @param key
	 * @param subType
	 * @param subPosition
	 * @return {*|null}
	 * @private
	 */
	libElementIdFragment(content, key, subType = '0', subPosition = 0) {
		return (content && content[`le_${key}_${subType}_${subPosition}`]) ? content[`le_${key}_${subType}_${subPosition}`].id : null
	}

	/**
	 * Return the JSON string representation of the current size of an element from editor data
	 * @param content
	 * @param key
	 * @param subType
	 * @param subPosition
	 * @return {string}
	 * @private
	 */
	libElementSizeFragment(content, key, subType = '0', subPosition = 0) {
		// console.log('SizeFragment', content[`le_${key}_${subType}_${subPosition}`].size)
		let size = (content && content[`le_${key}_${subType}_${subPosition}`]) ? (content && content[`le_${key}_${subType}_${subPosition}`]).size : null
		if (size === '{}') size = ''
		return (size) ? JSON.stringify(size) : '';

	}


	// ---------------------------------------------------------
	// Private
	//
	/**
	 * Render the main teaser tpl, only used in conjunction with a loaded template (hasTemplate:true)
	 * with title,
	 * @param includesImage
	 * @return {string}
	 * @private
	 */
	_renderTeaserTpl(data, readOnly = false) {
		let _subTitle = (data && typeof data.subTitle !== 'undefined') ? data.subTitle : '',
			_teaser = (data && typeof data.teaser !== 'undefined') ? data.teaser : '<p></p>',
			editable = readOnly ? '' : 'data-editable';
		return `<div class="header">
					<h2 data-fixture ${editable} class="subtitle" data-name="subTitle">${_subTitle || '...'}</h2>
					<h1 data-fixture ${editable} data-name="title">${data.title || 'Titel'}</h1>
					<div ${editable} data-name="teaser" class="teaser-size" data-show-itemtype="[2,32,64,4,8]">
						${_teaser}
					</div>
				</div>`;
	}

	/**
	 * Render the worksheet teaser tpl
	 * @param data
	 * @return {string}
	 * @private
	 */
	_renderTeaserTplForWorksheets(data, readOnly = false) {


		let _subTitle = (data && typeof data.subTitle !== 'undefined') ? data.subTitle : '',
			_teaser = (data && typeof data.teaser !== 'undefined') ? data.teaser : '',
			editable = readOnly ? '' : 'data-editable'

		// @deprecated sind 0.7n
		//	content = data.content && data.content.card; // this is a card type
		// let fileTitle = content && content.le_0_0_0 ? content.le_0_0_0.title : '';
		// let fileDesc = content.le_0_0_0 ? content.le_0_0_0.title : '';
		// <label class="uk-form-label">Arbeitsblatt-Datei zum Download</label>
		// <div ${editable} data-type="libelement" data-options='{"caption":true}' data-allowed='["worksheet", "document"]' data-name="card_0_0_le_0_0_0" data-id="${this.libElementIdFragment(content, 0)}" data-size='${this.libElementSizeFragment(content, 0)}' style="height:10rem;"></div>

		let tpl = `
		<div class="uk-background-muted uk-padding">
			<div class="uk-grid">
				<div class="uk-width-3-5">
	                <label class="uk-form-label" for="glink"><strong>Google Dokument:&nbsp;</strong></label>
	                <br/><small id="glink-results--msg">
	                    Kopiere die URL einer Google-Arbeitsanregung aus dem Browser und füge diese in das linke Feld ein.
	                </small>
	                <div class="uk-inline">
	                    <a class="uk-form-icon uk-form-icon-flip" href="#" uk-icon="icon: link" data-action="glink"></a>
	                    <input class="uk-input uk-form-width-large"
	                           aria-label="Check Url"
	                           data-on-change="wsLink"
	                           id="wsLink"
	                           name="wsLink"
	                           type="url"
	                           placeholder="Google Doc Url"
	                           value="${data?.wsLink || ''}"
	                    />
	                </div>
	            </div>
            </div>
            <div class="uk-width-2-5">
                <small>Ergebnis: <span is="wsLink-results-title"></span></small>
                <ul class="uk-iconnav" id="wsLink-results"></ul>
            </div>
			
		</div>
				
		<div class="uk-width-1-1 uk-margin-top">
			<h2 data-fixture ${editable} class="subtitle" data-name="subTitle">${_subTitle || ''}</h2>
			<h1 data-fixture ${editable} data-name="title">${data.title || 'Titel'}</h1>
			<div ${editable} data-name="teaser" class="teaser" data-show-itemtype="[2,32,64,4,8]">
				<p>${_teaser}</p>
			</div>
		</div>
				
			`;

		return tpl;
	}

	_renderGlossaryTpl(data, readOnly = false) {
		let _teaser = (data && typeof data.teaser !== 'undefined') ? data.teaser : '',
			editable = readOnly ? '' : 'data-editable';
		return `
			<div class="">
				<h1 data-fixture ${editable} data-name="title">${data.title || 'Titel'}</h1>
				<div ${editable} data-name="teaser">
					<p>${_teaser}</p>
				</div>
			</div>
		`;
	}


}
