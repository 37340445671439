import EventEmitter                             from "eventemitter3";
import TaskModel                                from "../models/task";
import { compareDates, eventList, parseParams } from "../utils/base";
import UIkit                                    from "uikit";
import CodeModel                                from "../models/codes";

export default class CodeAccount extends EventEmitter {
	constructor(BaseClass) {
		super();
		this.base = BaseClass;
		this.accountsTable = null;

		this.existingCodes = [];    // store existing codes from codesList
		this.isNew = true;          // A new record?

		this._initUI();
		this._initEvents().finally(() => {
			// Create || Update
			const params = parseParams(location.search);
			this._setIsNew(!params.id);
			this._edit(params?.id);
		})
	}

	_initUI() {
		// Hide status in NAV.
		$('body').addClass(`nostatus`);

		// StartDate, default, unless overwritten by edit
		const now = new Date()
		$('input[name="startDate"]').val(now.toISOString().split('T')[0]);

		// Initialise Datatable aside for connected user accounts
		this.accountsTable = $('#omAccountsTable').DataTable({
			pageLength: 50,
			columns: [
				{
					data: 'userName'
				},
				{data: 'email'},
				{
					data: 'id',
					render: function (data, type, row) {
						return `<a href="/nutzer?id=${row.id}" uk-icon="icon: pencil"></a>`
					},
				}
			],
			language: {
				url: './web/assets/i18n/datatable.de.json'
			}
		});
	}

	async _initEvents() {

		//-------  Main Navigation Events (Save, change state, etc.)
		this.base.NAVIGATION.on(eventList.NAV_SAVE, () => {
			this._save();
		});
		this.base.NAVIGATION.on(eventList.NAV_CANCEL, () => {
			// Just go back to list
			// Todo: with confirm on dirty.
			location.href = '/codes'
		});
		this.base.NAVIGATION.on(eventList.NAV_DELETE, () => {
			try {
				this.delete();  // Todo
			} catch (e) {
				console.warn('No current  model!');
			}
		});

		// --------- Tasks package selector
		let req = await TaskModel.loadCollection();
		if (req.status === 200) {
			let options = []
			req.data.forEach(t => {
				options.push({label: t.title, id: t.id})
			});
			const sel = $('select[name="tasks[]"]').selectize({
				persist: true,
				preload: true,
				placeholder: "Aufgabenpakete",
				closeAfterSelect: true,
				allowEmptyOption: true,
				multiple: true,
				valueField: 'id',
				labelField: 'label',
				searchField: ['label'],
				plugins: ['remove_button'],
				options: options,
				create: false
			});

			// Watch select change and calculate price
			sel.on('change', () => {
				this._calculatePrice();
			});
		}

		// --------- World package selector
		req = await omApi.getOption('world');
		if (req.status === 200) {
			let options = []
			req.data.forEach(t => {
				options.push({label: t.label, id: t.id})
			});
			const selWorlds = $('select[name="world[]"]').selectize({
				persist: true,
				preload: true,
				placeholder: "Welten",
				closeAfterSelect: true,
				allowEmptyOption: true,
				multiple: true,
				valueField: 'id',
				labelField: 'label',
				searchField: ['label'],
				plugins: ['remove_button'],
				options: options,
				create: false
			});

			// Watch select change and calculate price
			selWorlds.on('change', () => {
				this._calculatePrice();
			});
		}

		// --------- Epoch package selector
		req = await omApi.getOption('epoch', 1);
		if (req.status === 200) {
			let options = []
			req.data.forEach(t => {
				options.push({label: t.label, id: t.id})
			});
			const selEpochs = $('select[name="epoch[]"]').selectize({
				persist: true,
				preload: true,
				placeholder: "Epochen",
				closeAfterSelect: true,
				allowEmptyOption: true,
				multiple: true,
				valueField: 'id',
				labelField: 'label',
				searchField: ['label'],
				plugins: ['remove_button'],
				options: options,
				create: false
			});

			// Watch select change and calculate price
			selEpochs.on('change', () => {
				this._calculatePrice();
			});
		}

		// Watch inputs change and calculate price
		$('input[name="startDate"], input[name="endDate"], input[name="factor"], input[name="amount"]').on('change', () => {
			this._calculatePrice();
		});

		// -------- Copy code
		$('[data-action="copycode"]').on('click', this._copyCode);

		// -------- Check code field updates
		$('[data-action="onchange"]').on('change', (e) => {
			const command = $(e.target).data('command'),
				val = $(e.target).val();

			switch (command) {
				// Check the entered code, if available
				case 'validateCode':
					// A user can only update a code, if the record is new.
					if (this.isNew && this._checkIfCodeIsTaken(val)) {
						UIkit.notification({
							title: "Dieser Code ist vergeben",
							type: "warning"
						})
					} else if (!this.isNew) {
						$('input[name=code]').val($('input[name=id').val());   // Else we need to make sure that the code stays the same
					}
					break;
				case 'setEndDate':
					// NOT IMPLEMENTED YET
					console.info('helper set ed')
					break;
			}

		});

		return true;
	}

	/**
	 * RePopulate this form and datatable with new data
	 * @param id
	 * @returns {Promise<void>}
	 * @private
	 */
	async _populate(id) {
		const data = await CodeModel.get(id);

		// Convert factor
		data.factor = 100 - data.factor * 100;

		// Convert from endDate to months dropdown
		// let endDateDiff = compareDates(data.startDate, data.endDate, 'months');
		// $('select[name="endDateMonths"]').get(0).selectize.setValue(endDateDiff);

		// Populate form
		this.base.populate('#omCodeForm', data);

		// Set the code == id
		$('input[name="code"]').val(id);      // code = id.

		// Populate special fields (which our base class is incapable of yet):
		if (data?.tasks)
			$('select[name="tasks[]"]').get(0).selectize.setValue(data.tasks.map(t => t.id));

		if (data?.world !== 0)
			$('select[name="world[]"]').get(0).selectize.setValue(data.world.map(w => w.id));

		if (data?.epoch !== 0)
			$('select[name="epoch[]"]').get(0).selectize.setValue(data.epoch.map(e => e.id));

		// Fill accounts table:
		if (data?.accounts.length) {
			this.accountsTable.clear();
			this.accountsTable.rows.add(data.accounts).draw();
		}
		// Set some stats
		$('[data-dynamic="connected-user-num"]').text(`${data.accounts.length || 0} / ${data.amount}`);

	}

	/**
	 * Edit or create
	 * @param id
	 * @private
	 */
	async _edit(id) {
		if (id) {
			await this._populate(id);
		} else {
			// Get existing codes to be able to generate unique codes
			this.existingCodes = await CodeModel.loadCollection();
			this.existingCodes = this.existingCodes.map(c => c.id);
			// Auto-generate a simple code
			let code = this._generateRandomString();
			$('input[name=code]').val(code);    // a new code.
		}
	}

	/**
	 * Delete ()
	 * @param id
	 * @private
	 */
	_delete(id) {
		console.info('Delete ', id)
		// ...
	}

	/**
	 * Save record
	 * @private
	 */
	async _save() {
		$('.uk-form-controls').removeClass('error');  // hide error boxes
		const {data, errors} = this._validateAndConvert();
		if (!data || errors) {
			UIkit.notification({
				message: "Schau in's Formular, um Eingabefehler zu sehen.",
				status: 'danger',
				pos: 'top-right',
				timeout: 5000
			})
			return
		}
		const res = await CodeModel.set(data, true);
		// Add the id (name) to the location string
		// Update inputs
		// Note: this does not work, because server always responds with {id: ...}
		// if ((!data.id ||data.id === '') && res?.id) {
		if (res.id && this.isNew) {
			// replace the location
			location.replace(`${location.origin}/code/?id=${res.id}`);
			// Set to inputs
			$('input[name=id]').val(res.id);
			$('input[name=code]').val(res.id);
			this._setIsNew(false);
		}


	}

	_validateAndConvert() {
		let errors = false;

		// ---- Get form data
		let data = this.base.getFormData($('#omCodeForm'));

		// ---- Validate
		// Label
		if (!data?.label) {
			errors = true;
			$('input[name=label]').parent().addClass('error');
		}

		// if (compareDates(new Date(), new Date(data.startDate), 'days') < 0) {
		// 	// errors.push('Das Startdatum liegt in der Vergangenheit.');
		// 	errors = true;
		// 	$('input[name=startDate]').parent().addClass('error');
		// }

		if (!(data.endDate)) {
			errors = true;
			$('input[name=endDate]').parent().addClass('error');
		}
		if (compareDates(new Date(data.startDate), new Date(data.endDate), 'months') < 1) {
			// errors.push('Gib ein Enddatum an, dass wenigstens einen Monat nach dem Startdatum liegt.');
			errors = true;
			$('input[name=endDate]').parent().addClass('error');
		}
		if (data.factor === 0) {
			errors = true;
			$('input[name=factor]').parent().addClass('error');
		}
		if (data.amount < 1) {
			errors = true;
			$('input[name=endDate]').parent().addClass('error');
		}


		// ---- Convert

		// Tasks are comma separated String, not array
		data.tasks = data.tasks?.length && data.tasks.join(',') || '';    // server expects list
		// Worlds are bits
		data.world = data.world && data.world.reduce(function (previousValue, currentValue) {
			return +previousValue + +currentValue;
		}, 0) || 0;
		// Epochs are bits
		data.epoch = data.epoch && data.epoch.reduce(function (previousValue, currentValue) {
			return +previousValue + +currentValue;
		}, 0) || 0;
		// Factor is given as % off the price, so we need to convert
		data.factor = (100 - data.factor) / 100      // 0 - 1

		// @deprecated Add months to startDate = endDate | Calculate endDate from month-dropdown
		// data.endDate = this._addMonth(data.startDate, data.endDate);

		// --- Check packages again:
		if (!data.tasks.length && data.epoch === 0 && data.world === 0) {
			errors = true;
			$('[name="tasks[]"]').parent().addClass('error');
			$('[name="world[]"]').parent().addClass('error');
			$('[name="epoch[]"]').parent().addClass('error');

		}

		return {data: data, errors: errors};

	}

	_setIsNew(isNew) {
		this.isNew = isNew;
		if (!this.isNew) {
			// Editing existing: Lock the code input (== id!)
			$('input[name=code]').prop('disabled', true);
			$('[data-interactive]').html(`Code <strong>bearbeiten<strong>`);
		} else {
			// Editing new: unlock the code input
			$('input[name=code]').prop('disabled', false);
			$('[data-interactive]').html('Code <strong>anlegen</strong>');
		}
	}

	/**
	 * Generate an alphanumeric random string
	 * @param length INT number of digits.
	 * @returns {*|string|string}
	 * @private
	 */
	_generateRandomString(length = 8) {
		const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
		let result = '';
		for (let i = 0; i < length; i++) {
			result += chars.charAt(Math.floor(Math.random() * chars.length));
		}
		if (this._checkIfCodeIsTaken(result)) {
			return this._generateRandomString(); // try again if it's a duplicate
		} else {
			this.existingCodes.push(result);    // not necessary as we load from api
			return result;
		}
	}

	/**
	 * Simply check if a code is already existing in the db | list.
	 * @param code
	 * @returns {boolean}
	 * @private
	 */
	_checkIfCodeIsTaken(code) {
		return this.existingCodes.includes(code);
	}


	/**
	 * Simple click + copy
	 * @private
	 */
	_copyCode() {
		UIkit.notification({
			message: `Der Code ${$('input[name="id"]').text()} wurde in die Zwischenablage kopiert.`,
			status: 'success',
			pos: 'top-right',
			timeout: 5000
		});
	}

	/**
	 * Add months (n) to given date and generate endDate
	 * @param dateString
	 * @param monthsToAdd
	 * @returns {string}
	 * @private
	 */
	_addMonth(dateString, monthsToAdd) {
		let dt = new Date(dateString)
		dt.setMonth(dt.getMonth() + +monthsToAdd);
		return dt.toISOString().split('T')[0];
	}


	/**
	 * Calculate the current price
	 * @private
	 */
	_calculatePrice() {
		const len = $('select[name="tasks[]"]').get(0).selectize.getValue().length;
		const lenW = $('select[name="world[]"]').get(0).selectize.getValue().length;
		const lenE = $('select[name="epoch[]"]').get(0).selectize.getValue().length;

		const months = compareDates(new Date($('[name="startDate"]').val()), new Date($('[name="endDate"]').val()), 'months')
		const amount = $('input[name="amount"]').val()
		const factor = $('input[name="factor"]').val()


		let price = (amount * len * 5 * months)             // tasks // assuming 5 bucks per task
		price = price + (amount * lenE * 40 * +months)      //epoch - assuming 40

		// Worlds || 1-3 have 16, 4+5 have 8 tasks, so we need to calculate this
		let worlds = $('select[name="world[]"]').get(0).selectize.getValue();
		worlds.forEach(w => {
			if ([8, 16].includes(+w)) price = price + 40
			else price = price + 80;     // 16 tasks
		});

		// Calc. factor
		const normalprice = price.toFixed(2);
		price = (price * (100 - factor) / 100).toFixed(2);


		$('[data-dynamic="price-info"]').html(`${amount} ${amount > 1 ? 'Zugänge' : 'Zugang'} zu 
		${len} Aufgabe${len !== 1 ? 'n' : ''},<br/>
		${lenW} Welt${lenW !== 1 ? 'en' : ''},<br/>
		${lenE} Epoche${lenE !== 1 ? 'n' : ''},<br/> 
		für ${months} Monat${months > 1 ? 'e' : ''}`);
		$('[data-dynamic="price"]').text(price);
		$('[data-dynamic="normalprice"]').text(normalprice);
	}
}
