import KnowledgeModel                                                from "./models/knowledge";
import EventEmitter                                                  from "eventemitter3";
import UIkit                                                         from "uikit";
import Loader                                                        from "../../src/js/components/loader";
import DataTable                                                     from 'datatables.net'
import { compareDates, formatDate, getDateFormatted, getStatiLabel } from "./utils/base";

/**
 * Knowledge &&& Glossary Search View
 * @class
 * This populates datatables but uses different php templates
 * see: /modules/knowledgeSimple/index.php
 * see: /module/knowledge/search.php
 */

export default class SearchList extends EventEmitter {

	constructor(BaseClass, params = {itemType: null, pageLength: 25}) {
		super();
		// Inject the base class
		this.base = BaseClass;

		// Params: the filter params can be preset
		this.presetParams = params;

		// Datatable
		this.$DOM_KNTABLE = $('#omSearchTable');
		this.knTable = null;

		// Global setting [Todo: use itemTypes[]]
		this.itemType = params && params.itemType || 2;
		this.slug = this.base.getKNElementType(this.itemType).slug;
		this.slugEdit = this.base.getKNElementType(this.itemType).slugEdit;

		this.Loader = new Loader('#omSearchTable_wrapper', {show: true});

		// No task given, show a list of available knowledges / records
		this.setSearchParams();
		this._initUI();
		this._initEvents();
	}


	/**
	 * Combine search params and load collection
	 * @param params
	 */
	setSearchParams(params) {
		let _combined;
		// Any presets to obey?
		_combined = {...params, ...this.presetParams};      // note: presets overwrite!
		// Go
		this.getCollection(_combined);
	}

	/**
	 * Get a collection of knowledge records,
	 * filtered by params
	 * @param params object {taskId: <STRING>}
	 */
	getCollection(params) {
		this.Loader.show();
		// Go
		params.contentType = 0;
		let _params = Object.keys(params).map((key) => {
			return params[key].toString().length ? encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) : ''
		}).join('&');

		// console.log('GETTING BY PARAMS', _params)
		//
		KnowledgeModel.loadCollection(_params).then(knColl => {
			if (!knColl) {
				alert('Serverdaten fehlerhaft!');
				return;
			}
			// Show res
			$('[data-dynamic="omResults"]').text(`${ knColl.length } Ergebnisse.`)
			// Render table
			if (knColl.length) {
				// Clear table and render data
				this.knTable.clear();
				this.knTable.rows.add(knColl).draw();
			}
			// Update result count
			$('#omKnCount').text(`${ knColl.length } Ergebnis${ knColl.length !== 1 ? 'se' : '' }`);
			// Loader go home...
			this.Loader.hide();
		});
	}

	// ------- Private

	/**
	 * Initialise Events
	 * @private
	 */
	_initEvents() {
		// Delete action (and others)
		$('#omSearchTable').on('click', '[data-action=knowledge]', (e) => {
			const command = $(e.currentTarget).data('command'), param = $(e.currentTarget).data('param');
			switch (command) {
				case 'edit':
					location.href = param
					break;
				case 'delete':
					this._delete(param.itemId);
					break;
			}
		});

	}

	_initUI() {
		const _self = this
		// Title
		$('[data-dynamic="page-title"]').text(this.base.getKNElementType(this.itemType).labelPlural);

		// Add button, if any
		$('[data-dynamic="add-link"]').attr('href', this.base.getKNElementType(this.itemType).slugEdit);
		$('[data-dynamic="add-link"]').attr('data-tippy-content', this.base.getKNElementType(this.itemType).label + ' anlegen');

		// Hide things, dependent on itemType
		$(`[data-show-itemtype]`).map((i, t) => {
			let id = $(t).data('show-itemtype');
			if (id.includes(this.itemType))
				$(t).show();
			else
				$(t).hide();
		});

		/*
		 let _img = (el.teaserImg && el.teaserImg.thumb)
		 ? `<img class="uk-preserve-width uk-border-rounded" src="${ el.teaserImg.thumb }" width="60" height="60" alt="${ el.title }">`
		 : `<div class="uk-placeholder uk-text-center uk-padding-small">
		 <img src="/web/assets/images/logo.svg" class="logo--small" style="filter:grayscale(1);height:32px;"/>
		 </div>`;
		 */

		// Init datatable
		try {
			this.knTable = $('#omSearchTable').DataTable({
				owReorder: true,
				pageLength: this.presetParams.pageLength || 25,
				columnDefs: [
					{orderable: true, className: 'reorder', targets: [0, 1, 2]},
					//{orderable: false, targets: '_all'}
				],
				columns: [
					{
						title: 'Titel',
						data: 'title',
						width: 'auto',
						render: function (data, type, row) {
							return `<small>${ row.subTitle }</small><p style="margin-top:0.3em"><strong>${ row.title }</strong></p>`
						},
					},
					{
						title: 'Wiedervorlage',
						data: 'checkRemind',
						width: '8%',
						render: function (data, type, row) {
							return data > 0 && data !== 1900 ? data : ''

							// ~~ Highlight rows
							// let checkReminder = data && data !== '1900-01-01' ? formatDate(new Date(data)) : '';
							// return checkReminder;
							//  Highlight close or passed reminders: [better to use table renderer]
							// const diff = (el.itemType === 2 || el.itemType === 32) ? +compareDates(new Date(), new Date(el.checkRemind)) : null;
							// let diffstr = '-';
							// if (diff) {
							// 	if (diff <= 0)
							// 		_childClass += 'reminder--1';
							// 	if (diff > 0 && diff < 14)
							// 		_childClass += ' reminder--2 ';
							// 	diffstr = diff > 0 ? 'in ' + diff + ' Tage' : ''
							// 	diffstr += diff > 1 ? 'n' : '';
							// }
						}

					},
					{
						title: 'Status',
						data: 'status',
						width: '8%',
						render: function (data, type, row) {
							return getStatiLabel(data)
						},
					},
					{
						title: 'Bearbeite',
						data: 'itemId',
						width: '5%',
						render: function (data, type, row) {
							return `<a href="${ _self.slugEdit }?itemId=${ row.itemId }" uk-icon="icon: pencil" data-action="knowledge" data-command="edit" data-param='{"itemId":"${ row.itemId }"}'></a>&nbsp;&nbsp;
							<a href="javascript:void(0);" data-action="knowledge" data-command="delete" data-param='{"itemId":"${ row.itemId }"}' uk-icon="icon: trash; ratio: 1" data-tippy-content="Löschen" style="width:30px;height:30px;"></a>`;
						},
					}
				],
				language: {
					url: './web/assets/i18n/datatable.de.json'
				}
			});
		} catch (e) {
			// nothing.
		}
	}

	/**
	 * Delete action
	 * @param itemid
	 * @private
	 */
	_delete(itemId) {
		UIkit.modal.confirm('Willst du diesen Eintrag wirklich löschen?').then(() => {
			KnowledgeModel.delete(itemId).then(() => {
				setTimeout(() => {
					// Forward to overview
					window.location = this.slug;
				});
			})
		}, function () {
			console.log('Rejected.');
		});
	}


}



