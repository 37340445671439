/**
 * An organisation is just a virtual record
 * We have users, but if a real-world organisation is requesting access to some products
 * we generate a "code" for them to enter at the buy-page.
 *
 * There is no real database data for this kind of record yet.
 *
 * @type {{}}
 */
import UIkit from "uikit";

// ------ Dummy data

// Todo: @Henry - gruppiere Welten / Epochen NUR, wenn alle Aufgaben === expiryDate


const initial = {}

const CodeModel = {
	data: {...initial},

	/**
	 * Get a account / code record
	 * @param id
	 * @returns {Promise<unknown>|{}}
	 */
	get(id) {
		if (id === this.data?.id) return this.data;
		// Load from API
		return new Promise(async (resolve, reject) => {
			const res = await omApi.getCode(id);
			if (res.status === 200 && res?.data) {
				resolve(res.data);
			} else {
				// Notify
				UIkit.notification({
					message: `Der Code scheint nicht zu existieren.`,
					status: 'warning',
					pos: 'top-right',
					timeout: 5000
				});
				reject();
			}
		});
	},

	/**
	 * Todo: Set and save data
	 * @param data
	 * @param save
	 */
	async set(data, save = false) {
		return new Promise(async (resolve, reject) => {
			this.data = data;
			if (save) {
				const r = await omApi.setCode(data);
				if (r.status === 200 && r.data?.id) {
					resolve(r.data);
				} else {
					UIkit.notification({
						message: `Der Code konnte nicht gespeichert werden.`,
						status: 'warning',
						pos: 'top-right',
						timeout: 5000
					});
					reject();
					return;
				}
			}
			// Notify
			UIkit.notification({
				message: `Der Code wurde gespeichert.`,
				status: 'success',
				pos: 'top-right',
				timeout: 5000
			});
			// Resolve
			resolve(this.data.id);  // give back the ID
		})
	},

	/**
	 * Load all codes / organisations
	 * @returns {*}
	 */
	async loadCollection() {
		// return omApi.getCodes();
		return new Promise(async (resolve, reject) => {
			const res = await omApi.getCodes();
			if (res.status === 200) {
				resolve(res.data);
			} else {
				reject();
			}
		});
	},

	async delete(id) {
		UIkit.modal.confirm(`Soll dieser Code wirklich gelöscht werden?`, {stack: true})
		.then(() => {
			omApi.deleteCode(id)
			.then(() => {
				UIkit.notification({
					message: `Der Code wurde gelöscht.`,
					status: 'success',
					pos: 'top-right',
					timeout: 3000
				});
			}).catch(error => {
				if (error && error.response)
					UIkit.notification({
						message: `${error.response.data.message}`,
						status: 'warning',
						pos: 'top-right',
						timeout: 5000
					});
			});
		})
	}
}

export default CodeModel;

