import EventEmitter               from "eventemitter3";
import { eventList, parseParams } from "../utils/base";
import UserModel                  from "../models/user";
import CodeModel                  from "../models/codes";

export default class UserAccount extends EventEmitter {
	constructor(BaseClass) {
		super();
		this.base = BaseClass;

		this.codesTable = null;

		this.$OM_FORM = $('#omUserForm');

		// Create || Update
		const params = parseParams(location.search);
		this._edit(params?.id);

		this._initUI();
		this._initEvents();

	}

	_initUI() {
		// Initialise Datatable aside for connected user accounts
		this.codesTable = $('#omCodesTable').DataTable({
			pageLength: 50,
			columns: [
				{
					data: 'codeId'
				},
				{data: 'endDate'},
				{
					data: 'id',
					render: function (data, type, row) {
						return `<a href="/code?id=${ row.codeId }" uk-icon="icon: pencil"></a>`
					},
				}
			],
			language: {
				url: './web/assets/i18n/datatable.de.json'
			}
		});
	}

	_initEvents() {

		// Watch form state and apply to navigation
		this.$OM_FORM.dirty({
			onDirty: () => {
				this.base.emit(eventList.NAV_DIRTY, true);
				//Get array of dirty elements: maybe use base to set uk-danger? this.$OM_FORM.dirty("showDirtyFields");
			},
			onClean: () => {
				this.base.emit(eventList.NAV_DIRTY, false);
			}
		});

		//-------  Main Navigation Events (Save, change state, etc.)
		this.base.NAVIGATION.on(eventList.NAV_SAVE, () => {
			this._save();
		});
		this.base.NAVIGATION.on(eventList.NAV_CANCEL, () => {
			// Just go back to list
			if (this.$OM_FORM.dirty("isDirty")) {
				if (confirm("Du hast Daten verändert - willst Du wirklich abbrechen?") == true) {
					location.href = '/nutzerkonten'
				} else {

				}
			} else {
				location.href = '/nutzerkonten'
			}
		});
		this.base.NAVIGATION.on(eventList.NAV_DELETE, () => {
			try {
				this.delete();
			} catch (e) {
				console.warn('No current  model!');
			}
		});

	}


	/**
	 * Edit or create
	 * @param id
	 * @private
	 */
	async _edit(id) {
		this.$OM_FORM.dirty("setAsClean");
		if (id) {
			const data = await UserModel.get(id);
			this.base.populate('#omUserForm', data);
			data.id = id
			// update role select
			$('select[name=role]').get(0).selectize.setValue(data.role.id);
			// update gender select
			$('select[name=gender]').get(0).selectize.setValue(data.gender);
			// Newsletter bool = INT, so we need to do this here. sucks hard.
			$('[name=newsletter]').prop('checked', +data.newsletter === 1 ? true : false)
			// Show all subscriptions aside
			this.codesTable.clear();
			this.codesTable.rows.add(data.subscriptions).draw();

			// Set some stats
			// $('[data-dynamich="connected-user-num"]').text(`${data.accounts.length} / ${data.amount}`);

			this.$OM_FORM.dirty("setAsClean");
		} else {
			// Reset form.
		}
	}

	/**
	 * Todo: Delete ()
	 * @param id
	 * @private
	 */
	_delete(id) {
		// ...
	}

	/**
	 * Save record
	 * @private
	 */
	async _save() {
		const data = this._validateAndConvert();
		// For some stupid reason, the checkbox for newsletter isnt included
		data.newsletter = $('[name=newsletter]').prop('checked')
		data.newsletter = (data.newsletter) ? 1 : 0     // watch this. Php wants int
		const res = await UserModel.set(data, true)
		console.info('SAVED ', res)

	}

	_validateAndConvert() {
		// ---- Get form data
		let data = this.base.getFormData(this.$OM_FORM);

		// Validate. Todo. No time. fck.
		return data;
	}
}
