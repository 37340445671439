import "core-js/stable";
import "regenerator-runtime/runtime";
import EventEmitter     from "eventemitter3";
import ContentTools     from 'ContentTools/build/content-tools.min';
import "./editor_tools_none.coffee";
import "./editor_tools_quote.coffee";
import "./editor_tools_omlink.js";
import "./editor_tools_omlinks_lib.js";
import "./editor_tools_omlinks_kn.js";
import "./editor_tools_omimage.js";
import { eventList }    from "../utils/base";
import tippy            from "tippy.js";
import Slideshow        from "../components/affection/slideshow";
import EditorTemplating from "./templating";
import { Base64 }       from "js-base64";
import UIkit            from "uikit";
import LibraryElement   from "../components/library.element";

// Toolbox
// Font-Family: https://anthonyblackshaw.me/2018-01-22/font-family-selector

/*
 float: left;
 width: 60%;
 padding: 2em;
 font-size: 1.5em;
 */
ContentTools.DEFAULT_TOOLS = [
	[
		'bold',
		'italic',
		'blockquote'
	],
	[
		'align-left',
		'align-center',
		'align-right',
	],
	[
		'omLink',
		'omLinkLib',
		'omLinkKN',
	],
	[
		'heading',
		'subheading',
		'paragraph',
		'unordered-list',
		'ordered-list',
		'unindent',         // Todo: icon classes are wrong.
	],
	[
		'line-break',
		'table'
	],
	// Todo: readup -> https://getcontenttools.com/api/content-tools#history
	// [
	// 	'undo',
	// 	'redo',
	// 	'remove'
	// ]
];


// ---------- Style palettes
ContentTools.StylePalette.add([
	new ContentTools.Style('Infobox', 'infobox', ['p', 'blockquote']),
	new ContentTools.Style('Umrandung', 'infobox--border', ['p', 'blockquote']),
	new ContentTools.Style('Rechts fliessend', 'infobox--right', ['p', 'blockquote']),
	new ContentTools.Style('Highlight', 'infobox--highlight', ['p']),
	new ContentTools.Style('Serifenloser Font', 'is-family-sans-serif', ['p']),

	// Citations
	new ContentTools.Style('Infobox + Große Schrift', 'infobox--text-1', ['blockquote']),


	// Tables
	new ContentTools.Style('Tabelle + Kopfzeile', 'has-head', ['table']),
	new ContentTools.Style('Tabelle + Fußzeile', 'has-foot', ['table']),

	new ContentTools.Style('Tabelle + Zeilentrenner', 'rows', ['table']),
	new ContentTools.Style('Tabelle + Spaltentrenner', 'cols', ['table']),

	new ContentTools.Style('Highlight', 'highlight', ['tr']),

	new ContentTools.Style('Text links', 'text-left', ['td', 'th']),
	new ContentTools.Style('Text mittig', 'text-center', ['td', 'th']),
	new ContentTools.Style('Text rechts', 'text-right', ['td', 'th']),


]);


// ---------- Component definitions. Do not change the keys - they are used in the frontend too
const componentTemplates = [
	{id: 0, label: '<div><span uk-icon="icon: minus"></span>&nbsp;<span uk-icon="icon: minus"></span></div> <span class="txt">Trenner</span>'},
	{id: 1, label: '<div><span uk-icon="icon: table"></span>&nbsp;<span uk-icon="icon: table"></span></div> <span class="txt">Text dynamisch</span>'},
	{id: 2, label: '<span uk-icon="icon: more"></span> Text, zweispaltig'},
	{id: 3, label: '<span uk-icon="icon: more"></span> Text, dreispaltig'},
	{id: 6, label: '<div><span uk-icon="icon: image"></span>&nbsp;<span uk-icon="icon: table"></span></div> <span class="txt">Media & Text</span>'},
	// {id: 8, label: '<div><span uk-icon="icon: image"></span></div> <span class="txt">Bild</span>'},
	// {id: 7, label: '<div><span uk-icon="icon: table"></span>&nbsp;<span uk-icon="icon: image"></span></div> <span class="txt">Media rechts, Text umfließend</span>'},
	{id: 4, label: '<div><span uk-icon="icon: thumbnails"></span></div> <span class="txt">Slideshow / Bild / Video</span>'},
	{id: 5, label: '<div><span uk-icon="icon: image"></span>&nbsp;<span uk-icon="icon: image"></span></div> <span class="txt">2 Bilder, 50/50</span>'},
	{id: 9, label: '<div><span uk-icon="icon: table"></span>&nbsp;<span uk-icon="icon: thumbnails"></span></div> <span class="txt">Text links, Dimensionierung rechts</span>'},
	// {id: 10, label: '<span uk-icon="icon: comment"></span> Zitat'},
	{id: 11, label: '<span uk-icon="icon: file"></span> <span class="txt">iFrame</span>'},
];

// Worksheet components are dynamic components
const workSheetTemplates = [
	{id: 1001, label: 'Textfeld'},
	{id: 1002, label: 'Tabelle mit Formularen'}
];

/**
 * OmniMundi Editor Class.
 * This creates a contentTools instance
 *
 */
export default class Editor extends EventEmitter {

	/**
	 * Editor constructor
	 * @param base              inject our base.js
	 * @param placeholder       the wrapping DIV for the editor
	 * @param template INT      the id of any predefinded template to load
	 * @param data  OBJECT      json representation of the current COMPLETE record. E.g. task | knowledge. NOT just task.content, because the editor will handle other fields e.g. task.teaser as well!
	 * @param options           hasTemplate: BOOL, if true, then it will use the template: {} structure and try to init with any template (this is a half-assed deprecated fn. but needed for worksheets & glossary)
	 *                          hasWorksheet: BOOL, currently deprecated!
	 *                          ignoreEmptyComponents: since this editor is a shitty protoptype and should not used for production (but we do), this is a HACKY BOOL. If true, the editor will not check for empty component data
	 *                                                  if false, then the editor will stop when the method getData() is called but no content is found.
	 *                                                  This is a ***HACK*** && a TODO, because there should never be data loss.
	 * @param type
	 */
	constructor(base, placeholder = null, template, data, options = {hasTemplate: false, hasWorkSheets: false, ignoreEmptyComponents: false}, type = '') {
		super();
		// Base Class inject
		this.base = base;
		// Current template. Just an INT or NULL (Tasks)
		this.template = template;           // This is the main card's template, NULL if its used for tasks

		// LibraryElements
		this.omLibElements = [];                    // The placeholder libraryElements used
		this.attachedLibElements = [];              // Complete list of all attached libraryElements (including parsed html links)
		// Knowledges attached inline
		this.attachedLibElementsInComponents = [];  // All libElIds from components (part of attachedLibElements)
		this.attachedKnowledgeElements = []         // Complete list of all knowledge items linked.

		// A simple class that genereates template code. Has been moved out, because these are mainly deprecated and now in for backwards-compatibility
		this.OM_Templating = new EditorTemplating(this.base, this);

		// Has its own media modal @since 0.9.3
		// this.OM_Media_Modal = this.base.OM_Media;
		this.options = options;

		// Inline tips are dynamic. They will appear on hover over a lib/kn link and show some info
		this.inlineTips = [];

		// Instances of slideshows
		this.slideshows = {};       // Store instances of multiple? slideshows to keep control (destroy etc.); we could use jsDom, but hey....

		// DOM
		this.$componentWrapper = $('[data-region="components"]');
		this.$cardWrapper = $('[data-region="card"]');	// to be @deprecated

		// Initialise
		// console.log('DDD ', data)
		this.initialize(data);
	}

	async initialize(data) {
		await this._initTemplateSelectors();
		await this._initEditor(data);
		this._initEvents();
		this.emit(eventList.EDITOR_READY);
	}

	/**
	 * Return editor content
	 * @return {{data: FormData, initial: boolean, attachedLibItems: []}}
	 */
	getContent() {
		return this._getRTEContent();   // NOTE: This will emit a change event also!
	}

	/**
	 * Set editor contents [currently only TEXT (p) tags!]
	 * @param element - STRING of dom element selector ([name="xxx"]) to place content into
	 * @param data - JSON or Base64 encoded JSON representation of the expected object {card, components}
	 */
	async setContent(element, data) {
		return new Promise(resolve => {
			// --> Add content as new region
			let regions = this.instance.orderedRegions();

			// console.log('REGIONS ', regions)
			let currentRegion = regions.find(r => {
				return $(r._domElement).attr('data-name') === $(element).attr('data-name');
			})
			if (currentRegion) {
				currentRegion.setContent(data);
				this.instance.syncRegions();
			} else {
				console.warn('Region not here ', element)
				// this._addNewRegion(element, data);	// experimental
			}

			resolve(true);
		})
	}

	/**
	 * Returns an array of libraryElement associated within the editor.
	 * This includes the pre-set LibElement (placeholders) and links within the editor itself
	 *
	 * @return {*[]}
	 */
	getAttachedLibElements() {
		return this.attachedLibElements;
	}

	/**
	 * Returns the current array of attached knowledgeElements
	 * @return {[]}
	 */
	getAttachedKnowledgeElements() {
		return this.attachedKnowledgeElements;
	}

	/**
	 * Destroy and reset this instance
	 */
	destroy() {
		// Remove events
		$('[data-action=addcomponent]').off('click', () => {})

		// We need to destroy the editor unfortunately
		// Clear the Library element array
		this._clearLibElements();
		// Destroy all instances of slideshow
		this._clearSlideshow();

		// this.instance.stop();
		// this.instance.removeEventListener('saved', ()=>{});
		try {
			this.instance._children.forEach((child) => child.unmount && child.unmount());
			this.instance._children = [];
			this.instance._bindings = [];
			this.instance.destroy();
			this.instance = null;
		} catch (e) {
			console.warn('Editor destroyed before init.')
		}

	}

	// ================================================================================
	// Private
	// ================================================================================

	/**
	 * Initialise editor instance
	 * @param data JSON full object with a .content property! [todo: really?]
	 * @private
	 */
	async _initEditor(data) {
		let tplCard, tplComponents;

		// ------------------------------------------------------------
		// --- Init and start the editor
		this.instance = ContentTools.EditorApp.get();

		// Tag names for headings we propose
		ContentTools.Tools.Heading.tagName = 'h2';
		ContentTools.Tools.Subheading.tagName = 'h3';


		// ISSUE: https://github.com/GetmeUK/ContentTools/issues/363
		this.instance.init('*[data-editable], [data-fixture]', 'data-name', null, false);

		// // Add custom tool for <hr> insertion
		// class HrTool extends ContentTools.Tool {
		// 	constructor() {
		// 		super();
		// 		this.name = 'hr';
		// 		this.label = 'Horizontal Rule';
		// 		this.icon = 'hr';
		// 		this._applier = new ContentTools.Tool.Applier(['hr']);
		// 	}
		//
		// 	apply(element, selection, callback) {
		// 		const hr = document.createElement('hr');
		// 		element.parentElement.insertBefore(hr, element);
		// 		callback(true);
		// 	}
		// }
		//
		// // Register the custom tool
		// ContentTools.Tools.Hr = HrTool;

		//  using these selectors is unclean and we cannot use UNDO/REDO.
		this.instance._emptyRegionsAllowed = true;
		this.instance.start();
		this.instance.inspector().show();
		this.instance._emptyRegionsAllowed = false;

		// Content
		this.originalData = data
		this.content = (data && data.content) ? data.content : {card: {}, components: []};
		this.data = {content: this.content};

		// --------------------------------------------
		// TEMPLATES / Card
		// --------------------------------------------
		// --- Get card node: this is special to KN only. (could be refactored)
		// @deprecated since 0.35.11:
		// Get card template and set its content (kn only)
		if (this.options.hasTemplate) {
			tplCard = this._getTemplateString(this.template, data); // MAKE sure this has been set (or cleared)
			this.$cardWrapper.html(tplCard);
			this.$cardWrapper.attr('class', this.template); // set main wrapper css class
			this.instance.syncRegions();
		}
		// <------

		// --------------------------------------------
		// COMPONENTS (standard)
		// --------------------------------------------
		// Loop the given data props and check if there's an editable region associated
		// If they that match the [data-name], add them to this data model (to be returned)
		if (data)
			Object.keys(data).map((key) => {
				// "content" is reserved for the editor!!
				// Its data split up into {card:{}, components:[]}
				if (key === 'content') {
					// console.log(this.content.components)
					// Main content : assemble from components and add to DOM
					if ((this.content.components && Array.isArray(this.content.components))) {
						tplComponents = this._getAllComponentTemplates(this.content.components, true);
					}
				} else {
					// Set content for all div's with 'contenteditable' / free fields
					if ($(`[data-name="${ key }"]`).length) {
						if (!this.options.hasTemplate && this.instance.regions()[key]) {
							// Hack. In KN the title, subTitle + teaser fields are within the card region
							// _content = $('<textarea />').html(_content).text();
							// In other templates they may be separate-- so THIS works:
							let _content = this.base.isBase64String(data[key]) ? Base64.decode(data[key]) : data[key];
							// console.log('E C = ', key, _content)
							this.instance.regions()[key].setContent(_content);
						}
					}
				}
			});


		// ------------------------------------------------------------
		// No dirty prevent message from editor
		window.removeEventListener('beforeunload', this.instance._handleBeforeUnload);

		// this._getRTEContent(true);


		// ------------------------------------------------------------
		// Now init the libraryElements

		// Wait for rendering of all media
		// Note: this MUST receive a completely resolved promisechain. Else the editor will not work!
		// Check that the LibraryElement class emits the LE_RENDERED event
		// console.info('1 - init')
		await this._initLibElements();
		// console.warn('2 - DONE')

		// Slideshow. We do not wait for this one YET
		this._initSlideshows();

		// this.instance.start();
		// this.instance.inspector().show();
		// this.instance._emptyRegionsAllowed = false;

		// Another silly hack: A4 preview for the editor ---------------------
		$('.ct-toolbox .mytools').remove();
		$('.ct-toolbox').append(`
			<div class="mytools">
				<hr style="border: none; border-top: 1px solid #666;margin: 5px 0;"/>
				<ul style="margin:0px;padding:0;list-style:none;">
					<li style="cursor:pointer;padding: 5px; display:inline-block;" data-action="switchLayout" data-command="std" >
						<svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" style="pointer-events: none">
						  <g id="Group_32" data-name="Group 32" transform="translate(-647 -310)">
						    <g id="Group_28" data-name="Group 28">
						      <g id="Rectangle_61" data-name="Rectangle 61" transform="translate(647 310)" fill="none" stroke="#707070" stroke-width="1">
						        <rect width="36" height="36" rx="3" stroke="none"/>
						        <rect x="0.5" y="0.5" width="35" height="35" rx="2.5" fill="none"/>
						      </g>
						    </g>
						    <path id="Path_14" data-name="Path 14" d="M4,20H20v2H4ZM4,2H20V4H4Zm9,7h3L12,5,8,9h3v6H8l4,4,4-4H13Z" transform="translate(653 340) rotate(-90)" fill="#707070"/>
						  </g>
						</svg>
					</li>
					<li style="cursor:pointer;padding: 5px; display:inline-block;" data-action="switchLayout" data-command="a4">
						<svg xmlns="http://www.w3.org/2000/svg" width="28" height="36" viewBox="0 0 28 36" style="pointer-events: none">
						  <g id="Group_31" data-name="Group 31" transform="translate(-694 -310)">
						    <g id="Group_29" data-name="Group 29" transform="translate(47)">
						      <g id="Rectangle_61" data-name="Rectangle 61" transform="translate(647 310)" fill="none" stroke="#707070" stroke-width="1">
						        <rect width="28" height="36" rx="3" stroke="none"/>
						        <rect x="0.5" y="0.5" width="27" height="35" rx="2.5" fill="none"/>
						      </g>
						    </g>
						    <text id="A4" transform="translate(700 333)" fill="#707070" font-size="12" font-family="LibreFranklin-Medium, Libre Franklin" font-weight="500"><tspan x="0" y="0">A4</tspan></text>
						  </g>
						</svg>
					</li>
<!--					<li style="cursor:pointer;padding: 5px; display:inline-block;" data-action="switchLayout" data-command="tablet">Tablet</li>-->

				</li>
			</div>
		`);
		$(document).on('click', '[data-action="switchLayout"]', (e) => {
			const cmd = $(e.target).data('command');
			if (cmd === 'a4') $('.layout-wrapper').addClass('a4')
			else $('.layout-wrapper').removeClass('a4')
		})

		if (+$('.ct-toolbox').position().top < 120)
			$('.ct-toolbox').css('top', 120)

		// <---- end of silly hack

		// Next silly hack: Remove empty, orphaned <p>'s ---------------------
		setTimeout(() => {
			// $('[data-editable]').find('.ce-element--empty:not(:last-child):not(:first-child)').css('background', 'red').remove();
			$('[data-editable]').find('.ce-element--empty:not(:last-child)').css('background', 'orange').remove();
			// $('#component-wrapper [data-editable]').find('.ce-element--empty:last-child').css('background', 'orange').remove();
			// If no content has been set, initialise all empty [data-name] fields now
			this.instance.syncRegions();
		}, 200);

		// If no content has been set, initialise all empty [data-name] fields now
		this.instance.syncRegions();

		return true
	}

	/**
	 * (Re)Initialise LibraryElements.
	 * This will search all tagged library-elements [data-type] and
	 * try to fill it with content by creating previews
	 *
	 */
	async _initLibElements() {
		const waitForMedia = async (el) => {
			let data = $(el).data();
			if (data.name === 'teaserImg') return;  // Old things
			let {name, size} = data;    	// use the object data
			// console.log('   a -> place', $(el).data())
			let r = await this._placeLibElement(data, `[data-name="${ name }"]`, name, size, false);
			// console.log('   b -> done', data, r)
			return r
		}

		return Promise.all($(`#om-editor-wrapper [data-type="libelement"]`).map((i, el) => waitForMedia(el)))
	}

	_initSlideshows() {
		// ------------------------------------------------------------
		// Init the slideshows (components)
		$(`[data-type="component_4"]`).map((i, el) => {
			let name = $(el).attr('data-name'),          // DOM name attr of the element
				templateName = name.split('_')[0],       // Need to know if its the card template or a certain component
				index = $(el).data('index'),
				data = {};

			// Inside the card
			if (name.substr(0, 4) === 'card')
				data = this._extractSlideshowImages(this.data.content[templateName], index)      // get slideshow images at a certain position (index)
			else {
				// It's a component - one of possibly many. We must find the assoc type: component_4 (!)
				// The name is not really important (e.g. comp_4_0_slideshow_0 or comp_4_1_slideshow_0) -- only comp_4_{THISINDEX}_ will change, suggesting the order
				// doppelgemoppelt [should happen at extract method]
				let pos = name.split('_')[2];    // The name contains the position of the component
				data = this.data.content.components.find((node, i) => {
					return node.template === 'component_4' && +pos === +i
				});
				//
				// data = this._extractSlideshowImages(data, index);
				// delete data.template
			}
			// Remember to destroy if we change the template!!!!!!
			if (!this.slideshows[name]) {
				// console.log('----1---- setting data', data)
				this.slideshows[name] = new Slideshow(this.base, `[data-name=${ name }]`, data, {hasCaption: false, inlineMode: true, isResizeable: true});
				// this.slideshows[name].on(eventList.SLIDER_UPDATE, ()=>{
				// 	console.log('CHANGE IN SLIDER')
				// })
			}
		});
	}

	/**
	 * Refresh tippy (info popovers) after any change
	 * Tippy loads and displays content from HTML links within the editor.
	 * These links have a [data-id] && [data-libtype] attribute to determine what to load
	 * @private
	 */
	_reInitInlineTips() {
		// TODO: Use delegate instead!
		if (this.inlineTips) this.inlineTips.map(t => {
			if (t) t.destroy();
		});
		this.inlineTips = [];
		this.inlineTips = tippy('[data-linktype=library]', {
			content: (reference) => '<strong>Bibliothek:<br/></strong> ' + reference.getAttribute('data-title'),
		});
		this.inlineTips = tippy('[data-linktype=knowledge]', {
			content: '...',
			onShow(instance) {
				return window.omApi.getKnowledge(null, $(instance.reference).data('id'))
				.then(result => {
					// NOTE: GET returns alaways an ARRAY where key [0] is the actual KN Object
					// NOTE: we cannot use KnowledgeModel to get data, because it will overwrite the main knowledge record!
					if (result.data) {
						const kn = result.data;
						instance.setContent(`<h4 style="color:white;margin:0;"><svg xmlns="http://www.w3.org/2000/svg" viewBox="-5 -2 24 24" width="24" height="24" preserveAspectRatio="xMinYMin" class="icon__icon"><path d="M9 14v-2.298l.856-.597a5 5 0 1 0-5.712 0l.856.597V14h1V6a1 1 0 1 1 2 0v8h1zm0 2H5v2h4v-2zM0 7a7 7 0 1 1 11 5.745V18a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-5.255A6.992 6.992 0 0 1 0 7z" stroke="#ffffff"></path></svg>${ kn.title }</h4>
						<hr style="margin:5px 0;"/>
						<em style="color:white;">${ kn.teaser }</em>
						`);

					}
				}).catch((error) => {
					// Fallback if the network request failed
					instance.setContent(`Request failed. ${ error }`);
				});
			}

		});
	}

	/**
	 * Create and fill template selectors
	 * (NOTE: not yet implemented for card template, COMPONENTS only, only when we reuse this editor)
	 * @private
	 */
	async _initTemplateSelectors() {
		let $cwrapper = $('#componentselector'), html = '';
		componentTemplates.forEach((tpl) => {
			html += `<li class="om-compselector" data-action="addcomponent" data-template="${ tpl.id }">${ tpl.label }</li>`;
		});

		// @deprecated Option: Add worksheets
		if (this.options.hasWorkSheets) {
			html += `<ul class="list--interactive flex">`;
			html += `<li class="divider" style="">Arbeitsblätter</li>`;
			workSheetTemplates.forEach((tpl) => {
				html += `<li data-action="addcomponent" data-template="${ tpl.id }">${ tpl.label }</li>`;
			});
			html += `</ul>`;
		}

		$cwrapper.empty().html(`<ul  class="list--interactive flex">${ html }</ul>`)

		return true
	}

	/**
	 * Attach event listeners
	 * @return {string}
	 * @private
	 */
	_initEvents() {
		// ---------------------------------------------
		// EVENTS: Add new Component from selector
		let me = this, eventsAttached = false;

		// We do not have a working CTRL+Z / CMD+Z undo fn. It will destroy the editor
		// So this lame-as fn. needs to prevent this
		document.addEventListener('keydown', function (event) {
			if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
				event.preventDefault()
				event.stopImmediatePropagation()
			}
		});

		// ---------------------------------------------
		// Listen for editor changes [costly!]
		// Todo: check this again | xxxxx
		// ContentEdit.Root.get().bind('taint', () => {
		// 	this.emit(eventList.EDITOR_UPDATE, {data: this.getContent(), attachedLibItems: this.attachedLibElements});
		// });

		// Tippy for editor content (show info on hover)
		this.inlineTips = [];
		this._reInitInlineTips();
		/**
		 * When a HTML link has been inserted with either a LibraryElement OR a KnowledgeRecord
		 * then we need to get the ID and append it to the list of used ids (libraryItems[] || knowledgeItems[])
		 * --- since the parsing happens whenever the editor saves, this is only used to reInit tippy
		 */
		this.base.on(eventList.EDITOR_SET_LINK_DONE, (ids) => {
			// console.log('LINK SET DONE ', ids)  // IDS are not used, instead we reparse the whole html and collect
			this._reInitInlineTips();
			// setTimeout(() => {this.getContent();}, 800);  // Emits the update event
		});

		// Tippy is used here for the MAIN CONTROL (+) to add new components
		tippy('[data-action=tippy]', {
			content(reference) {
				const id = $(reference).data('template');
				const template = document.getElementById(id);
				return template.innerHTML;
			},
			// delay: [100, 100000],
			allowHTML: true,
			interactive: true,
			ignoreAttributes: true,
			onMount(instance) {
				// Listen for button actions; we need to do this after rendering.
				// unfortunately this will kick in always, adding new EL all the time
				if (!eventsAttached) {
					$('.om-wysiwyg').on('click', '[data-action="addcomponent"]', (e) => {
						me._renderComponent($(e.currentTarget).data('template'), {}, true, $('[data-region="components"] .region--wrapper').length);    //
					});
				}
				eventsAttached = true
			}
		});

		// ---------------------------------------------
		// Component removal action
		$('#om-editor-wrapper').on('click', '[data-action="removecomponent"]', (e) => {
			this._removeRegion(e);
		});

		// ---------------------------------------------
		// Move component down || up action ::: EXPERIMENTAL!
		// There must be a simpler way to achieve this. The editor holds orderedRegions()
		// The issue is that we have atomic regions / fixtures. A .region--wrapper div SHOULD be a region with containing elements.
		// Yet, this seems not to be the concept
		// https://github.com/GetmeUK/ContentTools/issues/231
		//
		const _move = async (e, pos) => {
			let $wrapper = this.$componentWrapper,													// only for components (default)
				$region = $(e.currentTarget).parent().prev().closest('.region--wrapper'),			// the actual block wrapper. It's not a region - it holds one or two
				$articles = $wrapper.find('.region--wrapper'),										// wtf????
				currentIndex = +$region.attr('data-pos'),											// get position / index
				newIndex = Math.min(Math.max(currentIndex + pos, 0), $articles.length - 1);

			this._moveItem($articles, currentIndex, newIndex);
			// $articles = $wrapper.find('.region--wrapper');	// refetch from dom!?
			$articles.each((i, wrapper) => {
				let $els = $region.find('[data-editable]'); // The actual component(s) within the region wrapper
				$(wrapper).attr('data-pos', i);
				if ($(wrapper).attr('data-comp')) {
					const compId = $(wrapper).attr('data-comp').split('_')
					// change data-name of region--wrapper!
					$(wrapper).attr(`data-comp`, `${ compId[0] }_${ i }`)
					// Update child elements (actual regions too!) with new names (index+/-)
					$els.map((j, comp) => {
						// Change the name attribute - the 2nd arg = positionInTemplate
						if (comp) {
							let name = $(comp).attr('data-name'),
								tmp = name.split('_'), newName = '';
							// See readme.md for naming conventions
							// 'comp' (string) immutable || componentId || component position (!) || component_type (string) || comp position || subposition (e.g. slideshow)
							if (tmp[3] === 'slideshow') {
								newName = `${ tmp[0] }_${ tmp[1] }_${ i }_${ tmp[3] }_${ tmp[4] }`;	// Slideshows have only 5
								// Move slideshow instance to new key
								this.slideshows[newName] = this.slideshows[name];
								delete this.slideshows[name];
							} else
								newName = `${ tmp[0] }_${ tmp[1] }_${ i }_${ tmp[3] }_${ tmp[4] }_${ tmp[5] }_${ tmp[6] }`;
							$(comp).attr('data-name', newName);    // add new index to position
							// console.log('[] - ', tmp, newName)
						}
					});
				}
			});

			// $wrapper.html($articles);
			$articles.appendTo($wrapper)
			// Note: This will destroy interactivity (on media lib elements)
			this.instance.syncRegions();
			// ...so we reInit them (memleak?)
			await this._initLibElements();

			// Dirty!
			this.base.forceDirty();
		}

		$('#om-editor-wrapper').on('click', '[data-action="movecomponent-down"]', (e) => {
			_move(e, 1);
		});

		$('#om-editor-wrapper').on('click', '[data-action="movecomponent-up"]', (e) => {
			_move(e, -1);
		});

		// ---------------------------------------------
		// Custom Component events

		// multi-column text switcher
		$('#om-editor-wrapper').on('change', '[data-action="changecolumns"]', (e) => {
			const component = $(e.currentTarget).closest('.region--wrapper')
			component.attr('data-columns', e.target.value)
			component.attr('data-options', JSON.stringify({columns: e.target.value}))
		});


		// Watch controls of worksheet components
		// @deprecated
		if (this.options.hasWorkSheets) {
			$('#om-editor-wrapper').on('change', '[data-on-change]', (e) => {
				let $input = $(e.target),
					optionName = $input.data('on-change'), // get it from the data attribute
					val = $input.val(),
					componentId = $input.data('target'),
					options = $(`[data-name=${ componentId }]`).attr('data-options');

				// console.log('GOT OPTS: ', componentId, options)
				if (!options) options = {};
				options[optionName] = val       // merge option
				// Apply
				this._applyWorkSheetOptions(componentId, options)
			})
		}


	}


	/**
	 * Apply worksheet options to show in UI
	 * @param componentId
	 * @param options
	 * @private
	 * @deprecated
	 */
	_applyWorkSheetOptions(componentId, options) {
		const $component = $(`[data-name=${ componentId }]`),
			$sheet = $component.find('._sheet'),
			id = componentId.split('_')[1]; // the actual ID of the component (e.g. 1001)

		// Reapply to the options attribute (for saving)
		$component.attr('data-options', options);

		switch (id) {
			case '1001':
				// Textarea component, update UI
				$sheet.attr('rows', options.rows || 4);
				break;

			case '1002':
				// Table of inputs
				let tbl = `<table>`, lbl;
				for (let r = 0; r < options.rows; r++) {
					tbl += `<tr>`;
					for (let c = 0; c < options.cols; c++) {
						lbl = (options.labels && options.labels[c]) ? options.labels[c] : '';   // Get label from saved options
						tbl += (r === 0) ? `<td><input class="__labels" name="opt_${ id }_${ c }" style="min-width:none;width:100%;" value="${ lbl }" /></td>` : `<td>&nbsp;</td>`
					}
					tbl += `</tr>`;
				}
				tbl += `</table>`;
				$sheet.html(tbl);
				break;
		}
	}

	// --------------------------------------------------------------------------
	// Main Templates
	// --------------------------------------------------------------------------

	/**
	 * Return a templating string for RTE editor
	 * This will build the whole form from scratch and set data
	 * These TEMPLATES are a predefined set (Knowledge view only)
	 * @deprecated
	 * @param id
	 * @param data JSON with bas64encoded content field
	 * @return {string|number}
	 * @private
	 */
	_getTemplateString(id, data) {
		return this.OM_Templating.getTemplateString(id, data, data.content && data.content.card ? data.content.card : {card: {}, components: []});
	}

	// --------------------------------------------------------------------------
	// Components
	// --------------------------------------------------------------------------

	/**
	 * Initialise and render all components by looping through editor JSON
	 * @param data OBJECT / JSON represenation of the components array
	 * @private
	 */
	_getAllComponentTemplates(content, render) {
		let regionsHTML = '';
		content.map(async (cmp, i) => {
			let tpl = await this._renderComponent(cmp.template && cmp.template.substr(10), cmp, render, i);
			regionsHTML += tpl;
		})

		return regionsHTML;
	}

	/**
	 * Render HTML components (Custom components)
	 * This expects a simple ID (INT) and returns a (filled) template string
	 * Check the switch method for more detail on each template part
	 * @Todo: move to templating.js ?
	 *
	 * @param type INT
	 * @param data OBJECT / JSON single node represenation of the components array
	 * @param render BOOL if true, it will append the element to the dom and initialise a new region, if not. just return the compiled tpl
	 * @private
	 */
	async _renderComponent(type, content, render = true, index) {
		let _tpl, id, attr;
		// return this.OM_Templating.renderComponent(id, content, index);

		// console.log('ADD++++', type, content, render, index)

		// Index = the current position / count of the element in the editor.regions() prop ~ DOM position :: WONT WORK if not rendered
		// let index = this.$componentWrapper.find('.region--wrapper').length; //Object.size(ContentTools.EditorApp.get().regions());
		// console.log('IXD ', index, this.$componentWrapper.find('.region--wrapper'))
		// Components are identified by INDEX in the dom. Not the component id

		// Options, enum. bullsh...
		let _columns = 1
		let options = null

		// The controls aside (-)
		let control = `<div class="controls--aside">
				<div class="control order" uk-icon="icon: arrow-down; ratio:1" data-action="movecomponent-down" /></div> 
				<div class="control remove" uk-icon="icon: minus-circle; ratio:1.25" data-action="removecomponent" ></div>
				<div class="control order" uk-icon="icon: arrow-up; ratio:1" data-action="movecomponent-up" /></div>
			</div>`;

		switch (+type) {
			case 0:
				// simple hr, not editable
				_tpl = `<div class="uk-clearfix hr" data-editable data-name="comp_${ type }_${ index }_html_0_0_0" >
							<hr/>
						</div>`;
				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_0_0_0"]`).get(0));
					});
				}
				break;
			case 1:

				// text, full width + columns
				// NOTE: data-options is NOT required. But it's saved in this format for scalability. data-columns is currently the only option and explicitly needed: because CSS will select by: [data-options="n"]
				// NOTE: We DO NOT use jQuery.data() anymore. Instead it's an .attr() string. So make sure to convert it.
				// ARGH: Test
				options = (content.options) ? window.omBase.convertToJson(content.options) : null;
				_columns = options && options.columns || 1
				let _content = this.OM_Templating.htmlContentFragment(content, 0)
				// Comp 2 WAS deprecated. 50|50 text. We just set this to 2 col:
				// if (+type === 2) {
				// 	_columns = 2
				// 	_content += this.OM_Templating.htmlContentFragment(content, 1)
				// }
				// // <-- end type 2
				// if (+type === 3) {
				// 	_columns = 3
				// 	_content += this.OM_Templating.htmlContentFragment(content, 1)
				// 	_content += this.OM_Templating.htmlContentFragment(content, 2)
				// }
				// <--- end type 3
				// type = 1;   // convert

				_tpl = `
					<div class="controls--ontop">
						<label>Spalten:</label> <input type="range" min="1" max="4" data-action="changecolumns" value="${ +_columns }"/>
					</div>
					<article data-editable data-name="comp_${ type }_${ index }_html_0_0_0"  >
						${ _content }
					</article>`;
				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }" data-options='${ content.options || null }' data-columns="${ _columns }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_0_0_0"]`).get(0));
					});
				}
				break;
			case 2:
				// text. 50:50
				_tpl = `
					<div class="uk-grid uk-child-width-1-1@s uk-child-width-1-2@m" >
						
							<article data-editable data-name="comp_${ type }_${ index }_html_0_0_0"  >
								${ this.OM_Templating.htmlContentFragment(content, 0) || '&nbsp;' }
							</article>
						
							<article data-editable data-name="comp_${ type }_${ index }_html_1_0_0" >
								${ this.OM_Templating.htmlContentFragment(content, 1) || '&nbsp;' }
							</article>
						
					</div>`;
				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_0_0_0"]`).get(0));
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_1_0_0"]`).get(0));
					});
				}
				break;
			case 3:
				// text. 1|1|1
				_tpl = `
					<div class="uk-grid uk-child-width-1-1@s uk-child-width-1-3@m">
							<article data-editable data-name="comp_${ type }_${ index }_html_0_0_0" data-component="${ type }_${ index }">
								${ this.OM_Templating.htmlContentFragment(content, 0) }
							</article>
							<article data-editable data-name="comp_${ type }_${ index }_html_1_0_0" data-component="${ type }_${ index }" >
								${ this.OM_Templating.htmlContentFragment(content, 1) }
							</article>
							<article data-editable data-name="comp_${ type }_${ index }_html_2_0_0" data-component="${ type }_${ index }" >
								${ this.OM_Templating.htmlContentFragment(content, 2) }
							</article>
						</div>`;
				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_0_0_0"]`).get(0));
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_1_0_0"]`).get(0));
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_2_0_0"]`).get(0));
					})
				}
				break;

			case 4:
			case 8:
				// SLIDESHOW!
				id = `comp_${ type }_${ index }_slideshow_0`, attr = `[data-name=${ id }]`;
				_tpl = `<div data-editable 
								class="uk-clearfix is-full"
								data-type="component_4" 
								data-name="${ id }" 
								></div>`

				type = 4    // Force 8 -> 4

				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(attr).get(0));
						this.slideshows[id] = new Slideshow(this.base, attr, content, {hasCaption: false, inlineMode: true, isResizeable: true});
					});
				}
				break;

			case 5:
				// images 50:50
				_tpl = `<div class="uk-clearfix uk-flex" >
							<div class="uk-width-1-2" >
								<div data-editable
								data-type="libelement" 
								data-name="comp_${ type }_${ index }_le_0_0_0" 
								data-allowed='["image","video","audio","character","doc"]'
								data-id="${ this.OM_Templating.libElementIdFragment(content, 0) }" 
								data-size='${ this.OM_Templating.libElementSizeFragment(content, 0) }'
								style="padding:0.75rem;"></div>
							</div>
							<div class="uk-width-1-2 uk-margin-left" >
								<div data-editable
								data-type="libelement" 
								data-name="comp_${ type }_${ index }_le_1_0_0" 
								data-allowed='["image","video","audio","character","doc"]'
								data-id="${ this.OM_Templating.libElementIdFragment(content, 1) }" 
								data-size='${ this.OM_Templating.libElementSizeFragment(content, 1) }'
								style="padding:0.75rem;"></div>
							</div>
						</div>
						`;
				// Append to DOM when this is added as new region
				if (render) {
					await this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }">` + _tpl + control + '</div>').append(async () => {
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_le_0_0_0"]`).get(0));
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_le_1_0_0"]`).get(0));
						await this._placeLibElement({id: this.OM_Templating.libElementIdFragment(content, 0)}, `[data-name="comp_${ type }_${ index }_le_0_0_0"]`, '', this.OM_Templating.libElementSizeFragment(content, 0));
						await this._placeLibElement({id: this.OM_Templating.libElementIdFragment(content, 1)}, `[data-name="comp_${ type }_${ index }_le_1_0_0"]`, '', this.OM_Templating.libElementSizeFragment(content, 1));
					});

				}
				break;
			case 6:
			case 7:
				// image float + text
				// Optional columns:
				options = (content.options) ? window.omBase.convertToJson(content.options) : null;
				_columns = options?.columns || 1
				// default alignment = left
				let alignment = (+type === 6) ? 'is-pulled-left' : 'is-pulled-right';

				_tpl = `
							<div class="controls--ontop">
								Spalten: <input type="range" min="1" max="4" data-action="changecolumns" value="${ +_columns }"/>
							</div>
					
						<article>
							<div class="${ alignment } is-one-third"
								data-editable
								data-type="libelement" 
								data-allowed='["image","video","audio","character","doc"]'
								data-name="comp_${ type }_${ index }_le_0_0_0" 
								data-id="${ this.OM_Templating.libElementIdFragment(content, 0) }" 
								data-size='${ this.OM_Templating.libElementSizeFragment(content, 0) }'>
							</div>
							
							<div data-editable data-name="comp_${ type }_${ index }_html_0_0_0" >
								${ this.OM_Templating.htmlContentFragment(content, 0) }
							</div>
						</article>
						`;
				// Append to DOM when this is added as new region
				if (render) {
					await this.$componentWrapper.append(`<div data-name="comp_${ type }_${ index }" class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }" data-columns="${ _columns }">` + _tpl + control + '</div>').append(async () => {
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_0_0_0"]`).get(0));
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_le_0_0_0"]`).get(0));
						await this._placeLibElement({id: this.OM_Templating.libElementIdFragment(content, 0)}, `[data-name="comp_${ type }_${ index }_le_0_0_0"]`, '', this.OM_Templating.libElementSizeFragment(content, 0));
					});

				}
				break;

			case 9:
				// Text left, dim right
				_columns = content.options && content.options.columns || 1;
				let _teaser = `<div data-editable data-name="teaser" class="teaser-size">` + (this.originalData && typeof this.originalData.teaser !== 'undefined') ? this.originalData.teaser : '';
				_tpl = `<div data-name="comp_${ type }_${ index }" class="uk-clearfix" >
							<img class="uk-width-1-2 uk-float-right" data-name="comp_${ type }_${ index }_dimensioning"  src="/web/assets/images/dim-large-sketch.svg" style="float:right; margin: 0 0 1em 1em;"/>
								<div>
									<div>
										<div class="om-task-teaserimage" data-name="teaser-image"
                                            style="background-size:cover;background-position:center;float:left;margin:0 0.5rem 0.5rem 0;background-color:#ccc;width:16rem;max-width:16rem;min-height:10rem;"></div>
                             
										<div data-editable data-name="teaser" class="teaser-size hide-if-maintask show-if-component-9">
											${ _teaser }
										</div>
									</div>
						
									<article data-editable data-name="comp_${ type }_${ index }_html_0_0_0" >
										${ this.OM_Templating.htmlContentFragment(content, 0) }
									</article>
								</div>
					</div>`;
				// Append to DOM when this is added as new region
				if (render) {
					const _tid = this.originalData && this.originalData.teaserImg && this.originalData.teaserImg.id ? this.originalData.teaserImg.id : null
					await this.$componentWrapper.append(`<div class="region--wrapper" data-comp="${ type }_${ index }" data-pos="${ index }" data-columns="${ _columns }">` + _tpl + control + '</div>').append(async () => {
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_html_0_0_0"]`).get(0));
						this._addNewRegion($(`[data-name="comp_${ type }_${ index }_dimensioning"]`).get(0));
						await this._placeLibElement({id: _tid}, `[data-name="teaser-image"]`, 'teaserImg', {}, {size: 'medium', resizeable: false});
					});
				}
				break;

			// case 10:
			// 	// @deprecated before it was born >:|
			// 	console.warn('@DEPRECATION WARNING: This citation element has no meaning....');
			// 	// A citation KNOWLEDGE TYPE with content (type=64)
			// 	_tpl = `<div class="uk-clearfix">
			// 				<div data-editable data-name="comp_${type}_${index}_citation"  ></div>
			// 			</div>`;
			// 	// Append to DOM when this is added as new region
			// 	if (render) {
			// 		this.$componentWrapper.append(`<div class="region--wrapper" data-comp="${type}_${index}" data-pos="${index}">` + _tpl + control + '</div>').append(() => {
			// 			this._addNewRegion($(`[data-name="comp_${type}_${index}_citation"]`).get(0));
			// 			// Open KN Dropdown showing only citations
			// 			this.base.emit(eventList.OPEN_KN_SELECTOR, {options: {filters: [64, 4], lock: true}});
			// 			this.base.on('KN_SELECTOR_SELECTION', (e) => {
			// 				const $target = $(e.currentTarget);
			// 				const param = $target.data('param');
			// 				const {knowledgeId} = param
			// 				// Nothing selected
			// 				if (!knowledgeId) {
			// 					console.warn('NO KN ID given!')
			// 				}
			// 				window.omApi.getKnowledge(null, knowledgeId).then(kn => {
			// 					// console.log('GOT KN ', kn.data.title, kn.data.teaser)
			// 					$(`[data-name="comp_${type}_${index}_citation"]`).append(`<strong>${kn.data.title}</strong>${kn.data.teaser}`);
			// 				})
			// 			})
			// 		});
			//
			// 	}
			// 	break;

			case 11:
				// A PDF in an iframe. If it has to be...
				id = `comp_${ type }_${ index }_le_0_0_0`,
					attr = `[data-name="comp_${ type }_${ index }_le_0_0_0"]`;
				let _url = content.le_0_0_0 ? content.le_0_0_0.url : '';
				_url = `//${ location.host }/web/media/${ _url }` || '';

				// ISSUE: Now libElements have definitive styles, we con't make it look good that easy in this case:
				_tpl = `
					<div style="position:relative" >
						<h4 style="margin-left: 3rem;">Inhalt des Frames</h4>
						<div class="uk-grid">
							<div class="uk-margin-small uk-width-1-3">
								<small><strong>Ein PDF</small></strong>
								<div data-editable
									data-type="libelement" 
									data-name="${ id }" 
									data-allowed='["document"]'
									data-id="${ this.OM_Templating.libElementIdFragment(content, 0) }"
									data-size='${ this.OM_Templating.libElementSizeFragment(content, 0) }'
									style="max-width:180px;" 
								></div>
							</div>
						
							<div class="uk-margin-small" >
								<small><strong>Eine Webseite</small></strong><br/>
								<small>Kopiere eine URL einer Webseite hier hinein, um diese im Frame anzuzeigen. Beachte, dass nur sichere Seiten (https) funktionieren.<br/>Leere das Feld, um ein PDF aus der Bibliothek zu verwenden.</small>
								<input id="uri_${ type }_${ index }" class="uk-input" type="text" placeholder="URL hier einfügen" name="iUrl" />
							</div>		
						</div>
						
						<iframe 
							id="frame_${ type }_${ index }"
							class="uk-clearfix is-full"
							src='${ _url }'  
							height="400">
						</iframe>
					<div>`;


				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }" data-comp="${ type }_${ index }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(attr).get(0));
						this._placeLibElement({id: this.OM_Templating.libElementIdFragment(content, 0)}, attr, '', this.OM_Templating.libElementSizeFragment(content, 0));
						// Events:

						// If any element (pdf) has been selected
						window.omBase.on('MEDIA_SELECTION', (payload) => {
							const {mediaArr, placeholder} = payload;
							console.log('Selected an element ', payload)
							if (mediaArr && mediaArr[0].url && placeholder === attr) {
								$(`#frame_${ type }_${ index }`).attr('src', mediaArr[0].urlPrefix + mediaArr[0].url);
							}
						})

						// Once iframe has loaded content
						// Todo: Iframe resize [ not active - need to distinct between pdf doc and website ]
						$(`#frame_${ type }_${ index }`).on('load', (e) => {
							// console.info('iframe loaded', content)
							const f = $(e.target).get(0);
							f.height = window.innerHeight - 100;
							// f.height = f.contentWindow.document.body.scrollHeight + "px";
						})

						// URL
						$(`#uri_${ type }_${ index }`).on('change', (e) => {
							// Empty. get back to pdf if any was set.
							if ($(e.target).val() === '') {
								// d.r.y.
								let uri = content.le_0_0_0 ? content.le_0_0_0.url : '';
								uri = `//${ location.host }/web/media/${ uri }` || '';
								$(`#frame_${ type }_${ index }`).attr('src', uri);
								return
							}
							// Test for valid URI
							const {valid, uri} = this.base.checkURL($(e.target).val())
							if (!valid) {
								UIkit.notification({
									message: `Das ist keine gültige Web-Adresse`,
									status: 'warning',
									pos: 'top-right',
									timeout: 3000
								});
								return;
							}
							// Update iframe
							$(`#frame_${ type }_${ index }`).attr('src', uri);
							// Todo: Now update the elenment's data somewhere. Fake a media element???
						})
					});
				}
				break;

			//---------------------------------
			// Worksheet components
			// @deprecated
			//
			case 1001:
				// Simple textarea
				id = `worksheet_${ type }_${ index }`;
				// TODO: read and apply options
				_tpl = `<div data-editable class="uk-clearfix worksheet" data-name="${ id }" data-comp="comp_${ id }_${ index }"  data-options=${ this.getComponentOptions(id, content, {rows: 4}, true) } uk-grid>
					<div class="uk-width-3-4"><small>ARBEITSBEREICH</small></div>
					<div class="uk-width-1-4"><small>OPTIONEN</small></div>
					<div class="uk-width-3-4">
						<textarea class="_sheet paper" rows="${ this.getComponentOptions(id, content).rows }" style="width:100%;" disabled></textarea>
					</div>
					<div class="uk-width-1-4">
						<!-- This controls the options for this component -->
						<label>Zeilen</label>
						<input type="number" min="1" max="20" value="${ this.getComponentOptions(id, content, {rows: 4}).rows }" data-on-change="rows" data-target="${ id }"/>
					</div>
					
				</div>`;
				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(`[data-name="worksheet_${ type }_${ index }"]`).get(0));
						this._applyWorkSheetOptions(id, this.getComponentOptions(id, content, {rows: 4}))
					});
				}
				break;

			case 1002:
				// Table of form items
				id = `worksheet_${ type }_${ index }`;
				_tpl = `<div data-editable class="uk-clearfix worksheet" data-name="worksheet_${ type }_${ index }" data-comp="comp_${ id }_${ index }" data-options=${ this.getComponentOptions(id, content, {
					rows: 1,
					cols: 3
				}, true) } uk-grid>
					<div class="uk-width-3-4"><small>ARBEITSBEREICH</small></div>
					<div class="uk-width-1-4"><small>OPTIONEN</small></div>
					<div class="uk-width-3-4">
						<div class="_sheet">
							<table>
							<tr>
							<td>&nbsp;<td>
							<td>&nbsp;<td>
							<td>&nbsp;<td>
							</tr>
							</table>
						</div>
					</div>
					<div class="uk-width-1-4">
						<!-- This controls the options for this component -->
						<label>Zeilen</label>
						<input type="number" min="1" max="20" value="${ this.getComponentOptions(id, content, {rows: 1, cols: 3}).rows }" data-on-change="rows" data-target="${ id }" /><br/>
						<label>Spalten</label>
						<input type="number" min="1" max="20" value="${ this.getComponentOptions(id, content, {rows: 1, cols: 3}).cols }" data-on-change="cols" data-target="${ id }"/>
					</div>
				</div>`;
				// Append to DOM when this is added as new region
				if (render) {
					this.$componentWrapper.append(`<div class="region--wrapper" data-pos="${ index }">` + _tpl + control + '</div>').append(() => {
						this._addNewRegion($(`[data-name="worksheet_${ type }_${ index }"]`).get(0));
						this._applyWorkSheetOptions(id, this.getComponentOptions(id, content, {rows: 1, cols: 3}))
					});
				}
				break;
		}

		// Append to DOM when this is added as new region
		// return `<div class="region--wrapper" data-pos="${index}">` + _tpl + control + '</div>';

		return true;
	}

	/**
	 *
	 * Simply read and set worksheet options directly into the dom.
	 *
	 * @param id
	 * @param data
	 * @param defaults JSON object of elements default options
	 * @param stringify BOOL to indicate whether we want the object or the string representation of it
	 * @return {string}
	 * @private
	 */
	getComponentOptions(id, data, defaults = '', stringify = false) {
		let opts = (data.options && Object.size(data.options)) ? data.options : defaults;
		return (stringify) ? JSON.stringify(opts) : opts;
	}

	// --------------------------------------------------------------------------
	// Content generation (a fiddly mess of conversion)
	// The template-DOM is parsed and merged into a clean, simple JSON object
	// Please, don't hate me!
	// --------------------------------------------------------------------------

	/**
	 * Update content from RTE
	 * This will collect all html data from the editor and perform a conversion
	 * to fill the form with normalised data
	 * The content field will store base64 encoded JSON
	 * THE BASIC FN is WAY SIMPLER: https://getcontenttools.com/getting-started (save changes)
	 * THIS IS F...D CODE!!!!!!!!!!! Todo: rebuild this.
	 * @param initial BOOL if set, then we may not display user confirmation (onChange) (receiving class is handling this)
	 * @private
	 */
	_getRTEContent(initial = false) {
		if (!this.instance) return;

		// this.instance.syncRegions();

		// The default way to get contentElement HTML is here: https://getcontenttools.com/api/content-edit
		// Yet we have custom elements and components, including media (LibraryElement)

		/**
		 * Get data from libelement
		 */
		const getLibraryData = (name) => {
			let $el = $(`[data-name="${ name }"]`),
				data = {
					id: $el.data('id'),
					size: $el.data('size'),
					libType: $el.data('libtype')
				}

			return data
		}

		// Retrieve html/editor contents
		let regions = this.instance.orderedRegions();
		/**

		 ##Basic JSON structure

		 {
		    card: {
		        template: 'ID',

		        html_....
		        html_....
		        le_....
		        ...
		    },
		    components: [{},{},...]

		 }

		 ##Basic component structure
		 {
		    template: 'ID',
		    html_N_N_N,
		    ...,
		    le_N_N_N,
		    ...

		 }

		 ###LibElement
		 le_ID1_N1_N2 : {
		    id: '<libID>',
		    libType: INT,
		    sizes: {w,h,key,class,x,y}
		 }

		 ID1 = 0 (normal) | s (slideshow)
		 N1 = position in template
		 N2 = position in slideshow

		  // Einzelnes Bild
	      le_0_0_1: {
	        libType: 1,
	        id: 1,
	        sizes: {}
	      },

	      // Slideshow:
	      le_s_1_0: {
	        libType: 1,
	        id: 2,
	        sizes: {}
	      },

		 */
		let _RTEData = {}; // new FormData();
		let _content = {
				// Not necessary for tasks @since 0.10.0
				card: {
					template: this.template
				},
				components: []
			},
			leMapCard = {},
			componentsMap = {},
			otherRegions = {};

		// Loop and collect
		// Note: r = region. You can get the content with r.html(). That's the main thing
		// Yet, it's more complicated. Because a region has multiple containers which are:
		// html_n_n_n   (pure html content)
		// le_n_n_n     (custom library element = image, video, etc.)
		// Plus, some components have options set as well. It's crazy.
		regions.map(r => {
			// console.info('DOM ', r)
			if (!r) return;
			const name = $(r._domElement) && $(r._domElement).attr('data-name');
			if (!name) return;
			let [mainType, componentId, index, elementType, positionInComponent, subType, positionSubType] = name.split('_');
			switch (mainType) {
				case 'comp':
					if (elementType === 'html') {
						// A HTML content string within a component
						// Todo: The regions do not return correct html when we have more than one html_ component
						// Another reason to clean this up!!!!!
						// componentsMap[name] = r.html();
						componentsMap[name] = $(`[data-name="${ name }"]`).html()
						// Remove specific class names
						const classNamesToRemove = ['ce-element', 'ce-element--type-text', 'ce-element--focused'];
						const regexClassNames = new RegExp(classNamesToRemove.join('\\s+'), 'g');
						componentsMap[name] = componentsMap[name].replace(regexClassNames, '');

						// Remove specific attribute
						var attributeToRemove = 'contenteditable=""';
						var regexAttribute = new RegExp(attributeToRemove, 'g');
						componentsMap[name] = componentsMap[name].replace(regexAttribute, '');


					} else if (elementType === 'le') {
						// A Media/Library Element in a component
						let {id, size, libType} = getLibraryData(name);    // Get data from DOM
						componentsMap[name] = {id: id || null, size: size, libType: libType};
						// Add to components array of libTypes:
						if (id)
							this.attachedLibElementsInComponents.push(id)
						// this.attachedLibElements.push(id);
					} else if (elementType === 'slideshow') {
						this._assySlideshow(name, componentsMap, leMapCard, mainType, positionInComponent, index);
					}
					break;

				// @deprecated: Cards.
				case 'card':
					// console.warn('CARD Content is @deprecated! This may be removed in future versions!');\
					if (elementType === 'html') {
						_content.card[`${ elementType }_${ positionInComponent }_${ subType }_${ positionSubType }`] = r.html().trim();
					} else if (elementType === 'le') {
						let {id, size, libType} = getLibraryData(name);
						// Get media ids AND set styles
						leMapCard[`${ elementType }_${ positionInComponent }_${ subType }_${ positionSubType }`] = {id: id || null, size: size, libType: libType};  //libType: data.libType
						// All IDs into our libItems array
						if (id)
							this.attachedLibElementsInComponents.push(id);
					} else if (elementType === 'slideshow') {
						this._assySlideshow(name, mainType, positionInComponent, index);
					}
					break;

				// --- worksheets disabled.
				// 	} else if (mainType === 'worksheet' && existsInDom) {
				// 		/*
				// 		@deprecated ::: currently we disabled the interactive worksheets / forms
				// 		Worksheets are components, but have some extras, of course
				// 	    name = worksheet_TEMPLATEID_INDEX
				// 		{
				// 			template: TEMPLATEID,
				// 		    options: {},        // depending on type
				// 		    data: {}            // depending on type
				// 		}
				// 		 */
				// 		console.warn('WORKSHEET Content is currently NOT supported!');
				// 		const $elem = $(`[data-name=${name}]`), id = name.split('_')[1];
				// 		const options = $elem.data('options') || {};
				//
				// 		// Additional controls for component 1002 / input fields in table head to options
				// 		if (id === '1002') {
				// 			options.labels = [];
				// 			const $inputs = $elem.find('input.__labels');
				// 			$inputs.map((i, input) => {
				// 				options.labels.push($(input).val());
				// 			})
				//
				// 		}
				// 		// Add to map
				// 		componentsMap[name] = {
				// 			template: 'comp_' + id,
				// 			options: options,
				// 			data: {}
				// 		};
				// 	} else {

				// All cases of fixtures or lonesome elements
				// It will setup (text!) input fields with data for plain storage to api
				default:
					// Any other [data-name] elements
					let strippedContent = r.html()
					let val = (name === 'title' || name === 'subTitle') ? this.base.stripHtml(strippedContent) : strippedContent;
					if (!initial)
						this._updateInputs(`input[name="${ name }"]`, val);
					break;
			}


		})

		// -------------------------------------
		//  Card content
		//  @deprecated since 0.35.xx
		_content.card = {..._content.card, ...leMapCard};

		// ------------------------------------
		// Assemble the components array, again...
		//
		// Need to loop again over the components BECAUSE:
		// editor.regions 	are containers
		// components 		are wrappers for one or multiple(!) regions
		// So we store components with regions inside!
		let tempMap = {};

		// console.log('COMP MAP -------------------------', componentsMap)
		for (const [key, value] of Object.entries(componentsMap)) {
			let [mainType, componentId, index, elementType, positionInComponent, subType, positionSubType] = key.split('_')
			// Finally, collect options from the component's data
			// This builds an array of components for different types
			/*
			 Example:
			 [
			 {template: 'component_1', options: {}, html_0_0_0: '<p>...</p>']}									// A simple 1-column text comp
			 {template: 'component_2', options: {}, html_0_0_0: '<p>...</p>', html_0_0_1: '<p>...</p>']}			// A simple 2-column text comp
			 ]
			 */
			if (!tempMap[`${ mainType }_${ componentId }_${ index }`]) {
				// A normal component has 1-n sub-comps. (html, le...)
				// Add options, if any
				const component = `[data-comp="${ componentId }_${ index }"]`;
				// console.log('OPTIONS --> ', index, $(component), $(component).attr('data-options'))
				const pos = $(component).attr('data-pos');
				tempMap[`${ mainType }_${ componentId }_${ index }`] = {template: `component_${ componentId }`, pos: pos, options: $(component).attr('data-options') || null};
				tempMap[`${ mainType }_${ componentId }_${ index }`][`${ elementType }_${ positionInComponent }_${ subType }_${ positionSubType }`] = value;
			} else {
				// Loop trough all other sub-elements (regions) and add to component. The naming conventions make the structure
				tempMap[`${ mainType }_${ componentId }_${ index }`][`${ elementType }_${ positionInComponent }_${ subType }_${ positionSubType }`] = value;
				// console.info('2) LE => ', value)
			}

		}

		let componentsArr = [];
		for (const [key, value] of Object.entries(tempMap)) {
			// console.info('VAR --- LE => ', tempMap)
			componentsArr.push(value);
		}
		// <------------------ END Components assy -----
		_content.components = componentsArr;

		// console.info('LE => ', _content.components)
		// -------------------------------------
		// Assemble the current list of libraryElements & knowledgeElements
		// Safest but slowest method is to parse the html

		this._collectLinkedIds();

		// -------------------------------------
		// Add "other items" (Everything which is usually outside the components-container === any element tagged data-editable)
		_RTEData = {..._content, ...otherRegions};
		// console.info('RTE -----> ', _RTEData)
		// Todo: this is a temporary hack until we figured out when data gets deleted:
		if (!_RTEData.components.length && !this.options.ignoreEmptyComponents) {
			// Regions length is 3 (Knowledge) -> title, teaser, subtitle. THIS WILL CREATE ISSUES ON ANY OTHER SCENARIO. So remove it!!!!!
			console.warn('Something gets deleted... STOP');
			return null
		}
		// Reinit tippy (for a links in html)
		this._reInitInlineTips();

		// Main Content (is base64)  -- everything as base64 for database field
		_RTEData.content = Base64.encode(JSON.stringify(_content));
		this._updateInputs('[name="content"]', _RTEData.content);
		// <----------

		// LibElements Input [no array - forfucksake-idontknowwhy]
		this._updateInputs('input[name="libItems"]', this.attachedLibElements.join());

		// Knowledge Input [array]
		this._updateInputs('input[name="knowledgeItems[]"]', this.attachedKnowledgeElements);

		// Emit changes for listening classes.
		this.emit(eventList.EDITOR_UPDATE, {data: _RTEData, attachedLibItems: this.attachedLibElements, initial: initial});

		// Return to caller (if any)
		return {data: _RTEData, attachedLibItems: this.attachedLibElements, initial: initial};
	}

	// Assemble a slideshow from component_4
	_assySlideshow(name, componentsMap, leMapCard, mainType, positionInComponent, index) {
		// console.log(':::::::: assySlideShow :: ', this.slideshows[name])
		if (!this.slideshows[name]) return;

		// -------------------------------------------
		// This could be clean and nice code. Sorry, it isn't
		// Slideshow component has _le-convention, so we could use the dom. But this component offers a getData() method
		// which handles it for us nice and clean.
		let data = this.slideshows[name].getData(),
			libItems = this.slideshows[name].getLibItems();
		let i = 0;
		for (const [key] of Object.entries(data)) {
			let d = data[key];
			if (mainType === 'card')
				leMapCard[`le_${ positionInComponent }_s_${ i }`] = {id: d.id || null, libType: d.libType, size: d.size};
			else if (mainType === 'comp') {
				// Do you get it?
				componentsMap[`component_4_${ index }_le_${ positionInComponent }_s_${ i }`] = {id: d.id || null, libType: d.libType, size: d.size};
			}
			i++;
		}
		// Add to our libIds
		this.attachedLibElements = this.attachedLibElements.concat(libItems);
	}

	//
	/**
	 * A slideshow is simply a collection of libraryElements,
	 * SchemaL le_n1_s_n2   - where
	 *      n1 = the position in template (if more than 1),
	 *      s = fixed, means "slideshow",
	 *      n2 = position within the slideshow
	 *
	 *  The structure ist FLAT which makes it seem complicated without JSON nesting (server expects just flat structures)
	 *  A component with 2 slideshows could look like this:
	 *
	 *  {
	 *      le_0_s_0: {},   // 1st image in slideshow 1
	 *      le_0_s_1: {},   // 2nd image in slideshow 1
	 *      le_1_s_0: {}    // 1st image in slideshow 2
	 *      ...
	 *  }
	 *
	 * @param content
	 * @param index INT     expects a number 0...n which is the INDEX of the slideshow (important if>1)
	 *                      where we make 'le_{index}_s' and find the images associated in data
	 * @return {string}
	 * @private
	 */
	_extractSlideshowImages(content, index) {
		let r = {};
		// The object could consists of mixed elements. We need to filter out the le_n_n - elements only
		// Since an object could look like: {html_0_0_0: '', le_{n1}_s_0: {}, le_{n1}_s_1: {}, ...} we care only for the le_ at position n1 (slideshow index in dom) - one of n
		// Additionally they are named le_{n1}_s_{n2} - n1 = slideshow position, n2 = image in slideshow
		for (const [key, value] of Object.entries(content)) {
			let index2match = key.split('_')[1],
				pos = key.split('_')[3],
				type = key.split('_')[2]; // @deprecate, only needed if images have the wrong type (int instead of 's')
			// If the index matches the key, the filter only the ones that have the index (le_{index})
			if (key.split('_')[0] === 'le' && +index === +index2match) {
				let newkey = `le_${ index }_${ type }_${ pos }`;
				r[newkey] = value;
			}
		}
		return r;
	}

	/**
	 * Destroy all slideshows
	 * @private
	 */
	_clearSlideshow() {
		Object.keys(this.slideshows).map((key, index) => {
			this.slideshows[key].destroy()
			delete this.slideshows[key];        // Hopefully GC collects it...
		});
	}

	// --------------------------------------------------------------------------
	// General utils
	// --------------------------------------------------------------------------

	/**
	 * Set or update a LibraryElement (TeaserImg only at this stage) -- make val[key] store to switch multiple?
	 * @param media JSON lib object - needs to have at least {id:null, libType:ID}
	 * @param placeholder STRING dom element to insert the LibElement
	 * @param input STRING with input name. Not needed within templates
	 * @param options JSON { sizeData OBJECT with sizes and classnames {w,h,class,key} }
	 * @private
	 */
	async _placeLibElement(media = {id: null, libType: 1}, placeholder, input = null, sizeData = null, options = {}) {
		// placeholder is used as key for the array of LibElements
		let current = this.omLibElements[placeholder]

		if (current && media) {
			// LibElement exists
			current.setData(media, false, null, true);
		} else {
			// NOTE: This is specific for the teaser image.
			// To editor this we need a different approach
			this.omLibElements[placeholder] = new LibraryElement(this.base, placeholder, media, {
				...{
					size: 'large',  // Note: this flag is used for preview image size in container. Always large here.
					showAddButton: true,
					sizeData: sizeData,
					showAdd: true,
					meta: true,
					caption: $(placeholder).data('options') && $(placeholder).data('options').caption || false,
					editable: false,
					selectable: false,
					deleteable: false,
					removeable: true,
					resizeable: true,
					allowed: $(placeholder).data('allowed') || null,
					input: input || placeholder
				},
				...options
			});
		}

		// Wait for image/media rendering
		return new Promise(resolve => {
			// resolve(placeholder)
			// return
			// Resolve this promise, once it is done:
			this.omLibElements[placeholder].once('LE_RENDERED', (e) => {
				// console.log('---<> Rendered', placeholder, e)
				resolve(placeholder)
			});
		});


	}

	/**
	 * Move an item into a new position within array.
	 * Used to order the components
	 * @param data
	 * @param from
	 * @param to
	 * @return {*}
	 * @private
	 */
	_moveItem(data, from, to) {
		// remove `from` item and store it
		let f = data.splice(from, 1)[0];
		// insert stored item into position `to`
		// Let any interested parties know
		this.emit(eventList.EDITOR_UPDATE, {data: this.getContent(), attachedLibItems: this.attachedLibElements});
		return data.splice(to, 0, f);
	}

	/**
	 * Add a new editor region
	 * @param domRegion
	 * @private
	 */
	_addNewRegion(domRegion, data = null) {
		// console.log('Adding region ', domRegion)
		// Add a new region to the editor
		let editor = this.instance // ContentTools.EditorApp.get();
		// If the editor is not 'editing' we're done
		if (!editor?.isEditing()) {
			return;
		}
		new ContentEdit.Region(domRegion);
		// to check for changes).
		// editor._regionsLastModified[name] = editor._regions[name].lastModified();
		editor.syncRegions();
		// Todo: reInstate : Let any interested parties know
		this.emit(eventList.EDITOR_UPDATE, {data: this.getContent(), attachedLibItems: this.attachedLibElements});
	}

	_removeRegion(e) {
		// let regionDom = $(e.currentTarget).parent().prev().closest('.region--wrapper');
		let regionDom = $(e.currentTarget).closest('.region--wrapper');
		// Cleanup libraryElements and slideshows
		Object.keys(this.slideshows).map((key, index) => {
			let name = $(regionDom).find('[data-type=component_4]').attr('data-name')
			if (this.slideshows[name]) {
				this.slideshows[name].destroy();
				delete this.slideshows[name];        // Hopefully GC collects it...
			}

		});
		// gc LibElements!
		regionDom.remove();
		this.instance.syncRegions();
		// Let any interested parties know ::
		this._collectLinkedIds();
		this.emit(eventList.EDITOR_UPDATE, {data: this.getContent(), attachedLibItems: this.attachedLibElements});
	}

	/**
	 * Simply update input fields with current data
	 * @param name
	 * @param val
	 * @private
	 */
	_updateInputs(name, val) {
		// if (name === 'input[name="libItems"]') console.warn('upd ', name, val)
		$(name).val(val);
	}

	/**
	 * Clear LibraryElement array
	 * @private
	 */
	_clearLibElements() {
		Object.keys(this.omLibElements).map((key, index) => {
			this.omLibElements[key].destroy()
			delete this.omLibElements[key];        // Hopefully GC collects it...
		});
	}

	/**
	 * Find all links to knowledge-items || library-items and collect
	 * This only checks the HTML text, not the components,
	 * hence we need to merge the components (LE)
	 * @private
	 */
	_collectLinkedIds() {
		let regions = this.instance.orderedRegions();
		// knLinks are simple. They ONLY exist as text-links in html (added here)
		// leLinks are different: we have this.attachedLibElements[] as a synthesis of 2 arrays
		//      a) all components and teaser images with libIds
		//      b) all text-links in html (added here)
		let knLinks = [],                                   // clear, they just exist in HTML
			leLinks = [...this.attachedLibElementsInComponents]  // add all component LE first.

		// Add HTML links
		regions.map(r => {
			if (r) {
				const _d = r.html().replace(/<p[^>]*>(?:\s|&nbsp;)*<\/p>/g, '').trim();
				const atags = $(_d).find('a');
				atags.map((i, a) => {
					// Library Elements
					let _id = $(a).attr('data-id') || null;
					if (_id && $(a).attr('data-linktype') === 'library') {
						// Push to library array
						leLinks.push(_id);
					} else if (_id && $(a).attr('data-linktype') === 'knowledge') {
						// Push to knowledges array
						// Todo: this loops twice.
						knLinks.push(_id);
					}
				});
			}
		});

		// Hacky clean of dupes
		this.attachedKnowledgeElements = knLinks;   // simple
		this.attachedKnowledgeElements = [...new Set(this.attachedKnowledgeElements)];
		this.attachedLibElements = leLinks;         // added
		this.attachedLibElements = [...new Set(this.attachedLibElements)];
	}
}
