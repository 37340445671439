import EventEmitter   from "eventemitter3";
import KnowledgeModel from "./models/knowledge";
import LibraryModel   from "./models/library";
import DataTable      from 'datatables.net';

export default class Start extends EventEmitter {

	constructor(BaseClass) {
		super();
		// Inject the base class
		this.base = BaseClass;

		// Get number of pure KN records
		KnowledgeModel.loadCollection('itemType=34&contentType=0').then(r => {
			$('[data-dynamic="numKN"]').text(r.length)
		});

		KnowledgeModel.loadCollection('itemType=4&contentType=0').then(r => {
			$('[data-dynamic="numGlossary"]').text(r.length)
		});

		KnowledgeModel.loadCollection('itemType=8&contentType=0').then(r => {
			$('[data-dynamic="numWorksheets"]').text(r.length)
		});

		LibraryModel.getCollection().then(r => {
			$('[data-dynamic="numMedia"]').text(r.mediaLib.total)
		});

		// -------- DataTable
		// https://datatables.net/examples/basic_init/data_rendering.html
		this.DATATABLE = $('#omSearchTable').DataTable({
			searching: false,
			ordering: true,
			paging: true,
			pageLength: 25,
			columnDefs: [
				{targets: 0, width: "40%", className: "title"},
				{targets: [1,2,3], className: "small"},
				{targets: [4,5,6], width: "25%", className: "small"},
			],
			language: {
				url: './web/assets/i18n/datatable.de.json'
			}
		})

		// -------- Search
		const $fullText = $('[name="search"]');
		const $type = $('[name="om-search-type"]');
		const $world = $('[name="om-search-world"]');
		const $epoch = $('[name="om-search-epoch"]');
		const $schoolSubj = $('[name="om-search-schoolSubj"]');
		const $goals = $('[name="om-search-goals"]');
		const $dimensioning = $('[name="om-search-dim"]');
		const $edutasks = $('[name="om-search-edutasks"]');


		$('[data-action="search"]').on('submit', () => {
			$('[data-busy]').addClass('busy');
			// $('[data-busy]').find('span').show();

			let param = '';

			// Fulltext
			param = `?search=${$fullText.val()}`;
			// Type
			const type = $type.get(0).selectize.getValue().length ? $type.get(0).selectize.getValue().reduce(((p, c) => +p + +c)) : null;
			param += type ? `&itemType=${type}` : '';
			// World
			// const worlds = $world.get(0).selectize.getValue().length ? $world.get(0).selectize.getValue().reduce(((p,c)=>+p + +c)) : null;
			const worlds = $world.get(0).selectize.getValue();
			param += worlds ? `&world=${worlds}` : '';
			// Epoch
			// const epochs = $epoch.get(0).selectize.getValue().length ? $epoch.get(0).selectize.getValue().reduce(((p,c)=>+p + +c)) : null;
			const epochs = $epoch.get(0).selectize.getValue();
			if (epochs) param += epochs ? `&epoch=${epochs}` : '';
			// Schoolsubjects
			const subjects = $schoolSubj.get(0).selectize.getValue().length ? $schoolSubj.get(0).selectize.getValue().reduce(((p, c) => +p + +c)) : null;
			param += subjects ? `&schoolSubj=${subjects}` : '';
			// Goals
			param += $goals.val() ? `&goals=${$goals.val()}` : ``;
			// Dimensioning Position
			param += `&position=${$dimensioning.get(0).selectize.getValue()}`;
			// EduTasks
			const eduTasks = $edutasks.get(0).selectize.getValue().length ? $edutasks.get(0).selectize.getValue().reduce(((p, c) => +p + +c)) : null
			param += (eduTasks) ? `&eduTaskId=${eduTasks}` : ``;

			// Dev:
			console.log(param)

			this.DATATABLE.clear().draw();

			omApi.search(param).then(res => {
				// console.log(res)
				if (res?.data.length)
					res.data.forEach(r => {
						if (r)
							this.DATATABLE.row.add([
								() => {
									let uri = '#';
									switch (+r.itemType) {
										case 1:
											uri = `/aufgabe/${r.itemId}`;
											break;
										case 2:
										case 4:
										case 32:
											uri = `/wissensbeitrag?itemId=${r.itemId}`;
											break;
										case 8:
											uri = `/arbeitsblatt?itemId=${r.itemId}`;
											break;

									}

									return `<a href="${uri}">${r.title}</a>`
								},
								()=>{return this.base.getGeneralType(r?.itemType || 0)},
								r.world.map(w=>w.label),
								r.epoch.map(e=>e.label),
								r.schoolSubj.map(ss => {
									return ' ' + ss.label;
								}),
								'n/a',
								r?.goals.map(g => g.label)
							]).draw(false);
					})


				$('[data-dynamic="numOfResults"]').text(`${res.data.length} Ergebnisse`);
				$('[data-busy]').removeClass('busy');

			})


		});
	}
}
