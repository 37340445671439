/**
 * Glossary Component (well, for all types !== 2)
 * This is a REDUNDANT/ DRY component to knowledge.js --> todo: unify.
 *
 * This can be STANDALONE or INCLUDED into the TASK view
 *
 */
import UIkit                             from 'uikit';
import KnowledgeModel                    from "./models/knowledge";
import {eventList, omCodes, parseParams} from "./utils/base";
import tippy                             from "tippy.js";
import Loader                            from "./components/loader";
import EventEmitter                      from "eventemitter3";
import 'jquery.dirty'   // https://www.npmjs.com/package/jquery.dirty
import Editor                            from "./editor/editor";


export default class Glossary extends EventEmitter {

	constructor(BaseClass) {
		super();
		// Inject the base class
		this.base = BaseClass;
		// Type
		this.pathname = window.location.pathname.replaceAll('/','');
		// ItemId, if any
		// this.itemId = new URL(location.href).searchParams.get('itemId');

		// Disable / hide component selector for editor (l.a.m.e.)
		$('.om-editor--compselector').hide();

		// Editor Class
		this.OM_Editor = null;
		// Switch GLOSSARY || CITATION
		this.$OM_FORM = $('#f-om-knowledge');
		// DOM : Depending on param type =>
		switch (this.pathname) {
			case 'zitat':
				this.itemType = 64;
				this.template = 'citation';
				this.pageTitle = 'Zitat';
				this.slug = 'zitat';
				break;
			case 'glossar':
				this.itemType = 4;
				this.template = 'glossary';
				this.pageTitle = 'Glossareintrag';
				this.slug = 'glossar';
				break;
		}



		// console.info(this)
		// Runtime
		this.initialised = false;   // Once the main editor is started, we're set

		/// ------------------
		// This will initialise a new SINGLETON knowledge record
		KnowledgeModel.create();    // Initialise new data model

		// Go
		this._initEvents();

		// Create or Update
		const params = parseParams(location.search)
		if (!params.itemId) {
			console.warn('No Valid ID. Creating.')
			this.editKnowledge({itemId: null});
		} else
			// Edit, if an ID is given OR create if knId = null
			this.editKnowledge({itemId: params.itemId});
	}


	/**
	 * Create or Edit Knowledge.
	 * @param param JSON object, expecting {taskId*, position*, itemId?, status?, itemPos?}
	 */
	editKnowledge(param) {
		// Clear form and editor
		this._destroyEditor();
		// Clear input fields
		this.$OM_FORM.trigger('reset');
		if (param && param.itemId) {
			// EDIT mode: Load the specific record:
			// this.loader.show();
			KnowledgeModel.load(null, param.itemId, {showLoading: true})
				.then(kn => {
					// Populate main form fields
					this._populate(kn);
				});
		} else {
			// CREATE Mode
			this._populate({id: null}, param);
		}
	}


// ----------------------------------------------------------------------------------------------
// CRUD
// ----------------------------------------------------------------------------------------------


	/**
	 * Save the knowledge
	 */
	save() {

		// if (KnowledgeModel.busy) return;
		// ------------- Editor HTML fields -------------
		const editorContent = this.OM_Editor.getContent().data || {};    // Update event has already filled the input fields!

		// ------------- Standard Input fields -------------
		// Serialize all common form data
		// let knData = this.base.getFormData(this.$OM_FORM, false);   // Todo: reinstate after fix (base.js)
		let knData = {
			...{
				itemId: $('[name="itemId"]').val(),
				title: $('[name="title"]').val(),
				subTitle: $('[name="subTitle"]').val(),
				teaser: $('[name="teaser"]').val(),
				itemType: this.itemType,
				position: 0,
				itemPos: 0,
				teaserImg: '',
				// ~~~ do we need this? yes. the api wants it.
				libItems: '',
				knowledgeItems: '',
				viewId: '',
				parentId: '',
				taskId: ''
			}, ...editorContent
		}

		// Validate
		if (!this._validate(knData)) {
			return;
		}

		KnowledgeModel.save(knData)
			.then(r => {
				// Reset dirty flag
				this.$OM_FORM.dirty("setAsClean");   // refresh dirty state watcher
				let newId = (r.data) ? r.data.itemId : null;
				if (newId) {
					// replace the location
					location.replace(`${location.origin}/${this.slug}/?itemId=${newId}`);
					// Set to input
					$('input[name=itemId]').val(newId);
				}

			});
	}

	/**
	 * Delete Knowledge
	 */
	delete(id) {
		// Confirm
		UIkit.modal.confirm('Willst du diesen Eintrag wirklich löschen?').then(function () {
			// TODO: add loading overlay
			KnowledgeModel.delete(id).then(() => {
				setTimeout(() => {
					// Forward to overview
					window.location = this.type == 'zitat' ? '/zitate' : '/glossarliste';
				});
			})
		}, function () {
			console.log('Rejected.')
		});
	}


	//--------------------------------------------------------------------------------------
	// Templating
	//--------------------------------------------------------------------------------------

	/**
	 * Set current template, show and arrange associated fields
	 * And init EDITOR
	 * @param id STRING name of template
	 */
	setTemplate(id) {
		$('[data-dynamic="page-title"]').text(this.pageTitle);
		// this.template = 'glossary';

		// Another hack: hide the components div, because all we do is use TITLE & TEASER
		if (this.template === 'glossary') {
			$('#component-wrapper').hide();
		}

		// Init RTE // Unfortunately we need to destroy and reinit on every change
		if (this.OM_Editor) {
			this.OM_Editor.off('EDITOR_UPDATE', () => {});
			this._destroyEditor();
		}
		this.OM_Editor = new Editor(this.base, '#om-editor-wrapper', id, KnowledgeModel.get(), {hasTemplate: true, ignoreEmptyComponents: true});
		// Hook up events, again
		this.OM_Editor.on('EDITOR_UPDATE', payload => {
			let {data, initial} = payload;
			// just set form dirty ~~~ Form fields need to keep in sync
			if (!initial)
				this.base.forceDirty();
		});
		this.initialised = true;
	}


	//--------------------------------------------------------------------------------------
	// Private
	//--------------------------------------------------------------------------------------


	/**
	 * Initialise event listeners
	 * Note that we do not remove EL as the lifetime of this ends with page refresh / redirect
	 * @private
	 */
	_initEvents() {
		// Watch form state and apply to navigation
		this.$OM_FORM.dirty({
			onDirty: () => {
				this.base.emit(eventList.NAV_DIRTY, true);
				//Get array of dirty elements: maybe use base to set uk-danger? this.$OM_FORM.dirty("showDirtyFields");
			},
			onClean: () => {
				this.base.emit(eventList.NAV_DIRTY, false);
			}
		});

		// Events & Routing
		$(document).on('click', "[data-action='knowledge']", (e) => {
			const $target = $(e.currentTarget);
			let command = $target.data('command');
			let param = $target.data('param');
			switch (command) {
				case 'save' :
					this.save();
					break;
				case 'saveClose' :
					this.save(true);
					break;
				// case 'delete' :
				// 	// this.delete();
				// 	break;
			}
		});


		/**
		 * Nav Events
		 */
		this.base.NAVIGATION.on(eventList.NAV_STATE, (payload) => {
			// Update Form Data (TODO: what about the model??? This would be a case for RXJS but too much overhead!)
			$('input[name=status]').val(payload);
			this.base.forceDirty();
		});
		this.base.NAVIGATION.on(eventList.NAV_SAVE, () => {this.save();});
		this.base.NAVIGATION.on(eventList.NAV_DELETE, () => {
			// get itemId from Input field
			this.delete($('[name="itemId"]').val());
		});


	}


	/**
	 * Validate input fields
	 * @param formData collected form data
	 * @return {boolean}
	 * @private
	 */
	_validate(formData) {
		let valid = true, errors = [];
		if (!formData.title.length) {
			valid = false;
			errors.push('Bitte gib einen Titel an.');
		}
		if (!formData.teaser.length) {
			valid = false;
			errors.push('Bitte gib einen Inhalt ein.');
		}
		// valid = false
		if (!valid) {
			UIkit.notification({
				message: `Achtung: ${errors.join('<br/>')}`,
				status: 'warning',
				pos: 'top-right',
				timeout: 5000
			});
		}
		// TODO: notify user
		return valid;
	}


	/**
	 * Populate the kn form with given data
	 * @param kn
	 * @private
	 */
	_populate(kn) {
		// Populate form
		this.base.populate(this.$OM_FORM, kn);    // Populate form inputs with fresh data
		// HACK!!! Set to input
		$('input[name=itemId]').val(kn.itemId);

		// ------------------
		// Create main editor
		// Populate and open template
		this.setTemplate(this.template, kn);
		// Reset dirty flag
		this.$OM_FORM.dirty("setAsClean");   // refresh dirty state watcher
	}

	/**
	 * Destroy the RTE
	 * @private
	 */
	_destroyEditor() {
		if (this.OM_Editor) {
			this.OM_Editor.off('EDITOR_UPDATE', () => {});
			this.OM_Editor.destroy();
		}
		this.initialised = false;
	}
}
