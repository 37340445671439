import UIkit from "uikit";

let initial = {
	id: "",
	userName: null,
	email: "",
	firstName: "",
	lastName: "",
	phone: null,
	organisation: "",
	newsletter: 0,
	street: "",
	city: "",
	zip: "",
	country: "",
	role: {
		id: 0,
		name: ""
	},
	subscriptions: [],
	secret: null,

}


let UserModel = {
	data: {...initial},         // This is just for caching

	/**
	 * Set props and save to server
	 * @param props JSON object to set
	 * @param sync  BOOL if true, then save to server
	 */
	async set(data, save = false) {
		this.data = {...initial, ...data};

		return new Promise(async (resolve, reject) => {
			if (save) {
				const r = await omApi.setAccount(data);

				if (r.status === 200) {
					resolve(true);
				} else {
					UIkit.notification({
						message: `Der Nutzer konnte nicht gespeichert werden.`,
						status: 'warning',
						pos: 'top-right',
						timeout: 5000
					});
					reject();
				}

				// Notify
				UIkit.notification({
					message: `Der Nutzer wurde gespeichert.`,
					status: 'success',
					pos: 'top-right',
					timeout: 5000
				});
				// Resolve ID
				resolve(this.data.id);  // give back the ID
			}
			// no save:
			resolve(this.data.id);  // return id.
		});
	},

	/**
	 * Get user / account data
	 * Since ~0.80+ we use this for all accounts
	 * IMPORTANT to realise that this class does not hold the logged in user data but changes
	 * data on each request!!!
	 * @param id
	 * @returns Promise
	 */
	async get(id) {
		// Get cached first
		// if (this.data?.id === id) return new Promise(resolve => resolve(this.data));
		// If not, reload from api
		const res = await omApi.getAccount(id);
		return new Promise((resolve) => {
			if (res.status === 200)
				resolve(res.data)
			else {
				UIkit.notification({
					message: `Nutzer konnte nicht abgerufen werden.`,
					status: 'danger',
					pos: 'top-right',
					timeout: 5000
				});
				resolve({})
			}
		})
	},

	/**
	 *
	 */
	async getCollection() {
		const res = await omApi.getAccounts();
		return new Promise((resolve, reject) => {
			if (res.status === 200) {
				// Todo: this is just demo-mixin subscription data
				let d = res.data.map(dd => {
					return {
						...dd, ...{
							"subscriptions": [
								{
									id: '12345ABC',                      // subscription ID von Paypal
									packages: [
										{
											// 1 Jahr
											id: '5e1f882891bc24.14465807',
											title: "Kreisläufe",
											startDate: '2023-01-01',
											endDate: '2023-12-31'
										},
										{
											// 6 Monat
											id: '5e2863fdd14821.85144592',
											title: "Emergenz",
											startDate: '2023-01-15',
											endDate: '2023-06-15'
										}
									]
								},
								{
									id: '9865XYZ',
									packages: [
										{
											id: '5e1f882891bc24.14465807',
											title: "Welt 5",
											startDate: '2023-01-15',
											endDate: '2023-06-15'
										},
										// ...+15
									]
								}
							]
						}
					}
				})
				resolve(d); //
				//
				resolve(res.data)
			} else {
				UIkit.notification({
					message: `Die Nutzerliste konnten nicht abgerufen werden.`,
					status: 'danger',
					pos: 'top-right',
					timeout: 5000
				});
				reject([])
			}
		})
	},

	// ---------- Methods.

	// ---- User CRUD methods

	/**
	 * Login
	 * @param userLogin
	 * @param userPass
	 * @returns {Promise}
	 */
	login: (userLogin, userPass) => {
		return new Promise((resolve, reject) => {
			window.omApi.login(userLogin, userPass)
			.then(
				result => {
					// Instantly check role:
					if (result.data.user?.role.id < 10) {

						reject({code: 401, message: 'Du bist leider kein Admin.'});
					}

					UserModel.current = result.data.user;
					// Keep the user in localStorage
					localStorage.setItem('$$omauth', JSON.stringify({...result.data.user, ...{loggedIn: true}}));
					resolve(result.data)
				})
			.catch(e => {
				// TODO: does not kick!!!
				UserModel.current = null;
				console.info('CATCH MODEL');
				reject({code: 401, message: e});
			});
		});
	},

	/**
	 * Log user out via api
	 * @returns {Promise<Promise<unknown> | Promise<void>>}
	 */
	logout: async () => {
		return window.omApi.logout();
	},


	/**
	 * Delete a user (NYI)
	 * @param id
	 * @returns {Promise}
	 */
	delete(id) {
		return new Promise((resolve, reject) => {
			// No delete
		});

	},

	resetPass(payload) {
		return window.omApi.resetPass(payload);
	},

	/**
	 * Change Password
	 * @param payload OBJECT - expects userLogin | userPwd | newPwd
	 * @returns {Promise}
	 */
	changePass(payload) {
		return window.omApi.changePass(payload);
	},

}

export default UserModel

