export default class KnowledgeAssociations {
	constructor() {
		// From knowledge-associations.inc.php
		this.DOM = {
			taskList: $('#om-kn--tasks'),
			children: $('[data-dynamic="om-kn--children"]'),
			parents: $('[data-dynamic="om-kn--parents"]')
		}

	}

	showAssocTasks(data) {
		if (!data.tasks) return;
		this.DOM.taskList.empty();
		data.tasks.forEach(task => {
			this.DOM.taskList.append(`
			<li><a href="/aufgabe/${task.id}">${task.title}</a></li>
			`)
		})

	}

	showAssocKnowledges(data) {
		// console.log('Assoc: ', data)
		if (data.parent) {
			const _html = `<a href="/wissensbeitrag/${data.parent.parentId}?viewId=${data.parent.parentId}">${data.parent.title}</a>`;
			this.DOM.parents.html(_html);
		}
		// Children
		this.DOM.children.empty();
		if (data.children)
			data.children.forEach(knc => {
				this.DOM.children.append(`<div class=""><a href="/wissensbeitrag/${knc.itemId}?viewId=${knc.viewId}">${knc.title}</a></div>`);
			});
	}
}
