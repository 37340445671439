import UIkit               from "uikit";
import LibraryElementModel from "../models/libelement";
import Loader              from './loader';
import { eventList }       from "../utils/base";
import EventEmitter        from "eventemitter3";
import dragDrop            from "drag-drop";
import LibraryElement      from "./library.element";

/**
 * @class
 * A controller for the media-meta data form
 * It shows dynamic forms for different types of media and stores them
 *
 *
 */
export default class MediaMetaModal extends EventEmitter {

	constructor(base) {
		super();
		this.base = base;
		this.initialised = false;
		// DOM
		this.WRAPPER = '#om-media-form'; // ID of wrapping element, so we don't mess up dom namespace
		// Todo: throws error on initalisation
		this.$ELEMENT = UIkit.modal("#om-media-meta-modal", {stack: true, escClose: false, bgClose: false});
		this.$LIBRARY_ELEMENT = null;   // The main LibraryElement
		// Linker
		this.$OM_LINKER = $(`${this.WRAPPER} #om-media-linker`);
		this.$OM_UPLOAD_FIELD = $(`${this.WRAPPER} input[name="om-upload"]`);
		this.$OM_MEDIA_UPLOADBTN = $(`${this.WRAPPER} #om-upload`);
		this.$OM_MEDIA_LINK_MSG = $(`#om-media-link-msg`);
		this.$OM_MEDIA_LINK_INPUT = $(`#om-media-link-input`);
		this.$OM_MEDIA_EMBED_INPUT = $(`#om-media-embed-input`);
		this.$OM_MEDIA_LOCATION_TYPE = $(`${this.WRAPPER} input[name="locationType"]`);

		this.$OM_MEDIA_DROP = $(`${this.WRAPPER} .dropzone`);
		this.TITLE = $('#om-media-meta-modal .uk-modal-title'); // better to use [data-dynamic]
		this.$DOWNLOAD_BTN = $('#f-media-meta-download');
		this.$FORM = $('#f-media-meta');
		this.$FORM_CONTAINER_META = $('#om-meta-data');
		this.$EXIF_CONTAINER = $(`${this.WRAPPER} #om-media-exif`);

		this.Loader = new Loader(this.$FORM, {show: false})
		this.fileList = []  // Save dropped files here

		// Initialise
		this._initEvents()
	}


	/**
	 * Show the modal with given ID
	 * retrieve data and set type & data
	 * @param type INT
	 * @param id STRING
	 */
	show(id, type) {
		// Get type as object (translated)
		let _type = this.base.getLibElementType(type);
		// Clear input & messages
		this.$EXIF_CONTAINER.hide();
		$('[data-dynamic="ac-references"]').text('');

		// Load record or initialise empty
		if (id) {
			// Set NEW attribute to input field:
			// $('[data-name="_isNew"]', false);
			// Update
			LibraryElementModel.load(id)
			.then(r => {
				_type = this.base.getLibElementType(r.libType); // refresh the type
				// Set data
				this.setData(r);
				// Title
				this.TITLE.html(`<img uk-svg style="width:52px;height:32px;" src="${_type.icon}" /> Bearbeite ${_type.label}: ${r.title}`);
			}).catch(e => {
				console.warn('Err loading libElement', e)
			})
		} else {
			// Create new type
			this.setData(LibraryElementModel.create({libType: type}));
			// Set NEW attribute to input field:
			// $('[data-name="_isNew"]', true);
			// Title
			this.TITLE.html(`Lege <strong>${_type.label}</strong>  an.`);
		}

	}

	/**
	 * Hide the modal
	 * Ideally remove all events.
	 * But check out: this.initialised -> it will remain true and prevent reinit of events
	 */
	hide() {
		try {
			this.$ELEMENT.hide();
		} catch (e) {
			console.warn(e)
		}
		// Remove form watcher
		// $('form input').off('keydown', (e) => {})
	}


	// ============================================================================================================================================
	// CRUD / Data
	// ============================================================================================================================================

	/**
	 * Set form data and create a new LibraryElement with the needed data
	 * @param data : JSON expecting a json object matching form fields {names}
	 */
	setData(data) {
		// Switch form type
		// Clear Form
		this._initForm(data).then(() => {
			// Populate the form
			this.base.populate(this.$FORM, data, true);
			// Populate media (new libraryElement)
			let allowedMime = this.base.getLibElementType(data.libType).allowedMimeTypes.join(',');
			this.$OM_UPLOAD_FIELD.attr('accept', allowedMime);  // Restrict input to allowed mime types
			// Create a new LibElement (and reuse!)
			this.$LIBRARY_ELEMENT = new LibraryElement(this.base, '[data-contains="libraryelement"]', data, {
				size: 'medium',             // show the medium sized version
				showAddButton: false,       // no add button, only display what is there
				caption: false,             // no caption
				showOnClick: true,          // opens the preview on click
				selectable: true,           // can be selected
				deleteableFile: true        // its file can be deleted when type = 2048
			});

			// Setup download button / link
			this.$DOWNLOAD_BTN.attr('href', data.urlPrefix + data.url);
			this.$DOWNLOAD_BTN.attr('download', data.title) // + '.' + data.url.split(/[#?]/)[0].split('.').pop().trim());
			this.$DOWNLOAD_BTN.attr('target="_blank"');

			//
			/**
			 * Selectize SELECTOR FIELDS: These MUST already be initialised by base.js
			 * Here we re-initialise for the current form
			 * and set content to data
			 */
			let type =  data.data ? data.data.type.id : 1 ,
				subType = data.data ? data.data.subType.id : 1;

			/**
			 * Setup selectize boxes for meta form
			 * TODO: Superflous!
			 */
			let $selects = this.$FORM_CONTAINER_META.find('[data-init="selectize"]');
			$selects.map((i, s) => {
				let $s = $(s), selectize = $s[0].selectize,
					isTagField = $(s).attr('multiple'),
					id = $s.attr('name') && $s.attr('name').match(/[^.]+$/)[0].replace('[]', ''),  // get plain name of field
					values = data.data ? data.data[id] : null;     // get matching data
				// Check for potential issues with new fields!
				if (isTagField === 'multiple') {
					if (typeof values === "string")
						values = values ? values.split(',') : null;
					selectize.setValue(values);
				}
			});

			// ------ Load Type field(s)
			this._setTypeOptions($('[name="data.type"]')[0].selectize, 'type', data.libType, 0, type);
			// SubType
			this._setTypeOptions($('[name="data.subType"]')[0].selectize, 'subType', data.libType, type, subType);

			// Licence field
			// supports custom text, so set the option we get from the server and select
			if ($('#om-select-metameta-licence') && !$('#om-select-metameta-licence')[0].selectize.getOption(data.media.licence).length) {
				$('#om-select-metameta-licence')[0].selectize.addOption({value: data.media.licence, text: data.media.licence});
				$('#om-select-metameta-licence')[0].selectize.setValue(data.media.licence, true);
			}

			// Tasks dropdown
			// this is READONLY now
			$('#om-select-mediameta-task').selectize()
			const t4s = data.tasks ? data.tasks.map(t => t.id) : [];
			$('#om-select-mediameta-task')[0].selectize.setValue(t4s);
			$('#om-select-mediameta-task')[0].selectize.disable()

			// ------- EVENTS (Part II ) ---------
			// -------- Watch events from LibElement

			// Metadata has been updated. Just bubble the event globally (is there need for this anymore?)
			this.$LIBRARY_ELEMENT.on(eventList.MEDIAMETA_UPDATED, (payload) => {
				// Bubble up to media.js, if wrapped
				this.base.emit(eventList.MEDIAMETA_UPDATED, payload);
			});

			// Listen to actions and errors from LibEl
			this.$LIBRARY_ELEMENT.on(eventList.ACTION, (payload) => {
				switch (payload.action) {
					case 'error.mediaLinkField' :
						// Wrong URI inserted (external links)
						this.$OM_MEDIA_LINK_INPUT.removeClass('uk-form-success').addClass('uk-form-danger');
						break;
					case 'set.locationType' :
						// Locationtype has been updated
						$('[name="locationType"]').val(payload.data);
						break;
					case 'success.mediaLinkField' :
						// Successfully linked media
						this.$OM_MEDIA_LINK_INPUT.removeClass('uk-form-danger').addClass('uk-form-success');
						break;
				}
			})

			// Wait for exif data (won't happen currenlty)
			this.$LIBRARY_ELEMENT.on(eventList.MEDIA_EXIF_AVAILABLE, payload => {
				// console.log('GOT EXIF DATA', Object.size(payload), payload)
				if (Object.size(payload) > 0) {
					this.$EXIF_CONTAINER.html(`<span uk-icon="info"></span> ${Object.size(payload)} Daten gefunden.`);

					let $ul = '<ul>'
					$.each(payload, function (idx, item) {
						$ul += '<li>' + idx + ':' + item + '</li>';
					})
					$ul += '</ul>';
					this.$EXIF_CONTAINER.html(`
					<button href="#toggle-animation" class="uk-button uk-button-default" type="button" uk-toggle="target: #toggle-animation; animation: uk-animation-fade">
					<span uk-icon="info"></span> ${Object.size(payload)} Daten gefunden.
					</button>
					<div id="toggle-animation" hidden class="uk-card uk-card-default uk-card-body uk-margin-small" style="word-break:break-word;font-size:10px;">${$ul}</div>
					`);

					// Update form :::: This would work nicely if we used subjects, templating engines or a framework
					this.base.populate(this.$FORM, LibraryElementModel.get(), true);
					//
					this.$EXIF_CONTAINER.show();
				}
				// this.$LIBRARY_ELEMENT.off(eventList.MEDIA_EXIF_AVAILABLE);
			})

		});
	}

	/**
	 * Save Meta Data
	 *
	 */
	async save(close = false) {
		const valid = await this._validate()
		if (!valid) return;

		this.Loader.show();

		// Get the data again from the form.
		let mediaData = this.base.getFormData(this.$FORM, true);  // this will convert the flat structure back to JSON

		// Mixin model (todo: remove all keys not in model)
		mediaData = {...LibraryElementModel.getInitial(), ...mediaData};


		// Add files, if any
		if (!this.fileList.length) {
			let fileList = this.$OM_UPLOAD_FIELD.get(0).files, filesArr = [];
			// Convert to a simple collection
			for (let i = 0, file; file = fileList[i]; i++)
				filesArr.push(file);
			// update global var
			this.fileList = filesArr;
		}

		if (this.fileList.length)
			mediaData = {...LibraryElementModel.getInitial(), ...mediaData, ...{mediaFiles: this.fileList}}

		// These are HACKS (use JSON object to post) :: it will be DOUBLE-converted in the API
		mediaData['data.genre'] = $('#om-select-mediameta-genre')[0].selectize.getValue().join(',');

		// Clear undefined tags
		if (mediaData.data.tags === "undefined") mediaData.tags = "";
		// Do it
		const id = await this.$LIBRARY_ELEMENT.setData(mediaData, true, {initial: false, loaded: true, progressing: true});
		// If created, set the ID here to avoid duplicates:
		if (id) {
			//LibraryElementModel.set({id: id});  // is this necessary? Using these quasi-singletons may fuck things up!!! We refrained from this in LibElement
			$('input[name="id"]').val(id);
		}
		// Hide Loader
		this.Loader.hide();
		// Close the modal, if no error and close is requested
		if (close && id) this.hide();

	}

	// ============================================================================================================================================
	// PRIVATE
	// ============================================================================================================================================

	/**
	 * Validate form data.
	 * Todo: complete this.
	 * @private
	 */
	async _validate() {

		// Check all this:
		let error = false;
		let fieldsInvalid = [];
		// Todo: improve
		if (!$('input[name="title"]').val()) {
			error = true;
			fieldsInvalid.push(' Titel');
		}

		let fv = 'Bitte trage mindestens folgende Daten ein:' + fieldsInvalid.join(', ');
		if (error) {
			UIkit.notification({
				message: `${fv}`,
				status: 'warning',
				pos: 'top-right',
				timeout: 5000
			});
		}
		return !error;
	}

	/**
	 * Initialise event listeners
	 * @private
	 */
	_initEvents() {

		/**
		 * Data-Actions
		 */
		$('[data-action="mediameta"]').on('click', (e) => {
			e.preventDefault()
			let command = $(e.currentTarget).data('command');
			// Disable buttons
			$('button').prop('disabled', true);
			switch (command) {
				case 'upload' :
					// Upload btn has been clicked, open upload window
					e.preventDefault();
					this.$OM_UPLOAD_FIELD.trigger('click'); // Open file uploader
					break;
				case 'submit' :
					// Well, save the media element
					this.save();
					break;
				case 'submit.close' :
					// Save and close this modal
					this.save(true);
					break;
			}
			// Enable buttons
			$('button').prop('disabled', false);
		})

		/**
		 * Modal behaviour
		 */
		$('#om-media-meta-modal').on({
			'show.uk.modal': () => {
				// Visible
			},
			'hide.uk.modal': () => {
				// Stop video and audio
				// TODO: prone to issues!
				$('audio').each(function () {
					this.pause(); // Stop playing
					this.currentTime = 0; // Reset time
				});
				$('video').each(function () {
					this.pause(); // Stop playing
					this.currentTime = 0; // Reset time
				});
			}
		});

		/**
		 * Media Upload : Watch changes to image file input field and send info to the current LibElement
		 * to show the preview.
		 */
		this.$OM_UPLOAD_FIELD.on('change', () => {
			let fileList = this.$OM_UPLOAD_FIELD.get(0).files, files = [];
			// Convert to a simple collection
			for (let i = 0, file; file = fileList[i]; i++) {
				//do your thing
				files.push(file);
			}
			this._handleUpload(files);
		});

		/**
		 * DropZone
		 */
		if (this.$OM_MEDIA_DROP.length) {
			dragDrop(this.$OM_MEDIA_DROP.get(0), {
					onDrop: (files, pos, fileList, directories) => {
						// console.log('Here are the dropped files', files) // Array of File objects
						// console.log('Dropped at coordinates', pos.x, pos.y)
						// console.log('Here is the raw FileList object if you need it:', fileList)
						// console.log('Here is the list of directories:', directories)
						this._handleUpload(files);
					},
					onDragEnter: () => {
						this.$OM_MEDIA_DROP.addClass('entered');
					},
					onDragOver: () => {
						this.$OM_MEDIA_DROP.addClass('entered');
					},
					onDragLeave: () => {
						this.$OM_MEDIA_DROP.removeClass('entered');
					}
				}
			);
		}

		// Initialise the reference dropdown if this record is a doc libType = 16
		// NOTE: This is always initialised, globally since the mediaModal is a single instance
		// if (+data.libType === 16) {
		this._initialiseReferenceAC();
	}

	/**
	 * Handle upload
	 * @param filesArr array of files. We currently only handle ONE.
	 * @private
	 */
	_handleUpload(filesArr) {
		// LibraryElement will receive file data, render preview and update data model
		if (this.$LIBRARY_ELEMENT && filesArr.length) {
			// Update title (!), only if empty (do not overwrite)
			if (this.$FORM.find('[name="title"]').val() === '')
				this.$FORM.find('[name="title"]').val(filesArr[0].name);
			// Update LibElement
			this.$LIBRARY_ELEMENT.handleFiles(filesArr);
			// Save the current file list
			this.fileList = filesArr
		}
	}

	/**
	 * Remove events (destroy)
	 * Todo: check this. These use anonymous functions!!
	 * @private
	 */
	_removeEvents() {
		this.$LIBRARY_ELEMENT.off(eventList.MEDIA_EXIF_AVAILABLE);
		this.$LIBRARY_ELEMENT.off(eventList.ACTION);
		$('[data-on-change]').off('change', () => {});
	}

	/**
	 * Initialise an empty or populated form
	 * by given type. Forms will differ per type.
	 * @param type STRING (type.id)
	 * @private
	 */
	_initForm(data) {

		let libType = this.base.getLibElementType(data.libType),
			val = null;

		// Clear the form
		this.$FORM.get(0).reset();
		this.fileList = [];
		// Insert the current null data
		this.base.populate(this.$FORM, LibraryElementModel.create({libType: libType.id}));    //LibraryElementModel.getInitial()


		// Setup the form
		return new Promise((resolve) => {
			// Dynamically load the included forms. Note that the DOM-ready event won't have the elements so use $.find()
			// @deprecated: this.$FORM_CONTAINER_META.load(`/includes/${type.id} #include`, (r) => {

			// Show only fields for current type.
			this.$FORM.find(`[data-show-libtype]`).map((i, t) => {
				let id = $(t).data('show-libtype');
				if (id.includes(libType.code))
					$(t).show();
				else
					$(t).hide();
			});

			// Some fields are read-only
			this.$FORM.find(`[data-disable-libtype]`).map((i, t) => {
				let id = $(t).data('disable-libtype');
				if (id.includes(libType.code))
					$(t).find('input').prop('disabled', true);
				else
					$(t).find('input').prop('disabled', false);
			});

			this.$ELEMENT.show();

			// Todo: timeouts are for losers. Refactor this.
			setTimeout(() => {
				// this._setTypeOptions($('[name="data.subType"]')[0].selectize, 'subType', data.data.subType);
				$('[name="data.subType"]') && $('[name="data.subType"]')[0].selectize.setValue(data.data.subType && data.data.subType.id)
			}, 3000)


			// Todo: check this script again. This is unclean. There are 2 scenarios: CREATE or UPDATE
			// Problem: The media-modal is only initialised ONCE. So _initEvents() are globals and won't trigger again if a new record is loaded
			// So anything that needs re-initialisation needs to be done here. Hence we have another "event init" which is a bit messy

			// LibType 16 has referenceId
			if (data.libType === 16 && data?.referenceId) {
				$('#om-select-ac-references')[0].selectize.clearOptions();
				$('#om-select-ac-references')[0].selectize.addOption(data.referenceId);
				$('#om-select-ac-references')[0].selectize.setValue(data.referenceId.id);
			}

			// console.log('resetting form -------', $('[name="om-upload"]').val())

			// ! --------- EXIT ? --------!
			// The following must be initialised ONCE,
			// so stop execution if the events are already attached [hacked]
			if (this.initialised) {
				resolve(true);
				return;
			}

			// --- Event init. Once. With data. Why not in _initEvents() ?
			// Actions, part 2. Should be in _initEvents()!
			// Switch event actions / commands and execute
			const onChangeOrPaste = (cmd, inputVal) => {
				switch (cmd) {
					// (1a) Change events (search): Highlight search button
					case 'setMode.link' :
						/**
						 *	This sets the mode to external / link.
						 */
						this.$OM_MEDIA_LINK_MSG.text('Externer Link zu Medium wird eingefügt.');
						// Try and update the preview;
						// this.$OM_MEDIA_LOCATION_TYPE.val(2);    // JS Updates locationType to a concrete value
						this.$LIBRARY_ELEMENT.handleFileLink(inputVal);
						// Clear all other options to insert
						this.$OM_MEDIA_EMBED_INPUT.val(''); // no more embed
						break;
					case 'setMode.embed' :
						if (!inputVal) {
							// it has been removed
							return
						}
						/**
						 *	This will get and embed code
						 */
						this.$OM_MEDIA_LINK_MSG.text('Ein Embed-Code wird eingefügt.');
						// Safely parse the stuff:
						// removes any whitespace
						inputVal = inputVal.replace('/\s\s+/', ' ');
						// matches everything between the object tags, and nothing else
						// const matches = inputVal.match(/<iframe[^>]*>(.*?)<\/iframe>/);
						// adds back the object tags and returns the value
						// inputVal = '<div style="position: relative; height: 0; overflow: hidden; padding-bottom: 56.25%;"><iframe>'+matches[0]+'</iframe></div>';
						// At least, remove scripts
						inputVal.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
						// And styles
						inputVal.replace(/(<style[\w\W]+style>)/g, "")
						// Try and update the preview:
						this.$LIBRARY_ELEMENT.handleEmbedCode(inputVal);
						// Clear others?
						break;
					// (1b) Change events (search): Highlight search button
					case 'setMode.upload' :
						/**
						 * Upload mode (internal)
						 */
						this.$OM_MEDIA_LINK_MSG.text('Die Datei wird auf unseren Server geladen.')
						this.$OM_MEDIA_LINK_INPUT.val('');
						this.$OM_MEDIA_LOCATION_TYPE.val(1);
						break;
					/**
					 * (2) If a main type selector changes, the subType may change too
					 * A BETTER way to do it is to always get the API data and let dom change state on results (i.e. [] = hide dd)
					 */
					case 'setSubType' :
						// console.info('SET SUBTYPE for type = ', libType);
						let selectize = $('[name="data.subType"]').length ? $('[name="data.subType"]')[0].selectize : null;
						// NOTE: A request per type
						switch (libType.id) {
							case 'audio' :
								// console.info('AUDIO ', val === 16, selectize)
								if (+val === 2048) selectize.enable(); else selectize.disable();  // Only id 2048 has subTypes
								break;
							case 'reference' :
								// Re-Populate the subType dropdown :::
								// this may cause issues when data is already populated but only the mainType has changed.
								let subType = LibraryElementModel.get().data ? LibraryElementModel.get().data.subType.id : -1; // getting data from LibElementModel can be dangerous...
								this._setTypeOptions(selectize, 'subType', libType.code, inputVal, subType); // Reset subtype to nothing!
								break;
						}
						break;
					case 'checkSubType' :
						this._checkTypeOptions(inputVal);
						break;
				}
			}

			/**
			 * Listen for paste events
			 */
			$('[data-on-paste]').on('paste', (e) => {
				let cmd = $(e.target).data('on-paste'),
					inputVal = e.originalEvent.clipboardData.getData('text');
				onChangeOrPaste(cmd, inputVal);
			})

			/**
			 * Listen for input change events
			 *
			 */
			$('[data-on-change]').on('change', (e) => {
				let cmd = $(e.target).data('on-change'),
					inputVal = $(e.target).val();
				onChangeOrPaste(cmd, inputVal);
			})

			this.initialised = true;
			resolve(true);
		})
	}

	/**
	 * Set types and subtypes
	 * The api needs 3 values: /<field>/<libType:INT>/<type:INT = value of current dd>
	 * TODO: use enhanced base.populate() fn for this
	 * @param selectize the selectize object
	 * @param field the fieldName used to set data to
	 * @param libType the ID/INT of the current libType
	 * @param type the ID/INT of the currently selected
	 * @param val the currently selected value (of the field)
	 * @private
	 */
	_setTypeOptions(selectize, field, libType, type, val) {
		if (field === 'subType' && (!type || type === 0)) type = 1; // Subtypes need a 1+
		// Fill option fields
		console.log(field, type)

		window.omApi.getOption(field, libType, type).then(r => {
			// ISSUE: https://github.com/selectize/selectize.js/pull/1080
			selectize.clearCache('option');
			selectize.clearOptions();

			// selectize@0.12.6 : https://github.com/selectize/selectize.js/pull/1080
			$.each(selectize.options, function (key, value) {
				delete selectize.options[key];
			});
			selectize.sifter.items = selectize.options;
			selectize.setValue(1);  // Default = first
			if (!r) return;
			selectize.addOption(r.data);    // set
			selectize.refreshOptions(false);
			val = !val ? r.data[0]?.id : val;
			selectize.setValue(val);  // Set selected option(s)
		});
	}

	/**
	 * Check the current selected subtype to match with any input field
	 * that will be shown
	 * @param val
	 * @private
	 */
	_checkTypeOptions(val) {
		let $fields = $('[data-subtype]');
		$fields.hide();
		$fields.map((i, f) => {
			if ($(f).data('subtype').includes(+val)) {
				$(f).show();
			}
		});
	}

	/**
	 * Initialise reference lookup field (AC) globally (once)
	 * This needs to be updated when a new record is created | edited
	 * @private
	 */
	_initialiseReferenceAC() {

		$('#om-select-ac-references').selectize({
			persist: false,
			preload: false,
			placeholder: "Verbinde mit Referenz",
			closeAfterSelect: true,
			allowEmptyOption: true,
			multiple: false,
			valueField: 'id',
			labelField: 'label',
			searchField: ['label'],
			plugins: ['remove_button'],
			create: false,
			load: function (query, callback) {
				$('[data-dynamic="ac-references"]').text('');
				if (!query.length) return callback([]);
				window.omApi.getOption('reference', query, 999).then(r => {
					if (!r.data.length)
						$('[data-dynamic="ac-references"]').text('Keine Referenzen gefunden')
					else
						$('[data-dynamic="ac-references"]').text(`${r.data.length} Referenz${r.data.length > 1 ? 'en' : ''} gefunden`)
					//
					callback(r.data)
				})
			}
			// Todo: empty specific form fields on clear
		});

		// ------
		// On change: refresh reference fields
		// We just load the selected reference record and then mixin the data-fields for display
		// This does not have influence on the DOC record, YET: we may save these fields again!
		// IF we do not want this behaviour, then either the API ignores it or we do nor use .setData() (because it fills the inputs) and just update the DOM instead.
		const refSelectize = $('#om-select-ac-references').get(0).selectize;
		refSelectize.on('change', async (id) => {
			const currentRecord = LibraryElementModel.get();
			if (!id || currentRecord.id === null) return;  // Only update when we have 2 solid records.
			let ref = await window.omApi.getMedia(id);
			ref = ref.data;
			// Now update the form fields again. ISSUE: Overwrite only selected fields of this current DOC (type).
			// Need to enumerate those fields here. Lamest but safest.
			this.setData({
				...LibraryElementModel.get(), ...{
					referenceId: ref.id,
					data: {
						author: ref.data.author,
						editor: ref.data.editor,
						edition: ref.data.edition,
						publishedYear: ref.data.publishedYear,
						publisher: ref.data.publisher,
						volume: ref.data.volume,
						publicationDate: ref.data.publicationDate,
						publicationPlace: ref.data.publicationPlace,
						anthologyTitle: ref.data.anthologyTitle,
						collection: ref.data.collection
					}
				}
			});
		})

	}
}
