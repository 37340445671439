/**
 * Base Class is an utility class where most main classes editor from
 * It will make sure that all ultility-methods are available
 * And that smaller components (e.g. metaNav) are accessible too!
 *
 * TODO: Remove the class, use flat exported methods instead. Easier to use, no multiple initalisations
 * You will see this is halfway done below.
 *
 *
 * TODO: Feature - form validator
 * - https://www.jqueryscript.net/form/alert-form-changed-dirty.html
 * - https://www.npmjs.com/package/simple-form-validation
 * - https://www.npmjs.com/package/jquery-validation
 *
 */
import EventEmitter from "eventemitter3";
import 'selectize/dist/css/selectize.css';
import 'selectize/dist/css/selectize.default.css';
import selectize    from 'selectize';
import 'tippy.js/dist/tippy.css';
import urlParser    from "js-video-url-parser";
import CryptoJS     from "crypto-js";

let thumbnailYoutubeVimeo = require('thumbnail-youtube-vimeo')

/**
 * Our events,enumerated
 * @type {*[]}
 */
export const eventList = {
	NAV_UPDATE:          'NAV_UPDATE',
	NAV_SAVE:            'NAV:SAVE',
	NAV_CANCEL:          'NAV:CANCEL',
	NAV_DELETE:          'NAV:DELETE',
	NAV_STATE:           'NAV:STATE',         // Todo: the nomenclature is no more exact. Should be: NAV_STATUS
	NAV_BUSY:            'NAV_BUSY',
	NAV_DIRTY:           'NAV_DIRTY',
	TASK_ADD:            'TASK:ADD',
	TASK_DELETE:         'TASK_DELETE',
	TASK_UPDATED:        'TASK_UPDATED',
	TASK_KNOWLEGE_READY: 'TASK_KNOWLEGE_READY',             //
	// Knowledge
	KNOWLEDGE_SAVED: 'KNOWLEDGE_SAVED',                     //
	// Knowledge select modal
	OPEN_KN_SELECTOR:      'OPEN_KN_SELECTOR',			        // Open the selector modal for knowledges
	CLOSE_KN_SELECTOR:     'CLOSE_KN_SELECTOR',                 // Close the modal for kn selection
	KN_SELECTOR_OPENED:    'KN_SELECTOR_OPENED',
	KN_SELECTOR_CLOSED:    'KN_SELECTOR_CLOSED',
	KN_SELECTOR_SELECTION: 'KN_SELECTOR_SELECTION',
	// Media
	MEDIA_SELECTION:      'MEDIA_SELECTION',				        // Media has been selected from library modal
	MEDIA_REMOVE:         'MEDIA_REMOVE',
	MEDIA_UPLOAD:         'MEDIA_UPLOAD',
	MEDIAMETA_UPDATED:    'MEDIAMETA_UPDATED',
	MEDIA_EXIF_AVAILABLE: 'MEDIA_EXIF_AVAILABLE',
	MEDIAMODAL_CLOSED:    'MEDIAMODAL_CLOSED',
	MEDIAMODAL_OPENED:    'MEDIAMODAL_OPENED',
	// Commands for media modal
	OPEN_MEDIA_LIBRARY:        'OPEN_MEDIA_LIBRARY',
	OPEN_MEDIA_LIBRARY_META:   'OPEN_MEDIA_LIBRARY_META',
	OPEN_MEDIA_LIBRARY_EDITOR: 'OPEN_MEDIA_LIBRARY_EDITOR', // BULLSHIT. Why?
	CLOSE_MEDIA_LIBRARY:       'CLOSE_MEDIA_LIBRARY',
	// Editor events
	EDITOR_READY:            'EDITOR_READY',
	EDITOR_UPDATE:           'EDITOR_UPDATE',                         // any update within the rte editor (content general)
	EDITOR_SET_LINK:         'EDITOR_SET_LINK',                     // a link needs to be set inline html (component only. todo: shitty)
	EDITOR_SET_LINK_DONE:    'EDITOR_SET_LINK_DONE',           // Editor has added a libraryelement link
	EDITOR_SET_LINK_REMOVED: 'EDITOR_SET_LINK_REMOVED',     // Editor has removed a link
	// SLIDER_UPDATE: 'SLIDER_UPDATE',
	ACTION: 'ACTION'    // Generic event where payload needs {action: ''}
};

/**
 * omCodes is a stupid name for the STATI of a record - used in 'getStatiLabel' for instance
 * @type {{status: [{id: number, title: string},{id: number, title: string},{id: number, title: string},{id: number, title: string}]}}
 */
export const omCodes = {
	// Status codes are declaring a posts visibility
	status: [
		{id: 1, title: 'Interne Vorlage'},
		{id: 2, title: 'Entwurf'},
		{id: 10, title: 'Intern / Bezahlt'},
		{id: 20, title: 'Öffentlich'},
	]
}

/**
 * Placeholders for different types of media. They are just icons for default media
 * @type {{GENERIC: string, IMAGE: string, VIDEO: string, APPLICATION: string, NOT_FOUND: string, DOC: string, CHARACTER: string, AUDIO: string}}
 */
export const omPlaceholders = {
	NOT_FOUND:   '/web/assets/images/notfound_placeholder.png',
	GENERIC:     '/web/assets/icons/icon-generic.svg',
	IMAGE:       '/web/assets/images/image_placeholder.png',
	VIDEO:       '/web/assets/images/video_placeholder.png',
	AUDIO:       '/web/assets/images/audio_placeholder.png',
	DOC:         '/web/assets/images/doc_placeholder.png',
	REF:         '/web/assets/images/ref_placeholder.png',
	APPLICATION: '/web/assets/images/doc_placeholder.png',
	CHARACTER:   '/web/assets/images/character_placeholder.png'
}

/**
 * Enumerated list of our libTypes. In sync with DB Bits (NOT mimeTypes!)
 * @enum
 * @type {({code: number, icon: string, id: string, label: string}|{code: number, icon: string, id: string, label: string}|{code: number, icon: string, id: string, label: string}|{code: number, icon: string, id: string, label: string}|{code: number, icon: string, id: string, label: string})[]}
 * https://wiki.selfhtml.org/wiki/MIME-Type/%C3%9Cbersicht
 */
export const libTypes = [
	{
		code:             1,
		id:               'image',
		label:            'Bild',
		icon:             omPlaceholders.IMAGE, // should be 'placeholder'
		allowedMimeTypes: ['image/jpg', 'image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/tiff']
	},
	{
		code:             16,
		id:               'text',
		label:            'Text',
		icon:             omPlaceholders.DOC,
		allowedMimeTypes: ['application/octet-stream', 'text/plain', 'application/pdf', 'application/rtf', 'application/msword', 'application/mspowerpoint', 'application/vnd.openxmlformats-officedocument', 'application/x-latex']
	},
	{
		code:             128,
		id:               'audio',
		label:            'Audio',
		icon:             omPlaceholders.AUDIO,
		allowedMimeTypes: ['audio/basic', 'audio/mpeg', 'audio/mp4', 'audio/ogg', 'audio/wav', 'audio/x-mpeg']
	},
	{
		code:             1024,
		id:               'video',
		label:            'Video',
		icon:             omPlaceholders.VIDEO,
		allowedMimeTypes: ['video/mpeg', 'video/ogg', 'video/mp4']
	},
	{
		code:             2048,
		id:               'reference',
		label:            'Referenz',
		icon:             omPlaceholders.DOC,
		allowedMimeTypes: ['image/jpg', 'image/png', 'image/jpeg', 'image/gif']
		// allowedMimeTypes: ['application/octet-stream', 'text/plain', 'application/pdf', 'application/rtf', 'application/msword', 'application/mspowerpoint', 'application/vnd.openxmlformats-officedocument', 'application/x-latex']
	},
	// Note:
	// @deprecated.
	{
		code:             16384,
		id:               'character',
		label:            'Person/ Charakter',
		icon:             omPlaceholders.CHARACTER,
		allowedMimeTypes: ['image/jpg', 'image/png', 'image/jpeg', 'image/gif']
	},
	{
		code:             -1,
		id:               'unknown',
		label:            'Unbekannt',
		icon:             omPlaceholders.GENERIC,
		allowedMimeTypes: []
	}
]

/**
 * Simply parse parameters from URLString
 * @param querystring
 * @returns {{}}
 */
export const parseParams = (querystring) => {

	// parse query string
	const params = new URLSearchParams(querystring);

	const obj = {};

	// iterate over all keys
	// and remove trailing slashes
	for (const key of params.keys()) {
		if (params.getAll(key).length > 1) {
			obj[key] = params.getAll(key).replace(/\/$/, "");
		} else {
			obj[key] = params.get(key).replace(/\/$/, "");
		}
	}

	return obj;
};

/**
 * Return a single string (title) of status code (records - task, kn, ws)
 * @param code
 * @returns {*}
 */
export function getStatiLabel(code) {
	return omCodes.status.find(s => s.id === code)?.title
}

/**
 * Get the size of an object
 * @param obj
 * @returns {number}
 */
Object.size = function (obj) {
	var size = 0, key;
	for (key in obj) {
		if (obj.hasOwnProperty(key)) size++;
	}
	return size;
};

/**
 * Flatten a (JSON) object structure to dot (.) props
 * @param data
 * @returns {{}}
 */
Object.flatten = function (data) {
	var result = {};

	function recurse(cur, prop) {
		if (Object(cur) !== cur) {
			result[prop] = cur;
		} else if (Array.isArray(cur)) {
			for (var i = 0, l = cur.length; i < l; i++)
				recurse(cur[i], prop + "[" + i + "]");
			if (l == 0)
				result[prop] = [];
		} else {
			var isEmpty = true;
			for (var p in cur) {
				isEmpty = false;
				recurse(cur[p], prop ? prop + "." + p : p);
			}
			if (isEmpty && prop)
				result[prop] = {};
		}
	}

	recurse(data, "");
	return result;
}

/**
 * Flattened object (json) back to nested
 * @param data
 * @returns {*|{}}
 */
Object.unflatten = function (data) {
	"use strict";
	if (Object(data) !== data || Array.isArray(data))
		return data;
	var regex        = /\.?([^.\[\]]+)|\[(\d+)\]/g,
	    resultholder = {};
	for (var p in data) {
		var cur  = resultholder,
		    prop = "",
		    m;
		while (m = regex.exec(p)) {
			cur = cur[prop] || (cur[prop] = (m[2] ? [] : {}));
			prop = m[2] || m[1];
		}
		cur[prop] = data[p];
	}
	return resultholder[""] || resultholder;
};

/**
 * Sum bits of an array of bits
 * @param array
 * @return {*}
 */
export function sumBits(array) {
	return array.reduce((total, current) => {
		const decimalValue = parseInt(current, 10);
		return total + decimalValue;
	}, 0);
}

/**
 * Deep clone any object
 * TODO: This won't work with very complex structures.
 * @param obj
 * @returns {any}
 */
export function deepClone(obj) {
	return JSON.parse(JSON.stringify(obj))
}

/**
 * UUID Generator
 * @returns {string}
 */
export function uuidv4() {
	return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
		return v.toString(16);
	});
}

/**
 * Debounce a fn by n milliseconds
 * @param func
 * @param timeout
 * @returns {(function(...[*]): void)|*}
 */
export function debounce(func, timeout = 300) {
	let timer;
	return (...args) => {
		clearTimeout(timer);
		timer = setTimeout(() => { func.apply(this, args); }, timeout);
	};
}

/**
 * Encode base64Url for JWT
 * @param source
 * @returns {*}
 */
export function base64url(source) {
	// Encode in classical base64
	let encodedSource = CryptoJS.enc.Base64.stringify(source);

	// Remove padding equal characters
	encodedSource = encodedSource.replace(/=+$/, '');

	// Replace characters according to base64url specifications
	encodedSource = encodedSource.replace(/\+/g, '-');
	encodedSource = encodedSource.replace(/\//g, '_');

	return encodedSource;
}

/**
 * Return a formatted date for DropDowns / Inputs
 * Pad 0s
 * @param date
 * @returns {string} YYYY-MM-DD
 */
export function getDateFormatted(date) {
	let day = date.getDate();
	let month = date.getMonth();
	month = month + 1;
	if ((String(day)).length == 1)
		day = '0' + day;
	if ((String(month)).length == 1)
		month = '0' + month;

	// return day + '-' + month + '-' + date.getFullYear();
	return `${ date.getFullYear() }-${ month }-${ day }`;
}

/**
 * Return (another) formatted date. This time human readable
 * @param date
 * @returns {string} DD.MM.YYYY
 */
export function formatDate(date) {
	const day = String(date.getDate()).padStart(2, '0');
	const month = String(date.getMonth() + 1).padStart(2, '0');
	const year = String(date.getFullYear());

	return `${ day }.${ month }.${ year }`;
}


/**
 * Compare dates and return the difference in days
 * @param first DATE object
 * @param second DATE object
 * @param dist STRING months | days
 * @returns {number} | INT
 */
export function compareDates(first, second, dist = 'days') {

	if (dist === 'months')
		return Math.abs((new Date(second).getFullYear() - new Date(first).getFullYear()) * 12 + (new Date(second).getMonth() - new Date(first).getMonth()));

	//
	// Copy date parts of the timestamps, discarding the time parts.
	const one = new Date(first.getFullYear(), first.getMonth(), first.getDate());
	const two = new Date(second.getFullYear(), second.getMonth(), second.getDate());

	// Do the math.
	const millisecondsPerDay = 1000 * 60 * 60 * 24;
	const millisBetween = two.getTime() - one.getTime();
	const days = millisBetween / millisecondsPerDay;

	// Round down.
	return Math.floor(days);
}

/**
 * This downloads a file with OAuth 2.0
 * Not yet implemented (we use direct downloads)
 * @param id
 * @returns {Promise<null|any>}
 */
// export async function checkGoogleDocId(id) {
// 	let accessToken = 'TOGET'; // Todo.
// 	let response = await fetch(`https://www.googleapis.com/drive/v3/files/${id}`, {
// 		headers: {
// 			'Authorization': 'Bearer ' + accessToken,
// 			'Accept': 'application/json'
// 		}
// 	});
//
// 	if (response.ok) {
// 		let data = await response.json();
// 		return data;
// 	} else {
// 		console.error(`Request failed with status ${response.status}: ${response.statusText}`);
// 		return null;
// 	}
// }

/**

/**
* Base that provides utilities and global events
* DO NOT EXTEND THIS, it is used via injection to all main classes
* Refer to this as: base.METHOD etc.
* TODO: move all fn out of here and use exported fn only. So we do not need injection or instantiation of this class
 */
export class Base extends EventEmitter {

	constructor() {
		super();
		this.NAVIGATION = null;
		this.USER = null;
	}

	/**
	 * Return all form data as serialised JSON object
	 * This will convert form tuples to JSON and unflatten any form fields with dot (.)
	 * prop.subprop --> prop { subprop : value }
	 * Todo: a general issue is using data-name conventions (e.g. editor | libElement) which may be picked up here. This means we have superflous fields sent to api all the time (e.g. w, h, sizes)
	 * @param $el
	 * @returns JSON
	 */
	getFormData($el, unflatten = false) {
		let objectData = {};
		let data = this.serializeAllArray($el);
		$(data).each((obj, item) => {
			// Filter unwanted fields. (null should never be there)
			if (item.name !== 'null' && item.name !== 'dirtyFlag') {
				// Multiselects -- they have a name with []. Collect items
				if (item.name.includes('[]')) {
					item.name = item.name.substr(0, item.name.length - 2);
					if (!objectData[item.name])
						objectData[item.name] = [];
					objectData[item.name].push(item.value);
				} else {
					objectData[item.name] = item.value;
				}
				// console.log(item.name + ':' + item.value, objectData)
				return objectData;
			} else {
				return {}
			}
		});

		// Restructure json
		data = (unflatten) ? Object.unflatten(objectData) : objectData;
		return data;
	}

	/*
	 To get all Multiselects:
	 getFormData($el, unflatten = false) {
	 let objectData = {};
	 let data = this.serializeAllArray($el);
	 $(data).each((index, item) => {
	 // Filter unwanted fields. (null should never be there)
	 if (item.name !== 'null' && item.name !== 'dirtyFlag') {
	 // Multiselects -- they have a name with []. Collect items
	 if (Array.isArray(objectData[item.name])) {
	 // If it's already an array, push the value
	 objectData[item.name].push(item.value);
	 } else if (objectData[item.name]) {
	 // If it's already set as a single value, convert it to an array
	 objectData[item.name] = [objectData[item.name], item.value];
	 } else if (item.name.includes('[]')) {
	 // If the name includes '[]', handle it as an array
	 const name = item.name.replace('[]', '');
	 objectData[name] = [item.value];
	 } else {
	 // Otherwise, set it as a single value
	 objectData[item.name] = item.value;
	 }
	 }
	 });
	 return objectData;
	 }

	 */

	/**
	 * Extend jQuery's form serialiser to include hidden / disabled fields
	 * @param $el
	 * @returns {jQuery}
	 */
	serializeAllArray($el) {
		let data = $el.serializeArray();
		// Add disabled form fields as well.
		$(':disabled[name]', $el).each(function () {
			data.push({name: this.name, value: $(this).val()});
		});
		return data;
	}

	/**
	 * Populate a form from JSON
	 *
	 * try this: https://github.com/therealparmesh/object-to-formdata
	 * or this: https://github.com/maxatwork/form2js
	 * @param frm $jQuery object of the form id
	 * @param data JSON data
	 * @param flatten BOOL if true, then make a flat array of our json
	 */
	populate(frm, data, flatten = true) {

		// Flattened multiselects are troublesome!
		let flatData = flatten ? Object.flatten(data) : data;
		// NOTE: If data is flattened, some arrays | multiselects will not be recognised
		// The whole issue to flatten data arises from the API which sometimes wants flat data
		// This is a hacky approach to a non-standard / non-existing clean REST api
		// populating form values from json object
		for (let name in flatData) {
			let val = flatData[name];
			let $el = $(`[name="${ name }"]`, frm), //document.getElementsByName(name),
			    type,
			    node;

			if ($el && $el.length) {
				node = $el[0].nodeName.toLowerCase();
				switch (node) {
					case "input":
						type = $el[0].getAttribute('type')
						switch (type) {
							case 'checkbox':
								if (val === true || val === false || val === 'checked') {
									$el.checked = val || ''
								} else if (val.length) {
									val = val.split(",");
									val.map(function (v) {
										for (let i = 0; i < $el.length; i++) {
											$el[i].value === v ? $el[i].checked = true : '';
										}
									});
								}
								break;
							case 'radio':
								for (let i = 0; i < $el.length; i++) {
									$el[i].value === val ? $el[i].checked = true : '';
								}
								break;
							default:
								$el[0].value = val;
								break;
						}
						break;
					case "select":
						// Use selectize
						let selectize  = $el[0].selectize,
						    targetOpts = $el.data('options') || {};
						// THIS WILL NOT WORK FOR ARRAYS name[]!
						// Init selectize if not set
						if (!selectize) {
							// Create options
							let opts = {
								...{
									create:  false,
									plugins: (targetOpts.multiple) ? ['remove_button'] : [],
								}, ...targetOpts
							}
							// Apply selectize
							$el.selectize(opts);
							selectize = $el[0].selectize;
						}

						// Check single or multiple
						if (targetOpts.multiple) {
							let _s = val.map(r => r.id);
							selectize.setValue(_s || []);  // Set selected option(s)
						} else if (val) {
							if (val.id)
								selectize.setValue(val.id);  // Set selected option(s)
							else
								selectize.setValue(val);  // Set selected option(s)
						}


						break;
					case "textarea":
						$el.html(val);
						break;
					default:
						// Assuming DIV! (RTE)
						val = this.htmlDecode(val)
						$el.html(val);
						break;
				}
			}
		}
	}

	/**
	 * Returns the LAST segment of current URL
	 * Used for our ID's
	 */
	getUrlSegment() {
		return window.location.pathname.substring(window.location.pathname.lastIndexOf("/") + 1);
	}

	/**
	 * Return a requested query parameter from an url
	 * @param parameterName string
	 * @return {null}
	 */
	findGetParameter(parameterName) {
		let result = null,
		    tmp    = [];
		let items = location.search.substr(1).split("&");
		for (let index = 0; index < items.length; index++) {
			tmp = items[index].split("=");
			if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
		}
		return result;
	}

	/**
	 * Init select boxes with selectize
	 */
	createSelectBoxes() {
		// We only init those fields that have a data-init attribute
		// Leaving other classes to control special dd fields (e.g. autocomplete)
		$('[data-init=selectize]').map((i, el) => {
			let targetOpts = $(el).data('options') || {},
			    opts       = {
				    ...{
					    create:  false,
					    plugins: (targetOpts.multiple) ? ['remove_button'] : [],
				    }, ...targetOpts
			    };

			$(el).selectize(opts);
		});
	}

	/**
	 * Return a general type of record (main types)
	 * @param type
	 * @returns {string}
	 */
	getGeneralType(type) {
		switch (type) {
			case 1:
				return 'Aufgabe';
				break;
			case 2:
				return 'Wissensbeitrag';
				break;
			case 4:
				return 'Glossar';
				break;
			case 8:
				return 'Arbeitsblatt';
				break;
			case 16:
				return 'Text/ Caption';
				break;
			case 32:
				return 'Charakter';
				break;
		}
	}

	/**
	 * Return a string of the current mime type (simplified!)
	 * @param type INT
	 * @returns {*}
	 */
	getLibElementType(type) {
		let r = libTypes.find(lt => lt.code === +type);
		return r || libTypes.find(lt => lt.code === -1);
	}

	/**
	 * Return info about a knowledge type
	 * These are abundant types! Can be anything from glossary to worksheet
	 * (since we decided to make everything a type of KN)
	 * @param type
	 * @return {{icon: string, id: string, label: string, type: number}}
	 */
	getKNElementType(type) {
		let r = {type: -1, id: 'record', label: 'Datensatz', icon: '<i uk-icon="icon: file-text"></i>'};
		switch (+type) {
			case 2:
				r = {
					type:        +type,
					id:          'knowledge',
					label:       'Wissensbeitrag',
					labelPlural: 'Wissensbeiträge',
					icon:        '<i uk-icon="icon: thumbnails"></i>',
					slug:        '/wissensbeitragsliste',
					slugEdit:    '/wissensbeitrag'      // Todo: is this right?
				};
				break;
			case 4:
				r = {
					type:        +type, id: 'glossary',
					label:       'Glossareintrag',
					labelPlural: 'Glossareinträge',
					icon:        '<i uk-icon="icon: info"></i>',
					slug:        '/glossarliste',
					slugEdit:    '/glossar'
				};
				break;
			case 8:
				r = {
					type:        +type,
					id:          'worksheet',
					label:       'Arbeitsblatt',
					labelPlural: 'Arbeitsblätter',
					icon:        '<i uk-icon="icon: pencil"></i>',
					slug:        '/arbeitsblaetter', slugEdit: '/arbeitsblatt'
				};
				break;
			case 16:
				r = {
					type:        +type,
					id:          'placeholder',
					label:       'Beschreibungstext',
					labelPlural: 'Beschreibungstexte',
					icon:        '',
					slug:        '',
					slugEdit:    ''
				};
				break;
			case 32:
				r = {
					type:        +type,
					id:          'character',
					label:       'Hero / Charakter',
					labelPlural: 'Heroes / Charaktere',
					icon:        '<i uk-icon="icon: users"></i>',
					slug:        '/heros',
					slugEdit:    '/wissensbeitrag'
				};
				break;
			case 64:    //@deprecated
				r = {
					type:        +type,
					id:          'citation',
					label:       'Zitat',
					labelPlural: 'Zitate',
					icon:        '<i uk-icon="icon: comment"></i>',
					slug:        '/zitate',
					slugEdit:    '/zitat'
				};
				break;
		}
		return r;
	}

	/**
	 * Decode urlencoded html query-strings
	 * @param input
	 * @return {string|string}
	 */
	htmlDecode(input) {
		let e = document.createElement('div');
		e.innerHTML = input;
		return e.childNodes[0] ? e.childNodes[0].nodeValue : '';
	}

	stripHtml(html) {
		let doc = new DOMParser().parseFromString(html, 'text/html');
		return doc.body.textContent || "";
	}

	/**
	 * Set a hidden input field (NEEDS to be in any form) to wake up the jquery dirty plugin
	 */
	forceDirty() {
		if ($('[name="dirtyFlag"]').length) {
			$('[name="dirtyFlag"]').val(Math.random()).change();
		}
	}

	/**
	 * Check an url string, additionally try to find out what provider the link comes from
	 * This list will be @enumerated and according to the omProviders() return the code of the external 'thing'
	 *
	 * @param str
	 * @return {{valid: boolean, locationType: {provider, id, code, mimeType}}}
	 */
	checkURL(str) {
		const pattern = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
		// What/who is the provider (enumerated!)
		let {provider, mimeType, locationType} = this.getProvider(str);
		return {valid: !!pattern.test(str), locationType: locationType, provider: provider, mimeType: mimeType, uri: str};
	}

	/**
	 * Get origin, file type and mime type of a URL,
	 * currently only youtube|vimeo
	 * TODO: deprecate
	 *
	 * @param url
	 * @return {{ provider, id, code, mimeType}}
	 *
	 */
	getProvider(url) {
		// Unknown
		if (!url) return {provider: 'unknown', locationType: 0, mimeType: ''};

		// (1) VIDEO matcher
		let retVal = {provider: 'unknown', mimeType: null}, matches;
		let parsed = urlParser.parse(url)

		if (parsed && parsed.provider === 'youtube') {
			retVal = {provider: "youtube", id: parsed.id, locationType: 4, mimeType: 'video/mp4'};
		} else if (parsed && parsed.provider === 'vimeo') {
			retVal = {provider: "vimeo", id: parsed.id, locationType: 8, mimeType: 'video/mp4'};
		} else
			retVal = {provider: "www", id: '', locationType: 2, mimeType: 'text/html'};


		return (retVal);
	}

	/**
	 * Extract a youtube id, universally used
	 */
	getYoutubeId(url) {
		const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
		let match = url.match(regExp);
		return (match && match[7].length == 11) ? match[7] : false;
	}

	/**
	 * @param url
	 * @return {string|null}
	 */
	getVimeoId(url) {
		const regExp = /(?:https?:\/\/)?(?:www\.)?vimeo.com\/(?:manage\/(?:\w+\/)?|groups\/(?:\w+\/)?|groups\/([^\/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/
		// const regExp = /^.*(vimeo\.com\/)((channels\/[A-z]+\/)|(groups\/[A-z]+\/videos\/))?([0-9]+)/
		let match = regExp.exec(url)
		return (match) ? match[3] : null;
	}

	/**
	 * Tries to get the preview poster image of any video
	 * Uses private methods
	 *
	 * @param url
	 * @return {src: string, exif: json }
	 */
	getVideoPoster(url) {
		return new Promise((resolve) => {
			let {mimeType, locationType} = this.getProvider(url);
			// We will not get exif data! (see _getVimeoPoster() to achieve this)
			thumbnailYoutubeVimeo(url)
			.then(
				result => {
					resolve({src: result, exif: null, locationType: locationType, mimeType: mimeType})
				},
				err => {
					console.warn('YT preview not loaded ', err, url)
					resolve({
						src:          omPlaceholders.VIDEO,
						exif:         null,
						locationType: locationType,
						mimeType:     mimeType
					});
				}
			);

		});
	}

	/**
	 * Return an embeddable media object : video
	 * This is built for UIKit : https://getuikit.com/docs/video
	 * @param url
	 * @param locationType
	 * @param mimeType
	 * @return {string}
	 */
	getVideoObject(url, locationType, mimeType) {
		let tpl = '';
		// NOTE: the whole locationType concept does more harm than good. Try to dynamically parse the content.
		// Workaround -- as long there is no locationType from DB, get it from the URL
		if (!locationType) locationType = this.getProvider(url).locationType;
		// Switch by locationType
		if (locationType === 1 || locationType === 2) {
			// internal Todo: mime types.
			tpl = `<video controls playsinline>
				<source src="${ url }" type="video/mp4"/>
			</video>`;
		} else if (locationType === 4) {
			// Youtube
			let _vUrl = 'https://www.youtube-nocookie.com/embed/' + this.getYoutubeId(url); //.replace("watch?v=", "embed/")
			tpl = `<iframe src="${ _vUrl }?controls=1&showinfo=0rel=0&loop=0&modestbranding=1&wmode=transparent&playsinline=1" frameborder="0" allowfullscreen uk-cover></iframe>`
		} else if (locationType === 8) {
			// Vimeo
			url = `https://player.vimeo.com/video/${ this.getVimeoId(url) }`;
			tpl = `<iframe src="${ url }" frameborder="0" allowfullscreen ></iframe>`
		}
		return tpl;
	}

	/**
	 * Keep newLine from textInputs and show it as <br/>
	 * @param str
	 * @param is_xhtml
	 * @return {string}
	 */
	nl2br(str, is_xhtml) {
		var breakTag = (is_xhtml) ? '<br />' : '<br>';
		var replaceStr = '$1' + breakTag  //'$1'+ breakTag +'$2';
		return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, replaceStr);
	}

	isBase64String(str) {
		const base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;
		return base64regex.test(str);
	}

	/**
	 * Try and convert a JSON string to a JSON object
	 * Note: the issue here it's not checking for any validity other than convertability
	 * @param str
	 * @return {any}
	 */
	convertToJson(str) {
		try {
			JSON.parse(str);
		} catch (e) {
			return str;
		}
		return JSON.parse(str);
	}

	/**
	 * Return a vimeo poster img url
	 * TODO: This contains a JSON with more info (meta data), why not use it as pendent to getExif?
	 * @param id
	 * @private
	 */
	// _getVimeoPoster(id) {
	// 	// TODO: This will stop @jQuery undefined. Must be an issue with ES6 scope
	// 	let uri = 'https://www.vimeo.com/api/v2/video/' + id + '.json?callback=?'; // ?callback=showThumb";
	// 	return new Promise((resolve, reject) => {
	// 		// TODO: throws 'jQuery is not defined' error
	// 		$.getJSON(uri, {format: "json"}, function (data) {
	// 			resolve({src: data[0].thumbnail_large, exif: data[0]});
	// 		}).fail(() => {
	// 			// TODO: notify user
	// 			console.warn('wrong URI')
	// 			reject();
	// 		});
	// 	})
	// }

	// Capture a frame of a uploaded video NYI
	// _capturePreview(file, seekTo = 0.0) {
	// 	// var canvas = document.getElementById('canvas');
	// 	// var video = document.getElementById('video');
	// 	// canvas.getContext('2d').drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
	//
	// 	console.log("getting video cover for file: ", file);
	// 	return new Promise((resolve, reject) => {
	// 		// load the file to a video player
	// 		const videoPlayer = document.createElement('video');
	// 		videoPlayer.setAttribute('src', URL.createObjectURL(file));
	// 		videoPlayer.load();
	// 		videoPlayer.addEventListener('error', (ex) => {
	// 			reject("error when loading video file", ex);
	// 		});
	// 		// load metadata of the video to get video duration and dimensions
	// 		videoPlayer.addEventListener('loadedmetadata', () => {
	// 			// seek to user defined timestamp (in seconds) if possible
	// 			if (videoPlayer.duration < seekTo) {
	// 				reject("video is too short.");
	// 				return;
	// 			}
	// 			// delay seeking or else 'seeked' event won't fire on Safari
	// 			setTimeout(() => {
	// 				videoPlayer.currentTime = seekTo;
	// 			}, 200);
	// 			// extract video thumbnail once seeking is complete
	// 			videoPlayer.addEventListener('seeked', () => {
	// 				console.log('video is now paused at %ss.', seekTo);
	// 				// define a canvas to have the same dimension as the video
	// 				const canvas = document.createElement("canvas");
	// 				canvas.width = videoPlayer.videoWidth;
	// 				canvas.height = videoPlayer.videoHeight;
	// 				// draw the video frame to canvas
	// 				const ctx = canvas.getContext("2d");
	// 				ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
	// 				// return the canvas image as a blob
	// 				ctx.canvas.toBlob(
	// 					blob => {
	// 						resolve(blob);
	// 					},
	// 					"image/jpeg",
	// 					0.75 /* quality */
	// 				);
	// 			});
	// 		});
	// 	});
	//
	// }


	/** Some shitty print fn. Opening up a modal and trying to inject html from a selected DIV.
	 * @param html
	 * @returns {boolean}
	 */
	printElement(html) {
		var mywindow = window.open('', 'PRINT', 'height=400,width=600');

		mywindow.document.write('<html><head><title>' + document.title + '</title>');
		// mywindow.document.write('<link rel="stylesheet" href="print.css" type="text/css" />');
		mywindow.document.write('</head><body >');
		mywindow.document.write('<h1>' + document.title + '</h1>');
		mywindow.document.write(html);
		mywindow.document.write('</body></html>');

		mywindow.document.close(); // necessary for IE >= 10
		mywindow.focus(); // necessary for IE >= 10*/

		mywindow.print();
		mywindow.close();

		return true;
	}

}
